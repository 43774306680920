import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../button/button-st1';
import Slider from '../../slider'
import './styles.scss'
import { useContext } from 'react';
import {Context} from '../../../contexts/GlobalContext'
import SignUpPrompt from '../../overlay-prompts/sign-up';
import Agent from '../../../pages/SpeedRun/title';
import { FlowButton } from '../../flow-ui/flow-blocks';

Banner.propTypes = {
  item: PropTypes.object
}

function BannerOld(props) {
    const { globalState, setGlobalState } = useContext(Context)
    var link = '/createItem'
    if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
        link = '#'
    }
    return (
        <div className="themes-container2 themes-slider" style={{backgroundColor: 'white'}}>
            <div className="flat-wrap" style={{border: 'none'}}>
                <div className="wrap-heading flat-slider flex" style={{backgroundColor: 'transparent', height: '540px', position: 'relative'}}>
                    <div className='bannerImage'>
                        <Slider/>
                    </div>
                    <div className="content" style={{backgroundColor: 'transparent', margin: '0', marginTop: '80px'}}>
                        <h1 className="heading" style={{fontSize: '27px', color: 'rgb(40, 40, 40)', fontWeight: 400, textTransform: 'none'}}>
                            {/* <strong>Sellagen lets you <ScrollingText/> data</strong> */}
                            <strong>
                                <span style={{fontSize: '27px', lineHeight: 1, display: 'block'}}>
                                    <i 
                                        className="fas fa-bolt" 
                                        style={{
                                            fontSize: '80%',
                                            color: 'rgb(29, 191, 113)',
                                            textShadow: '0 0 15px rgba(29, 191, 113, 0.5)'
                                        }}
                                        aria-hidden={true}
                                    ></i>
                                    &nbsp;&nbsp;AI-Driven<br/>&nbsp;
                                </span>
                                <span style={{fontSize: '60px', lineHeight: 1}}>
                                    Marketplace Infrastrucure&nbsp;
                                    {/* <span style={{fontSize: '32px'}}>for</span> */}
                                </span>
                                <div style={{
                                    fontSize: '28px',
                                    lineHeight: 1, display: 'block',
                                    fontWeight: 500,
                                    fontFamily: "'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace",
                                    position: 'relative',
                                    marginTop: '5px',
                                    marginBottom: '10px'
                                }}>
                                    <span style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <span style={{fontSize: '20px', fontWeight: 700}}>for&nbsp;</span>
                                        <span style={{
                                            backgroundColor: 'rgb(40, 44, 52)',
                                            color: 'rgb(29, 191, 113)',
                                            borderRadius: '10px',
                                            lineHeight: 1.2,
                                            padding: '5px 15px'
                                        }}>
                                            Data and Machine Learning
                                        </span>
                                    </span>
                                    <br/>&nbsp;
                                </div>
                            </strong>
                        </h1>
                        <p className="sub-heading" style={{backgroundColor: 'transparent', fontWeight: 500, textTransform: 'none'}}>
                            Buy, sell, request, subscribe to data as well as train and deploy your AI models with datasets from wide variety of data suppliers. Easily access, contribute, and collaborate on open-source datasets.
                            {/* Easily access, monetize and share data and become part of a community where buyers and sellers interact today! */}
                        </p>
                        <div className="flat-bt-slider flex style2" style={{backgroundColor: 'transparent'}}>
                            <Button
                                title='Search Dataset' addclass='sc-button style-2 btn-2' path='/search'
                                style={{
                                    border: 'none', boxShadow: 'none', borderRadius: '10px', backgroundColor: 'rgb(29, 191, 113)',
                                    marginRight: '20px'
                                }}
                                style2={{color: 'rgb(246, 248, 250)'}}
                            />
                            <Button
                                title='Sell a Gen' addclass='sc-button style-2 btn-5' path={link}
                                style={{backgroundColor: 'rgb(246, 248, 250)', border: 'none', boxShadow: 'none', borderRadius: '10px'}}
                                style2={{color: 'rgb(29, 191, 113)'}}
                                onClick={() => {
                                    if(link === '#') {
                                        setGlobalState({overlayContent: <SignUpPrompt
                                            title={'Sign Up'}
                                            msg={
                                                <>You need to sign up before buying or selling any data. Already have an account? <strong><a href='/login' className="sellagen-propmt-link">Sign In</a></strong> instead.</>
                                            }
                                        />})
                                    }
                                }}
                            />
                        </div>
                    </div>
                 </div>
            </div>
        </div>
    );
}

function Banner(props) {
  const { globalState } = useContext(Context)
  return (
    <div className="flat-slider" style={{width: '100%', maxWidth: '650px'}}>
      <h1 className="heading" style={{fontSize: '28px', color: 'rgb(40, 40, 40)', fontWeight: 400, textTransform: 'none'}}>
        <FlowButton
          style={{fontSize: '16px', backgroundColor: 'rgb(240, 248, 250)', color: 'rgb(29, 191, 113)', filter: 'brightness(100%)', cursor: 'default'}}
        >Nelima</FlowButton><br/><br/>
        <span style={{
          fontSize: '18px', lineHeight: 1, display: 'block', fontWeight: 500,
          color: 'rgb(60, 60, 60)'
        }}>
          Community-Driven<br/>&nbsp;
        </span>
        <span style={{fontSize: '56px', lineHeight: 1.2, fontFamily: 'Roboto Mono'}}>
          Large Action Model&nbsp;
        </span>
      </h1>
      <br/>
      <p className="sub-heading" style={{
        fontWeight: 500, textTransform: 'none', marginTop: '25px', marginBottom: '40px',
        fontSize: '18px', color: '#706c83'
      }}>
        Nelima is the first ever community-led conversational AI that can execute actions.
        Analyze data, publish a website, browse the web or even ask Nelima to keep an eye on incoming package deliveries!
        {/* Buy, sell, request, subscribe to data as well as train and deploy your AI models with datasets from wide variety of data suppliers. Easily access, contribute, and collaborate on open-source datasets. */}
        {/* Easily access, monetize and share data and become part of a community where buyers and sellers interact today! */}
      </p>
      <div className="flat-bt-slider flex style2">
        <Button
          title='Explore Nelima' addclass='sc-button style-2 btn-2' path='/nelima'
          style={{
            border: 'none', boxShadow: 'none', borderRadius: '10px', backgroundColor: 'rgb(29, 191, 113)',
            marginRight: '20px'
          }}
          style2={{color: 'rgb(246, 248, 250)'}}
        />
        <Button
          title='Try Now' addclass='sc-button style-2 btn-5' path={'#'}
          style={{backgroundColor: 'rgb(246, 248, 250)', border: 'none', boxShadow: 'none', borderRadius: '10px'}}
          style2={{color: 'rgb(29, 191, 113)'}}
          onClick={() => {
            if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
              window.location.href = '/register'
            } else {
              window.location.href = '/nelima'
            }
          }}
        />
      </div>
    </div>
  )
}

export default Banner