import React, {useState, useContext, useEffect} from 'react'
import '../scss/components/section.scss'
import Header from '.././components/header/header.jsx'
import axios from '../utilities/axios'
import Footer from '../components/footer/footer-v1'
import dataFooter from '../assets/fake-data/data-footer'
import Bottom from '../components/bottom'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import Spinner from '../components/spinner'

import img from '../assets/images/login.svg'
import { Context } from '../contexts/GlobalContext'
import { useIsMounted } from '../utilities/hooks.js'
import { stringCleanup } from '../utilities/handyFunctions.js'

function Register(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [userName, setUserName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [business, setBusiness] = useState('')
  const [isBusiness, setIsBusiness] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSpecialLoading, setIsSpecialLoading] = useState(false)
  const [specialError, setSpecialError] = useState('')
  const { globalState } = useContext(Context)
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const step = queryParams.get('continue') || ''

  function submitDetails() {
    let payload = { email, password, firstName, lastName, business, userName }
    if(!isLoading) {
      setIsLoading(true)
      axios.post('user/new', payload).then(response => {
        if(response.data && response.data.email) {
          if(step === 'integrate') {
            window.location.href = '/login?continue=integrate'
          } else {
            window.location.assign('/signup-success')
          }
        } else {
          setIsLoading(false)
        }
      }).catch(err => {
        setIsLoading(false)
        console.log(err)
      })
    }
  }

  const userNameChecker = (value) => {
    if(!value) {
      setSpecialError('')
      setIsSpecialLoading(false)
      return
    }
    if(value.length < 8) {
      setSpecialError('Username should have atleast eight characters')
      return
    }
    if(!isSpecialLoading) {
      let payload = {value}
      setIsSpecialLoading(true)
      axios.post('user/exists', payload, {withCredentials: false}).then(response => {
        if(isMounted) {
          if(response?.data?.exists === false) {
            setSpecialError('Username available')
          } else if(response?.data?.exists === true) {
            setSpecialError('Username already exists')
          } else {
            setSpecialError('Failed to verify username')
          }
          setIsLoading(false)
        }
      }).catch(err => {
        setIsLoading(false)
        setSpecialError('Failed to verify username')
        console.log(err)
      })
    }
  }

  useEffect(() => {
    if(globalState && globalState.user) {
      navigate('/')
    }
  })

  return (
    <div className='wrapper-style' id='wrapper'>
      {<Header />}
      <div className="tf-section flat-login flat-auctions-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wrap-login" >
                <div className="box-login post">
                  <img src={img} alt="Sellagen Login" 
                    className="absolute mark-login sellagen-login-img"
                  />
                  <div className="heading-login">
                    <h2 className="fw-5">Sign Up</h2>
                    <div className="flex"><p>Already have an account? </p><Link to={`/login${step === 'integrate' ? '?continue=integrate': ''}`} className="text-p text-color-4 fw-6">Sign In</Link></div>
                  </div>
                  <form id="contactform"
                    style={{marginTop: '-10px'}}
                    onSubmit={(event) => {
                      event.preventDefault()
                      submitDetails()
                      return false
                    }}
                  >
                    <div className="form-login flat-form flex-one">
                      <div className="info-login">
                        {/* <fieldset>
                          <p className="title-infor-account">Account Type</p>
                          <div style={{
                            width: '100%',
                            display: 'flex',
                            marginTop: '5px',
                            marginBottom: '10px'
                          }}>
                            <div className={`sellagen-selector ${isBusiness ? '' : 'sellagen-selected'}`}
                              style={{marginRight: '10px'}}
                              onClick={() => setIsBusiness(false)}
                            >
                              <i className="far fa-user" style={{fontSize: 20}}></i><br/>
                              <span>Individual</span>
                            </div>
                            <div className={`sellagen-selector ${isBusiness ? 'sellagen-selected' : ''}`}
                              style={{marginLeft: '10px'}}
                              onClick={() => setIsBusiness(true)}
                            >
                              <i className="far fa-briefcase" style={{fontSize: 20}}></i><br/>
                              <span>Business</span>
                            </div>
                          </div>
                        </fieldset> */}
                        {/* {
                          isBusiness ? 
                          <>
                            <fieldset>
                              <p className="title-infor-account">Business Name</p>
                              <input 
                                  type="text" 
                                  id="name" 
                                  className="tb-my-input" 
                                  name="John" 
                                  placeholder="Business Inc." 
                                  aria-required="true"
                                  required=""
                                  onChange={event => { setBusiness(event.target.value) }}
                              />
                            </fieldset>
                            <br/>
                            <br/>
                            <fieldset>
                              <h3>Representative Details</h3>
                            </fieldset>
                          </>
                          :
                          <>
                            <fieldset>
                                <p className="title-infor-account">Username
                                  <span style={{float: 'right', fontSize: '10px', fontWeight: 600, ...specialError === 'Username available' ? {color: 'rgb(29, 191, 113)'} : {color: 'crimson'}}}>{specialError}</span>
                                </p>
                                <input 
                                    value={userName}
                                    type="text"
                                    id="name" 
                                    className="tb-my-input" 
                                    name="appleseed" 
                                    placeholder="example"
                                    aria-required="true" 
                                    required=""
                                    onChange={event => {
                                      let val = stringCleanup(event.target.value)
                                      setUserName(val)
                                      userNameChecker(val)
                                    }}
                                />
                            </fieldset>
                          </>
                        } */}
                        <fieldset>
                            <p className="title-infor-account">First Name</p>
                            <input 
                                type="text" 
                                id="name" 
                                className="tb-my-input" 
                                name="John" 
                                placeholder="John" 
                                aria-required="true" 
                                required=""
                                onChange={event => { setFirstName(event.target.value) }}
                            />
                        </fieldset>
                        <fieldset>
                            <p className="title-infor-account">Last Name</p>
                            <input 
                                type="text" 
                                id="name" 
                                className="tb-my-input" 
                                name="Appleseed" 
                                placeholder="Appleseed" 
                                aria-required="true" 
                                required=""
                                onChange={event => { setLastName(event.target.value) }}
                            />
                        </fieldset>
                        <fieldset>
                          <p className="title-infor-account">Email</p>
                          <input
                            value={email}
                            type="email"
                            name="email"
                            id="email"
                            placeholder='example@email.com'
                            aria-required="true"
                            required
                            onChange={event => { setEmail(event.target.value) }}
                          />
                        </fieldset>
                        <fieldset className="style-pass">
                          <p className="title-infor-account">Password</p>
                          <div style={{
                            fontSize: '10px',
                            backgroundColor: 'rgb(246, 248, 250)',
                            borderRadius: '5px',
                            padding: '10px',
                            margin: '5px 0 10px 0'
                          }}><strong>Password should contain:</strong>
                            <ul style={{listStyleType: 'circle', marginTop: '5px', marginLeft: '5px'}}>
                              <li>At least One Lower Case</li>
                              <li>At least One Upper Case</li>
                              <li>At least One Number</li>
                              <li>At least One Symbol: !@#$%^&*_=+-</li>
                              <li>Password length should be 8 to 24 characters</li>
                            </ul>
                          </div>
                          <input 
                            value={password}
                            type="password" 
                            name="password" 
                            id="password" 
                            placeholder="•••••••••••••••"
                            aria-required="true"
                            required 
                            pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,24}$'
                            onChange={event => { setPassword(event.target.value) }}
                          />
                        </fieldset>
                        <br/><br/><br/>
                        <button className="submit button-login">
                          {isLoading ? <Spinner /> : 'Sign Up'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {<Footer data={dataFooter}/>}
      {<Bottom classection='widget-social-footer'/>}
    </div>
  );
}

export default Register;