import React from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';

function PrivacyPolicy(props) {
    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <div className='sellagen-fact'>
                <h2 className="sellagen-fact-title"><strong>Privacy Policy</strong></h2>
                <br/>
                <p>These This privacy policy (“Policy”) outlines how Sellagen Inc. ("Sellagen," "we," "us," or "our") collects, uses, shares, and protects information about you when you access and use our website, products, and services (collectively, the "Services"). </p>
                <br/>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Our Services</p>
                    <p>Sellagen offers large action model software named Nelima, accessible through our website at https://sellagen.com and associated internet domains.</p>
                <br/>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Information Sellagen Collects</p>
                    <p>When you interact with our website, we may automatically collect certain information from your device, including your IP address, device type, unique device identification numbers, browser type, broad geographic location (e.g., country or city-level location), and other technical details. This information helps us understand our website visitors, their origins, and their interests, enabling us to improve our service quality and relevance.</p><br/>
                    <p>In the past 12 months, we have collected various categories of personal data, which are shared with specific third parties. The personal data categories we collect include:</p><br/>
                    <p><strong>Profile or Contact Data:</strong> This includes first and last names, email addresses, and phone numbers. These data types are shared with service providers and parties you authorize, access, or authenticate.</p><br/>
                    <p><strong>Device/IP Data:</strong> This encompasses IP addresses, device IDs, and the types of devices/operating systems/browsers used to access our services. These data types are shared with service providers and analytics partners.</p><br/>
                    <p><strong>Web Analytics:</strong> We collect data on web page interactions, the referring webpage/source through which you accessed our services, non-identifiable request IDs, and statistics associated with the interaction between your device or browser and our services. These data types are shared with service providers and analytics partners.</p><br/>
                    <p><strong>Third-Party Credentials:</strong> This includes email addresses, usernames, and device IDs, which are shared with service providers and parties you authorize, access, or authenticate.</p><br/>
                    <p><strong>Employment-Related Data:</strong> We collect job titles and company names, which are shared with service providers and analytics partners.</p><br/>
                    <p><strong>Geolocation Data:</strong> This includes IP-address-based location information, which is shared with service providers and analytics partners.</p><br/>
                <br/>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Information Sellagen Does Not Collect</p><br/>
                    <p>We do not intentionally gather sensitive information, such as data relating to race or ethnic origin, political opinions, religion, or health.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Sharing Information</p>
                <p className="title-text fs-14 fw-5 text-color-4">Categories of Sources of Personal Data</p><br/>
                <ul style = {{marginLeft: '20px', fontSize: '14px', lineHeight: 1.5}}>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>You
                        <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                When you provide such information directly to us.
                                <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                                    <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>When you create an account or use our interactive tools and Services.</li>
                                    <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys or questionnaires. When you send us an email or otherwise contact us.</li>
                                </ul>
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                When you use the Services and such information is collected automatically
                                <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                                    <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>Through Cookies (defined in the “Tracking Tools, Advertising and Opt-Out” section below).</li>
                                    <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>If you use a location-enabled browser, we may receive information about your location.</li>
                                    <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>Third Parties
                        <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Vendors.
                                <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                                    <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>We may use analytics providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support.</li>
                                </ul>
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Advertising Partners
                                <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                                    <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>We receive information about you from some of our vendors who assist us with marketing or promotional services related to how you interact with our websites, applications, products, Services, advertisements or communications.</li>
                                </ul>
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Third Party Networks
                                <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                                    <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>If you provide your third-party network account credentials to us or otherwise sign in to the Services through a third-party site or service, some content and/or information in those accounts may be transmitted into your account with us.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <br/>
                <p className="title-text fs-14 fw-5 text-color-4">Our Commercial or Business Purposes for Collecting or Disclosing Personal Data</p><br/>
                <ul style = {{marginLeft: '20px', fontSize: '14px', lineHeight: 1.5}}>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>Providing, Customizing and Improving the Services
                        <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Creating and managing your account or other user profiles.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Processing orders or other transactions; billing.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Providing you with the products, services or information you request.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Meeting or fulfilling the reason you provided the information to us.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Providing support and assistance for the Services.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Improving the Services, including testing, research, internal analytics, product development, and training the models that power the Services.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Personalizing the Services, website content and communications based on your preferences.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Doing fraud protection, security and debugging.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws, such as the California Consumer Privacy Act, as amended by the California Privacy Rights Act of 2020 (the “CCPA”).
                            </li>
                        </ul>
                    </li>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>Corresponding with You
                        <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Responding to correspondence that we receive from you, contacting you when necessary or requested, and sending you information about Sellagen or the Services.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Sending emails and other communications according to your preferences or that display content that we think will interest you.
                            </li>
                        </ul>
                    </li>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>Meeting Legal Requirements and Enforcing Legal Terms
                        <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Protecting the rights, property or safety of you, Sellagen or another party.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Enforcing any agreements with you.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Responding to claims that any posting or other content violates third-party rights.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Resolving disputes.
                            </li>
                        </ul>
                    </li>
                </ul><br/>
                <p>We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated or incompatible purposes without providing you notice.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">How We Disclose Your Personal Data</p>
                <p>We disclose your Personal Data to the categories of service providers and other parties listed in this section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a “sale” of your Personal Data. For more information, please refer to the state-specific sections below.</p><br/>
                <ul style = {{marginLeft: '20px', fontSize: '14px', lineHeight: 1.5}}>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>Service Providers. These parties help us provide the Services or perform business functions on our behalf. They include:
                        <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Hosting, technology and communication providers.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Payment processors.
                            </li>
                        </ul>
                    </li>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>Analytics Partners. These parties provide analytics on web traffic or usage of the Services. They include:
                        <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Companies that track how users found or were referred to the Services.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Companies that track how users interact with the Services.
                            </li>
                        </ul>
                    </li>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>Parties You Authorize, Access or Authenticate
                        <ul style = {{marginLeft: '15px', marginTop: '5px'}}>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Third parties you access through the services.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Social media services.
                            </li>
                            <li style = {{listStyleType: 'disc', marginBottom: '4px'}}>
                                Other users.
                            </li>
                        </ul>
                    </li>
                </ul>    
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Legal Obligations</p>
                <p>We may share any Personal Data that we collect with third parties in conjunction with any of the activities set forth under “Meeting Legal Requirements and Enforcing Legal Terms” in the “Our Commercial or Business Purposes for Collecting Personal Data” section above.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Business Transfers</p>
                <p>All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before your information becomes subject to different privacy and security policies and practices.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Data that is Not Personal Data</p>
                <p>We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing information that makes the data personally identifiable to a particular user. We may use such aggregated, de-identified or anonymized data and share it with third parties for our lawful business purposes, including to analyze, build and improve the Services and promote our business, provided that we will not share such data in a manner that could identify you.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Tracking Tools, Advertising and Opt-Out</p>
                <p>The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript (collectively, “Cookies”) to enable our servers to recognize your web browser, tell us how and when you visit and use our Services, analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data– usually text files – placed on your computer, tablet, phone or similar device when you use that device to access our Services. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s).</p>
                <p>We use the following types of Cookies:</p><br/>
                <ul style = {{marginLeft: '20px', fontSize: '14px', lineHeight: 1.5}}>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>
                        Essential Cookies. Essential Cookies are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling these Cookies may make certain features and services unavailable.
                    </li>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>
                        Functional Cookies. Functional Cookies are used to record your choices and settings regarding our Services, maintain your preferences over time and recognize you when you return to our Services. These Cookies help us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
                    </li>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>
                        Functional Cookies. Functional Cookies are used to record your choices and settings regarding our Services, maintain your preferences over time and recognize you when you return to our Services. These Cookies help us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
                    </li>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>
                        Performance/Analytical Cookies. Performance/Analytical Cookies allow us to understand how visitors use our Services. They do this by collecting information about the number of visitors to the Services, what pages visitors view on our Services and how long visitors are viewing pages on the Services. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Services’ content for those who engage with our advertising. For example, Google LLC (“Google”) uses cookies in connection with its Google Analytics services. Google’s ability to use and share information collected by Google Analytics about your visits to the Services is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out of Google’s use of Cookies by visiting the Google advertising opt-out page at www.google.com/privacy_ads.html or the Google Analytics Opt-out Browser Add-on at <a style={{color: 'rgb(29, 191, 113)'}} href='https://tools.google.com/dlpage/gaoptout/' target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout</a>.
                    </li>
                </ul>
                <br/>
                <p>You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your device. If you do this, however, you may have to manually adjust some preferences every time you visit our website and some of the Services and functionalities may not work.</p><br/>
                <p>To explore what Cookie settings are available to you or to modify your preferences with respect to Cookies, you can access your Cookie management settings by clicking here. To find out more information about Cookies generally, including information about how to manage and delete Cookies, please visit <a style={{color: 'rgb(29, 191, 113)'}} href='http://www.allaboutcookies.org/' target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org</a>.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Use of Google User Data (Optional Integration)</p>
                <p>Our Service offers an optional, not mandatory, integration with Google APIs to enhance its functionality. This feature is available only upon your active choice to enable it. Activation grants us access to, use, storage, and potential sharing of specific Google user data. User data used by our service is limited to email ID. This data is used exclusively to support and improve our Service's features that require Google integration. We strictly adhere to Google's <a style={{color: 'rgb(29, 191, 113)'}} href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes' target="_blank" rel="noopener noreferrer">Limited Use Requirements</a> in managing all Google user data.</p><br/>
                <p>We prioritize your privacy and data security. Any Google user data we access via our integration is in full compliance with the Google <a style={{color: 'rgb(29, 191, 113)'}} href='https://developers.google.com/terms/api-services-user-data-policy' target="_blank" rel="noopener noreferrer">API Services User Data Policy</a>, which includes Limited Use requirements. We leverage this data solely for enhancing service features you elect to use.</p><br/>
                <p>In the spirit of transparency, it's important to note that our AI-based platform incorporates large language models (LLMs) provided by third parties, such as Google, Microsoft, Meta and Anthropic. Integrating your Gmail account with our service constitutes your explicit opt-in consent, allowing us to share necessary data with these LLMs via their APIs. This enhances the application's functionality tailored to your needs. The data shared with LLMs mainly includes email content but is strictly confined to what is essential for the tasks you instruct our system to execute. We require these providers to use your information only for the purpose of facilitating our AI-powered tool, and we do not allow these providers to train their AI models using your personal information. We do not use any Google user data to develop, improve, or train generalized AI and/or ML models.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Data Security</p>
                <p>We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the internet or storing data is completely secure.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Data Retention</p>
                <p>We retain Personal Data about you for as long as necessary to provide you with our Services or to perform our business or commercial purposes for collecting your Personal Data. When establishing a retention period for specific categories of data, we consider who we collected the data from, our need for the Personal Data, why we collected the Personal Data, and the sensitivity of the Personal Data. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. We may further retain information in an anonymous or aggregated form where that information would not identify you personally.</p><br/>
                <p>For example:</p><br/>
                <ul style = {{marginLeft: '20px', fontSize: '14px', lineHeight: 1.5}}>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>
                        We retain your profile information and credentials for as long as you have an account with us.
                    </li>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>
                        We retain your payment data for as long as we need to process your purchase or subscription.
                    </li>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>
                        We retain your device/IP data for as long as we need it to ensure that our systems are working appropriately, effectively and efficiently.
                    </li>
                    <li style = {{listStyleType: 'disc', marginBottom: '10px'}}>
                        We retain your user content for training our models. Free-tier users may have their workflow descriptions and generated content (e.g., plans or code) included in training by default. Paid-tier users are opted-out by default but can opt-in.
                    </li>
                </ul>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Personal Data of Children</p>
                <p>As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data from children under 13 years of age; if you are a child under the age of 13, please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we learn we have collected Personal Data from a child under 13 years of age, we will delete that information as quickly as possible. If you believe that a child under 13 years of age may have provided Personal Data to us, please contact us at support@sellagen.com.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Your Privacy Rights</p>
                <p>To the extent provided for by local law and subject to applicable exceptions, you may have certain rights with respect to your Personal Data, including those set forth below. For more information about these rights, or to submit a request, please email us at support@sellagen.com. Please note that in some circumstances, we may not be able to fully comply with your request, such as if it is frivolous or extremely impractical, if it jeopardizes the rights of others, or if it is not required by law, but in those circumstances, we will still respond to notify you of such a decision. In some cases, we may also need you to provide us with additional information, which may include Personal Data, if necessary to verify your identity and the nature of your request.</p><br/>
                <p>Access: You can request more information about the Personal Data we hold about you and request a copy of such Personal Data. You can also access certain of your Personal Data by logging on to your account.</p><br/>
                <p>Rectification: If you believe that any Personal Data we are holding about you is incorrect or incomplete, you can request that we correct or supplement such data. You can also correct some of this information directly by logging on to your account.</p><br/>
                <p>Erasure: You can request that we erase some or all of your Personal Data from our systems.</p><br/>
                <p>Withdrawal of Consent: If we are processing your Personal Data based on your consent (as indicated at the time of collection of such data), you have the right to withdraw your consent at any time. Please note, however, that if you exercise this right, you may have to then provide express consent on a case-by-case basis for the use or disclosure of certain of your Personal Data, if such use or disclosure is necessary to enable you to utilize some or all of our Services.</p><br/>
                <p>Portability: You can ask for a copy of your Personal Data in a machine-readable format. You can also request that we transmit the data to another controller where technically feasible.</p><br/>
                <p>Objection: You can contact us to let us know that you object to the further use or disclosure of your Personal Data for certain purposes, such as for direct marketing purposes.</p><br/>
                <p>Restriction of Processing: You can ask us to restrict further processing of your Personal Data.</p><br/>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Other State Law Privacy Rights</p>
                <p className="title-text fs-14 fw-4 text-color-4">California Resident Rights</p>
                <p>Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in order to submit such a request, please contact us at support@sellagen.com.</p>
                <p className="title-text fs-14 fw-4 text-color-4">Nevada Resident Rights</p>
                <p>If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties. You can exercise this right by contacting us at support@sellagen.com with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address associated with your account. Please note that we do not currently sell your Personal Data as sales are defined in Nevada Revised Statutes Chapter 603A.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Contact Us</p>
                <p>For inquiries about this Policy, reach out to us at support@sellagen.com.</p>
            </div>
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default PrivacyPolicy