import React from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx'
import Banner from '../components/banner/banner-v3';
// import dataBanner from '../assets/fake-data/data-banner';
import Footer from '../components/footer/footer-v2';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import SearchBar from '../components/searchBar';

import { useContext } from 'react'
import FrontDeck from '../components/front-deck'
import { Context } from '../contexts/GlobalContext'
import { useParams } from 'react-router-dom'
import getPrompts from '../components/overlay-prompts/getPrompts'
import { useEffect } from 'react'
import SandBox from '../pages/SpeedRun/sandbox'
import APIBanner from '../components/api-banner/index.jsx'

function HomePrompt(props) {
    const { setGlobalState } = useContext(Context)
    const params = useParams()
    const overlay = getPrompts(params.prompt)

    useEffect(() => {
        if(overlay !== null) setGlobalState({overlayContent: overlay})
    }, [])
    
    return (
        <div className='wrapper-style' id='wrapper'>
            <Header />
            <Banner/>
            <div className='front-sndbx-wrapper'>
                <SandBox demo={true}/>
            </div>
            <APIBanner/>
            <FrontDeck/>
            <SearchBar/>
            <Footer data={dataFooter}/>
            <Bottom classection='widget-social-footer home2'/>
        </div>
    )
}

export default HomePrompt