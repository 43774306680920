import React, { useContext, useState } from 'react'
import { RightPanel, RightPanelBasicTitle } from '../panel-utils'
import Agent from '../../title'
import { FlowBasic, FlowButton, FlowDropDown, FlowOverlayPrompt, FlowStackedBody, FlowTags } from '../../../../components/flow-ui/flow-blocks'
import SandBoxContext from '../../../../contexts/SandBoxContext'
import '../styles.scss'
import { Context } from '../../../../contexts/GlobalContext'
import supportedServices, { getCompatibleServices, getServiceWithID } from './supportedServices'
import Axios from '../../../../utilities/axios'
import Spinner from '../../../../components/spinner'
import { getDateFromObjectID } from '../../../../utilities/handyFunctions'

const LLMServicePanel = () => {
  const {sandboxState, sandboxSetState, setOverlay, temporaryStateManagement} = useContext(SandBoxContext)
  const {globalState, syncUser} = useContext(Context)
  var serviceKeys = Object.keys(sandboxState?.services || {})

  const handleLogin = () => {
    setOverlay(
      <FlowOverlayPrompt
        title='Sign In Required'
        promptMsg={
        <>
          You are not signed in! You have to sign in in order to utilize your web services with <Agent/>.
        </>
        }
        titleIcon={<i className="fal fa-exclamation-triangle" style={{color: 'orange'}}></i>}
        proceedMsg={<><i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;Sign In</>}
        cancelMsg={<><i className="fal fa-times" style={{color: 'crimson'}}></i>&nbsp;Cancel</>}
        onProceed={() => {
          localStorage.setItem('code-initiation', 'true')
          temporaryStateManagement('save')
          window.location.href = '/login?continue=integrate'
          setOverlay(null)
        }}
        onCancel={() => {
          setOverlay(null)
        }}
      />
    )
  }

  const handleOnSelection = (service) => {
    setOverlay(<OnboardingProceed serviceName={service}/>)
  }

  let options = getCompatibleServices(serviceKeys)
  let schedules = globalState?.user?.schedules ?? []
  schedules = schedules.sort((a, b) => getDateFromObjectID(b._id) - getDateFromObjectID(a._id))

  return (
    <RightPanel>
      <RightPanelBasicTitle>
        <div className='sndbx-panel-close'
          onClick={() => {
            sandboxSetState({showSidePanel: false})
          }}
        >
          <i className="far fa-chevron-right"></i>
        </div>
        <i className="fal fa-sparkles" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;&nbsp;Services Panel
      </RightPanelBasicTitle>
      <div style={{width: '100%', height: '100%', overflowY: 'scroll', padding: '10px'}}>
        <FlowBasic
          title='Services'
          titleIcon={<i className="fal fa-sparkles"></i>}
          border={'2px solid rgba(255, 255, 255, 0.2)'}
        >
          <div className='sv2-flow-stacked-body' style={{height: 'auto', alignItems: 'unset'}}>
            <div className='title-icon'>
              <div className='square'
                style={{bottom: '12px'}}
              ></div>
            </div>
            <div style={{marginTop: '4px', width: '100%'}}>
              <div style={{backgroundColor: 'rgba(255,255, 255, 0.05)', borderRadius: '5px', padding: '10px', lineHeight: 1.3, fontFamily: 'Roboto Mono'}}>
                You can add web services like, for instance email, so that <Agent/> can utilize them to get tasks done on your behalf.
              </div>
              <FlowStackedBody showStep={false}>
                <div style={{width: '100%', display: 'inline-flex', alignItems: 'center', marginTop: '12.5px'}}>
                  <div style={{flexGrow: 1}}>
                    Web Services
                  </div>
                  <FlowDropDown
                    selected=''
                    // options={serviceKeys.length > 0 && serviceKeys[0] === 'email-service' ? [] : ['Email (Gmail)']}
                    options={options}
                    onSelection={ elem => {
                      // check sign-in
                      if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) handleLogin()
                      else handleOnSelection(elem)
                    }}
                    initialMsg='Select Service'
                    emptyMsg='No Service Available'
                  />
                </div>
              </FlowStackedBody>
              {serviceKeys.length > 0 ? <br/> : null}
              {
                serviceKeys.map((elem, i) => (
                  <ServiceControlBlock key={i} service={sandboxState?.services[elem]}/>
                ))
              }
            </div>
          </div>
        </FlowBasic>
        <br/>
        <FlowBasic
          title={
          <div>
            Schedules&nbsp;&nbsp;&nbsp;
            <span style={{backgroundColor: 'rgba(255,255, 255, 0.1)', borderRadius: '5px', padding: '2px 5px', cursor: 'pointer'}}
              onClick={() => {syncUser()}}
            >
              <i className="far fa-sync-alt"></i>
            </span>
          </div>}
          titleIcon={<i className="fal fa-clock"></i>}
          border={'2px solid rgba(255, 255, 255, 0.2)'}
        >
          <div className='sv2-flow-stacked-body' style={{height: 'auto', alignItems: 'unset'}}>
            <div className='title-icon'>
              <div className='square'
                style={{bottom: '12px'}}
              ></div>
            </div>
            <div style={{marginTop: '4px', width: '100%'}}>
              <div style={{backgroundColor: 'rgba(255,255, 255, 0.05)', borderRadius: '5px', padding: '10px', lineHeight: 1.3, fontFamily: 'Roboto Mono'}}>
                You can ask <Agent/> to schedule tasks ahead of time and they'll be executed in the background while you're away.
              </div>
              <FlowStackedBody showStep={false}>
                <div style={{width: '100%', display: 'inline-flex', alignItems: 'center', marginTop: '12.5px'}}>
                  <div style={{flexGrow: 1}}>
                    Scheduled Tasks
                  </div>
                </div>
              </FlowStackedBody>
              <br/>
              {
                schedules.length > 0 ?
                schedules.map(s => <ScheduleCard key={s._id} schedule={s}/>)
                : 
                <ScheduleCardEmpty/>
              }
            </div>
          </div>
        </FlowBasic>
      </div>
    </RightPanel>
  )
}

export default LLMServicePanel

const ServiceControlBlock = ({service}) => {
  const {setOverlay} = useContext(SandBoxContext)
  let settings = null
  // if not an empty-service (the default for no services) then must have controls
  // serviceID is mapped
  if(service.serviceID !== 'empty-service') {
    settings = (
      <div style={{marginTop: '10px', marginBottom: '2px'}}>
        <FlowTags
          styles={{
            fontSize: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.07)',
          }}
        >
          {
            service?.isActive ?
            <>
              <i style={{color: 'rgb(29, 191, 113)'}} className="fas fa-circle"></i> Active
            </>
            :
            <>
              <i style={{color: 'orange'}} className="fas fa-circle"></i> Inactive
            </>
          }
        </FlowTags>
        <span className='sndbx-context-ctrl'
          onClick={() => {
            // setOverlay(<ServiceSpecificControl key={`${service.serviceID}-${service.isActive}`} service={service}/>)
            setOverlay(<ServiceSpecificControl serviceID={service.serviceID}/>)
          }}
        >
          <FlowTags
            styles={{
              fontSize: '10px',
              backgroundColor: 'rgba(255, 255, 255, 0.12)',
              color: 'inherit'
            }}
          ><i className="fal fa-tools"></i> Settings</FlowTags>
        </span>
      </div>
    )
  }
  let extra = null
  if(service?.extra) {
    extra = (
      <div style={{backgroundColor: 'rgba(0, 0, 0, 0.1)', marginTop: '10px', lineHeight: 1.3, borderRadius: '5px', padding: '7px 10px', fontSize: '10px'}}>
        {service?.extra}
      </div>
    )
  }
  return(
    <div className='sndbx-context-blocks'>
      <i className={service.icon}></i>&nbsp;{service.title}<span style={{float: 'right'}}>{service.value}</span>
      {extra}
      {settings}
    </div>
  )
}

const taskSpecificValues = {
  'recurrent': {
    icon: 'far fa-infinity',
    color: '255, 165, 0',
    title: 'Recurrent Schedule'
  },
  'single': {
    icon: 'far fa-calendar-day',
    color: '29, 191, 113',
    title: 'Single Schedule'
  },
  'countdown': {
    icon: 'far fa-calendar-alt',
    color: '116, 163, 251',
    title: 'Countdown Schedule'
  }
}

const taskStatusValues = {
  'error': {
    title: 'Error',
    icon: 'far fa-exclamation-triangle',
    color: 'crimson'
  },
  'evaluate': {
    title: 'Evaluating',
    icon: 'far fa-hourglass-half',
    color: 'royalblue'
  },
  'done': {
    title: 'Done',
    icon: 'far fa-check-double',
    color: 'rgb(29, 191, 113)'
  },
  'waiting': {
    title: 'Waiting',
    icon: 'far fa-clock',
    color: 'orange'
  },
  'running': {
    title: 'Running',
    icon: 'far fa-check',
    color: 'rgb(29, 191, 113)'
  }
}

const ScheduleCard = ({schedule}) => {
  const [isLoading, setIsLoading] = useState(false)
  const {syncUser} = useContext(Context)
  let {
    _id='', nextRunAt='', numOfRepetitions=0, runCount=0, reference='', disabled=null, lastRunAt=''
  } = schedule

  let taskType = null
  if(numOfRepetitions === 1) {
    taskType = 'single'
  } else if(numOfRepetitions === -1) {
    taskType = 'recurrent'
  } else {
    taskType = 'countdown'
  }

  const handleOperation = async (operation, _id) => {
    if(!isLoading) {
      try {
        setIsLoading(true)
        await Axios.post('/scheduler/update', {operation, _id})
      } catch(err) {
        // do nothing
      } finally {
        setIsLoading(false)
        syncUser()
      }
    }
  }

  if(!taskType) {
    return null
  }

  let nextRunAtObj = new Date(nextRunAt)
  let nextRunAtFormattedDate = nextRunAtObj.toLocaleDateString('en-US', {
    year: 'numeric', month: 'long', day: '2-digit'
  })
  let nextRunAtFormattedTime = nextRunAtObj.toLocaleTimeString('en-US', {
    hour: '2-digit', minute: '2-digit', hour12: true
  })

  let timestamp = `At ${nextRunAtFormattedTime}, ${nextRunAtFormattedDate}`

  let scheduleCommons = taskSpecificValues[taskType]
  let status = 'error'
  if(taskType === 'single') {
    if((nextRunAtObj.getTime() + 60 * 1000) < Date.now() && disabled !== true) {
      status = 'error'
    } else if(nextRunAtObj.getTime() < Date.now() && disabled !== true) {
      status = 'evaluate'
    } else if(runCount === 1 || disabled === true) {
      status = 'done'
      try {
        let lastRunAtObj = new Date(lastRunAt)
        let lastRunAtFormattedDate = lastRunAtObj.toLocaleDateString('en-US', {
          year: 'numeric', month: 'long', day: '2-digit'
        })
        let lastRunAtFormattedTime = lastRunAtObj.toLocaleTimeString('en-US', {
          hour: '2-digit', minute: '2-digit', hour12: true
        })
        timestamp = `At ${lastRunAtFormattedTime}, ${lastRunAtFormattedDate}`
      } catch(err) {
        // do nothing
      }
    } else {
      status = 'waiting'
    }
  } else if(taskType === 'countdown') {
    if((nextRunAtObj.getTime() + 60 * 1000) < Date.now() && disabled !== true) {
      status = 'error'
    } else if(nextRunAtObj.getTime() < Date.now() && disabled !== true) {
      status = 'evaluate'
    } else if(runCount === numOfRepetitions || disabled === true) {
      status = 'done'
      let lastRunAtObj = new Date(lastRunAt)
      let lastRunAtFormattedDate = lastRunAtObj.toLocaleDateString('en-US', {
        year: 'numeric', month: 'long', day: '2-digit'
      })
      let lastRunAtFormattedTime = lastRunAtObj.toLocaleTimeString('en-US', {
        hour: '2-digit', minute: '2-digit', hour12: true
      })
      timestamp = `At ${lastRunAtFormattedTime}, ${lastRunAtFormattedDate}`
    } else if(runCount > 0) {
      status = 'running'
    } else {
      status = 'waiting'
    }
  } else if(taskType === 'recurrent') {
    if(runCount > 0) {
      status = 'running'
    } else {
      status = 'waiting'
    }
  }
  let statusObj = taskStatusValues[status]
  let repCountStats = null
  if(taskType === 'countdown' || taskType === 'single') {
    repCountStats = (
      <div style={{
        width: 'fit-content', display: 'inline-block', padding: '5px', float: 'right',
        color: `rgb(${scheduleCommons.color})`
      }}>
        {`${runCount}/${numOfRepetitions}`}
      </div>
    )
  }
  return (
    <div className='sndbx-context-blocks'>
      <div className='sndbx-schedule-delete'
        onClick={() => {
          handleOperation('delete', _id)
        }}
      ><i className='far fa-trash-alt'></i>&nbsp;Delete</div>
      <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
        <div style={{
          width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center',
          backgroundColor: `rgba(${scheduleCommons.color}, 0.15)`, borderRadius: '5px',
          color: `rgb(${scheduleCommons.color})`, fontSize: '15px'
        }}>
          <i className={scheduleCommons.icon}></i>
        </div>&nbsp;
        <div style={{lineHeight: 1.4}}>
          <span style={{color: `rgb(${scheduleCommons.color})`}}>
            {scheduleCommons.title}
          </span><br/>
          <span style={{fontSize: '10px', fontWeight: 400}}>
            {timestamp}
          </span>
        </div>
      </div>
      <br/>
      <div style={{
        backgroundColor: 'rgba(0, 0, 0, 0.2)', width: 'fit-content', padding: '5px 10px 5px 10px',
        borderRadius: '5px 5px 0 0', display: 'inline-block'
      }}>
        <i className='far fa-bolt'></i>&nbsp;Action
      </div>
      <FlowTags
        styles={{
          marginLeft: '2px',
          fontSize: '10px',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          color: 'inherit'
        }}
      >Status: <span>{statusObj.title}&nbsp;<i className={`${statusObj.icon}`}></i></span></FlowTags>
      {repCountStats}
      <div style={{
        backgroundColor: 'rgba(0, 0, 0, 0.2)', lineHeight: 1.75, borderRadius: '0 5px 5px 5px', padding: '7px 10px', fontSize: '10px',
        width: '100%'
      }}>
        {trimToNthChar(reference, 120)}
      </div>
    </div>
  )
}

const trimToNthChar = (str, n) => {
  if (str.length <= n) {
    return str
  }
  let endIndex = str.indexOf(' ', n)
  if (endIndex === -1) {
    return str.slice(0, n)+'...'
  }
  return str.slice(0, endIndex)+'...'
}

const ScheduleCardEmpty = () => {
  return (
    <div className='sndbx-context-blocks' style={{
      height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center',
      opacity: 0.7
    }}>
      No Scheduled Tasks
    </div>
  )
}

const OnboardingProceed = ({serviceName=''}) => {
  const {setOverlay, temporaryStateManagement} = useContext(SandBoxContext)
  let serviceDetails = null
  if(serviceName in supportedServices) {
    serviceDetails = supportedServices[serviceName]
  }
  if(!serviceDetails) return null
  return(
    <FlowOverlayPrompt
      title={serviceDetails.title}
      promptMsg={serviceDetails.value}
      titleIcon={<i className={serviceDetails.icon}></i>}
      proceedMsg={<><i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;Proceed</>}
      cancelMsg={<><i className="fal fa-times" style={{color: 'crimson'}}></i>&nbsp;Cancel</>}
      onProceed={() => {
        localStorage.setItem('code-initiation', 'true')
        temporaryStateManagement('save')
        window.location.href = serviceDetails.link
        setOverlay(null)
      }}
      onCancel={() => {
        setOverlay(null)
      }}
      consent={serviceDetails?.consent || null}
    />
  )
}

const ServiceSpecificControl = ({serviceID}) => {
  const {syncUser} = useContext(Context)
  const {sandboxState, setOverlay} = useContext(SandBoxContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isRemoveLoading, setIsRemoveLoading] = useState(false)
  // const [popUpMsg, setPopUpMsg] = useState(null)

  if(!serviceID) return null
  const service = sandboxState.services[serviceID]
  if(!service) return null

  const statusToggleHandler = ({status, serviceID}) => {
    if(isLoading) return null
    if(!status || !serviceID) return null
    setIsLoading(true)

    Axios.post('service/toggle-status', {status, serviceID}).then(response => {
      syncUser(false, () => {
        setIsLoading(false)
      })
    }).catch(err => {
      setIsLoading(false)
    })
  }

  const serviceRemoveHandler = ({serviceID}) => {
    if(isRemoveLoading) return null
    if(!serviceID) return null
    setIsRemoveLoading(true)

    Axios.get(`service/remove/${serviceID}`).then(response => {
      setOverlay(null)
      syncUser(false, () => {
        setIsRemoveLoading(false)
      })
    }).catch(err => {
      setOverlay(null)
      setIsRemoveLoading(false)
    })
  }

  // service.serviceID (from getServiceContext function) is the unique serviceID from backend
  let serviceDetails = getServiceWithID(service?.serviceID)
  if(!serviceDetails) {
    return null
  }
  let promptMsg = 
  <>
  {/* Service {serviceDetails.serviceKey} is {service?.isActive ? 'active' : 'inactive'} */}
  Service Status&nbsp;&nbsp;<FlowTags
    styles={{
      fontFamily: 'Roboto Mono',
      fontSize: '10px',
      backgroundColor: 'rgba(255, 255, 255, 0.07)',
    }}
  >
    {
      service?.isActive ?
      <>
        <i style={{color: 'rgb(29, 191, 113)'}} className="fas fa-circle"></i> Active
      </>
      :
      <>
        <i style={{color: 'orange'}} className="fas fa-circle"></i> Inactive
      </>
    }
  </FlowTags>

  <br/><br/>
  {
    !service?.isActive ?
    <>
      <div style={{marginBottom: '5px'}}>
        You can activate this service. Upon activation, Nelima will be able to utilize this specific feature. You can deactivate the service again later.
      </div>
      <FlowButton style={{
        lineHeight: 1, fontSize: '10px', padding: '3px 7px', userSelect: 'none'
      }}
        onClick={() => {
          // activate handler
          if(service?.serviceID) {
            statusToggleHandler({status: 'active', serviceID: service?.serviceID})
          }
        }}
      >
        {
          isLoading ?
          <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)', height: '10px', width: '10px', borderWidth: '1.5px'}}/>
          :
          <i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>
        }
        &nbsp;Activate Service
      </FlowButton>
    </>
    :
    <>
      <div style={{marginBottom: '5px'}}>
        You can deactivate this service. When the service is inactive, Nelima will not have access to utilize this specific feature. You can activate the service again later.
      </div>
      <FlowButton style={{
        lineHeight: 1, fontSize: '10px', padding: '3px 7px', userSelect: 'none'
      }}
        onClick={() => {
          if(service?.serviceID) {
            statusToggleHandler({status: 'inactive', serviceID: service?.serviceID})
          }
        }}
      >
        {
          isLoading ?
          <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)', height: '10px', width: '10px', borderWidth: '1.5px'}}/>
          :
          <i className="fal fa-exclamation-triangle" style={{color: 'orange'}}></i>
        }
        &nbsp;Deactivate Service
      </FlowButton>
    </>
  }
  <br/><br/>
  <div>
    You can also remove the service integration from Nelima. By removing the integration, we will remove the access granted by the 3rd Party App to take action on your behalf.
  </div>
  </>
  return(
    <FlowOverlayPrompt
      title={<>Service Settings - {serviceDetails.serviceKey}</>}
      promptMsg={promptMsg}
      titleIcon={<i className={serviceDetails.icon}></i>}
      proceedMsg={<><i className="fal fa-times-circle" style={{color: 'crimson'}}></i>&nbsp;Remove</>}
      cancelMsg={<>Close</>}
      onProceed={() => {
        // handle remove
        // setOverlay(null)
        serviceRemoveHandler({serviceID: service?.serviceID})
        // reload page
      }}
      onCancel={() => {
        setOverlay(null)
      }}
      consent={<>I am aware that access to {serviceDetails.serviceKey} will be permanently removed.</>}
    />
  )
}