import React, {useState} from 'react'
import './styles.scss'

const APIBlock = ({title, description, miscs=[{title: '', component: ''}]}) => {
  const [currentTab, setCurrentTab] = useState(1)
  return (
    <div className='api-docs-block-container'>
      <div className='api-docs-content'>
        <div style={{fontSize: '20px', fontWeight: 700, color: 'rgb(85, 85, 85)'}}>
          {title}
        </div>
        <p style={{fontSize: '14px', color: 'rgb(100, 100, 100)', fontWeight: 500, marginTop: '16px'}}>
          {description}
        </p>
      </div>
      <div className='sv2-static-tabs'>
        {
          miscs.map((element, i) => (
            <React.Fragment key={`sv2-tblk-${i+1}`}>
              <div className={`sv2-static-tab-block${currentTab === i+1 ? ' sv2-static-tab-checked': ''}`}
                onClick={() => {setCurrentTab(i+1)}}
              >
                {element.title}
              </div>
              <div className={`sv2-static-tab-container${currentTab === i+1 ? ' sv2-static-container-checked': ''}`}
                style={currentTab === 1 ? {borderRadius: '0 7px 7px 7px'}: null}
              >
                {element.component}
              </div>
            </React.Fragment>
          ))
        }
      </div>
    </div>
  )
}

export default APIBlock