import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { Context } from '../../contexts/GlobalContext'
import axios from '../../utilities/axios'
import { useIsMounted } from '../../utilities/hooks'
import CreateStripe from '../overlay-prompts/create-stripe'
import SignUpPrompt from '../overlay-prompts/sign-up'
import Spinner from '../spinner'
import './styles.scss'

function PaymentManager(props) {
  const { globalState, setGlobalState } = useContext(Context)
  var view = null
  var status = globalState?.stripe

  const [isLoading, setIsLoading] = useState(true)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const isMounted = useIsMounted()

  const fetchPaymentDetails = () => {
    axios.get('user/self-payment').then(response => {
      if(isMounted) {
        if(response.data?.url) {
          console.log(response.data)
					setGlobalState({ stripe: {url: response.data.url, ready: true} })
				} else if(response.data?.msg === 'not_ready') {
					setGlobalState({ stripe: null })
				} else {
					setGlobalState({ stripe: {url: null, ready: false} })
				}
        setIsLoading(false)
      }
    }).catch(err => {
      console.log(err)
      if(isMounted) {
        setGlobalState({ stripe: {url: null, ready: false} })
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    if(globalState.stripe) {
      setIsLoading(false)
    } else {
      fetchPaymentDetails()
    }
  }, [])

  const dashboardLinkHandler = () => {
    if(!isButtonLoading) {
      setIsButtonLoading(true)
      axios.get('user/stripe-dashboard').then(response => {
        if(isMounted) {
          if(response.data.url) {
            setIsRedirecting(true)
            setTimeout(() => {
              if(isMounted) {
                setIsButtonLoading(false)
                setIsRedirecting(false)
              }
            }, 5000);
            // window.open(response.data.url, '_blank')
            window.location.assign(response.data.url)
          } else {
            if(isMounted) {
              setIsButtonLoading(false)
              setIsRedirecting(false)
            }
            console.log('Error retriving link')
          }
        }
      }).catch(err => {
        if(isMounted) {
          setIsButtonLoading(false)
          setIsRedirecting(false)
        }
        console.log(err)
      })
    }
  }

  if(isLoading) {
    return (
      <div className='payment-manager-container'>
        <div style={{
          width: '100%',
          height: '236px', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          backgroundColor: 'rgb(246, 248, 250)',
          borderRadius: '10px'
          }}
        >
          <Spinner/>
        </div>
      </div>
    )
  }

  if(status === null) {
    view = (
      <>
        <div className='payment-manager-container'>
          <div className='payment-manager-content'>
            <div style={{fontSize: '30px', fontWeight: 900, color: 'rgb(70, 70, 70)'}}>
              Register account in Stripe Connect
            </div>
            <p style={{fontSize: '14px', color: 'rgb(100, 100, 100)', fontWeight: 500, padding: '18px 0'}}>
              Registering your Sellagen account in Stripe enables you to make payouts from your Sellagen account.
            </p>
            <button className='payment-button outline'
              onClick={() => {
                window.location.assign('https://stripe.com/payments')
              }}
            >Learn More</button>
            <button className='payment-button filled'
              onClick={() => {
                setGlobalState({
                  overlayContent: (<CreateStripe />)
                })
              }}
            >Register Account</button>
          </div>
        </div>
      </>
    )
  } else {
    view = (
      <>
        <div className='payment-manager-container'>
          <div className='payment-manager-content'>
            <div style={{fontSize: '30px', fontWeight: 900, color: 'rgb(70, 70, 70)'}}>
              Stripe Dashboard Manager
            </div>
            <p style={{fontSize: '14px', color: 'rgb(100, 100, 100)', fontWeight: 500, padding: '18px 0'}}>
              Stripe Dashboard Manager allows you to log into your Stripe account dashboard for processing payouts from your dataset sales.
            </p>
            <button className='payment-button outline'
              onClick={() => {
                window.location.assign('https://stripe.com/payments')
              }}
            >Learn More</button>
            <button className='payment-button filled'
              onClick={() => {
                if(status?.ready && status?.url) {
                  window.location.assign(status?.url)
                } else {
                  setGlobalState({overlayContent: 
                    <SignUpPrompt
                      title={'Stripe Dashboard'}
                      msg={'Stripe Dashboard is not available at the moment. Please try again later. If you are facing this issue for a while then contact us at support@sellagen.com'}
                      btnMsg={'Dismiss'}
                      btnLink={'/dashboard'}
                    />
                  })
                }
              }}
            >
              Stripe Dashboard
            </button>
            {/* <button className='payment-button filled'
              onClick={dashboardLinkHandler}
            >
              {isButtonLoading ? 
                <>{isRedirecting? 'Redirecting' : 'Loading'}&nbsp;&nbsp;<Spinner /></>
                :
                <>Stripe Dashboard</>
              }
            </button> */}
          </div>
        </div>
      </>
    )
  }
  return (view)
}

export default PaymentManager