import React, { useContext, useState } from 'react'
import './styles.scss'
import {FlowBasic, FlowStackedBody, FlowDropDown, FlowTags, FlowElement, FlowTitle, FlowButton, FlowConnector} from './flow-blocks'
import SandBoxContext from '../../contexts/SandBoxContext'

const FunctionEntry = ({warningFlags}) => {
  const { sandboxState, sandboxSetState } = useContext(SandBoxContext)
  const [selection, setSelection] = useState(sandboxState?.functionDetails?.output || '') // return selection
  const { functionDetails } = sandboxState
  let parameters = functionDetails.parameters || []
  return (
    <FlowBasic
      title='Function Properties'
      titleIcon={<i className="fal fa-microchip"></i>}
      border={'2px solid rgba(255, 255, 255, 0.2)'}
    >
      <FlowElement
        attributeName={functionDetails.title}
        attributeType='function'
        mark={warningFlags[functionDetails.title] === true ? 'check' : warningFlags[functionDetails.title] === false ? 'warn' : null}
      />
      <br/>
      <FlowTitle
        title='Function Arguments'
        icon={<i className="fal fa-brackets-curly"></i>}
      />
      {
        parameters?.length > 0 ?
        parameters.map((param, idx) => {
          return(
            <FlowElement
              key={`${param?.title}-${idx}`}
              attributeName={param?.title}
              attributeType={param?.dataType}
              required={param?.isRequired}
              hasEnum={param?.enum?.length > 0}
              mark={warningFlags[param?.title] === true ? 'check' : warningFlags[param?.title] === false ? 'warn' : null}
            />
          )
        })
        :
        null
      }
      <FlowElement
        attributeName=''
        attributeType=''
        addButton={true}
      />
      <br/>
      <FlowTitle
        title='Function Return'
        icon={<i className="fal fa-poll-h"></i>}
      />
      <FlowStackedBody
        showStep={true}
        icon={
          <FlowTags 
            styles={{
              backgroundColor: 'rgba(255, 255, 255, 0.12)', 
              color: 'rgb(97, 176, 239)',
              fontSize: '8px',
              fontFamily: 'Roboto Mono',
              lineHeight: 1,
              fontWeight: 700,
              color: 'rgb(192, 121, 221)'
            }}>
              type
          </FlowTags>
        }
      >
        <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
          <div style={{flexGrow: 1}}>
            Return
            {
              warningFlags['output'] === true ?
              <i className="fal fa-check" style={{marginLeft: '4px', color: 'rgb(29, 191, 113)'}}></i>
              :
              <i className="fal fa-exclamation-circle" style={{marginLeft: '4px', color: 'orange'}}></i>
            }
          </div>
          <FlowDropDown
            selected={selection}
            options={['int', 'float', 'str', 'None']}
            onSelection={elem => {
              setSelection(elem)
              let func = sandboxState?.functionDetails || {}
              func['output'] = elem
              sandboxSetState({functionDetails: func})
            }}
            initialMsg='Select Return Type'
            color='rgb(192, 121, 221)'
          />
        </div>
      </FlowStackedBody>
    </FlowBasic>
  )
}

export default FunctionEntry

const FunctionEntryDemo = () => {
  return (
    <div style={{marginTop: '-27px', filter: 'brightness(120%)'}}>
      <FlowBasic
        title={
          <>
            Action Invocation
            <FlowButton style={{marginLeft: '10px'}}>Reset</FlowButton>
          </>
        }
        titleIcon={<i className="fal fa-project-diagram"></i>}
        border={'2px solid rgba(255, 255, 255, 0.2)'}
      >
        <FlowStackedBody
          showStep={true}
          icon={
            <FlowTags 
              styles={{
                backgroundColor: 'rgba(255, 255, 255, 0.12)', 
                color: 'rgb(97, 176, 239)'
              }}>
              <i className="far fa-function"></i>
            </FlowTags>
          }
        >
          <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
            <div style={{flexGrow: 1}}>
              Function
            </div>
            <FlowDropDown
              selected={<><i className="fas fa-lock" style={{color: 'orange', fontSize: '10px', filter: 'brightness(90%)'}}></i>&nbsp;search_web</>}
              options={[]}
              color='rgb(97, 176, 239)'
              initialMsg='Select Function'
              emptyMsg='No Function Detected'
              disabled={true}
              width='130px'
            />
          </div>
        </FlowStackedBody>
      </FlowBasic>
      <FlowConnector length={35}/>
      <FlowBasic
        title='Function Properties'
        titleIcon={<i className="fal fa-microchip"></i>}
        border={'2px solid rgba(255, 255, 255, 0.2)'}
      >
        <div className='sv2-flow-funcprop-row'>
          <div className='sv2-flow-row-attr'>
            <div className='name' style={{width: '70px', color: 'rgb(97, 176, 239)'}}>
              search_web
            </div>
          </div>
          <div className='sv2-flow-row-attr dtype'>
            <div className='sv2-flow-dtype' style={{color: 'rgb(97, 176, 239)'}}>
              <i className="far fa-function"></i>
            </div>
          </div>
          <div className='sv2-flow-row-attr details'>
            <div className='sv2-flow-dtype sv2-flow-dtype-btn'>
              UPDATE
              <i className="fal fa-check" style={{marginLeft: '4px', color: 'rgb(29, 191, 113)'}}></i> 
            </div>
          </div>
        </div>
        <br/>
        <FlowTitle
          title='Function Arguments'
          icon={<i className="fal fa-brackets-curly"></i>}
        />
        <div className='sv2-flow-funcprop-row'>
          <div className='sv2-flow-row-attr'>
            <div className='name' style={{width: '70px'}}>
              query
            </div>
          </div>
          <div className='sv2-flow-row-attr dtype'>
            <div className='sv2-flow-dtype' style={{color: 'rgb(152, 195, 121)'}}>
              str
            </div>
          </div>
          <div className='sv2-flow-row-attr details'>
            <div className='sv2-flow-dtype sv2-flow-dtype-btn'>
              UPDATE
              <i className="fal fa-check" style={{marginLeft: '4px', color: 'rgb(29, 191, 113)'}}></i> 
            </div>
            <div className='sv2-flow-dtype' style={{color: 'inherit'}}>REQ</div>
          </div>
        </div>
        <div className='sv2-flow-funcprop-row'>
          <div className='sv2-flow-row-attr'>
            <div className='name' style={{width: '70px'}}>
              max_results
            </div>
          </div>
          <div className='sv2-flow-row-attr dtype'>
            <div className='sv2-flow-dtype' style={{color: 'rgb(209, 154, 102)'}}>
              int
            </div>
          </div>
          <div className='sv2-flow-row-attr details'>
            <div className='sv2-flow-dtype sv2-flow-dtype-btn'>
              UPDATE
              <i className="fal fa-check" style={{marginLeft: '4px', color: 'rgb(29, 191, 113)'}}></i> 
            </div>
          </div>
        </div>
        <div className='sv2-flow-funcprop-row'>
          <div className='sv2-flow-row-attr details'>
            <div className='sv2-flow-dtype sv2-flow-dtype-btn' style={{maxWidth: '200px', padding: '3px 6px'}}>
              <i className="fal fa-plus"></i>&nbsp;Add Argument</div>
          </div>
        </div>
        <br/>
        <FlowTitle
          title='Function Return'
          icon={<i className="fal fa-poll-h"></i>}
        />
        <FlowStackedBody
          showStep={true}
          icon={
            <FlowTags 
              styles={{
                backgroundColor: 'rgba(255, 255, 255, 0.12)', 
                color: 'rgb(97, 176, 239)',
                fontSize: '8px',
                fontFamily: 'Roboto Mono',
                lineHeight: 1,
                fontWeight: 700,
                color: 'rgb(192, 121, 221)'
              }}>
                type
            </FlowTags>
          }
        >
          <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
            <div style={{flexGrow: 1}}>
              Return<i className="fal fa-check" style={{marginLeft: '4px', color: 'rgb(29, 191, 113)'}}></i>
            </div>
            <FlowDropDown
              selected={'dict'}
              options={[]}
              initialMsg=''
              color='rgb(192, 121, 221)'
              disabled={true}
              width='130px'
            />
          </div>
        </FlowStackedBody>
      </FlowBasic>
    </div>
  )
}

export {FunctionEntryDemo}