import React, {useContext, useEffect, useState} from 'react'
import Markdown from 'react-markdown'
import './sandbox-chat.scss'
import SandBoxContext from '../../../contexts/SandBoxContext'
import { FlowButton } from '../../../components/flow-ui/flow-blocks'
import { quotationCleaner, urlFormatter } from '../../../utilities/input-formatter'
import reactStringReplace from 'react-string-replace'

const SandBoxChat = () => {
  const { sandboxState } = useContext(SandBoxContext)
  const { prompts, testPrompts } = sandboxState
  var content = null
  var intro = null
  var padding = null
  if(sandboxState?.metadata?.promptTesting?.enabled === true) {
    content = [...testPrompts]
  } else {
    content = [...prompts]
  }
  if(content?.length > 0) {
    padding = <div style={{flexGrow: 1}}></div>
    content.reverse()
    content = content.map((c, i) => <SandBoxConvoBlock key={`${Math.random()}`} role={c.role} content={c.content} reference={c?.reference}/>)
  } else {
    intro = <BasicIntro/>
  }
  return (
    <div className='sndbx-chat-container' style={{paddingLeft: '10px'}}>
      <div className='sndbx-content-body' style={{display: 'flex', flexDirection: 'column-reverse'}}>
        {intro}
        {content}
        {padding}
      </div>
    </div>
  )
}

export default SandBoxChat

const BasicIntro = () => {
  const {sandboxState} = useContext(SandBoxContext)
  var testMode = null
  if(sandboxState?.metadata?.promptTesting?.enabled === true) {
    testMode = <><FlowButton style={{marginLeft: '10px', marginBottom: '3px', filter: 'brightness(100%)', fontWeight: 700, color: 'orange'}}>Test Mode</FlowButton></>
  }
  return (
    <div className='sndbx-intro'>
      <div style={{paddingLeft: '10px'}}>
        <div style={{display: 'flex', alignItems: 'flex-end'}}>
          I am Nelima{testMode}
        </div>
        <span style={{fontSize: '14px', fontWeight: 500}}>Community driven Large Action Model.</span>
      </div>
    </div>
  )
}

const SandBoxConvoBlock = ({role, content, reference}) => {
  if(reference) {
    return <>
      <SandBoxAssistantBlock content={content}/>
      <SandBoxUserBlockReply content={reference}/>
      <div style={{marginTop: '15px'}}></div>
    </>
  }
  if(role === 'assistant') return <SandBoxAssistantBlock content={content}/>
  else if(role === 'user') return <SandBoxUserBlock content={content}/>
}

const SandBoxUserBlock = ({content=''}) => {
  let formattedContent = reactStringReplace(content, /(https?:\/\/[^\s]+)/g, urlFormatter)
  return (
    <div className='sndbx-convo-base sndbx-convo-user'>
      {/* <div style={{minWidth: '16px', marginRight: '8px', fontSize: '16px', lineHeight: 1}}>
        <i className="fas fa-quote-left"></i>
      </div> */}
      <div className='quote-blk'><i className="fas fa-quote-left"></i></div>
      {formattedContent}
    </div>
  )
}

const SandBoxUserBlockReply = ({content=''}) => {
  let formattedContent = reactStringReplace(content, /(https?:\/\/[^\s]+)/g, urlFormatter)
  return (
    <div className='sndbx-convo-base sndbx-convo-user'>
      {/* <div className='sndbx-convo-rply'><i className="fas fa-reply"></i>&nbsp;Nelima replied to You</div> */}
      <div className='sndbx-convo-rply'>
        <div className='rply-smbl'></div>
        &nbsp;&nbsp;Nelima replied to You
      </div>
      <div className='quote-blk'><i className="fas fa-clock" style={{marginTop: '2.5px', color: 'orange'}}></i></div>
      {formattedContent}
    </div>
  )
}

const SandBoxAssistantBlock = ({content='', isEdited=false}) => {
  const [isEditedFlag, setIsEditedFlag] = useState(isEdited === true ? 'edited' : 'original')
  const [isCopied, setIsCopied] = useState(false)
  var view = null
  useEffect(() => {
    const links = document.querySelectorAll('.markdown-wrapper a')
    links.forEach(link => {
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
    })
  }, [])

  if(isEditedFlag === 'editing') {
    view = (
      <div>Hello</div>
    )
  } else if(isEditedFlag === 'edited' || isEditedFlag === 'original') {
    view = (
      <Markdown className='markdown-wrapper'>
        {quotationCleaner(content)}
      </Markdown>
    )
  }

  const handleCopy = () => {
    if(!isCopied) {
      navigator.clipboard.writeText(content).then(() => {
        setIsCopied(true)
        setTimeout(() => {
          if(setIsCopied) setIsCopied(false)
        }, 2000)
      }).catch(err => {
        console.log('Error copying to clipboard')
      })
    }
  }
  return (
    <div className='sndbx-convo-base sndbx-convo-assistant'>
      <div className='convo-control'>
        {/* <i className="far fa-pen ctrl-btn"></i> */}
        {isCopied ? 
          <i className="far fa-check ctrl-btn"></i>
          :
          <i className="far fa-clipboard ctrl-btn"
            onClick={handleCopy}
          ></i>
        }
      </div>
      {/* <div style={{minWidth: '16px', marginRight: '8px', fontSize: '16px'}}>
        &nbsp;
      </div> */}
      {view}
    </div>
  )
}