import React from 'react'
import Spinner from '../spinner'

const Loading = () => {
  return (
    <div style={{
      width: '100hw',
      height: 'calc(100vh - 90px)',
      marginTop: '90px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Spinner style={{
        width: '40px',
        height: '40px'
      }}/>
    </div>
  )
}

export default Loading