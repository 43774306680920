import React from 'react'
import './styles.scss'
import map1 from './map1.png'
import map2 from './map2.png'
import map3 from './map3.png'
import map4 from './map4.png'
import map5 from './map5.png'
import map6 from './map6.png'
import sign from './sign.svg'
import { useNavigate } from 'react-router-dom'
// const $ = window.jQuery
// require('jvectormap-next')($)
// $.fn.vectorMap('addMap', 'world_mill', require('../worldmap/world-mill'))

const FrontDeck = () => {
  const navigate = useNavigate()
  const downloadDataset = (id) => {
    navigate(`item/${id}`, {state: {downloadSample: true}})
  }
  // var dataset = {
  //   ca: 999999
  // }
  
  // useEffect(() => {
  //   $("#my-world").vectorMap({
  //     // Functionality
  //     map: "world_mill",
  //     enableZoom: false,
  //     values: dataset,
  //     backgroundColor: "rgb(246, 248, 250)",
  //     borderColor: "#818181",
  //     borderOpacity: 0.25,
  //     borderWidth: 1,
  //     color: "#f4f3f0",
  //     hoverColor: "#1dbf71",
  //     normalizeFunction: "linear",
  //     scaleColors: ["#70ddae", "#1dbf71"],
  //     selectedColor: "#1dbf71"
  //   })
  // }, [])
  
  return(
    <>
      <div className='sellagen-deck-title'>
        Featured Datasets
      </div>
      <div className="sellagen-deck-grid">
        <div className="sellagen-grid-unit block1"
          style={{padding: '20px'}}
        >
          <p style={{fontSize: '22px', fontWeight: 'bold'}}>
            App Data Global Coverage
          </p>
          <br/>
          <p style={{fontSize: '12px'}}>
            By Datasys Group, Inc dba Datastream Group
          </p>
          <br/>
          <div style={{
            fontSize: '14px', 
            color: 'rgb(90, 90, 90)', 
            lineHeight: 1.5
          }}>
            Mobile app user data can be incredibly valuable for marketers, allowing you to create stronger campaigns, build more effective models, and make smarter decisions.
          </div>
          <div className='bullet-Container'>
            <div className='bullet-points'>
              <i className="far fa-map-marker-alt icon-margin"></i>
              NA, SA and Asia
            </div>
            <div className='bullet-points'>
              <i className="far fa-sliders-v icon-margin"></i>
              Subscription-based Usage
            </div>
            <div className='bullet-points'>
              <i className="far fa-info-circle icon-margin"></i>
              Updates Daily
            </div>
          </div>
          <div className='deck-button'
            onClick={() => {
              window.location.assign('/item/64419a1a9043203c6e030dca')
            }}
          >
            Details
          </div>
        </div>
        <div className="sellagen-grid-unit block2"
          style={{padding: '15px'}}
        >
          <FieldTags fields={['MAID', 'DeviceType', 'timestamp', 'app_bundle', 'country_code', 'ip', 'carrier']}/>
        </div>
        <div className="sellagen-grid-unit block3" style={{padding: 0}}>
          <div style={{
            width: '100%',
            height: '130px'
          }}>
            <SlidingInfo>
              <div className="slide2">
                <img src={map3} className='slide-image2'/>
              </div>

              <div className="slide2">
                <span className='marker-container' style={{paddingTop: 1}}>
                  <i className="far fa-map-marker-alt icon-margin"></i>
                  <div className='location-tooltip'>
                    NA
                    SA
                    Asia
                  </div>
                </span>
                <img
                  className='slide-image2'
                  src={map6}
                />
              </div>

              <div className="slide2">
                <div className='slide-content2'>
                  <span style={{fontSize: '24px', fontWeight: 500, textAlign: 'center'}}>
                    Custom<br/>License
                    {/* <div style={{fontSize: '14px', textAlign: 'center', fontWeight: 700, marginTop: '2px'}}>License</div> */}
                  </span>
                </div>
              </div>
              
              <div className="slide2">
                <div className='slide-content2' onClick={() => downloadDataset('64419a1a9043203c6e030dca')}>
                  <DownloadSample
                    iconVal='fal fa-file-image'
                    format='.csv'
                    misc={(<>View <br/>Sample</>)}
                  />
                </div>
              </div>

              <div className="slide2">
                <span className='marker-container' style={{paddingTop: 1}}>
                  <i className="far fa-map-marker-alt icon-margin"></i>
                  <div className='location-tooltip'>
                    NA
                    SA
                    Asia
                  </div>
                </span>
                <img
                  className='slide-image2' 
                  src={map6}
                />
              </div>
            </SlidingInfo>
          </div>
        </div>
        <div className="sellagen-grid-unit block4">
          <PriceBlock
            value='180k'
          />
        </div>
      </div>
      <div className="sellagen-deck-grid">
        <div className="sellagen-grid-unit block1"
          style={{padding: '20px', paddingBottom: '65px'}}
        >
          <p style={{fontSize: '22px', fontWeight: 'bold'}}>
            Image Dataset for Object Detection in Autonomous Vehicles
          </p>
          <br/>
          <p style={{fontSize: '12px'}}>
            By Plexis Inc.
          </p>
          <br/>
          <div style={{
            fontSize: '14px', 
            color: 'rgb(90, 90, 90)', 
            lineHeight: 1.5
          }}>
            Contains 3,000 annotated images specifically curated for object detection in autonomous vehicles. Includes typically encountered objects in autonomous driving scenarios.
          </div>
          <div className='bullet-Container'>
            <div className='bullet-points'>
              <i className="far fa-map-marker-alt icon-margin"></i>
              Global
            </div>
            <div className='bullet-points'>
              <i className="far fa-sliders-v icon-margin"></i>
              Static Data Compilation
            </div>
            <div className='bullet-points'>
              <i className="far fa-info-circle icon-margin"></i>
              Labeled Dataset for Machine Learning
            </div>
          </div>
          <div className='deck-button'
            onClick={() => {
              window.location.assign('/item/64164eafe8612aa5bb5c5395')
            }}
          >
            Details
          </div>
        </div>
        <div className="sellagen-grid-unit block2"
          style={{padding: '15px', display: 'flex'}}
        >
          {/* <FieldTags fields={['start-time', 'finish-time', 'area_id', 'geometry', 'km2', 'population', 'sample_size', 'sentiment_🙂', 'sentiment_😐', 'sentiment_🙁', 'confidence']}/> */}
          <img src={sign} style={{height: '150px'}}/>
          <div style={{display: 'inline-block', fontSize: '13px', marginLeft: '20px', lineHeight: 1.5, paddingTop: '20px'}}>
            <strong>
              Each Sample Contains: <br/>
            </strong>
            <div style={{marginLeft: '5px'}}>
              • One image <br/>
              • One or more objects <br/>
              • One or more Labels <br/>
              • One or more Bounding boxes <br/>
              • Bounding Box Format: COCO Format
            </div>
          </div>
        </div>
        {/* <div className="sellagen-grid-unit block3"
          onClick={() => downloadDataset('639b8c734c2e6fe5fa6feb22')}
        >
          <DownloadSample
            iconVal='fal fa-file-spreadsheet'
            format='.csv'
            misc={(<>View <br/>Sample</>)}
          />
        </div> */}
        <div className='sellagen-grid-unit block3'
          // style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '42px', textAlign: 'center'}}
        >
          {/* 3000 <br/><div style={{fontSize: '20px'}}>Images</div> */}
          <span style={{fontSize: '32px', fontWeight: 500}}>
            3000<br/>
            <div style={{fontSize: '14px', textAlign: 'center', fontWeight: 700, marginTop: '2px'}}>Images</div>
          </span>
        </div>
        <div className="sellagen-grid-unit block4">
          <span className='marker-container'>
            <i className="far fa-map-marker-alt icon-margin"></i>
            <div className='location-tooltip'>
              Global
            </div>
          </span>
          <img
            className='map-viewer'
            src={map5}
          />
        </div>
      </div>
      <div className="sellagen-deck-grid">
        <div className="sellagen-grid-unit block1"
          style={{padding: '20px'}}
        >
          <p style={{fontSize: '22px', fontWeight: 'bold'}}>
            Hyperlocal Sentiment
          </p>
          <br/>
          <p style={{fontSize: '12px'}}>
            By Affectra-AI
          </p>
          <br/>
          <div style={{
            fontSize: '14px', 
            color: 'rgb(90, 90, 90)', 
            lineHeight: 1.5
          }}>
            Objectively quantify the overall 🙂😐🙁 sentiment of specific places in time. Measurable at neighborhood or street-block precision for daily or monthly time periods.
          </div>
          <div className='bullet-Container'>
            <div className='bullet-points'>
              <i className="far fa-map-marker-alt icon-margin"></i>
              Canadian Metropolitan Area
            </div>
            <div className='bullet-points'>
              <i className="far fa-sliders-v icon-margin"></i>
              Customizable Output Granularity
            </div>
            <div className='bullet-points'>
              <i className="far fa-info-circle icon-margin"></i>
              Generated Upon Request
            </div>
          </div>
          <div className='deck-button'
            onClick={() => {
              window.location.assign('/item/639b8c734c2e6fe5fa6feb22')
            }}
          >
            Details
          </div>
        </div>
        <div className="sellagen-grid-unit block2"
          style={{padding: '15px'}}
        >
          <FieldTags fields={['start-time', 'finish-time', 'area_id', 'geometry', 'km2', 'population', 'sample_size', 'sentiment_🙂', 'sentiment_😐', 'sentiment_🙁', 'confidence']}/>
        </div>
        <div className="sellagen-grid-unit block3"
          onClick={() => downloadDataset('639b8c734c2e6fe5fa6feb22')}
        >
          <DownloadSample
            iconVal='fal fa-file-spreadsheet'
            format='.csv'
            misc={(<>View <br/>Sample</>)}
          />
        </div>
        <div className="sellagen-grid-unit block4">
          {/* Submit<br/>Requirements */}
          <span className='marker-container'>
            <i className="far fa-map-marker-alt icon-margin"></i>
            <div className='location-tooltip'>
              Canada
            </div>
          </span>
          <img
            className='map-viewer'
            src={map1}
          />
        </div>
      </div>
      <div className="sellagen-deck-grid">
        <div className="sellagen-grid-unit block1"
          style={{padding: '20px'}}
        >
          <p style={{fontSize: '22px', fontWeight: 'bold'}}>
            Organizations Profiling Data
          </p>
          <br/>
          <p style={{fontSize: '12px'}}>
            By J. McKenzie
          </p>
          <br/>
          <div style={{
            fontSize: '14px', 
            color: 'rgb(90, 90, 90)', 
            lineHeight: 1.5
          }}>
            This dataset contains profiling and insights data for companies ranging from early-stage startups to the Fortune 1000.
          </div>
          <div className='bullet-Container'>
            <div className='bullet-points'>
              <i className="far fa-map-marker-alt icon-margin"></i>
                United States
            </div>
            <div className='bullet-points'>
              <i className="far fa-sliders-v icon-margin"></i>
                Static Data Compilation
            </div>
            <div className='bullet-points'>
              <i className="far fa-info-circle icon-margin"></i>
                Contains Acquition Details
            </div>
            <div className='bullet-points'>
              <i className="far fa-exclamation-triangle icon-margin"></i>
                Redacted Sample Fields
            </div>
          </div>
          <div className='deck-button'
            onClick={() => {
              window.location.assign('/item/63b9aca24c2e6fe5fa6ffe37')
            }}
          >
            Details
          </div>
        </div>
        <div className="sellagen-grid-unit block2"
          style={{padding: '15px'}}
        >
          <FieldTags fields={['identifier', 'loc_city', 'loc_continent', 'loc_country', 'loc_group', 'diversity_spotlights', 'revenue_range', 'short_description', 'operating_status', 'phone_number', 'company_type', 'more']}/>
        </div>
        <div className="sellagen-grid-unit block3" style={{padding: 0}}>
          <div style={{
            width: '100%',
            height: '130px'
          }}>
            <SlidingInfo>
              <div className="slide2">
                <img src={map3} className='slide-image2' style={{border: '1px solid red'}}/>
              </div>

              <div className="slide2">
                <span className='marker-container' style={{paddingTop: 1}}>
                  <i className="far fa-map-marker-alt icon-margin"></i>
                  <div className='location-tooltip'>
                    USA
                  </div>
                </span>
                <img
                  className='slide-image2'
                  src={map4}
                />
              </div>

              <div className="slide2">
                <div className='slide-content2'>
                  <span style={{fontSize: '32px', fontWeight: 500}}>
                    1.4M<br/>
                    <div style={{fontSize: '14px', textAlign: 'center', fontWeight: 700, marginTop: '2px'}}>Records</div>
                  </span>
                </div>
              </div>
              
              <div className="slide2">
                <div className='slide-content2' onClick={() => downloadDataset('63b9aca24c2e6fe5fa6ffe37')}>
                  <DownloadSample
                    iconVal='fal fa-file-image'
                    format='.xlsx'
                    misc={(<>View <br/>Sample</>)}
                  />
                </div>
              </div>

              <div className="slide2">
                <span className='marker-container' style={{paddingTop: 1}}>
                  <i className="far fa-map-marker-alt icon-margin"></i>
                  <div className='location-tooltip'>
                    USA
                  </div>
                </span>
                <img
                  className='slide-image2' 
                  src={map4}
                />
              </div>
            </SlidingInfo>
          </div>
        </div>
        <div className="sellagen-grid-unit block4">
          <PriceBlock
            value='50.00'
            misc='(Download instantly)'
          />
        </div>
      </div>
      <div className="sellagen-deck-grid">
        <div className="sellagen-grid-unit block1"
          style={{padding: '20px'}}
        >
          <p style={{fontSize: '22px', fontWeight: 'bold'}}>
            Companies - Medical Products Germany
          </p>
          <br/>
          <p style={{fontSize: '12px'}}>
            By M. Pohler
          </p>
          <br/>
          <div style={{
            fontSize: '14px', 
            color: 'rgb(90, 90, 90)', 
            lineHeight: 1.5
          }}>
            Data for medical products companies in Germany.
          </div>
          <div className='bullet-Container'>
            <div className='bullet-points'>
              <i className="far fa-map-marker-alt icon-margin"></i>
              Germany
            </div>
            <div className='bullet-points'>
              <i className="far fa-sliders-v icon-margin"></i>
              Static Data Compilation
            </div>
            <div className='bullet-points'>
              <i className="far fa-info-circle icon-margin"></i>
              Healthcare Industry Leads
            </div>
            <div className='bullet-points'>
              <i className="far fa-exclamation-triangle icon-margin"></i>
              Redacted Sample Fields
            </div>
          </div>
          <div className='deck-button'
            onClick={() => {
              window.location.assign('/item/6391a163753c2d1f3895bae7')
            }}
          >
            Details
          </div>
        </div>
        <div className="sellagen-grid-unit block2"
          style={{padding: '15px'}}
        >
          <FieldTags fields={['Name', 'Address', 'Phone', 'Website', 'EMails', 'Lead-Names', 'Delivery Area', 'Establishment Year', 'Number of Employees']}/>
        </div>
        <div className="sellagen-grid-unit block3" style={{padding: 0}}>
          <div style={{
            width: '100%',
            height: '130px'
          }}>
            <SlidingInfo>
              <div className="slide2">
                <img src={map3} className='slide-image2'/>
              </div>

              <div className="slide2">
                <span className='marker-container' style={{paddingTop: 1}}>
                  <i className="far fa-map-marker-alt icon-margin"></i>
                  <div className='location-tooltip'>
                    Germany
                  </div>
                </span>
                <img
                  className='slide-image2'
                  src={map2}
                />
              </div>

              <div className="slide2">
                <div className='slide-content2'>
                  <span style={{fontSize: '32px', fontWeight: 500}}>
                    5.7k<br/>
                    <div style={{fontSize: '14px', textAlign: 'center', fontWeight: 700, marginTop: '2px'}}>Records</div>
                  </span>
                </div>
              </div>
              
              <div className="slide2">
                <div className='slide-content2' onClick={() => downloadDataset('6391a163753c2d1f3895bae7')}>
                  <DownloadSample
                    iconVal='fal fa-file-image'
                    format='.png'
                    misc={(<>View <br/>Sample</>)}
                  />
                </div>
              </div>

              <div className="slide2">
                <span className='marker-container' style={{paddingTop: 1}}>
                  <i className="far fa-map-marker-alt icon-margin"></i>
                  <div className='location-tooltip'>
                    Germany
                  </div>
                </span>
                <img
                  className='slide-image2' 
                  src={map2}
                />
              </div>
            </SlidingInfo>
          </div>
        </div>
        <div className="sellagen-grid-unit block4">
          <PriceBlock
            value='25.00'
            misc='(Download instantly)'
          />
        </div>
      </div>
      <div className="sellagen-deck-grid">
        <div className="sellagen-grid-unit block1"
          style={{padding: '20px'}}
        >
          <p style={{fontSize: '22px', fontWeight: 'bold'}}>
            Contact List of BUSINESS LEADS
          </p>
          <br/>
          <p style={{fontSize: '12px'}}>
            By M. Carstensen
          </p>
          <br/>
          <div style={{
            fontSize: '14px', 
            color: 'rgb(90, 90, 90)', 
            lineHeight: 1.5
          }}>
            Good mix of business leaders from EU, US and Australia. Includes owners, CEO's, office managers, ecommerce managers, CFO's and accounting managers.
          </div>
          <div className='bullet-Container'>
            <div className='bullet-points'>
              <i className="far fa-map-marker-alt icon-margin"></i>
              EU, US AND AUSTRALIA
            </div>
            <div className='bullet-points'>
              <i className="far fa-sliders-v icon-margin"></i>
              Static Data Compilation
            </div>
            <div className='bullet-points'>
              <i className="far fa-info-circle icon-margin"></i>
              Business Leads
            </div>
          </div>
          <div className='deck-button'
            onClick={() => {
              window.location.assign('/item/639b10ab4c2e6fe5fa6fe99f')
            }}
          >
            Details
          </div>
        </div>
        <div className="sellagen-grid-unit block2"
          style={{padding: '15px'}}
        >
          <FieldTags fields={['EMAIL', 'FIRST NAME', 'LAST NAME', 'TITLE', 'COMPANY', 'COMPANY SIZE', 'CITY', 'WEBSITE', 'FOUNDED', 'COMPANY LINKEDIN', 'INDUSTRY', 'COMPANY TYPE']}/>
        </div>
        <div className="sellagen-grid-unit block3" style={{padding: 0}}>
          <div style={{
            width: '100%',
            height: '130px'
          }}>
            <SlidingInfo>
              <div className="slide2">
                <img src={map3} className='slide-image2'/>
              </div>

              <div className="slide2">
                <span className='marker-container' style={{paddingTop: 1}}>
                  <i className="far fa-map-marker-alt icon-margin"></i>
                  <div className='location-tooltip'>
                    Australia
                    EU
                    USA
                  </div>
                </span>
                <img
                  className='slide-image2' 
                  src={map3}
                />
              </div>

              <div className="slide2">
                <div className='slide-content2'>
                  <span style={{fontSize: '32px', fontWeight: 500}}>
                    13k<br/>
                    <div style={{fontSize: '14px', textAlign: 'center', fontWeight: 700, marginTop: '2px'}}>Records</div>
                  </span>
                </div>
              </div>
              
              <div className="slide2">
                <div className='slide-content2'>
                  <span style={{fontSize: '32px', fontWeight: 500}}>
                    1.5MB<br/>
                    <div style={{fontSize: '14px', textAlign: 'center', fontWeight: 700, marginTop: '2px'}}>File Size</div>
                  </span>
                </div>
              </div>

              <div className="slide2">
                <span className='marker-container' style={{paddingTop: 1}}>
                  <i className="far fa-map-marker-alt icon-margin"></i>
                  <div className='location-tooltip'>
                    Australia
                    EU
                    USA
                  </div>
                </span>
                <img
                  className='slide-image2' 
                  src={map3}
                />
              </div>
            </SlidingInfo>
          </div>
        </div>
        <div className="sellagen-grid-unit block4">
          <PriceBlock
            value='200.00'
            misc='(Download instantly)'
          />
        </div>
      </div>
      <div className="sellagen-deck-grid">
        <div className="sellagen-grid-unit block1"
          style={{padding: '20px', paddingBottom: '65px'}}
        >
          <p style={{fontSize: '22px', fontWeight: 'bold'}}>
            Personal Facebook Data
          </p>
          <br/>
          <p style={{fontSize: '12px'}}>
            By J. Kachamila
          </p>
          <br/>
          <div style={{
            fontSize: '14px', 
            color: 'rgb(90, 90, 90)', 
            lineHeight: 1.5
          }}>
            The following data is the my personal data requested on Facebook's platform. The data was delivered on October 27th 2022 and the timeframe spans from 2011 until 2022.
          </div>
          <div className='bullet-Container'>
            <div className='bullet-points'>
              <i className="far fa-map-marker-alt icon-margin"></i>
              United States
            </div>
            <div className='bullet-points'>
              <i className="far fa-sliders-v icon-margin"></i>
              Static Data Compilation
            </div>
            <div className='bullet-points'>
              <i className="far fa-user icon-margin"></i>
              Personal Data
            </div>
          </div>
          <div className='deck-button'
            onClick={() => {
              window.location.assign('/item/635edbd8b68a3a143817c530')
            }}
          >
            Details
          </div>
        </div>
        <div className="sellagen-grid-unit block2"
          style={{padding: '15px'}}
        >
          <FieldTags fields={['activity_messages', 'ads_information', 'bug_bounty', 'collaborations', 'digital_wallets', 'events', 'facebook_assistant', 'facebook_gaming', 'feed', 'fundraisers', 'groups', 'messages', 'meta_spark', 'and more...']}/>
        </div>
        <div className="sellagen-grid-unit block3" style={{padding: 0}}>
          <div style={{
            width: '100%',
            height: '130px'
          }}>
            <SlidingInfo>
              <div className="slide2">
                <img src={map4} className='slide-image2'/>
              </div>

              <div className="slide2">
                <span className='marker-container' style={{paddingTop: 1}}>
                  <i className="far fa-map-marker-alt icon-margin"></i>
                  <div className='location-tooltip'>
                    USA
                  </div>
                </span>
                <img
                  className='slide-image2' 
                  src={map4}
                />
              </div>

              <div className="slide2">
                <div className='slide-content2'>
                  <span style={{fontSize: '32px', fontWeight: 500}}>
                    47 Fields<br/>
                    <div style={{fontSize: '10px', textAlign: 'center', fontWeight: 700, marginTop: '2px'}}>Oct 22, Last Updated</div>
                  </span>
                </div>
              </div>
              
              <div className="slide2">
                <div className='slide-content2'>
                  <span style={{fontSize: '32px', fontWeight: 500}}>
                    1.2GB<br/>
                    <div style={{fontSize: '14px', textAlign: 'center', fontWeight: 700, marginTop: '2px'}}>File Size</div>
                  </span>
                </div>
              </div>

              <div className="slide2">
                <span className='marker-container' style={{paddingTop: 1}}>
                  <i className="far fa-map-marker-alt icon-margin"></i>
                  <div className='location-tooltip'>
                    USA
                  </div>
                </span>
                <img
                  className='slide-image2' 
                  src={map4}
                />
              </div>
            </SlidingInfo>
          </div>
        </div>
        <div className="sellagen-grid-unit block4">
          <PriceBlock
            value='5.99'
            misc='(Download instantly)'
          />
        </div>
      </div>
      <div className="sellagen-deck-grid">
        <div className="sellagen-grid-unit block1"
          style={{padding: '20px', paddingBottom: '65px'}}
        >
          <p style={{fontSize: '22px', fontWeight: 'bold'}}>
            Construction Companies, High Accuracy Marketing Data
          </p>
          <br/>
          <p style={{fontSize: '12px'}}>
            By D. P.
          </p>
          <br/>
          <div style={{
            fontSize: '14px', 
            color: 'rgb(90, 90, 90)', 
            lineHeight: 1.5
          }}>
            A dataset Construction Company leads. The data isn't filtered, but has an accuracy of around 80-90%. Great for cold email campaigns, as well as cold calling.
          </div>
          <div className='bullet-Container'>
            <div className='bullet-points'>
              <i className="far fa-map-marker-alt icon-margin"></i>
              United States
            </div>
            <div className='bullet-points'>
              <i className="far fa-sliders-v icon-margin"></i>
              Static Data Compilation
            </div>
            <div className='bullet-points'>
              <i className="far fa-info-circle icon-margin"></i>
              Construction Leads
            </div>
          </div>
          <div className='deck-button'
            onClick={() => {
              window.location.assign('/item/636c239a0ee7fd83720a4dbe')
            }}
          >
            Details
          </div>
        </div>
        <div className="sellagen-grid-unit block2"
          style={{padding: '15px'}}
        >
          <FieldTags fields={['Company Name', 'Avg Ratings', 'Category', 'Phone Number', 'Website', 'Country', 'City', 'Niche', 'Email', 'Owners Name']}/>
        </div>
        <div className="sellagen-grid-unit block3" style={{padding: 0}}>
          <div style={{
            width: '100%',
            height: '130px'
          }}>
            <SlidingInfo>
              <div className="slide2">
                <img src={map4} className='slide-image2'/>
              </div>

              <div className="slide2">
                <span className='marker-container' style={{paddingTop: 1}}>
                  <i className="far fa-map-marker-alt icon-margin"></i>
                  <div className='location-tooltip'>
                    USA
                  </div>
                </span>
                <img
                  className='slide-image2' 
                  src={map4}
                />
              </div>

              <div className="slide2">
                <div className='slide-content2'>
                  <span style={{fontSize: '32px', fontWeight: 500}}>
                    11k<br/>
                    <div style={{fontSize: '14px', textAlign: 'center', fontWeight: 700, marginTop: '2px'}}>Records</div>
                  </span>
                </div>
              </div>
              
              <div className="slide2">
                <div className='slide-content2'>
                  <span style={{fontSize: '32px', fontWeight: 500}}>
                    685kB<br/>
                    <div style={{fontSize: '14px', textAlign: 'center', fontWeight: 700, marginTop: '2px'}}>File Size</div>
                  </span>
                </div>
              </div>

              <div className="slide2">
                <span className='marker-container' style={{paddingTop: 1}}>
                  <i className="far fa-map-marker-alt icon-margin"></i>
                  <div className='location-tooltip'>
                    USA
                  </div>
                </span>
                <img
                  className='slide-image2' 
                  src={map4}
                />
              </div>
            </SlidingInfo>
          </div>
        </div>
        <div className="sellagen-grid-unit block4">
          <PriceBlock
            value='250.00'
            misc='(Download instantly)'
          />
        </div>
      </div>
    </>
  )
}

const FieldTags = ({fields}) => {
  return(
    <>
    <div style={{fontSize: '14px', fontWeight: 'bold', marginBottom: '0px'}}>
      Data Fields
    </div>
    <br/>
    <div className='field-container'>
      {
        fields.map((val, idx) => (
          <div className='field-tags' key={idx}>
            <strong>
              {val}
            </strong>
          </div>
        ))
      }
    </div>
    </>
  )
}

const PriceBlock = ({value, misc}) => {
  return(
    <>
      <span style={{fontSize: '10px', color: 'rgb(29, 191, 113)'}}><span style={{fontSize: '30px'}}>${value}</span>&nbsp;USD
        <br/>
        {
          misc ?
          <span style={{fontSize: '8px', color: 'rgb(120, 120, 120)'}}>{misc}</span>
          :
          null
        }
      </span>
    </>
  )
}

const SlidingInfo = ({children}) => {
  return (
    <>
      <section className="img-slider2">
        <div className="slider-container2">
          {children}
        </div>
      </section>
    </>
  )
}

const DownloadSample = ({iconVal, format, misc}) => {
  return (
    <>
      <span>
        <i className={iconVal}></i><br/>
        <div style={{fontSize: '14px', textAlign: 'center', fontWeight: 700}}>{format}</div>
      </span>
      <div style={{fontSize: '14px', marginLeft: '10px', fontWeight: 700}}>
        {misc}
      </div>
    </>
  )
}

export default FrontDeck