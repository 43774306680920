import React, { useContext, useState } from 'react'
import { FlowOverlayPrompt } from '../../../../components/flow-ui/flow-blocks'
import SandBoxContext from '../../../../contexts/SandBoxContext'
import Axios from '../../../../utilities/axios'

const ChatHistoryTransitionPanel = () => {
  const {sandboxSetState, setOverlay} = useContext(SandBoxContext)
  const [isLoading, setIsLoading] = useState(false)

  const saveChatHandler = async () => {
    if(!isLoading) {
      setIsLoading(true)
      let prompts = localStorage.getItem('prompts')
      if(prompts) {
        try {
          prompts = JSON.parse(prompts) || []
          localStorage.removeItem('prompts')
        } catch(err) {
          prompts = []
          localStorage.removeItem('prompts')
        }
      }
      let conversations = prompts.map(p => ({
        role: p.role, content: p.content, reference: p?.reference || null, tast: p?.task || null
      }))
      let response = await Axios.post('/function/save-chat', {conversations})
      if(response?.data?.chatID && response?.data?.chatID !== 'unknown') {
        sandboxSetState({
          chatID: response?.data?.chatID,
          prompts: prompts
        })
        setIsLoading(false)
        setOverlay(null)
      }
    }
  }

  return (
    <div style={{position: 'relative', width: '100%', maxWidth: '400px'}}>
      <FlowOverlayPrompt
        title='Chat History Detected'
        promptMsg={
        <>
          Previous chat history detected on Browser. Would you like to save this chat and continue? By saving, your chats will be accessible on other devices. You can clear your chats anytime.
          <br/><br/>
          Alternatively, you can start a new conversation, but this will erase your existing chats.
        </>
        }
        titleIcon={<i className="fal fa-comment-alt-lines" style={{color: 'orange'}}></i>}
        proceedMsg={<><i className="fal fa-save" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;Save & Continue</>}
        cancelMsg={<><i className="fal fa-plus" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;Start New Chat</>}
        onProceed={() => {
          saveChatHandler()
        }}
        onCancel={() => {
          Axios.get('/documents/remove-all').then(response => {
            // do nothing
          }).catch(err => {
            // do nothing
          })
          localStorage.removeItem('prompts')
          sandboxSetState({prompts: []})
          setOverlay(null)
        }}
      />    
    </div>
  )
}

export {ChatHistoryTransitionPanel}