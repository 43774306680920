import React, { useContext, useEffect, useState } from "react"
import { FilePond } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import Axios from "../../utilities/axios"
import axios from 'axios'
import SandBoxContext from '../../contexts/SandBoxContext'
import { FlowButton, FlowInput, FlowOverlayPrompt } from '../flow-ui/flow-blocks'
import Spinner from '../spinner'
import { useEscapeKey } from "../../utilities/hooks"

const StorageUploader = ({directory= '', disabled=false, onCompletion=()=>{}}) => {
  const [files, setFiles] = useState([])
  const getGCPSignedURL = async (fileName) => {
    let url = ''
    fileName = String(fileName).trim()
    if(fileName.endsWith('/')) {
      fileName = `${directory}${fileName}`
    } else {
      fileName = `${directory}/${fileName}`
    }
    try {
      url = (await Axios.post('storage/upload-link', { fileName })).data?.signedUrl
    } catch(err) {
      console.log(err)
    }
    return url
  }
  return (
    <FilePond
      files={files}
      onupdatefiles={setFiles}
      disabled={disabled}
      allowImagePreview={false}
      allowMultiple={true}
      maxFiles={10}
      onaddfile={() => {}}
      onprocessfile={() => {}}
      server={{
        process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
          let extension = file.name.substring(file.name.lastIndexOf('.'))
          const url = await getGCPSignedURL(file.name)
          const request = new XMLHttpRequest()
          console.log('Received url for', file.name, url)
          request.open('PUT', url)
          request.setRequestHeader('Content-Type', 'application/octet-stream')
          
          request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total)
          }

          request.onload = () => {
            if (request.status >= 200 && request.status < 300) {
              load('some upload completion msg')
            } else {
              error('some error msg')
            }
          }
          request.send(file)
          return {
            abort: () => {
              request.abort()
              abort()
            }
          }
        }
      }}
      name="file"
      labelIdle='<strong>Drag & Drop your files or <span class="filepond--label-action">Browse</span></strong>'
      credits=''
    />
  )
}

const CreateFolderPanel = ({folderChain=[], onConfirm=()=>{}}) => {
  const {setOverlay} = useContext(SandBoxContext)
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEscapeKey(() => {
    setOverlay(null)
  })
  
  const applyCreate = async () => {
    if(isLoading) return
    setIsLoading(true)
    try {
      let folderPrefix = folderChain.map(f => f.name)
      let p1 = preProcessNJoin(folderPrefix, value)
      let response = await Axios.post('storage/exec/newdir', {p1})
      if(response.data?.success === true) {
        onConfirm(value)
      } else {
        // failed
      }
    } catch(error) {
      console.log(error)
      // failed
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <FlowOverlayPrompt
      title='Create New Folder'
      promptMsg={
        <>
          <div style={{marginBottom: '5px'}}>Enter a name for the folder</div>
          <FlowInput
            style={{width: '100%', maxWidth: '100%'}}
            value={value}
            onChange={(e) => {
              setValue(String(e.target.value).replace(/\//g, ''))
            }}
            placeHolder='New Folder'
            autoFocus={true}
            onSubmit={applyCreate}
            disabled={isLoading}
          />
        </>
      }
      titleIcon={<i className="fal fa-folder-plus"></i>}
      proceedMsg={isLoading ? <div style={{display: 'flex', alignItems: 'center'}}><Spinner dark={true} size={10} style={{borderWidth: '2px'}}/>&nbsp;Create</div> : 'Create'}
      onProceed={applyCreate}
      proceedDisabled={value ? false : true}
      onCancel={() => setOverlay(null)}
    />
  )
}

const RenamePanel = ({file, folderChain=[], onConfirm=()=>{}}) => {
  const {setOverlay} = useContext(SandBoxContext)
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  
  if(!file) {
    return null
  }

  const applyRename = async () => {
    if(isLoading) return
    setIsLoading(true)
    try {
      let folderPrefix = folderChain.map(f => f.name)
      let p1 = preProcessNJoin(folderPrefix, file.name)
      let p2 = preProcessNJoin(folderPrefix, value)
      
      let response = await Axios.post('storage/exec/move', {p1, p2})
      if(response.data?.success === true) {
        onConfirm(value)
      } else {
        // failed
      }
    } catch(error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  let title = 'Rename File/Folder'
  if(typeof file.isDir === 'boolean') {
    title = file.isDir ? 'Rename Folder' : 'Rename File'
  }
  return (
    <FlowOverlayPrompt
      title={title}
      promptMsg={
        <>
          <div style={{marginBottom: '5px'}}>Enter new name for <span style={{textDecoration: 'underline'}}>{file.name}</span></div>
          <FlowInput
            style={{width: '100%', maxWidth: '100%'}}
            value={value}
            onChange={(e) => {
              setValue(String(e.target.value).replace(/\//g, ''))
            }}
            placeHolder={file.name}
            disabled={isLoading}
            onSubmit={applyRename}
          />
        </>
      }
      titleIcon={<i className="fal fa-pen" style={{color: 'royalblue'}}></i>}
      proceedMsg={isLoading ? <div style={{display: 'flex', alignItems: 'center'}}><Spinner dark={true} size={10} style={{borderWidth: '2px'}}/>&nbsp;Rename</div> : 'Rename'}
      onProceed={applyRename}
      proceedDisabled={value ? false : true}
      onCancel={() => setOverlay(null)}
    />
  )
}

const DeletePanel = ({file, folderChain=[], onConfirm=()=>{}}) => {
  const {setOverlay} = useContext(SandBoxContext)
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  
  if(!file) {
    return null
  }

  const applyDelete = async () => {
    if(value !== 'DELETE') return
    if(isLoading) return
    setIsLoading(true)
    try {
      let folderPrefix = folderChain.map(f => f.name)
      let p1 = preProcessNJoin(folderPrefix, file.name)
      
      let response = await Axios.post('storage/exec/remove', {p1})
      if(response.data?.success === true) {
        onConfirm(value)
      } else {
        // failed
      }
    } catch(error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  let title = 'Delete File/Folder'
  if(typeof file.isDir === 'boolean') {
    title = file.isDir ? 'Delete Folder' : 'Delete File'
  }
  return (
    <FlowOverlayPrompt
      title={title}
      promptMsg={
        <>
          <div style={{marginBottom: '5px'}}>The item <span style={{textDecoration: 'underline'}}>{file.name}</span> will be permanently deleted.</div>
          <div>Confirm deletion by typing "DELETE" below:</div>
          <FlowInput
            style={{width: '100%', maxWidth: '100%'}}
            value={value}
            onChange={(e) => {
              setValue(String(e.target.value).replace(/\//g, ''))
            }}
            placeHolder=''
            disabled={isLoading}
            onSubmit={applyDelete}
          />
        </>
      }
      titleIcon={<i className="fal fa-trash" style={{color: 'crimson'}}></i>}
      proceedMsg={isLoading ? <div style={{display: 'flex', alignItems: 'center'}}><Spinner dark={true} size={10} style={{borderWidth: '2px'}}/>&nbsp;Delete</div> : 'Delete'}
      onProceed={applyDelete}
      proceedDisabled={value === 'DELETE' ? false : true}
      onCancel={() => setOverlay(null)}
    />
  )
}

const MoveLoadingPanel = ({source, target, onConfirm=()=>{}}) => {
  const [isLoading, setIsLoading] = useState(false)
  const {setOverlay} = useContext(SandBoxContext)

  if(!source || !target) {
    return null
  }

  const applyMoving = async () => {
    if(isLoading) return
    setIsLoading(true)
    try {
      let response = await Axios.post('storage/exec/move', { p1: source, p2: target })
      if(response.data?.success === true) {
        onConfirm()
      } else {
        // failed
      }
    } catch(error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FlowOverlayPrompt
      title='Moving Items'
      promptMsg={
        <>
          <div style={{marginBottom: '5px'}}>Please confirm that you want to move the items.</div>
        </>
      }
      titleIcon={<i className="fal fa-exchange" style={{color: 'royalblue'}}></i>}
      proceedMsg={isLoading ? <div style={{display: 'flex', alignItems: 'center'}}><Spinner dark={true} size={10} style={{borderWidth: '2px'}}/>&nbsp;Moving</div> : 'Move'}
      onProceed={applyMoving}
      proceedDisabled={isLoading}
      onCancel={() => setOverlay(null)}
    />
  )
}

const preProcessNJoin = (folderPrefix, value) => {
  // removing the top most directory
  if(folderPrefix[0] === 'My Drive') {
    folderPrefix = folderPrefix.slice(1)
  }
  folderPrefix = folderPrefix.join('/')
  let path = ''
  if(folderPrefix.endsWith('/')) {
    path = String(`${folderPrefix}${value}`).replace(/^\/+/, '')
  } else {
    path = String(`${folderPrefix}/${value}`).replace(/^\/+/, '')
  }
  return path
}

const getFilePath = (fileMap, file) => {
  let chain = getFolderChain(fileMap, file.parentId)
  let folderPrefix = chain.map(f => f.name)
  return preProcessNJoin(folderPrefix, file.name)
}

const getFolderChain = (fileMap, currentFolderId) => {
  const currentFolder = fileMap[currentFolderId]
  const folderChain = [currentFolder]

  let parentId = currentFolder.parentId
  while (parentId) {
    const parentFile = fileMap[parentId]
    if (parentFile) {
      folderChain.unshift(parentFile)
      parentId = parentFile.parentId
    } else {
      break
    }
  }

  return folderChain
}

const createFileMap = (fileList) => {
  const fileMap = {}

  // Initial pass: Create entries for each file/folder
  fileList.forEach((file) => {
    const { path, isDir, metadata } = file;

    fileMap[path] = {
      id: path,
      name: path.split('/').filter(Boolean).pop(),
      isDir: isDir,
      metadata: metadata,
      childrenIds: [],
      parentId: null, // Initialize parentId as null
      size: metadata.size || null, // Extract size from metadata
      modDate: metadata.updated || null, // Extract modDate from metadata
    }
  })

  // Second pass: Set parentId and establish parent-child relationships
  fileList.forEach((file) => {
    let { path } = file
    let modPath = path.replace(/\/+$/, '')
    const parentPath = modPath.substring(0, modPath.lastIndexOf('/') + 1) || null
    if (parentPath && fileMap[parentPath]) {
      fileMap[parentPath].childrenIds.push(path)
      fileMap[path].parentId = parentPath
    }
  })

  return fileMap
}

const downloadFile = async (file, folderChain, willEncodeURIComponent=true) => {
  let folderPrefix = folderChain.map(f => f.name)
  let p1 = preProcessNJoin(folderPrefix, file.name)
  try {
    // Fetch the file as a Blob
    if(willEncodeURIComponent) {
      var path = encodeURIComponent(p1)
    }
    let url = `https://cloud.sellagen.com/v1/My Drive/${path}`
    const response = await axios.get(url, {
      withCredentials: true,
      responseType: 'blob', // Ensures the response is a Blob
    })

    // Create a Blob URL for the fetched data
    const blobURL = URL.createObjectURL(response.data)

    // Create an anchor element
    const anchor = document.createElement('a')
    anchor.href = blobURL

    // Set the filename suggestion; this triggers the browser's save-as popup
    const contentDisposition = response.headers['content-disposition']
    let filename = 'download'  // Default filename
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?(.+?)"?$/)
      if (match) {
        filename = match[1]
      }
    }
    anchor.download = filename

    // Append the anchor to the DOM
    document.body.appendChild(anchor)

    // Trigger the download
    anchor.click()

    // Clean up by revoking the Blob URL and removing the anchor
    URL.revokeObjectURL(blobURL)
    document.body.removeChild(anchor)
  } catch (error) {
    console.error("Error downloading file:", error)
    if(willEncodeURIComponent) {
      path = encodeURIComponent(p1)
    }
    let url = `https://cloud.sellagen.com/v1/My Drive/${path}`
    window.open(url, '_blank')
  }
}


export {
  StorageUploader, 
  CreateFolderPanel, 
  RenamePanel, 
  DeletePanel, 
  MoveLoadingPanel, 
  getFilePath,
  createFileMap,
  downloadFile
}