import React from 'react';
import Header from '../components/header/header.jsx'
// import Lister from '../components/lister/index.jsx'
import Lister from '../components/lister/lister2.jsx'

const AddListing = ({edit=false}) => {
  return(
    <>
      <Header/>
      <Lister edit={edit}/>
    </>
  )
}

export default AddListing