import React, { useContext, useState, useSyncExternalStore } from 'react'
import DataImportManager from './data-import'
import './system.scss'
import './styles.scss'
import { ConsoleContext } from '../../contexts/ConsoleContext'
import { projectComponents } from './project-components'
import InstanceManager from './compute-instance'
import DeployManager from './deploy-instance'
import AutoPilotManager from './autopilot-component'

const SystemPanel = () => {
  const {conversations, activePanels, activeComponent} = useContext(ConsoleContext)
  const hasConversations = conversations?.length > 0
  var currentComponentObj = null
  
  const allComponentPanels = Object.keys(projectComponents)
  allComponentPanels.push('prj-manager')
  const currentActiveComponents = activePanels.filter(value => allComponentPanels.includes(value))
  if(currentActiveComponents.length > 0) {
    currentComponentObj = getComponent(currentActiveComponents[0])
  } else if(hasConversations) {
    currentComponentObj = <MiniManager/>
  } else {
    currentComponentObj = getComponent(activeComponent)
  }
  
  return (currentComponentObj)
}

const getComponent = (componentID, wrapped = true) => {
  let component = null
  if(componentID === 'prj-manager') component = <ProjectManager/>
  else if(componentID === 'datablock') component = <DataImportManager/>
  else if(componentID === 'compute') component = <InstanceManager/>
  else if(componentID === 'deploy') component = <DeployManager/>
  else if(componentID === 'autopilot') component = <AutoPilotManager/>
  else return null
  if(wrapped) return <SystemPanelWrapper>{component}</SystemPanelWrapper>
  else return component
}

const SystemPanelWrapper = (props) => {
  const {conversations} = useContext(ConsoleContext)
  const hasConversations = conversations?.length > 0
  return (
    <div className='sv2-spdrn-system-panel'>
      <div className='system' style={ hasConversations ? {height: '100%'}: {maxHeight: '100%', overflowY: 'auto'}}>
        {props.children}
      </div>
    </div>
  )
}

const SystemWindowControls = ({linkProject=true}) => {
  const {openPanel, closePanel, conversations, activeComponent} = useContext(ConsoleContext)
  const hasConversations = conversations?.length > 0
  return (
    <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
      {
        linkProject &&
        <div
          style={{cursor: 'pointer', color: 'rgb(29, 191, 113)'}}
          onClick={() => {openPanel('prj-manager')}}
        >
          <i className="fas fa-layer-group"></i>
        </div>
      }
      { hasConversations && 
        <div
          style={{cursor: 'pointer', marginLeft: '15px'}}
          onClick={() => {closePanel(activeComponent)}}
        >
          <i className="far fa-compress-alt"></i>
        </div>
      }
    </div>
  )
}

const MiniManager = () => {
  const {openPanel, activeComponent} = useContext(ConsoleContext)
  var currentComponent = null
  if(Object.keys(projectComponents).includes(activeComponent)) {
    currentComponent = projectComponents[activeComponent]
  } else {
    currentComponent = {
      componentID: 'prj-manager',
      componentTitle: 'Project Manager',
      componentIcon: 'fas fa-layer-group',
      color: 'rgb(29, 191, 113)'
    }
  }
  return (
    <div className='sv2-spdrn-system-mini'>
      <div className='system' style={{display: 'flex', alignItems: 'center'}}>
        {
          activeComponent !== 'prj-manager' &&
          <div className='sv2-system-panel-control sv2-panel-mini'
            style={{marginRight: '45px'}}
            onClick={() => {openPanel('prj-manager')}}
          >
            <i className="fas fa-layer-group" style={{color: 'rgb(29, 191, 113)'}}></i>
          </div>
        }
        <div className='sv2-system-panel-control sv2-panel-mini'
          onClick={() => {openPanel(currentComponent.componentID)}}
        >
          <i className="far fa-expand-alt"></i>
        </div>
        <div>
          <i 
            className={currentComponent.componentIcon}
            style={{color: currentComponent.color}}
          ></i>&nbsp;&nbsp;{currentComponent.componentTitle}
        </div>
      </div>
    </div>
  )
}

const ProjectManager = () => {
  return (
    <div className='sv2-cloud-base-manager'>
      <div className='sv2-manager-subblock sv2-manager-title'>
        <i className="fas fa-layer-group" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;&nbsp;Project Manager
        <SystemWindowControls linkProject={false}/>
      </div>
      <div className="sv2-manager-subblock base-content">
        <div className="sv2-base-content-title">Project Components</div>
        <div className="sv2-manager-data-list">
          {
            Object.keys(projectComponents)
            .map((comp, i) => 
              <ProjectComponentCard 
                key={comp} 
                component={projectComponents[comp]}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}

const ProjectComponentCard = ({component}) => {
  const {project, updateProject, state, setState} = useContext(ConsoleContext)
  const activeComponents = project?.components || []
  const isAdded = activeComponents.includes(component.componentID)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [willDelete, setWillDelete] = useState(false)
  return (
    <div className="sv2-manager-data-card"
      onClick={() => {
        if(!isAdded) {
          updateProject({
            task: 'update-component',
            action: 'add',
            componentID: String(component.componentID)
          }, () => {
            if(!state.activeEditor && (['compute', 'deploy'].includes(component.componentID))) {
              setState({activeEditor: component.componentID})
            }
          })
        } else {
          setShowConfirmation(true)
        }
      }}
    >
      <div className="sv2-mgr-dc-title" style={{width: '100%'}}>
        <i className={component.componentIcon} style={{color: component.color, width: '23px'}}></i>
        {component.componentTitle}
        {isAdded && <div className="sv2-mgr-comp-action" style={{marginLeft: '5px', color: 'rgb(29, 191, 113)', opacity: 1}}>Added</div>}
        <div className="sv2-mgr-comp-action">
          {isAdded ? 
            <><i className="fas fa-layer-minus"></i>&nbsp;&nbsp;Remove</>
            :
            <><i className="fas fa-layer-plus"></i>&nbsp;&nbsp;Add</>
          }
        </div>
      </div>
      <div className="sv2-mgr-dc-body" style={{width: '100%', marginTop: '5px', lineHeight: 1.3}}>
        {component.description}
        {showConfirmation && <>
          <br/><br/>
          Are you sure you want to remove this component? Type remove to confirm.
          <div className="sv2-mgr-ds-search sv2-mgr-ds-search-alt" style={{marginTop: '5px'}}>
            <input
              placeholder='remove'
              className="sv2-mgr-ds-search-input"
              autoFocus={true}
              onChange={(e) => {
                if(e.target.value === 'remove') {
                  setWillDelete(true)
                } else {
                  setWillDelete(false)
                }
              }}
            />
          </div>
          <div style={{display: 'flex', marginTop: '10px'}}>
            <div className="sv2-manager-subblock base-content base-add-ds-btn"
              style={{height: '30px', fontSize: '12px', marginBottom: 0, color: 'rgb(190, 190, 190)', fontWeight: 500, borderRadius: '3px'}}
              onClick={(e) => {
                setShowConfirmation(false)
                e.stopPropagation()
              }}
            >Cancel</div>&nbsp;&nbsp;&nbsp;
            
            <div className="sv2-manager-subblock base-content base-add-ds-btn"
              style={{
                height: '30px', fontSize: '12px', marginBottom: 0, borderRadius: '3px',
                ...willDelete ? {color: 'crimson', cursor: 'pointer'} : {color: 'rgb(120, 120, 120)', cursor: 'not-allowed'}
              }}
              onClick={(e) => {
                updateProject({
                  task: 'update-component',
                  action: 'remove',
                  componentID: String(component.componentID)
                }, data => {
                  setShowConfirmation(false)
                })
                e.stopPropagation()
              }}
            >Remove</div>
          </div>
        </>}
      </div>
    </div>
  )
}

export default SystemPanel
export {SystemWindowControls}