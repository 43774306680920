import { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react'
import Axios from './axios'

// returns if a component is mounted or not
const useIsMounted = () => {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return useCallback(() => isMounted.current, [])
}

const useEscapeKey = (onEscapePress) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        onEscapePress()
      }
    }
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [onEscapePress])
}

// to lock scrolling outside of the overlay
const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = originalStyle
    }
  }, [])
}

// useState with callback
const useStatePro = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null)

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb
    setState(state)
  }, [])

  useEffect(() => {
    if (cbRef.current) {
      console.log('usestate pro value', state)
      cbRef.current(state)
      cbRef.current = null
    }
  }, [state])

  return [state, setStateCallback]
}

const useFetch = ({
  method='get', url='/', payload=null,
  onSuccess=null,
  onFailure=null
}) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const isMounted = useIsMounted()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await Axios({method, url, data: payload})
        if(isMounted) {
          if (response.status !== 200 || response.status !== 201) {
            throw new Error({errorMsg: response.statusText, response})
          }
          if(onSuccess) onSuccess(response)
          setIsLoading(false)
          setData(response.data)
          setError(null)
        }
      } catch ({errorMsg, response}) {
        if(isMounted) {
          if(onFailure) onFailure(response)
          setError(`${errorMsg}`)
          setIsLoading(false)
        }
      }
    }
    fetchData()
  }, [url])
  return {data, isLoading, error}
}

const useInterval = (callback, delay) => { 
  const savedCallback = useRef()
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => { 
    function func() { 
      savedCallback.current(); 
    } 
    if (delay !== null) { 
      let id = setInterval(func, delay); 
      return () => clearInterval(id); 
    } 
  }, [delay])
}


export { 
  useLockBodyScroll,
  useEscapeKey,
  useIsMounted,
  useStatePro,
  useInterval,
  useFetch,
}