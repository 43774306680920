import React, {Fragment, useContext } from "react";
import '../../../scss/components/section.scss';
import PropTypes from 'prop-types';
import './styles.scss'
import { formatBytes } from "../../../utilities/handyFunctions";
import { getCategory } from "../../../utilities/categories";
import MiniDonationPanel from "../../mini-donation-panel";
import {Context} from '../../../contexts/GlobalContext'

Card.propTypes = {
  item: PropTypes.object,
  onClickPrice: PropTypes.func
}

function Card(props) {
    const { item } = props
    const willShowStatus = props?.showStatus || false
    const {setGlobalState} = useContext(Context)
    var altContent = ''
    if(willShowStatus) {
        if(item?.approvalStatus === 'approved') {
            altContent = (
                <span className='sellagen-tag green'><strong>Approved</strong></span>
            )
        } else if(item?.approvalStatus === 'rejected') {
            altContent = (
                <span className='sellagen-tag red'><strong>Rejected</strong></span>
            )
        } else {
            altContent = (
                <span className='sellagen-tag yellow'><strong>Reviewing</strong></span>
            )
        }
    } else {
        var name = String(item?.userName).split('#')
        if(name.length === 1) {
            altContent = (
                <>By <a className="sellagen-hyperlink" href={`https://sellagen.com/profile/${item.userID._id}`}>
                    {name[0]}
                    </a>&nbsp;&nbsp;{item?.userID?.verified ? <i className="fas fa-check-circle"></i> : ''}</>
            )
        } else {
            altContent = (
                <>By <a className="sellagen-hyperlink" href={`https://sellagen.com/profile/${item.userID._id}`}>{`${name[0].slice(0, 1)}. ${name[1]}`}</a>&nbsp;&nbsp;{item?.userID?.verified ? <i className="fas fa-check-circle"></i> : ''}</>
            )
        }
    }
    return (
        <Fragment>
            <div 
                className=""
                key={item._id}
                style={{padding: 0, margin: 0}}
            >
                <div className='cardContent'>
                    <div className="seller">
                        {altContent}
                    </div>
                    <div className="category-card">
                        { props.donationPrompt === true ? 
                            <span
                                className="item-edit"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setGlobalState({
                                        overlayContent: <MiniDonationPanel datasetID={item._id} donationLink={item.donationLink}/>,
                                        overlayExitCB: () => {window.location.reload()}
                                    })
                                }}
                            >
                                {item.donationLink ? <i className="fas fa-donate"></i>: <i className="fal fa-donate"></i>}&nbsp;&nbsp;&nbsp;
                            </span>
                            :
                            null
                        }
                        {
                            props.edit ?
                            <span
                                className="item-edit"
                                onClick={(e) => {
                                    window.open(`/editItem/${item._id}`, '_blank')
                                    e.stopPropagation()
                                }}
                            ><i className="fal fa-pen"></i>&nbsp;&nbsp;</span>
                            :
                            null
                        }
                        {getCategory(item.category).icon}
                    </div>
                    <div className="title">
                        <strong>{String(item.title).slice(0, 75)}</strong>
                    </div>
                    <hr className="divider"/>
                    <span style={{
                        fontSize: '14.5px',
                        fontWeight: 500
                    }}>
                        <i className="far fa-arrow-to-bottom"></i>&nbsp;&nbsp;{formatBytes(item.size)}
                    </span>
                    <span style={{float: 'right'}}>
                        { item?.licenseType === 'open-source' ?
                            <span style={{
                                border: '1px solid',
                                borderRadius: '5px',
                                padding: '2px 5px',
                                fontSize: '12px',
                                fontFamily: "Roboto Mono, Sans Mono, Consolas, Courier, monospace"
                            }}>Open Source</span>
                            :
                            <><i className="fal fa-dollar-sign"></i>&nbsp;<strong>{item.price}<span style={{fontSize: '8px'}}> USD</span></strong></>
                        }
                    </span>
                </div>
            </div>
        </Fragment>
    )
}

export default Card;