import React from 'react'
import "../styles.scss"

const SignUpPrompt = ({title, msg, btnMsg, btnLink='/register'}) => {
  return (
    <>
      <div className="sellagen-prompt-title">
        <strong>{title || 'Sign Up'}</strong>
      </div>
      <p className="sellagen-para">
        {msg || 'You need to sign up before proceeding'}
      </p>
      <br/>
      <a href={btnLink}>
        <button
          className={`sellagen-prompt-button`}
        >
          <strong>
            {btnMsg || 'Sign Up'}
          </strong>
        </button>
      </a>
    </>
  )
}

export default SignUpPrompt