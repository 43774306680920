import React, { useContext } from "react"
// import './system.scss'
import './styles.scss'
import { projectComponents } from "./project-components"
import { ConsoleContext } from "../../contexts/ConsoleContext"
import { SystemWindowControls } from "./system-manager"

const InstanceManager = () => {
  const computeComponent = projectComponents['compute']
  const {project} = useContext(ConsoleContext)
  return (
    <div className='sv2-cloud-base-manager'>
      <div className='sv2-manager-subblock sv2-manager-title'>
        <i className={computeComponent.componentIcon} style={{color: computeComponent.color}}></i>&nbsp;&nbsp;{computeComponent.componentTitle}
        <SystemWindowControls/>
      </div>
      <div className="sv2-manager-subblock base-content">
        <div className="sv2-base-content-title">Runtime Details</div>
        <div className="sv2-manager-data-list">
          <div className="sv2-manager-data-card">
            {
              project?.mainEntryPoint &&
              <div className="sv2-mgr-dc-title" style={{width: '100%', fontSize: '14px', overflow: 'visible', marginBottom: '5px'}}>
                Execution Entrypoint&nbsp;&nbsp;<span className="sv2-manager-filename" style={{color: 'rgb(29, 191, 113)', backgroundColor: 'rgba(29, 191, 113, 0.2)'}}>{project.mainEntryPoint}</span>
              </div>
            }
            <div className="sv2-mgr-dc-body" style={{width: '100%', marginTop: '0px', lineHeight: 1.5}}>
              <div className="sv2-manager-instance-details">Language</div>:&nbsp;Python3<br/>
              <div className="sv2-manager-instance-details">Version</div>:&nbsp;3.10<br/>
              <div className="sv2-manager-instance-details">Datasource</div>:&nbsp;
                {project?.datasetImports?.length > 0 ? <span style={{color: projectComponents['datablock'].color}}>{projectComponents['datablock'].componentTitle}</span> : 'None'}<br/>
            </div>
          </div>
        </div>
      </div>
      <div className="sv2-manager-subblock base-content">
        <div className="sv2-base-content-title">Instance Details</div>
        <div className="sv2-manager-data-list">
          <div className="sv2-manager-data-card">
            <div className="sv2-mgr-dc-body" style={{width: '100%', marginTop: '0px', lineHeight: 1.5}}>
              <div className="sv2-manager-instance-details">Accelerator</div>:&nbsp;
              <div className="sv2-cloud-misc-action-button misc-alt" style={{color: 'rgb(29, 191, 113)'}}>
                <strong>Nvidia A100 40 GB</strong>
              </div><br/>
              <div className="sv2-manager-instance-details">Number of Acc.</div>:&nbsp;1<br/>
              <div className="sv2-manager-instance-details">Disk Size</div>:&nbsp;25 GB<br/>
              <div className="sv2-manager-instance-details">Runtime</div>:&nbsp;6 Hours<br/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstanceManager