import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { Context } from '../../contexts/GlobalContext'
import axios from '../../utilities/axios'
import './styles.scss'

function APIManager(props) {
  const { globalState, patchUser } = useContext(Context)
  var view = null
  const [apiKey, setApiKey] = useState(globalState?.user?.apiKey || '')
  const [apiKeyUpdatedAt, setApiKeyUpdatedAt] = useState(globalState?.user?.apiKeyUpdatedAt || '')

  useEffect(() => {
    setApiKey(globalState?.user?.apiKey || '')
    setApiKeyUpdatedAt(globalState?.user?.apiKeyUpdatedAt || '')
  }, [])

  const isEligibleForUpdate = (lastUpdate) => {
    if(lastUpdate) {
      let lastUpdateValue = new Date(lastUpdate).valueOf()
      let currentDateValue = new Date().valueOf()
      let diff = Math.abs(lastUpdateValue - currentDateValue) / (1000 * 3600 * 24)
      return diff > 7.00
    } else {
      return false
    }
  }

  const requestAPIKeyUpdate = () => {
    if(isEligibleForUpdate(globalState?.user?.apiKeyUpdatedAt)) {
      axios.get('user/update-api-key').then(response => {
        let currDate = new Date()
        alert('API key updated')
        setApiKey(response.data.key || '')
        setApiKeyUpdatedAt(currDate)
        patchUser({
          apiKey: response.data.key || '',
          apiKeyUpdatedAt: currDate
        })
      }).catch(err => {
        console.log(err)
      })
    }
  }

  view = (
    <>
      <div className='api-manager-container'>
        <div className='api-manager-content'>
          <div style={{fontSize: '30px', fontWeight: 900, color: 'rgb(70, 70, 70)'}}>
            Sellagen API Key
          </div>
          <p style={{fontSize: '14px', color: 'rgb(100, 100, 100)', fontWeight: 500, padding: '18px 0'}}>
            The API key allows you to authenticate API requests. The API key is unique to your account.
          </p>
          <div className='api-block nice-font'>
            {apiKey}&nbsp;
            <i className="fas fa-copy copy-button"
              onClick={() => {
                navigator.clipboard.writeText(apiKey).then(() => {
                  alert('API key copied to clipboard')
                }).catch(err => {
                  console.log(err)
                })
              }}
            ></i>
          </div>
          <div className={`api-block api-button${isEligibleForUpdate(apiKeyUpdatedAt) ? '':' api-disabled'}`}
            onClick={requestAPIKeyUpdate}
          >
            Generate New Key
          </div>
        </div>
      </div>
    </>
  )
  
  return (view)
}

export default APIManager