import React , {useState} from 'react';
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import { Link } from "react-router-dom";
import img1 from '../assets/images/icon/icon1.svg';
import img2 from '../assets/images/icon/icon2.svg';
import img3 from '../assets/images/icon/icon3.svg';

function Contact(props) {

    const [dataContact] = useState(
        [
            {
                img: img1,
                text: 'Contact Phone',
                adress: 'Not available',
            },
            {
                img: img2,
                text: 'Contact Mail',
                adress: 'support@sellagen.com',
            },
            {
                img: img3,
                text: 'Registered Office',
                adress: 'Wilmington, Delaware',
            }
        ]
    )

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <section className="tf-section tf-map" style={{paddingTop: '180px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-adderss">
                                <div className="inner-box flex-one">
                                    {
                                        dataContact.map((item,index) => (
                                            <div className="post flex" key={index}>
                                                <div className="icon icon-phone">
                                                    <img src={item.img} alt="" />
                                                </div>
                                                <div className="content">
                                                    <p className="text-color-1">{item.text}</p>
                                                    <Link className="fw-4 fs-20" to="tel:+123456789">{item.adress}</Link>
                                                </div> 
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className=" flat-contact flat-edit-profile">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-contact">
                                <div className="heading center">
                                    <h2 className="fw-6">Contact Us</h2>
                                    <p>Submit a general inquiry and we'll get in touch soon!</p>
                                </div>
                                <div className="form-upload-profile post"> 
                                    <form action="#" className="form-profile">                     
                                        <div className="form-infor-profile">
                                            <div className="form-infor flex flat-form">
                                                <div className="info-account">                              
                                                    <fieldset>                                               
                                                        <input type="text" placeholder="Your Full Name" required />
                                                    </fieldset>
                                                    <fieldset>                                            
                                                        <input type="number" placeholder="+55 (121) 234 444" required />                                             
                                                    </fieldset>                                                                                                       
                                                </div>
                                                <div className="info-social">
                                                    <fieldset>                                                
                                                        <input type="email" placeholder="jane.doe@gmail.com" required />
                                                    </fieldset>                                  
                                                    
                                                    <fieldset>                                                   
                                                        <input type="text" placeholder="Enter Your Country" required />                                          
                                                    </fieldset>                                       
                                                </div>  
                                            </div> 
                                            <fieldset className="message">                           
                                                <textarea placeholder="Write Your Message" tabIndex="4" rows="3" required></textarea>                           
                                            </fieldset>                                                              
                                        </div>
                                        <div className="button-contact center">
                                            <button className="submit" type="submit">
                                                Submit Now
                                            </button>     
                                        </div>                                      
                                    </form>              
                                </div> 
                            </div>                           
                        </div>
                    </div>               
                </div>
            </div>
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Contact;