import React, { useState } from "react"
import { useIsMounted } from "../../utilities/hooks"
import Axios from "../../utilities/axios"
import Spinner from "../spinner"

const MiniDonationPanel = ({datasetID, donationLink, onUpdate}) => {
  const [link, setLink] = useState(donationLink || '')
  const [willEdit, setWillEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isMounted = useIsMounted()
  
  const linkUpdateHandler = (value, remove=false) => {
    if(!isLoading) {
      if(value || remove) {
        setIsLoading(true)
        Axios.patch('/user/dataset/edit', {datasetID, payload: {donationLink: value}})
        .then(response => {
          if(response?.data && isMounted) {
            if(onUpdate) onUpdate(response?.data?.donationLink)
          } else {
            console.log('Error updating link')
          }
          setIsLoading(false)
          setWillEdit(false)
        })
        .catch(err => {
          setIsLoading(false)
          console.log(err)
        })
      }
    }
  }

  return (
    <div style={{width: '100%', color: 'rgb(70, 70, 70)', fontSize: '18px', lineHeight: 1.5}}>
      <strong>Donation Link</strong><br/>
      <div style={{marginTop: '15px', fontSize: '14px'}}>
        You can include donation links alongside your Open Source datasets to enable supporters to offer incentives for your contributions.<br/><br/> Please note that the donation process is not managed by the Sellagen system, and you are free to use your preferred payment service for this purpose.<br/><br/>
      </div>
      <div style={{marginTop: '15px', fontSize: '14px', fontWeight: 800, position: 'relative'}}>
      {willEdit ? 
        <div style={{width: '100%', display: 'flex'}}>
          <input 
            className="sv2-general-input"
            style={{flex: 1}}
            autoFocus
            value={link}
            placeholder="https://example.com"
            onChange={e => {
              setLink(e.target.value)
            }}
          />
          <div className="sv2-general-button"
            style={{borderRadius: '7px', width: '50px', marginLeft: '10px', fontSize: '20px'}}
            onClick={() => {
              linkUpdateHandler(link)
            }}
          >
            {isLoading ? <Spinner/> : <i className="far fa-save"></i>}
          </div>
        </div>
        :
        link ?
          <>
            <div style={{
              color: 'rgb(29, 191, 113)', 
              display: 'inline-block', 
              width: '200px', 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
              <i className="far fa-link"></i>&nbsp;&nbsp;{link}
            </div>
            <div 
              className="sv2-general-button"
              style={{float: 'right', marginRight: '5px', padding: '5px'}}
              onClick={() => {
                linkUpdateHandler('', true)
                setLink('')
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </div>
          </>
          :
          <div className="sv2-general-button"
            style={{padding: '10px 0', borderRadius: '10px'}}
            onClick={() => {setWillEdit(true)}}
          >
            Add Donation Link
          </div>
      }
      </div>
    </div>
  )
}

export default MiniDonationPanel