import React from 'react'
import APIBlock from './api-block'
import CodeViewer from '../code-viewer'
import './styles.scss'

const downloadSourceCode = {
  '1': {
    language: 'syntax',
    className: 'language-javascript white-hljs',
    source: "GET /v1/storage/<data-path> HTTP/1.1\nHost: api.sellagen.com\nX-Api-Key: your-api-key"
  },
  '2': {
    language: 'bash',
    className: 'language-bash',
    source: "$ curl https://api.sellagen.com/v1/storage/<data-path> -H \"X-Api-Key: your-api-key\" -o filename.ext"
  },
  '3': {
    language: 'javascript',
    className: 'language-javascript',
    source: "const fs = require('fs')\nconst request = require('request')\n\n// replace <data-path> with desired data path\nvar url = 'https://api.sellagen.com/v1/storage/<data-path>'\n\nrequest(url, {headers: {'X-Api-Key': 'your-api-key'}})\n.on('request', res => {\n  var fileName = String(res.request.uri.pathname).split('/').pop()\n  res.pipe(fs.createWriteStream(fileName))\n})\n.on('error', err => {\n  console.log(err)\n})"
  },
  '4': {
    language: 'python',
    className: 'language-python',
    source: "import requests ## Make sure you have the python requests package -> pip install requests\nfrom urllib.parse import urlparse\n\n## replace <data-path> with desired data path\nfile_url = 'https://api.sellagen.com/v1/storage/<data-path>'\napi_key = 'your-api-key'\n\nwith requests.get(file_url, headers={'X-Api-Key': api_key}, stream=True) as response:\n  file_name = urlparse(file_url).path.split('/')[-1]\n  if response.status_code == 200:\n    with open(file_name, 'wb') as file:\n      for chunk in response.iter_content(chunk_size=8192):\n        file.write(chunk)\n  else:\n    print('Download failed')"
  }
}

const uploadSourceCode = {
  '1': {
    language: 'syntax',
    className: 'language-javascript white-hljs',
    source: "PUT /v1/storage/<data-path> HTTP/1.1\nHost: api.sellagen.com\nContent-Type: application/octet-stream\nX-Api-Key: your-api-key\n\nBody"
  },
  '2': {
    language: 'bash',
    className: 'language-bash',
    source: "$ curl -X PUT https://api.sellagen.com/v1/storage/<data-path> -H \"X-Api-Key: your-api-key\" -H \"Content-Type: application/octet-stream\" --data-binary \"/path/to/file.ext\""
  },
  '3': {
    language: 'javascript',
    className: 'language-javascript',
    source: "const fs = require('fs')\nconst request = require('request')\n\n// replace <data-path> with desired data path\nvar url = 'https://api.sellagen.com/v1/storage/<data-path>'\nvar fileName = '/path/to/file.ext'\n\nrequest.put(url, {\n  headers: {\n    'X-Api-Key': 'your-api-key'\n  },\n  formData: {\n    file: fs.createReadStream(fileName)\n  }\n})\n.on('response', res => {\n  if(res.statusCode === 200) {\n    console.log('File uploaded successfully')\n  } else {\n    console.log('There was an error')\n  }\n})"
  },
  '4': {
    language: 'python',
    className: 'language-python',
    source: "import requests ## Make sure you have the python requests package -> pip install requests\n\n## replace <data-path> with desired data path\nfile_url = 'https://api.sellagen.com/v1/storage/<data-path>'\napi_key = 'your-api-key'\nfile_path = '/path/to/file.ext'\n\nfile_obj = open(file_path, 'rb')\n\nresponse = requests.put(file_url, headers={'X-Api-Key': api_key}, data=file_obj, stream=True)\n\nif response.status_code == 200:\n  print('File uploaded successfully')\nelse:\n  print('There was an error')"
  }
}

const APIDocumentation = () => {
  return(
    <div className='api-docs-container'>
      <div style={{fontSize: '30px', fontWeight: 900, color: 'rgb(70, 70, 70)', marginBottom: '20px'}}>
        Sellagen API Documentation
      </div>
      <APIBlock
        key={'sv2-blk-1'}
        title={<><span style={{color: 'rgb(29, 191, 113)'}}>Downloading</span> a dataset from Sellagen</>}
        description={
          <>
            This API enables you to programmatically download datasets from Sellagen. 
            Each request permits the download of a single dataset file. 
            Presently, all datasets on our server are treated as individual files. 
            In cases where a dataset comprises multiple files, they are compressed into a single zip file for delivery. 
            To download, simply copy the generated data path of the desired dataset, which can be located on the datasets page. 
            Also ensure to include your API Key in the authorization header <InlineSourceCode sourceCode={'X-Api-Key'}/> to authenticate your request.
          </>
        }
        miscs={[
          {
            title: 'API Usage',
            component: <CodeViewer sourceCode={downloadSourceCode}/>
          }
        ]}
      />
      <APIBlock
        key={'sv2-blk-2'}
        title={<><span style={{color: 'rgb(29, 191, 113)'}}>Uploading</span> a dataset to Sellagen</>}
        description={
          <>
            This API enables you to programmatically upload datasets to Sellagen.
            Each request permits the upload of a single dataset file.
            Presently, all datasets on our server are treated as individual files. 
            Hence if your dataset contain multiple files or folders, please pack them into one zip file.
            Before uploading the dataset, it's necessary to list the dataset and access the generated data path. 
            Also ensure to include your API Key in the authorization header <InlineSourceCode sourceCode={'X-Api-Key'}/> to authenticate your request.
          </>
        }
        miscs={[
          {
            title: 'API Usage',
            component: <CodeViewer sourceCode={uploadSourceCode}/>
          }
        ]}
      />
    </div>
  )
}

const InlineSourceCode = ({sourceCode}) => {
  return (
    <span className='sv2-inline-source-code'>
      {sourceCode}
    </span>
  )
}

export default APIDocumentation