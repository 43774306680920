import React from "react"
import './styles.scss'

const DummySearch = () => {
  return (
    <div className="dummySearchBar">
      <div className="wrapper">
        <div className="static-txt">Dataset on</div>
        <ul className="dynamic-txts">
          <li><span>Pharmaceutical Trials</span></li>
          <li><span>Stock Market Analysis</span></li>
          <li><span>Daily Consumer Behavior</span></li>
          <li><span>Annual Carbon Emmision</span></li>
        </ul>
      </div>
    </div>
  )
}

export default DummySearch