import React from 'react'
import './styles.scss'

const APIBanner = () => {
  return (
    <div className='sv2-api-banner'>
      <div className='sv2-api-bnr-blk1'>
        <div style={{
          fontSize: '26px', fontWeight: 700, color: 'rgb(90, 90, 90)', lineHeight: 1.4
        }}>
          Integrate Sellagen API
        </div>
        <br/><br/>
        <div style={{
          fontSize: '12px', fontWeight: 400, color: 'rgb(90, 90, 90)', lineHeight: 1.5
        }}>
          The Sellagen API enables seamless connectivity and integration of data from our servers directly into your workflows, applications, and services.
          Dive into our comprehensive guides and real-world examples to effortlessly integrate the Sellagen API, kickstart your data buying, selling, and sharing journey.
        </div>
        <br/><br/>
        <div className='sv2-api-get-started'
          onClick={() => {window.open('/sellagen-api', '_blank')}}
        >
          Get started with API&nbsp;&nbsp;<i className="fas fa-external-link"></i>
        </div>
      </div>
      <div className='sv2-api-bnr-blk2'>
        <div className='sv2-code-blk-tab'>
          <i className="fas fa-terminal"></i>&nbsp;&nbsp;Download Dataset
        </div>
        $ curl https://api.sellagen.com/v1/storage/<span style={{color: 'rgb(152, 195, 121)'}}>&lt;data-path&gt;</span>
        <br/>&emsp;&emsp;-H <span style={{color: 'rgb(152, 195, 121)'}}>"X-Api-Key: your-api-key"</span>
        <br/>&emsp;&emsp;-o filename.ext<div className='sv2-code-cursor-container'><div className='sv2-code-cursor'></div></div>
      </div>
    </div>
  )
}

export default APIBanner