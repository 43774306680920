import React, { useContext, useEffect, useState } from "react"
import './styles.scss'
import { getCategory } from "../../utilities/categories"
import { formatBytes } from "../../utilities/handyFunctions"
import axios from '../../utilities/axios'
import { useIsMounted } from "../../utilities/hooks"
import { ColorPill, projectComponents } from "./project-components"
import { Context } from "../../contexts/GlobalContext"
import { ConsoleContext } from "../../contexts/ConsoleContext"
import { SystemWindowControls } from "./system-manager"

const DataImportManager = () => {
  const computeComponent = projectComponents['datablock']
  const [toggleSearch, setToggleSearch] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const { project } = useContext(ConsoleContext)
  return (
    <div className='sv2-cloud-base-manager'>
      {
        toggleSearch ?
        <MiniDatasetSearch onClose={() => setToggleSearch(false)}/>
        :
        <>
          <div className='sv2-manager-subblock sv2-manager-title'>
            <i className={computeComponent.componentIcon} style={{color: computeComponent.color}}></i>&nbsp;&nbsp;{computeComponent.componentTitle}
            {/* <i className="far fa-database" style={{color: 'orange'}}></i>&nbsp;&nbsp;Dataset Manager */}
            <SystemWindowControls/>
          </div>
          <div className="sv2-manager-subblock base-content">
            <div className="sv2-base-content-title">Data Imports
              <span style={{float: 'right', fontWeight: 500, cursor: 'pointer'}}>
                <i className={editMode ? "fas fa-save" : "fas fa-pen"}
                  onClick={() => setEditMode(!editMode)}
              ></i></span>
            </div>
            <MiniDataList list={project?.datasetImports || []} emptyMsg="No Dataset Import" editMode={editMode}/>
          </div>
          <div className="sv2-manager-subblock base-content base-add-ds-btn"
            onClick={() => {
              setToggleSearch(true)
            }}
          >
          <i className="fas fa-plus"></i>&nbsp;&nbsp;Import Datasets
          </div>
        </>
      }
    </div>
  )
}

export default DataImportManager


const MiniDatasetSearch = ({value, onClose}) => {
  const [results, setResults] = useState([])
  const [lastClicked, setLastClicked] = useState('')
  const isMounted = useIsMounted()

  useEffect(() => {
    if(value) {
      searchHandler(value)
    }
  }, [])
  
  const searchHandler = (value) => {
    if(value) {
      axios.get(`/search/${value}`, {withCredentials: false}).then(response => {
        if(response.data) {
          if(isMounted) {
            setResults(response.data)
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
  return (
    <div className="sv2-manager-subblock base-content"
      style={{height: '100%', overflow: 'scroll'}}
    >
      <div className="sv2-mgr-ds-search">
        <input
          placeholder="Search Dataset"
          className="sv2-mgr-ds-search-input"
          autoFocus={true}
          onChange={(e) => {
            searchHandler(e.target.value)
            setLastClicked('')
          }}
        />
        <div className="sv2-mgr-ds-search-icon"
          onClick={() => {
            if(onClose) {
              onClose()
            }
          }}
        >
          {/* <i className="far fa-search"></i> */}
          <i className="far fa-times"></i>
        </div>
      </div>
      <MiniDataList list={results} emptyMsg="No Match" lastClicked={lastClicked} setLastClicked={setLastClicked}/>
    </div>
  )
}

const MiniDataList = ({list, emptyMsg='No Match Found', lastClicked=null, setLastClicked=null, editMode=true}) => {
  return (
    <div className="sv2-manager-data-list">
      {
        list.length > 0 ?
        list.map((dataset, i) => 
          <MiniDataCard 
            dataset={dataset} 
            key={`${dataset._id}`}
            lastClicked={lastClicked}
            setLastClicked={setLastClicked}
            editMode={editMode}
          />
        ) :
        <div className="sv2-manager-empty-list">{emptyMsg}</div>
      }
    </div>
  )
}

const MiniDataCard = ({dataset, lastClicked, setLastClicked, editMode=true}) => {
  const { isPurchased, syncUser } = useContext(Context)
  const { updateProject, setState, isImported, project } = useContext(ConsoleContext)
  var ds = getCategory(dataset.category)
  var licType = dataset.licenseType === 'open-source' ? 'Open Source Dataset' : 'Proprietary Dataset'
  var purchasedFlag = null
  let addButton = null

  if(isImported(dataset._id)) {
    // >> dataset added, showing remove
    addButton = (
      <div 
        className='sv2-mgr-dc-add-btn sv2-mgr-dc-add-btn-refresh'
        style={{
          color: 'rgb(220, 20, 60)',
          backgroundColor: 'rgba(220, 20, 60, 0.2)'
        }}
        onClick={() => {
          // todo: add dataset
          updateProject({
            task: 'data-import',
            action: 'remove',
            datasetID: String(dataset._id)
          }, data => {
            if(setLastClicked) setLastClicked('')
          })
        }}
      >
        <i className="far fa-minus-circle"></i>
        <div className="sv2-mgr-dc-purchase-info" style={{height: '95px', border: '0.5px solid rgb(220, 20, 60)'}}>
          <div className="sv2-mgr-dc-purchase-info-sub" style={{paddingLeft: '10px'}}>
            <span style={{fontWeight: 700, fontSize: '12px'}}>Remove Dataset&nbsp;&nbsp;<i className="far fa-info-circle" style={{color: 'rgb(220, 20, 60)'}}></i></span><br/>
            <div style={{marginTop: '5px'}}>The dataset has been added to this project. If you intend to remove the dataset, please ensure there are no dependencies.</div>
          </div>
        </div>
      </div>
    )
  } else if(dataset.licenseType === 'open-source') {
    // >> showing add button, dataset can be added
    addButton = (
      <div 
        className='sv2-mgr-dc-add-btn sv2-mgr-dc-add-btn-refresh'
        onClick={() => {
          // todo: add dataset
          updateProject({
            task: 'data-import',
            action: 'add',
            datasetID: String(dataset._id)
          }, data => {
            setLastClicked('')
          })
        }}
      >
        <i className="far fa-plus-circle"></i>
        <div className="sv2-mgr-dc-purchase-info" style={{height: '90px', border: '0.5px solid rgb(29, 191, 113)'}}>
          <div className="sv2-mgr-dc-purchase-info-sub" style={{paddingLeft: '10px'}}>
            <span style={{fontWeight: 700, fontSize: '12px'}}>Import Dataset&nbsp;&nbsp;<i className="far fa-info-circle" style={{color: 'rgb(29, 191, 113)'}}></i></span><br/>
            <div style={{marginTop: '5px'}}>You have access to this dataset. Please restrict your usage of the dataset in accordance with the specified license terms.</div>
          </div>
        </div>
      </div>
    )
  } else if(dataset.licenseType === 'proprietary') {
    // >> dataset is proprietary
    if(isPurchased(dataset._id)) {
      purchasedFlag = <span style={{color: 'rgb(29, 191, 113)', fontSize: '10px'}}><i className="far fa-check-double">&nbsp;Purchased</i></span>
      // >> showing add button, dataset can be added
      addButton = (
        <div 
          className='sv2-mgr-dc-add-btn sv2-mgr-dc-add-btn-refresh'
          onClick={() => {
            // todo: add dataset
            updateProject({
              task: 'data-import',
              action: 'add',
              datasetID: String(dataset._id)
            }, data => {
              setLastClicked('')
            })
          }}
        >
          <i className="far fa-plus-circle"></i>
          <div className="sv2-mgr-dc-purchase-info" style={{height: '90px', border: '0.5px solid rgb(29, 191, 113)'}}>
            <div className="sv2-mgr-dc-purchase-info-sub" style={{paddingLeft: '10px'}}>
              <span style={{fontWeight: 700, fontSize: '12px'}}>Import Dataset&nbsp;&nbsp;<i className="far fa-info-circle" style={{color: 'rgb(29, 191, 113)'}}></i></span><br/>
              <div style={{marginTop: '5px'}}>You have access to this dataset. Please restrict your usage of the dataset in accordance with the specified license terms.</div>
            </div>
          </div>
        </div>
      )
    } else {
      if(lastClicked === dataset._id) {
        // >> dataset was clicked before, showing refresh button
        addButton = (
          <div 
            className='sv2-mgr-dc-add-btn sv2-mgr-dc-add-btn-refresh'
            onClick={() => {
              // todo: refresh list
              syncUser()
              setLastClicked('')
              // refreshList()
            }}
          >
            <i className="far fa-sync-alt"></i>
            <div className="sv2-mgr-dc-purchase-info" style={{height: '70px', border: '0.5px solid rgb(29, 191, 113)'}}>
              <div className="sv2-mgr-dc-purchase-info-sub" style={{paddingLeft: '10px'}}>
                <span style={{fontWeight: 700, fontSize: '12px'}}>Refresh Status&nbsp;&nbsp;<i className="far fa-info-circle" style={{color: 'rgb(29, 191, 113)'}}></i></span><br/>
                <div style={{marginTop: '5px'}}>If you've purchased the dataset, please refresh the list to update its status.</div>
              </div>
            </div>
          </div>
        )
      } else {
        // >> dataset can be purchased, showing redirection button
        addButton = (
          <div className='sv2-mgr-dc-add-btn sv2-mgr-dc-add-btn-lic'
            onClick={() => {
              window.open(`/item/${dataset._id}`, '_blank')
              setLastClicked(dataset._id)
            }}
          >
            <i className="far fa-plus-circle"></i>
            <div className="sv2-mgr-dc-purchase-info">
              <div style={{width: '80px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '30px', color: 'orange'}}>
                <i className="fal fa-file-certificate"></i>
              </div>
              <div className="sv2-mgr-dc-purchase-info-sub">
                <span style={{fontWeight: 700, fontSize: '12px'}}>Proprietarty Dataset&nbsp;&nbsp;<i className="far fa-info-circle" style={{color: 'orange'}}></i></span><br/>
                <div style={{marginTop: '5px'}}>Before gaining access, you must purchase this dataset. Visit the dataset page to obtain more information, and please review the attached license to ensure it aligns with your intended usage.</div>
              </div>
            </div>
          </div>
        )
      }
    }
  }

  return (
    <div className="sv2-manager-data-card" onClick={() => {
      setState({datasetInView: {
        datasetId: String(dataset._id)
      }})
      console.log('opening dataset')
    }}>
      <div className="sv2-mgr-dc-title">{dataset.title}</div>
      {
        editMode ? 
        <div className="sv2-mgr-dc-add-btn-container">
          {addButton}
        </div>
        :
        null
      }
      <div className="sv2-mgr-dc-body">
        By <span className="sv2-mgr-dc-ellipsis">{String(dataset.userName).split('#').join(' ')}</span>&nbsp;•&nbsp;{formatBytes(dataset.size)}&nbsp;&nbsp;&nbsp;{purchasedFlag}
        <br/><br/>
        <ColorPill color={ds.value}>{dataset.category}</ColorPill>&nbsp;&nbsp;
        <ColorPill 
          styles={{
            color: 'rgb(180, 180, 180)', 
            backgroundColor: 'rgb(30, 30, 30)',
            border: '1px solid rgb(30, 30, 30)'
          }}
        >{licType}</ColorPill>&nbsp;&nbsp;
        <ColorPill 
          styles={{
            color: 'rgb(180, 180, 180)', 
            backgroundColor: 'rgb(30, 30, 30)',
            border: '1px solid rgb(30, 30, 30)'
          }}
          onClick={() => {
            window.open(`/item/${dataset._id}`, '_blank')
          }}
        >Link&nbsp;&nbsp;<i className="far fa-external-link"></i></ColorPill>&nbsp;&nbsp;
        {
          !editMode &&
          <ColorPill 
            styles={{
              color: 'rgb(180, 180, 180)', 
              backgroundColor: 'rgb(30, 30, 30)',
              border: '1px solid rgb(30, 30, 30)'
            }}
            onClick={() => {
              navigator.clipboard.writeText("'./data_imports/18281310411.csv'").then(() => {
                alert('Data path copied to clipboard')
              }).catch(err => {
                console.log(err)
              })
            }}
          >Copy&nbsp;&nbsp;<i className="far fa-copy"></i></ColorPill>
        }
      </div>
    </div>
  )
}


export {MiniDataCard, MiniDataList}