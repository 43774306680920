import React, { useContext, useState } from "react";
import { FilePond, File, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import axios from "../../utilities/axios"
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginFilePoster
)

const ImageUploader = ({id, setBusy, disabled=false, link=null}) => {
  var fileState = []
  if(link) {
    fileState = [{
      source: '12345',
      options: {
        type: 'local',
        file: {
          name: 'default.jpg',
          type: 'image/jpeg'
        },
        metadata: {
          poster: `https://d2np86vew8tc5p.cloudfront.net/${link}`
        }
      }
    }]
  }
  const [files, setFiles] = useState(fileState)

  const getSignedURL = async (fileFormat) => {
    let payload = {
      id: id,
      format: fileFormat
    }
    var url = ''
    try {
      url = await (await axios.post('user/upload/get-presigned', payload)).data.signedURL
    } catch(err) {
      console.log(err)
    }
    return url
  }

  return (
    <>
    <FilePond
      files={files}
      onupdatefiles={setFiles}
      disabled={disabled}
      allowMultiple={false}
      onaddfile={(err, file) => {
        if(err) {
          console.log('problem loading file')
        } else {
          // setBusy(true)
          // file loaded and will start upload
        }
      }}
      onprocessfile={(err, file) => {
        if(err) {
          // console.log('Upload error', formatBytes(file.fileSize))
        } else {
          // setGlobalState({overlayContent: <UploadSuccess id={id}/>})
        }
      }}
      server={{
        process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
          let extension = file.name.substring(file.name.lastIndexOf('.'))
          const url = await getSignedURL(extension)

          const request = new XMLHttpRequest()
          request.open('PUT', url)
          request.setRequestHeader('Content-Type', 'application/octet-stream')

          request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total)
            // console.log(formatBytes(e.loaded))
          }

          request.onload = function () {
            if (request.status >= 200 && request.status < 300) {
              load('some upload completion msg')
            } else {
              error('some error msg')
            }
          }
          request.send(file)
          return {
            abort: () => {
              request.abort()
              abort()
            }
          }
        }
      }}
      name="file"
      labelIdle='<strong>Drag & Drop Profile image or <span class="filepond--label-action">Browse</span></strong>'
      imagePreviewHeight={170}
      imageCropAspectRatio='1:1'
      imageResizeTargetWidth={200}
      imageResizeTargetHeight={200}
      stylePanelLayout='compact circle'
      styleLoadIndicatorPosition='center bottom'
      styleProgressIndicatorPosition='right bottom'
      styleButtonRemoveItemPosition='left bottom'
      allowFilePoster={true}
      credits=''
    />
    </>
  )
}

export default ImageUploader