import React, { useContext, useEffect } from 'react'
import SandBoxStroage from '../../../components/file-explorer/file-explorer-back'
import { RightPanel, RightPanelBasicTitle } from './panel-utils'
import SandBoxContext from '../../../contexts/SandBoxContext'
import { FlowBasic } from '../../../components/flow-ui/flow-blocks'
import Spinner from '../../../components/spinner'

const FsMap = {
  rootFolderId: 'My Drive/',
  fileMap: {
    'My Drive/': {
      id: 'My Drive/',
      name: 'My Drive',
      isDir: true,
      childrenIds: [],
      childrenCount: 0
    }
  }
}

const StoragePanel = () => {
  const {syncStorage, sandboxState, sandboxSetState} = useContext(SandBoxContext)
  const {isStorageSyncing, isInitialStorageSync} = sandboxState

  useEffect(() => {
    if(isInitialStorageSync) {
      syncStorage()
    }
  }, [])

  return (
    <RightPanel>
      <RightPanelBasicTitle>
        <div className='sndbx-panel-close'
          onClick={() => {
            sandboxSetState({showSidePanel: false})
          }}
        >
          <i className="far fa-chevron-right"></i>
        </div>
        <i className="fal fa-hdd" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;&nbsp;Storage Panel
      </RightPanelBasicTitle>
      <div style={{width: '100%', overflowY: 'scroll', padding: '10px'}}>
        <FlowBasic
          title={<div style={{display: 'flex', alignItems: 'center'}}>
            File Explorer&nbsp;&nbsp;&nbsp;
            <span style={{backgroundColor: 'rgba(255,255, 255, 0.1)', borderRadius: '5px', padding: '2px 5px', cursor: 'pointer'}}
              onClick={() => {
                syncStorage()
              }}
            >
              <i className="far fa-sync-alt"></i>
            </span>&nbsp;&nbsp;&nbsp;
            {
              isStorageSyncing ?
              <Spinner dark={true} size={15}/>
              :
              null
            }
          </div>}
          titleIcon={<i className="fal fa-folders"></i>}
          border={'2px solid rgba(255, 255, 255, 0.2)'}
        >
          {
            isInitialStorageSync ?
            null
            :
            <div style={{marginTop: '10px', backgroundColor: 'rgba(255,255, 255, 0.05)', borderRadius: '5px', lineHeight: 1.3}}>
              {/* <SandBoxStroage initBaseFileMap={baseFileMap} initRootFolderId='My Drive/'/> */}
              <SandBoxStroage/>
            </div>
          }
        </FlowBasic>
      </div>
    </RightPanel>
  )
}

export default StoragePanel