import React from 'react'
import "./styles.scss"

const Spinner = ({style, size=null, dark=false}) => {
  let extraStyles = {}
  if(typeof size === 'number') {
    extraStyles = {width: size, height: size}
  }
  if(dark) {
    return (<Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)', ...extraStyles, ...style}}/>)
  }
  return <div className="sellagen-loader" style={{...extraStyles, ...style}}></div>
}

export default Spinner