import React, {useState} from 'react'
import './styles.scss'
import { RightPanel } from './panel-utils'
import FlowFunctionTesting from '../../../components/flow-ui/flow-testing-ui'
import { FlowBasic, FlowButton } from '../../../components/flow-ui/flow-blocks'
import { FunctionIntegrationTitle } from './instructions'

const FuncTesting = () => {
  const [outputView, setOutputView] = useState('console')
  const [result, setResult] = useState(null)
  const [error, setError] = useState(false)
  var duration = null
  var output = <span>No Output</span>
  var log = <span>No Console Logs</span>
  var timeout = null
  if(result?.duration) duration = String(Number(result.duration).toFixed(2))+'ms'
  if(result?.output === null) output = 'None'
  if(result?.output) output = String(result?.output)
  if(result?.log) log = result?.log
  if(result?.error === 'timeout') {
    timeout = <span style={{fontFamily: 'Roboto Mono', color: 'red'}}>Time Limit Exceeded</span>
  } else if(error) {
    output = 'An error occurred. This may not be related to your code. Please try again later.'
    log = 'An error occurred. This may not be related to your code. Please try again later.'
  }
  return (
    <RightPanel>
      <FunctionIntegrationTitle/>
      <div style={{width: '100%', height: '100%', overflowY: 'scroll', padding: '10px', display: 'flex', flexDirection: 'column'}}>
        <FlowFunctionTesting
          onTest={
            () => {
              setResult(null)
              setError(false)
            }}
          onResponse={result => setResult(result)}
          onError={() => {
            setResult(null)
            setError(true)
          }}
        />
        <br/>
        {
          output || log ?
          <FlowBasic border='2px solid rgba(255, 255, 255, 0.2)'
            style={{flexGrow: 1}}
            titleIcon={<i className="fal fa-terminal"></i>}
            title={<>
              <FlowButton
                style={{marginRight: '10px', marginLeft: '0px'}}
                onClick={() => setOutputView('return')}
              ><span style={outputView === 'return' ? {color: 'rgb(29, 191, 113)', filter: 'brightness(140%)'}: {}}>Return Value</span></FlowButton>
              <FlowButton
                style={{marginRight: '10px'}}
                onClick={() => setOutputView('console')}
              ><span style={outputView === 'console' ? {color: 'rgb(29, 191, 113)', filter: 'brightness(140%)'}: {}}>Console Output</span></FlowButton>
              {
                duration ?
                  <FlowButton
                    style={{backgroundColor: 'transparent', cursor: 'default', filter: 'brightness(140%)'}}
                  ><i className="fal fa-clock"></i>&nbsp;{duration}</FlowButton>
                  :
                  null
              }
              {timeout}
            </>}
          >
            <div style={{ marginTop: '5px', whiteSpace: 'pre-line',
              backgroundColor: 'rgb(39, 44, 53)', width: '100%', 
              height: 'calc(100% - 36px)', maxHeight: '400px',
              padding: '5px', lineHeight: 1.25,
              overflow: 'scroll', borderRadius: '5px', fontFamily: 'Roboto Mono'}}>
                {outputView === 'return' ? output : log}
            </div>
          </FlowBasic>
          :
          null
        }
      </div>
    </RightPanel>
  )
}

export default FuncTesting