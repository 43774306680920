import React, { useState } from 'react'
import "../styles.scss"
import Spinner from '../../spinner'
import { useIsMounted } from '../../../utilities/hooks'
import axios from '../../../utilities/axios'

const ProceedCheckout = ({product_id}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const isMounted = useIsMounted()

  if(!product_id) {
    return(<>Nothing to show</>)
  }

  const handleCheckout = () => {
    if(!isLoading) {
      setIsLoading(!isLoading)
      axios.post('buyer/checkout', {product_id}).then(response => {
        console.log(response.data)
        if(isMounted) {
          if(response.data?.url) {
            setIsRedirecting(true)
            window.location.assign(response.data.url)
          } else {
            console.log(response.data)
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
  return (
    <>
      <div className="sellagen-prompt-title">
        <strong>Proceed to Payment</strong>
      </div>
      <p className="sellagen-para">
        You will be redirected to the payment page. Are you sure you want to proceed? <strong>Please note that the purchase is non refundable</strong>.
      </p>
      <button 
        className={`sellagen-prompt-button ${isLoading ? 'loading-style' : 'outlined-style'}`}
        onClick={handleCheckout}
      >
        <strong>
          {!isLoading ? 
            'Proceed to Payment'
            :
            isRedirecting ? 'Redirecting' : 'Please Wait'
          }
        </strong> &nbsp;&nbsp;
        {isLoading ? <Spinner/> : null}
      </button>
    </>
  )
}

export default ProceedCheckout