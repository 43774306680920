import React, { useContext, useState } from 'react'
import './styles.scss'
import Axios from '../../utilities/axios'
import Spinner from '../spinner'
import { Context } from '../../contexts/GlobalContext'

const DataURIManager = ({datasetObject}) => {
  const {_id, userID, completeness, licenseType} = datasetObject
  const { globalState } = useContext(Context)
  
  if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
    return null
  }
  var panel = null

  if(userID && userID._id === globalState.user._id) {
    if(completeness === 'complete' || completeness === 'pending' || completeness === 'front-pending') {
      panel = <DataURIOutput 
        datasetID={_id} 
        isPublisher={true} 
        isOpenSource={licenseType === 'open-source'}
      />
    } else {
      panel = <ExtensionInput 
        datasetID={_id}
        onSuccess={() => { window.location.reload() }}
      />
    }
  } else {
    panel = <DataURIOutput datasetID={_id} isOpenSource={licenseType === 'open-source'}/>
  }

  return panel
}

const DataURIOutput = ({datasetID, isPublisher=false, isOpenSource=false}) => {
  /// if dataset publisher views the page then it'll be visible 
  /// if purchaser views the page then it'll be visible
  /// otherwise blank
  
  const { globalState, isPurchased } = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)
  const [dataPath, setDataPath] = useState('')

  if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
    return null
  }
  if(isOpenSource) {
    /// do nothing
  } else {
    if(!isPurchased(datasetID)) {
      if(!isPublisher) {
        return null
      }
    }
  }

  const revealURIHandler = () => {
    if(dataPath) {
      setDataPath('')
      setIsLoading(false)
      return
    } else {
      if(!isLoading) {
        setIsLoading(true)
        Axios.post('dataset-key', {datasetID, ...(isPublisher ? {isPublisher} : {})}).then(response => {
          if(response.data && response.data?.datasetKey) {
            setDataPath(response.data.datasetKey)
            setIsLoading(false)
          } else {
            setDataPath('')
            setIsLoading(false)
          }
        }).catch(err => {
          setIsLoading(false)
          setDataPath('')
          console.log(err)
        })
      }
    }
  }
  var truncatedDataPath = ''
  if(dataPath) {
    truncatedDataPath = dataPath.slice(0, 20)+'...'+dataPath.slice(dataPath.length-12, dataPath.length)
  }

  return (
    <>
      <br/><br/>
      <div className='sv2-DataURIManager-container'>
        <div style={{fontSize: '20px', fontWeight: 700}}>
          Data Path
        </div><br/><br/>
        <div style={{lineHeight: 1.5}}>
        Use the data path to download this dataset using the <span className='sv2-api-tag' onClick={() => {window.open('/sellagen-api', '_blank').focus()}}>Sellagen API</span>
        </div><br/><br/>
        <div style={{display: 'flex', alignItems: 'flex-end'}}>
          <div style={{width: '85%', margin: '0 10px 0 0'}}>
            <div className='sv2-DataURIManager-output'>
              {dataPath ?
                <>
                  <div className='sv2-DataURIManager-datapath-content'>
                    {truncatedDataPath}
                  </div>
                  <i className="fas fa-copy copy-button"
                    style={{color: '#bbc7de'}}
                    onClick={() => {
                      navigator.clipboard.writeText(dataPath).then(() => {
                        alert('Data path copied to clipboard')
                      }).catch(err => {
                        console.log(err)
                      })
                    }}
                  ></i>
                </> : '************************'
              }
            </div>
          </div>
          <div className='sv2-DataURIManager-submit'
            onClick={revealURIHandler}
            style={{flexGrow: 1, backgroundColor: '#282c34', color: '#bbc7de', borderRadius: '8px', fontSize: '18px'}}
          >
            {/* {isLoading ? <Spinner/> : dataPath ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>} */}
            {isLoading ? <Spinner/> : dataPath ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
          </div>
        </div>
      </div>
    </>
  )
}

const ExtensionInput = ({datasetID, onSuccess, onFailure, initial = false}) => {
  const [extension, setExtension] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const extensionFormatter = (value) => {
    if(value && value?.length > 0 && value[0] === '.') {
      if(String(value).split('.').length <= 2) {
        setExtension(value)
        setIsError(false)
      }
    } 
    else if(value === null || value === undefined || value === '') {
      setExtension('')
      setIsError(false)
    }
  }

  const generateURIHandler = () => {
    if(!isLoading) {
      setIsLoading(true)
      if(extension && datasetID) {
        Axios.post('generate-dataset-key', {extension, datasetID})
        .then(response => {
          setIsLoading(false)
          if(response?.data?.success && response?.data?.success === 'True') {
            if(onSuccess) {
              onSuccess()
            }
          } else {
            if(onFailure) {
              onFailure()
            } else {
              setIsError(true)
            }
          }
        }).catch(err => {
          setIsLoading(false)
          if(onFailure) {
            onFailure()
          } else {
            setIsError(true)
          }
        })
      } else {
        setIsError(true)
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      {
        initial ? 
        null
        :
        <><br/><br/></>
      }
      <div className='sv2-DataURIManager-container'>
        <div style={{fontSize: '20px', fontWeight: 700}}>
          Data Path
        </div><br/><br/>
        <div style={{lineHeight: 1.5}}>
        The data path is essential for downloading and uploading dataset file using the <span className='sv2-api-tag' onClick={() => {window.open('/sellagen-api', '_blank').focus()}}>Sellagen API</span> functionality. 
        {initial ? ' ' : 'Data path for this listing has not been generated yet.'}
        To generate a new data path please correctly enter the file extension of the dataset you're going to upload below.
        In case of multiple files zip them with an archiever.
        </div><br/><br/>
        <div style={{display: 'flex', alignItems: 'flex-end'}}>
          <div style={{width: '80%', margin: '0 15px 0 0'}}>
            <div className={`sv2-DataURIManager-title${isError ? ' sv2-DataURIManager-error': ''}`}>
              Dataset File Extension
            </div>
            <input
              className='sv2-DataURIManager-input'
              value={extension}
              onChange={(e) => {
                extensionFormatter(e.target.value)
              }}
              placeholder='.ext'
            />
          </div>
          <div className='sv2-DataURIManager-submit'
            onClick={generateURIHandler}
            style={{flexGrow: 1}}
          >
            { isLoading ? <Spinner/> : 'Generate'}
          </div>
        </div>
      </div>
    </>
  )
}

export default DataURIManager
export { ExtensionInput, DataURIOutput }