import React, { useState, useEffect } from 'react'
import categories from '../../utilities/categories'
import { RichEditor } from '../editor'
import MultiInput from '../multi-input'
import MultiInputSelect from '../multi-input-select'
import SV2Slider from '../sv2-slider'
import licenses from '../../utilities/licenses'
import axios from '../../utilities/axios'
import { useIsMounted } from '../../utilities/hooks'
import Uploader, {SampleUploader} from '../uploader'
import './styles.scss'
import '../../scss/components/checkbox.scss'
import { useContext } from 'react'
import { Context } from '../../contexts/GlobalContext'
import UploadSuccess from '../overlay-prompts/upload-success'
import { useNavigate, useParams } from 'react-router-dom'
import { DataURIOutput, ExtensionInput } from '../data-uri-manager'

const Lister = ({edit}) => {
  // form configuration
  // 'agreement', 'title', 'description', 'searchTags', 'geoTags', 'category', 'price', 'license', 'sample-uploader', 'uploader'
  const formOrder = ['title', 'description', 'searchTags', 'geoTags', 'category', 'licenseType', 'license', 'price', 'agreement', 'sample-uploader', 'uploader']
  const [allSteps, setAllSteps] = useState(formOrder)
  const initializationStep = 'agreement'
  const postInitializationStep = allSteps[allSteps.indexOf(initializationStep) + 1]
  const optionalSteps = ['sample-uploader'] + edit ? ['uploader'] : []

  const [currStep, setCurrStep] = useState(0)
  const [currStepKey, setCurrStepKey] = useState(allSteps[0])
  const sliderRef = React.createRef()
  
  const params = useParams()
  const [datasetID, setDatasetID] = useState(edit ? String(params.item) : '')
  
  const [listing, setListing] = useState(edit ? {agreement: {checked: false}}: {})
  const [keyState, setKeyState] = useState('initial')
  const [isLoading, setIsLoading] = useState(false)
  const [busy, setBusy] = useState(false)
  const [allowNext, setAllowNext] = useState(edit ? true : false)
  const [apiUpload, setApiUpload] = useState(false)
  const [showDataURI, setShowDataURI] = useState(false)
  const isMounted = useIsMounted()
  const { globalState, setGlobalState } = useContext(Context)
  const navigate = useNavigate()

  const showOverlay = (mode) => {
    let title = ''
    let msg = null
    if(mode === 'finished') {
      title = 'Dataset Successfully Listed'
      msg = 
        <>
          Dataset was successfully listed and it is submitted for review. The review usually takes 1-2 days. We will contact you via email to learn more details about the dataset. 
          <br/><br/>Meanwhile, create a Stripe Connect account in the <a href='/dashboard'>Dashboard</a> (if you haven't already) to setup the payout process.
          {
            apiUpload && globalState?.user?.business ? null
            :
            <>
              <br/><br/><i className="fas fa-exclamation-triangle" style={{color: 'crimson'}}></i>&nbsp;
              Data listing may not get approved or even removed from the system if the data file is not uploaded within seven days of the key generation.
            </>
          }
        </>
    } else if(mode === 'updated') {
      title = 'Dataset Successfully Updated'
      msg = 
        <>
          Dataset was successfully edited. Please feel free to reach out for any issues or concerns at <a href='mailto:support@sellagen.com'>support@sellagen.com</a>.
        </>
    }
    setGlobalState({ overlayContent: <UploadSuccess id={datasetID} title={title} msg={msg} /> })
  }

  const updateListingState = (field, value) => {
    if(field === 'licenseType') {
      if(listing?.license?.access === 'open-source') {
        if(value !== 'open-source') {
          setListing({...listing, [field]: value, 'license': null})
        } else {
          setListing({...listing, [field]: value})
        }
      } else if(listing?.license?.access !== 'open-source') {
        if(value === 'open-source') {
          setListing({...listing, [field]: value, 'license': null})
        } else {
          setListing({...listing, [field]: value})
        }
      }
    } else {
      setListing({...listing, [field]: value})
    }
    
    if(field !== 'sample-uploader' || field !== 'uploader') {
      setAllowNext(sliderComponents[field][1](value))
    }
  }

  const fetchListingInfo = (id, onSuccess) => {
    if(!isLoading) {
      setIsLoading(true)
      axios.get(`dataset/${id}`).then(response => {
        if(isMounted) {
          if(response.data && response.data?.dataset?._id) {
            var {userID, title, description, searchTags, geoTags, category, price, license, licenseType} = response.data?.dataset
            if(userID._id !== globalState?.user?._id) navigate('/')
            var dataset = {
              agreement: {checked: false},
              title: title,
              description: description,
              searchTags: searchTags || [],
              geoTags: geoTags || [],
              category: category,
              price: String(price),
              license: license || licenses['personal-use'],
              licenseType: licenseType
            }
            setListing(dataset)
            setKeyState('rerender')
            if(onSuccess) {
              onSuccess(dataset)
            }
          }
          setIsLoading(false)
        }
      }).catch(err => {
          console.log(err)
          setIsLoading(false)
      })
    }
  }

  useEffect(() => {
    if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
      navigate('/')
      return
    }
    if(edit && datasetID) {
      var cutOffIndex = allSteps.indexOf(initializationStep) + 1
      var newSteps = allSteps.slice(0, cutOffIndex)
      // Removing price selection panel for open-source dataset
      fetchListingInfo(datasetID, ds => {
        if(ds?.licenseType === 'open-source') {
          let priceIndex = newSteps.indexOf('price')
          if(priceIndex > -1) {
            newSteps.splice(priceIndex, 1)
          }
        }
        setAllSteps(newSteps)
      })
    }
  }, [])

  useEffect(() => {
    if(!edit && datasetID) {
      increment()
    }
  }, [datasetID])

  const handleSubmission = (onSuccess=null) => {
    if(!isLoading) {
      setIsLoading(true)
      let payload = {...listing}
      payload['price'] = parseFloat(payload['price'] || '0.00')
      if(payload['license'].key !== 'custom-license') {
        delete payload['license']['details']
      }
      if(edit) {
        payload['id'] = datasetID
        axios.post('user/dataset/edit', payload).then(response => {
          if(isMounted) {
            if(response.data && response.data._id) {
              setIsLoading(false)
              if(onSuccess) onSuccess(response.data._id)
            }
          }
        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })
      } else {
        axios.post('user/dataset/create', payload).then(response => {
          if(isMounted) {
            if(response.data && response.data._id) {
              setIsLoading(false)
              if(onSuccess) onSuccess(response.data._id)
            }
          }
        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })
      }
    }
  }

  const increment = () => {
    console.log(allSteps)
    let nextStep = allSteps[allSteps.indexOf(currStepKey) + 1]
    if(globalState?.user?.business && optionalSteps.includes(nextStep)) {
      // for business accounts you can skip the steps in businessOptionalSteps
      setAllowNext(true)
    }
    else if(nextStep && allSteps.indexOf(nextStep) > -1) {
      // if next step exist then proceed
      let isNextStepValid = sliderComponents[nextStep][1](listing[nextStep])
      setAllowNext(isNextStepValid)
    } else {
      setAllowNext(false)
    }
    if(nextStep) {
      setCurrStepKey(nextStep)
      sliderRef.current.slickNext()
    }
    setCurrStep(currStep+1)
  }

  const next = () => {
    // handle submission after preinitialization step

    if(currStepKey === initializationStep) {
      // initialize or update
      handleSubmission(id => {
        if(edit) {
          showOverlay('updated')
          setCurrStep(currStep+1)
        } else {
          if(id) {
            // updating datasetID will trigger effect which will then call increment
            setDatasetID(id)
          }
        }
      })
    }
    else if(currStepKey === allSteps[allSteps.length - 1]) {
      showOverlay('finished')
      setCurrStep(currStep+1)
    }
    else increment()
  }

  const back = () => {
    if(currStep > 0) {
      let prevStep = allSteps[allSteps.indexOf(currStepKey) - 1]
      setCurrStep(currStep-1)
      setCurrStepKey(prevStep)
      setAllowNext(true)
      sliderRef.current.slickPrev()
    }
  }

  const sliderComponents = {
    'agreement':
      [<SV2FormattedField
        title='Read the Statement'
        style={{maxWidth: '600px', maxHeight: '650px'}}
        element={(
          <SV2StatementPanel
            value={listing['agreement']}
            onChange={ value => { updateListingState('agreement', value) }}
          />
        )}
      />, value => value?.checked === true],
    'title':
      [<SV2FormattedField
        title='What should be the title of the dataset?'
        brief={<>Please provide a meaningful title. Adding a concise title with a good summarization will increase the chance of getting discovered more often. <strong>Max Length: 50 Characters</strong></>}
        element={(
          <input
            className='sv2-form-content-input'
            type='text'
            placeholder='Pharmaceutical Trials'
            maxLength={50}
            value={listing['title']}
            onChange={(e) => { updateListingState('title', e.target.value) }}
          />
        )}
      />, value => !(value === undefined || value === '' || value === null)],
    'description':
      [<SV2FormattedField
        title='How would you describe the dataset?'
        brief='Describe the data as precisely as possible. Make sure to include complete information regarding the data listed such as the acquisition methodology, variables explanation, subject matter and other.'
        style={{
          maxWidth: '650px',
          maxHeight: '600px'
        }}
        element={(
          <RichEditor
            key={keyState}
            saveContents = { value => {
              updateListingState('description', value)
            }}
            stringState = {listing['description'] || ''}
          />
        )}
      />, sv2DescInputValidator],
    'searchTags':
      [<SV2FormattedField
        title='Add keywords relating to the dataset'
        brief={<>Adding search tags related to the dataset boosts it's appearance on search results. Select tags wisely. <strong>Add upto 10 tags.</strong></>}
        element={(
          <MultiInput
            key= {`searchTags-${keyState}`}
            values = {listing['searchTags']}
            placeholder = 'e.g. pharma, stocks, physics, time series'
            buttonTitle = {(<i className="far fa-tags" style={{fontSize: '16px'}}></i>)}
            writeCallback = {(values) => {
              updateListingState('searchTags', values)
              // if(values === undefined || values.length === 0) {
              //   setAllowNext(false)
              // } else {
              //   setAllowNext(true)
              // }
            }}
            limit = {10}
          />
        )}
      />, value => !(value === undefined || value.length === 0)],
    'geoTags':
      [<SV2FormattedField
        title='Add Geo Coverage of the Dataset'
        brief={<>Adding geo tags help buyers identify the geo-location coverage of the dataset. Geo tags also provide better location-based search experience to the buyers.</>}
        element={(
          <MultiInputSelect
            key= {`geoTags-${keyState}`}
            values = {listing['geoTags']}
            buttonTitle = {(<i className="far fa-tags" style={{fontSize: '16px'}}></i>)}
            writeCallback = {(values) => {
              updateListingState('geoTags', values)
              // if(values === undefined || values.length === 0) {
              //   setAllowNext(false)
              // } else {
              //   setAllowNext(true)
              // }
            }}
            limit = {200}
          />
        )}
      />, value => !(value === undefined || value.length === 0)],
    'category':
      [<SV2FormattedField
        title='Which category fits the dataset best?'
        brief={<>Please select an appropriate category for the dataset. Categorizing the dataset helps to show up on the proper domain results.</>}
        style={{
          maxHeight: '600px'
        }}
        element={(
          <SV2Selector 
            terms={categories}
            value={listing['category'] || null}
            onClick={(c) => {
              updateListingState('category', c)
              if(c === undefined || c === '' || c === null) {
                setAllowNext(false)
              } else {
                setAllowNext(true)
              }
            }}
          />
        )}
      />, value => !(value === undefined || value === '' || value === null)],
    'price':
      [<SV2FormattedField
        title={
        <>
          Price your Dataset !!
          <span style={{display: globalState?.user?.business ? 'block' : 'none', color: 'rgb(150, 150, 150)'}}>&nbsp;(Optional)</span>
        </>}
        brief={<>Provide an appropriate price for the dataset. It's pretty self explanatory.<strong>The price amount should be in US Dollars. <span style={{color: 'crimson'}}>Minimum value for a dataset must be US $5.00.</span></strong></>}
        element={(
          <SV2PriceInput
            value={listing['price']}
            onChange={ price => {
              updateListingState('price', price)
            }}
          />
        )}
      />, value => sv2PriceInputValidator(value, globalState?.user?.business)],
    'licenseType':
      [<SV2FormattedField
        title='License Type'
        brief={<>Please choose the type of licensing and accessibility you wish to associate with the dataset's usage.</>}
        style={{ maxHeight: '600px' }}
        element={(
          <SV2LicenseType
            value={listing['licenseType'] || ''}
            onChange={ licenseType => {
              updateListingState('licenseType', licenseType)
              if(licenseType === 'open-source') {
                let newAllSteps = [...allSteps]
                let priceIndex = newAllSteps.indexOf('price')
                if(priceIndex > -1) {
                  newAllSteps.splice(priceIndex, 1)
                  setAllSteps(newAllSteps)
                }
              } else if(licenseType === 'proprietary') {
                // if(listing['license']?.access === 'open-source') {
                //   updateListingState('license', licenses['personal-use'])
                // }
                let newAllSteps = [...allSteps]
                if(!newAllSteps.includes('price')) {
                  // finds in which index it was originally located
                  let prePriceFormIndex = formOrder.indexOf('price') - 1
                  if(prePriceFormIndex > -1) {
                    let prePriceForm = formOrder[prePriceFormIndex]
                    let priceIndex = newAllSteps.indexOf(prePriceForm) + 1 // inserts after licenseType
                    newAllSteps.splice(priceIndex, 0, 'price')
                    setAllSteps(newAllSteps)
                  }
                }
              }
            }}
          />
        )}
      />, value => (value === 'proprietary' || value === 'open-source')],
    'license':
      [<SV2FormattedField
        title='Define a License for the dataset'
        brief={<>License is necessary to define how the buyer can make use of the dataset and if they are allowed to alter or redistribute the dataset.</>}
        style={{
          maxWidth: '650px',
          maxHeight: '650px'
        }}
        element={(
          <SV2LicensePanel
            value={listing['license']}
            isOpenSource={listing['licenseType'] === 'open-source'}
            onChange={ e => { updateListingState('license', e); console.log(e) }}
          />
        )}
      />, sv2LicenseInputValidator],
    'sample-uploader': 
      [<SV2FormattedField
        title='Upload a Sample of the Dataset'
        brief={<>Sample Dataset helps the buyer to get an idea of the dataset.</>}
        element={(
          <SampleUploader id={datasetID} setBusy={setBusy} onCompletion={() => setAllowNext(true)}/>
        )}
      />, val => true],
    'uploader':
      [<SV2FormattedField
        title='Upload the Dataset'
        style={{maxHeight: '650px'}}
        brief={
          apiUpload ?
            <>
              The actual data file which is delivered to the buyer. Upon purchase it can be accessible to the buyer through the browser or Sellagen API. <br/><br/>
              <strong style={{padding: '1px 5px', backgroundColor: '#282c34', color: '#bbc7de', borderRadius: '3px'}}>Data Path</strong> 
              &nbsp;points to the data file that will be stored in the Sellagen servers and referred to when applying reads or writes through the API.
              {
                globalState?.user?.business ? null
                :
                <>
                  <i className="fas fa-exclamation-triangle" style={{color: 'crimson'}}></i>&nbsp;
                  Data listing may not get approved or even removed from the system if the data file not uploaded within seven days of the key generation.
                </>
              }
            </>
            :
            <>
              The actual data file which is delivered to the buyer. Upon purchase it can be accessible to the buyer through the browser or Sellagen API.
            </>
        }
        element={(
          <>
            { apiUpload ? 
              showDataURI ? <DataURIOutput datasetID={datasetID} isPublisher={true}/> : <ExtensionInput datasetID={datasetID} initial={true} onSuccess={() => { setAllowNext(true); setShowDataURI(true)}}/>
              :
              <Uploader id={datasetID} setBusy={setBusy} onCompletion={() => {setAllowNext(true)}}/>
            }
            <div style={{textAlign: 'center', marginTop: '20px', marginBottom: '5px'}}>
              <span className='sv2-api-tag' style={{background: 'transparent', border: '1px solid rgb(180, 180, 180)', fontWeight: 'normal'}}
                onClick={() => { setApiUpload(!apiUpload) }}
              >
                {apiUpload ? 'Or upload from Browser' : 'Or upload with Sellagen API'} &nbsp;<i className="fas fa-arrow-circle-right"></i>
              </span>
            </div>
          </>
        )}
      />, val => false]
  }

  return (
    <div className='sv2-form-container'>
      <div className='sv2-form-top'>
        <SV2Slider sliderRef={sliderRef}>
          {
            allSteps.map(key => {
              var view = sliderComponents[key][0]
              return (
                <div key={key+'-wrap'} className='sv2-form-slider-container'>
                  <div className='sv2-form-slider-content'>
                    {view}
                  </div>
                </div>
              )
            })
          }
        </SV2Slider>
      </div>
      <div className='sv2-form-bottom'>
        <div className='sv2-form-progress-bar'>
          <div className='sv2-form-progress-stat'
            style={{width: `${currStep*100/(allSteps.length)}%`}}
          ></div>
        </div>
        <div className='sv2-form-progress-control'>
          <div className='sv2-form-progress-control-blocks'>
            <button className='sv2-form-button-prev'
              disabled={busy || currStepKey === postInitializationStep}
              style={currStepKey === allSteps[0] || currStepKey === postInitializationStep ? {display: 'none'} : null}
              onClick={back}
            >
              Back
            </button>
          </div>
          <div className='sv2-form-progress-control-blocks' style={{justifyContent:'flex-end'}}>
            <button className='sv2-form-button'
              disabled={busy || !allowNext}
              onClick={next}
            >
              {
                currStepKey === allSteps[allSteps.length-1] ?
                  edit ? 'Confirm Update' : 'Finish'
                  :
                  'Next'
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lister

const SV2FormattedField = ({ title, brief, element, style = {} }) => {
  return (
    <div className='sv2-form-content-block' style={style}>
      <div className='sv2-form-content-title'>
        {title}
      </div>
      <div className='sv2-form-content-body'>
        {brief ? 
          <div style={{
            color: 'rgb(120, 120, 120)',
            lineHeight: '1.5',
            marginBottom: '20px',
          }}>
            {brief ? brief : null}
          </div>
          :
          null
        }
        {element}
      </div>
    </div>
  )
}

const SV2StatementPanel = ({value={}, onChange}) => {
  return(
    <div className='sv2-statement-container'>
      <div style={{
        width: '100%',
        maxHeight: '350px',
        borderRadius: '10px',
        padding: '10px 15px',
        fontSize: '12px',
        backgroundColor: 'white',
        color: 'rgb(75, 75, 75)',
        lineHeight: 1.5,
        textAlign: 'justify',
        fontFamily: 'monospace',
        overflow: 'scroll'
      }}>
        By creating a listing on Sellagen, you confirm that the data you are uploading is legal, accurate, and truthful. It is your responsibility to ensure that the data you are listing is safe and legal to be transacted. By creating a listing, you also agree to our terms and conditions, which include ensuring that the data you upload is compliant with all applicable laws and regulations. We take the safety and legality of our marketplace very seriously and appreciate your cooperation in ensuring that all data listed is safe and legal to be transacted. Please note that Sellagen is not responsible for any legal issues arising from the data uploaded by sellers, it is the seller's sole responsibility to ensure the data is legal and safe to be transacted.
      </div>
      <div className='sv2-statement-agree-block'>
        <div style={{display: 'inline-flex'}}>
          <div className="round">
            <input type="checkbox" id="checkbox" 
              checked={value?.checked === true}
              onChange={(e) => {
                if(e.target.checked) {
                  onChange({
                    checked: true,
                    ip: '',
                    timestamp: ''
                  })
                } else {
                  onChange({checked: false})
                }
              }}
            />
            <label htmlFor="checkbox"></label>
          </div>
          <div style={{lineHeight: 1.5, fontSize: '12px'}}>
            I Agree and Confirm
          </div>
        </div>
      </div>
    </div>
  )
}

const SV2Selector = ({terms, value, onClick, blockSize = 80}) => {
  return(
    <div className='sv2-selector-container'
      style={{ gridTemplateColumns: `repeat(auto-fit, minmax(${blockSize}px, 1fr))` }}
    >
      {
        terms.map((term, index) => {
          return(
            <div key={index} className='sv2-selector-component'
              style={{
                border: term.title === value ? '2px solid rgb(120, 120, 120)' : '',
                backgroundColor: term.title === value ? 'rgb(236, 238, 240)' : 'transparent',
              }}
              onClick={() => { onClick(term.title) }}
            >
              {term.icon}
              <span style={{fontSize: '8px', marginTop: '8px'}}>{term.title}</span>
            </div>
          )
        })
      }
    </div>
  )
}

const SV2LicenseType = ({value, onChange}) => {
  return(
    <>
      <div className='sv2-attribute-select-container'>
        <div className='sv2-attribute-selections'
          onClick={() => onChange('proprietary')}
        >
          <div className='sv2-attribute-selections-check'>
            {value === 'proprietary' ? <i className="fas fa-check-circle"></i> : <i className="far fa-circle"></i>}
          </div>
          <div className='sv2-attribute-selections-contents'>
            <div style={{fontSize: '13px', fontWeight: 700, marginBottom: '3px'}}>Proprietary</div>
            This dataset is distributed commercially. It requires a purchase, and once bought, the buyer gains access to the dataset. However, please note that the buyer does not possess the right to modify the dataset (unless otherwise specified in the license) or redistribute it.
          </div>
        </div>
        <br/>
        <div className='sv2-attribute-selections'
          onClick={() => onChange('open-source')}
        >
          <div className='sv2-attribute-selections-check'>
            {value === 'open-source' ? <i className="fas fa-check-circle"></i> : <i className="far fa-circle"></i>}
          </div>
          <div className='sv2-attribute-selections-contents'>
          <div style={{fontSize: '13px', fontWeight: 700, marginBottom: '3px'}}>Open Source</div>
            This dataset is available at no cost. Usage, modification, and distribution of the dataset are subject to the terms outlined in the license agreement. The data consumer can access and download the dataset upon accepting the licensing terms.
          </div>
        </div>
      </div>
    </>
  )
}

const SV2PriceInput = ({value, onChange}) => {
  const isNumeric = (str) => {
    if (typeof str != "string") return false
    return !isNaN(str) && !isNaN(parseFloat(str))
  }

  const priceFormatHandler = (value) => {
    var tempValue = String(value).replaceAll('$', '')
    tempValue = String(tempValue).replaceAll('.', '')
    if(isNumeric(tempValue)) {
      if(tempValue.length === 4) {
        if(tempValue[0] === '0') {
          tempValue = tempValue.slice(1, tempValue.length)
        }
      }
      tempValue = tempValue.padStart(3, '0')
      var interger = tempValue.slice(0, tempValue.length - 2)
      var fraction = tempValue.slice(tempValue.length - 2, tempValue.length)
      tempValue = `${interger}.${fraction}`
      onChange(tempValue)
    }
  }
  
  return(
    <input
      className='sv2-price-input'
      value={value ? `\$${value}` : '$0.00'}
      onChange={(e) => priceFormatHandler(e.target.value)}
    />
  )
}

const SV2LicensePanel = ({value={}, isOpenSource=false, onChange}) => {
  const [customLicense, setCustomLicense] = useState(null)
  
  useEffect(() => {
    if(value?.key === 'custom-license' && value?.details !== licenses['custom-license'].details) {
      setCustomLicense(value?.details)
    }
  }, [])
  return(
    <>
    <div className='sv2-license-container'>
      <div className='sv2-license-select'>
        {Object.keys(licenses).map(i => {
          if(!isOpenSource) {
            if(licenses[i]?.access === 'open-source') return
          }
          return(
            <span className='sv2-license-title'
              key={i}
              onClick={() => {
                onChange({key: i, ...licenses[i]})
              }}
              style={{border: i === value?.key ? '2px solid rgb(120, 120, 120)' : ''}}
            >
              {licenses[i].title}
            </span>
          )
        })}
      </div>
      <hr style={{
        border: '1px solid rgb(200, 200, 200)', 
        borderRadius: '15px',
        margin: '10px 0 0px 0px'
      }}/>
      <div className='sv2-license-details'>
        {
          value ?
            value?.key === 'custom-license' ?
              (
                <textarea className='license-clause-custom'
                  value={customLicense || value?.details}
                  onChange={ e => {
                    onChange({
                      key: 'custom-license',
                      title: 'Custom License',
                      details: e.target.value
                    })
                    setCustomLicense(e.target.value)
                  }}
                >
                </textarea>
              )
              :
              // value.details
              licenses[value?.key ? value.key : 'personal-use'].details
            : licenses['personal-use'].details
        }
      </div>
    </div>
    </>
  )
}

const sv2DescInputValidator = stringifiedContent => {
  var isValidInput = false
  if(stringifiedContent === undefined || stringifiedContent === '' || stringifiedContent === null) {
    isValidInput = false
  } else {
    try {
      let desc = JSON.parse(stringifiedContent || '')
      if(desc['blocks'] && desc['blocks'].length) {
        if(desc['blocks'][0]['text']) {
          isValidInput = true
        } else {
          isValidInput = false
        }
      } else {
        isValidInput = false
      }
    } catch(err) {
      isValidInput = false
    }
  }
  return isValidInput
}

const sv2PriceInputValidator = (price, business=null) => {
  if(price === undefined || price === '' || price === null) {
    return false
  } else if (parseFloat(price) < 5.00) {
    if(business && parseFloat(price) === 0.00) {
      return true
    } else{
      return false
    }
  } else {
    return true
  }
}

const sv2LicenseInputValidator = (value) => {
  if(value?.key === 'custom-license') {
    return value?.details ? true : false
  } else {
    return !(value === undefined || value === null)
  }
}