import React, { useState, useContext } from 'react'
import './styles.scss'
import {FlowBasic, FlowStackedBody, FlowDropDown, FlowTitle, FlowTestParams, FlowButton} from './flow-blocks'
import SandBoxContext from '../../contexts/SandBoxContext'
import Spinner from '../spinner'

const FlowFunctionTesting = ({onTest=()=>{}, onResponse=()=>{}, onError=()=>{}}) => {
  const [options, setOptions] = useState([])
  const [selection, setSelection] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [parseWarning, setParseWarning] = useState('')
  const {parseScript, sandboxState, sandboxSetState, updateMetadata, parseTestParams, handleFunctionTest} = useContext(SandBoxContext)

  var paramPlaceHolder = '{\n  "argument_1": "value_1",\n  "argument_2": "value_2",\n  "argument_3": "value_3"\n}'
  var isAttached = false
  if(sandboxState?.metadata?.jsonEditorActive === false) isAttached = true

  var testButton = <><i className="fas fa-exclamation-circle" style={{color: 'orange'}}></i>&nbsp;Pending</>
  var testStyle = {cursor: 'not-allowed', filter: 'none'}
  if(selection) {
    if(isLoading) {
      testButton = <><Spinner
        style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)', borderWidth: '2px', height: '10px', width: '10px'}}
      />&nbsp;Testing</>
      testStyle = {filter: 'none'}
    } else {
      testButton = <><i className="fas fa-play-circle" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;TEST</>
      testStyle = {}
    }
  }

  return (
    <FlowBasic
      title='Function Testing'
      titleIcon={<i className="fal fa-code"></i>}
      border={'2px solid rgba(255, 255, 255, 0.2)'}
    >
      <FlowStackedBody
        showStep={false}
      >
        <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
          <div style={{flexGrow: 1, padding: '5px'}}>
            Function
          </div>
          <FlowDropDown
            selected={selection}
            options={options}
            onCheck={() => {
              // parse
              let funcs = parseScript()
              let funcNames = funcs.map(func => func.title)
              setOptions(funcNames)
            }}
            onSelection={elem => {
              // updateMetadata('write', 'editorActive', false)
              setSelection(elem)
            }}
            color='rgb(97, 176, 239)'
            initialMsg='Select Function'
            emptyMsg='No Function Detected'
          />
        </div>
      </FlowStackedBody>
      <br/>
      <FlowTitle 
        title={<>Sample Testing Paramters - <strong><span style={{fontFamily: 'Roboto Mono'}}>JSON</span></strong></>}
        icon={<i className="fal fa-brackets-curly"></i>}
      />
      <div className='sv2-flow-stacked-body' style={{height: 'auto', alignItems: 'unset'}}>
        <div className='title-icon'>
          <div className='square'
            style={{bottom: '12px'}}
          ></div>
        </div>
        <div style={{marginTop: '4px', width: '100%', paddingBottom: '5px'}}>
          <FlowTestParams
            placeHolder={paramPlaceHolder}
          />
          <br/>
          {
            !isAttached ?
            <>
              <FlowButton
                style={selection ? {} : {cursor: 'not-allowed', filter: 'none'}}
                onClick={() => {
                  if(selection) {
                    setParseWarning('')
                    const functionTestParams = parseTestParams()
                    if(functionTestParams === null) {
                      setParseWarning('JSON Syntax Error')
                    } else {
                      sandboxSetState({functionTestParams})
                      updateMetadata('write', 'jsonEditorActive', false)
                    }
                  }
                }}
              >
                <><i className="fal fa-plus"></i>&nbsp;Attach Parameters</>
              </FlowButton>&nbsp;&nbsp;<span style={{color: 'crimson', fontSize: '10px', fontFamily: 'Roboto Mono'}}>{parseWarning}</span>
              <br/>
            </>
            :
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-evenly', fontSize: '11px'}}>
              <FlowButton
                onClick={() => {updateMetadata('delete', 'jsonEditorActive', false)}}
              >
                <><i className="fas fa-pen"></i>&nbsp;Edit</>
              </FlowButton>
              <FlowButton style={testStyle}
                onClick={() => {
                  onTest()
                  setIsLoading(true)
                  handleFunctionTest(
                    selection,
                    response => { 
                      setIsLoading(false)
                      onResponse(response)
                    },
                    error => {
                      setIsLoading(false)
                      onError(error)
                    }
                  )
                }}
              >{testButton}</FlowButton>
              <FlowButton style={{backgroundColor: 'transparent', cursor: 'default'}}>
                <><i className="fal fa-microchip"></i>&nbsp;2000ms</>
              </FlowButton>
              <FlowButton style={{backgroundColor: 'transparent', cursor: 'default'}}>
                <><i className="fal fa-memory"></i>&nbsp;256MB</>
              </FlowButton>
            </div>
          }
        </div>
      </div>
    </FlowBasic>
  )
}

export default FlowFunctionTesting