import React , { useState, useContext } from 'react';
import { Link , NavLink, useNavigate } from 'react-router-dom';
import menus from '../../pages/menu';
import DarkMode, { SearchButton } from './DarkMode';
import './styles.scss';
import logo from '../../assets/images/logo/logo_dark.png'
import { msgReadHelper } from '../../utilities/handyFunctions'
import { Context } from '../../contexts/GlobalContext'
// import MainMsgPanel from '../msg-panel'

const Header = ({paramShowSearch = false}) => {
    const navigate = useNavigate()

    const { globalState, setAccountMode } = useContext(Context)

    const [menuActive, setMenuActive] = useState(null)
    const [showSearch, setShowSearch] = useState(false || paramShowSearch)

    const handleMenuActive = () => {
        setMenuActive(!menuActive)
    }
    
    const [activeIndex, setActiveIndex] = useState(null)

    const handleDropdown = index => {
        if(index instanceof Number) {
            setActiveIndex(index)
        } else {
            setAccountMode(index)
        }
    }

    const regex = /^(\/search\/).*/

    const searchRequest = (event) => {
        let value = String(event.target.value)
        if(String(window.location.pathname).match(regex)) {
            navigate(`/search/${encodeURIComponent(value)}`, {replace: true, state: { showSearch: showSearch }})
        } else {
            navigate(`/search/${encodeURIComponent(value)}`, {state: { showSearch: showSearch }})
        }
    }

    var willAutoFocus = false
    var value = ''
    if(window.location.pathname.match(regex)) {
        value = String(window.location.pathname).replace('/search/', '')
        value = decodeURIComponent(value)
        willAutoFocus = true        // stops autofocus at search-bar on pages other than search
    }

    var searchReveal = null

    if(showSearch) {
        searchReveal = {
            display: 'flex',
            width: '100%'
        }
    }

    var menuStyle = {paddingLeft: '40px', paddingRight: '20px'}
    if(menuActive) {
        menuStyle = {paddingLeft: '10px', paddingRight: '20px'}
    }

    let unreadMsgs = 0
    if(globalState.threads.length > 0) {
        globalState.threads.map(val => {
            let {unreadMarker, otherParty, lastMsg, lastRead} = msgReadHelper(val, globalState.user._id)
            if(unreadMarker) {
                unreadMsgs += 1
            }
        })
    }

    return (
        <header id="header_main" className="header_1 js-header is-fixed">
        <div className="themes-container">
            <div className="row">
                <div className="col-md-12">
                    <div id="site-header-inner">
                        <div className="wrap-box flex">
                            <div id="site-logo" className="clearfix" >
                                <div id="site-logo-inner">
                                    <NavLink to="/" className="main-logo"><img src={logo} alt="Icoland" style={{width: '150px'}}/></NavLink>
                                </div>
                            </div>
                            <div className={`mobile-button ${menuActive ? 'active' : ''}`} 
                                onClick={handleMenuActive}
                            ><span></span></div>
                            <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} style={menuStyle}>
                                <ul id="menu-primary-menu" className="menu">
                                    {
                                        menus.slice(0, 4).map((data,idx) => (
                                            <li key={idx} onClick={()=> handleDropdown(idx)}
                                                className={`menu-item menu-item-addition ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}` }
                                            >
                                                <Link to={data.links} style={{fontWeight: 500, fontSize: '14px'}}>{data.name}</Link>
                                                {
                                                    data.namesub &&
                                                    <ul className="sub-menu" style={{padding: '10px', borderRadius: '10px', width: '150px'}}>
                                                        {
                                                            data.namesub.map((submenu) => (
                                                                <li key={submenu.id} className="menu-item submenu-item-addition">
                                                                    <NavLink to={submenu.links} onClick={handleDropdown} style={{fontWeight: 500, fontSize: '14px'}}>
                                                                        {submenu.sub}
                                                                    </NavLink>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                }
                                            </li>
                                        ))
                                    }
                                    {/* { globalState?.user ? 
                                        <li key={3} className={`menu-item menu-item-addition ${menus[3].namesub ? 'menu-item-has-children' : ''}`}>
                                            <Link to={menus[3].links} style={{fontWeight: 500, fontSize: '14px'}}>{`You are a ${capitalizeFirstLetter(String(globalState.mode))}`}</Link>
                                            {
                                                menus[3].namesub &&
                                                <ul className="sub-menu" style={{padding: '10px', borderRadius: '10px', width: '150px'}}>
                                                    {
                                                        menus[3].namesub.map((submenu) => (
                                                            <li key={submenu.id} className="menu-item submenu-item-addition">
                                                                <NavLink to='#' 
                                                                    onClick={() => handleDropdown(submenu.mode)} 
                                                                    style={() => {
                                                                        if(submenu.mode === globalState.mode) {
                                                                            return {fontWeight: 500, fontSize: '14px', color: 'rgb(29, 191, 113)'}
                                                                        } else {
                                                                            return {fontWeight: 500, fontSize: '14px', color: 'rgb(4, 19, 12)'}
                                                                        }
                                                                    }}
                                                                >
                                                                    {submenu.sub}
                                                                </NavLink>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                        </li>
                                        :
                                        ''
                                    } */}
                                </ul>
                            </nav>
                            {/* <div className="flat-search-btn flex" style={{right: 0, width: '43%', ...searchReveal}}>
                                <div className="header-search flat-show-search" style={{width: '100%', margin: '0 80px 0 0px'}}>
                                    <div className="top-search" style={{width: '100%', margin: 0}}>
                                        <form action="#" method="get" role="search" className="search-form"
                                            style={{width: '100%'}}
                                        >
                                            <input type="search" id="s" className="search-field style" placeholder="Search Datasets" name="s" title="Search for" required=""
                                                style={{
                                                    border: 'none',
                                                    height: '50px',
                                                    backgroundColor: 'rgb(246, 248, 250)',
                                                    boxShadow: 'none',
                                                    borderRadius: '12px',
                                                    color: 'rgb(90, 90, 90)'
                                                }}
                                                value={value}
                                                onChange={ e => searchRequest(e) }
                                                autoFocus={willAutoFocus}
                                                autoComplete="off"
                                            />
                                            {
                                                showSearch ?
                                                <button className="search search-submit" title="Close"
                                                    style={{backgroundColor: 'transparent', color: 'rgb(90, 90, 90)'}}
                                                >
                                                    <i className="far fa-times" 
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setShowSearch(false)
                                                        }}></i>
                                                </button>
                                                :
                                                <button className="search search-submit" title="Search"
                                                    style={{backgroundColor: 'transparent', color: 'rgb(90, 90, 90)'}}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                    }}
                                                >
                                                    <i className="far fa-search"></i>
                                                </button>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DarkMode unreadMsgCount={unreadMsgs}/>
        {/* {
            globalState.showMsgPanel ?
            <div style={{
                width: '600px',
                height: '600px',
                position: 'fixed',
                bottom: '10px',
                right: '10px'
            }}>
                <MainMsgPanel/>
            </div>
            :
            null
        } */}
        {/* {
            showSearch ?
            null
            :
            <span className='search-button-toggle' onClick={() => {setShowSearch(true)}}>
                <SearchButton display={<i className="far fa-search"></i>}/>
            </span>
        } */}
    </header>
    );
}

export default Header;