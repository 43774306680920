import React, {useState, useEffect, useContext} from 'react'
import '../scss/components/section.scss'
import Header from '../components/header/header.jsx'
import axios from '../utilities/axios'
import Footer from '../components/footer/footer-v1'
import dataFooter from '../assets/fake-data/data-footer'
import Bottom from '../components/bottom'
import {Link, useLocation} from 'react-router-dom'

import { Context } from '../contexts/GlobalContext'
import { useNavigate } from 'react-router-dom'
import img from '../assets/images/login.svg'
import Spinner from '../components/spinner'

function Login(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [willRememberMe, setWillRememberMe] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const { globalState, setGlobalState } = useContext(Context)
  const navigate = useNavigate()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const step = queryParams.get('continue') || ''

  useEffect(() => {
    if(globalState && globalState.user) {
      navigate('/')
    }
  })

  function submitDetails() {
    if(!isLoading) {
      setIsLoading(true)
      let payload = { email, password, willRememberMe }
      axios.post('user/login', payload, {withCredentials: false}).then(response => {
        if(response.data && response.data.user) {
          let { user } = response.data
          setGlobalState({user}, () => {
            localStorage.setItem('user', JSON.stringify(user))
            if(step === 'integrate') {
              window.location.href = '/nelima'
            } else {
              navigate('/')
            }
          })
        } else {
          setIsLoading(false)
        }
      }).catch(err => {
        if(err?.response?.status === 404) {
          setIsLoading(false)
          setIsError(true)
        }
      })
    }
  }

  var errorMsg = null

  if(isError) {
    errorMsg = (
      <div style={{
        fontSize: '10px',
        backgroundColor: 'rgb(255, 221, 224)',
        color: 'rgb(211, 47, 47)',
        borderRadius: '5px',
        padding: '10px',
        margin: '5px 0 10px 0',
        position: 'relative'
      }}><strong>Error Signing In:</strong>
        <div className='sv-warning-msg-close-btn'
          onClick={() => setIsError(false)}
        ><strong>⨉</strong></div>
        <ul style={{listStyleType: 'circle', marginTop: '5px', marginLeft: '5px'}}>
          <li>• Email not recognized.</li>
          <li>• Entered wrong password.</li>
        </ul>
      </div>
    )
  }

  return (
    <div className='wrapper-style' id='wrapper'>
      {<Header />}
      <div className="tf-section flat-login flat-auctions-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wrap-login" >
                <div className="box-login post">
                  <img src={img} alt="Sellagen Login" 
                    className="absolute mark-login sellagen-login-img"
                  />
                  <div className="heading-login">
                    <h2 className="fw-5">Sign In</h2>
                    <div className="flex"><p>Don't have an account yet? </p><Link to={`/register${step === 'integrate' ? '?continue=integrate': ''}`} className="text-p text-color-4 fw-6">Sign Up</Link></div>
                  </div>
                  <form id="contactform"
                    onSubmit={(event) => {
                      event.preventDefault()
                      submitDetails()
                      return false
                    }}
                  >
                    <div className="form-login flat-form flex-one">
                      <div className="info-login">
                        <fieldset>
                          <p className="title-infor-account">Email</p>
                          <input
                            value={email}
                            type="email"
                            name="email"
                            id="email"
                            placeholder='example@email.com'
                            aria-required="true"
                            required
                            onChange={event => { setEmail(event.target.value) }}
                          />
                        </fieldset>
                        <fieldset className="style-pass">
                          <p className="title-infor-account">Password</p>
                          <input 
                            value={password}
                            type="password" 
                            name="password" 
                            id="password" 
                            placeholder="*************"
                            aria-required="true"
                            // minLength={8}
                            maxLength={24}
                            required 
                            // pattern={"^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]$"}
                            // onInvalid={event => { event.currentTarget.setCustomValidity('At least one uppercase letter, one lowercase letter and one number')}}
                            onChange={event => { setPassword(event.target.value) }}
                          />
                        </fieldset>
                        {errorMsg}
                        <div className="row-form style-1 flex-two">
                          <label className="flex align">
                            <input
                              value={willRememberMe}
                              type="checkbox"
                              onChange={event => { setWillRememberMe(event.target.checked) }}
                            />
                            <span className="btn-checkbox flex-two"></span>
                            <span className="text-p text-color-7">Remember Me</span>
                          </label>
                        </div>
                        <button className="submit button-login" style={{fontWeight: 500,}}>
                          {isLoading ? <Spinner style={{color: 'white'}}/> : 'Sign In'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {<Footer data={dataFooter}/>}
      {<Bottom classection='widget-social-footer'/>}
    </div>
  );
}

export default Login;