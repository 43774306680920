import React, { Component } from 'react'
import Overlay from '../components/overlay'
import axios from '../utilities/axios'

const Context = React.createContext()

class Global extends Component {
	constructor(props) {
		super(props)
		var user = null
		var mode = 'buyer'
		if(localStorage.hasOwnProperty('user')) {
			var value = localStorage.getItem('user')
			user = JSON.parse(value)
		}
		if(localStorage.hasOwnProperty('mode')) {
			mode = localStorage.getItem('mode')
		} else {
			localStorage.setItem('mode', mode)
		}
		this.state = { 
			user: user, 
			overlayContent: null,
			overlayExitCB: null, 
			stripe: null, 
			mode: mode,
			threads: [],
			showMsgPanel: false,
			showMenu: false,
			activeThread: null,
			activeThreadMetadata: null,
			enableMessaging: true,
			sandboxState: null
		}
	}

	_isMounted = false
	_intervalID = null

	loadMessages = () => {
		if(this.state.user && this.state.enableMessaging) {
			axios.get('user/all-threads').then(response => {
				if(this._isMounted) {
					if(response.data?.threads.length > 0) {
						this.setState({threads: response.data?.threads})
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	syncUser = (initial=false, callback=() => {}) => {
		axios.get('user/self').then(response => {
			if(this._isMounted) {
				if(response.data?.user) {
					this.setState({user: response.data.user}, callback)
					localStorage.setItem('user', JSON.stringify(response.data.user))
					// this.loadMessages()
					// this._intervalID = setInterval(() => {
					// 	this.loadMessages()
					// }, 30 * 1000)    // 30 seconds
				} else {
					this.setState({user: null}, callback)
					localStorage.removeItem('user')
				}
			}
		}).catch(err => {
			if(err && err.response && err.response.status === 401) {
				if(this._isMounted) {
					this.setState({user: null}, callback)
					localStorage.removeItem('user')
				}
			} else if(err && err.response && initial) {
				if(this._isMounted) {
					this.setState({user: null}, callback)
					localStorage.removeItem('user')
				}
			} else console.log(err)
		})
	}

	logOutUser = () => {
		axios.post('user/logout', {logoutAll: false}).then(response => {
			if(this._isMounted) {
				if(response.data?.currentStatus === 'success') {
					localStorage.removeItem('user')
					localStorage.removeItem('prompts')
					localStorage.removeItem('initial-context')
					localStorage.removeItem('context-enabled')
					localStorage.removeItem('service-enabled')
					localStorage.removeItem('sandbox')
					this.setState({user: null}, () => {
						window.location.replace('/')
					})
				} else {
					window.location.replace('/dashboard')
				}
			}
		})
	}

	threadLookup = (sellerID) => {
		if(sellerID) {
			let potentialThread = this.state.threads.filter((value, idx) => {
				let {threadID} = value
				if((
					threadID.buyerID === this.state.user?._id && threadID.sellerID === sellerID)
					||
					(threadID.sellerID === this.state.user?._id && threadID.buyerID === sellerID)
				) {
					return threadID._id
				} else return null
			})
			if(potentialThread.length > 0) {
				return potentialThread[0].threadID._id
			} else return null
		} else {
			return null
		}
	}

	patchUser = (updateDicts = {}) => {
		let newUser = {...this.state.user}
		Object.keys(updateDicts).forEach(element => {
			newUser[element] = updateDicts[element]
		})
		this.setState({
			user: newUser
		})
	}

	markThreadReadOffline = (id) => {
		if(id) {
			let potentialThreadForUpdate = null
			let tempThreads = this.state.threads.filter((value, idx) => {
				let {threadID} = value
				if(threadID._id === id) {
					potentialThreadForUpdate = {...value}
					return null
				} else {
					return value
				}
			})
			if(potentialThreadForUpdate) {
				let {threadID} = potentialThreadForUpdate
				threadID['buyerLastRead'] = String(new Date().toISOString())
				threadID['sellerLastRead'] = String(new Date().toISOString())
				potentialThreadForUpdate['threadID'] = threadID
				tempThreads = [...tempThreads, potentialThreadForUpdate]
				this.setState({threads: tempThreads})
			}
		}
	}

	loadStripe = () => {
		axios.get('user/self-payment').then(response => {
			if(this._isMounted) {
				if(response.data?.url) {
					this.setState({ stripe: {url: response.data.url, ready: true} })
				} else if(response.data?.msg === 'not_ready') {
					this.setState({ stripe: null })
				} else {
					this.setState({ stripe: {url: null, ready: false} })
				}
			}
		}).catch(err => {
			if(err && err.response && err.response.status === 401) {
				if(this._isMounted) {
					this.setState({ user: null, stripe: null })
				}
			} else console.log(err)
		})
	}

	isPurchased = (itemID) => {
		const purchases = this.state.user?.purchases || {}
		return itemID in purchases
	}

	setAccountMode = (mode) => {
		if(mode === 'buyer' || mode === 'seller') {
			this.setState({mode: mode})
			localStorage.setItem('mode', mode)
		}
	}

	componentDidMount() {
		this._isMounted = true
		this.syncUser(true)
	}

	render() {
		return(
			<Context.Provider value = {{
				globalState: this.state,
				setGlobalState: this.setState.bind(this),
				syncUser: this.syncUser,
				patchUser: this.patchUser,
				loadStripe: this.loadStripe,
				isPurchased: this.isPurchased,
				setAccountMode: this.setAccountMode,
				threadLookup: this.threadLookup,
				loadMessages: this.loadMessages,
				markThreadReadOffline: this.markThreadReadOffline,
				logOutUser: this.logOutUser
			}}>
				{ this.state.overlayContent !== null ? 
					<Overlay 
						content={ this.state.overlayContent }
						close={ () => {
							this.setState({ overlayContent: null })
							console.log(this.state.overlayExitCB)
							if(this.state?.overlayExitCB) {
								this.state.overlayExitCB()
								this.setState({ overlayExitCB: null })
							}
						}}
					/>
					:
					null
				}
				{this.props.children}
			</Context.Provider>
		)
	}

	componentWillUnmount() {
		this._isMounted = false;
		if(this._intervalID) {
			clearInterval(this._intervalID);
		}
	}
}

export {Global, Context}