const dataFaq = [
    {
        id: 0,
        title: 'What is SELLAGEN?',
        content: 'SELLAGEN is an online data marketplace where businesses and individuals can purchase and sell their data. We allow users to easily access and monetize data.',
    },
    {
        id: 1,
        title: 'Does SELLAGEN contact its users?',
        content: 'We reach out to the SELLAGEN seller community only for data verification, if we detect an anomaly. We make sure that all incoming data are well defined and documented, who is the seller in relations to the data they possess and does it make sense for the seller to possess such data according to their user profile. Collection standards, protocols, and methodologies for data collection are documented and verified. We investigate how did the seller acquire this data and through which method was it acquired.',
    },
    {
        id: 2,
        title: 'How does SELLAGEN ensures data Safety?',
        content: 'Verifying and validating data allows for our platform to convey and support accurate and reliable data, reducing the risks of inaccurate performance data and provide a sufficient level of confidence to users that data within our listings are credible. For this purpose, we established guidelines that serve as guidance to check incoming data, thus limiting fraud and address the issues of data integrity and credibility.',
    },
    {
        id: 3,
        title: 'Am I allowed to resell data that I bought in on SELLAGEN?',
        content: 'To protect the integrity of our marketplace, reselling data bought on SELLAGEN is strictly forbidden. Please check out our Terms & Conditions for more information.',
    },
    {
        id: 4,
        title: 'Is my personal information safe?',
        content: 'We care about your privacy. You can read our Terms of Service for more as well as our Cookie Policy.',
    },
    {
        id: 5,
        title: 'How can I contact SELLAGEN?',
        content: 'You can contact an administrator through the following email: support@sellagen.com',
    },
    {
        id: 6,
        title: 'How do I create a listing?',
        content: 'On the top right-hand corner, click Your profile, while on the “You are a Seller” to redirect you to your seller dashboard. Then click on “List a new Dataset”. You can choose a title for your data listing, a category, add a description and set a price. Once completed, click on “Submit Listing” to finalize your listing.',
    },
    {
        id: 7,
        title: 'Does SELLAGEN charge fees?',
        content: 'The only fee SELLAGEN charges is the 20% commission fee associated with each transaction.',
    },
    {
        id: 8,
        title: 'How do I connect my banking information?',
        content: 'SELLAGEN uses Stripe to process all payments. To connect, go to your Seller dashboard by clicking Your profile. Then click on “Register Account” to register your banking information with Stripe.',
    },
    {
        id: 9,
        title: 'I am a Seller, how much is my data worth?',
        content: 'Like any market, the value of personal data fluctuates primarily based on supply and demand. Some factors to keep in mind when setting a price are quality, availability, subject matter, convenience, uniqueness, difficulty of replication and relevancy.',
    },
    {
        id: 10,
        title: 'How long does it take for me to receive payment as a Seller?',
        content: 'Once a purchase has been made, Sellers can expect payment in about 10 days.',
    },
    {
        id: 11,
        title: 'Do I have to PAY TAXES on income earned on SELLAGEN?',
        content: 'It is the sole responsibility of sellers to verify their personal tax obligation, report, and pay taxes according to the laws of the state and/or country of residence, as applicable and required by local law and regulations.',
    },
    {
        id: 12,
        title: 'How can I price my dataset?',
        content: 'We allow Sellers to offer free datasets on our platform. If you decide to sell a dataset for money, the minimum price that can be set US $5.00',
    },
    {
        id: 13,
        title: 'How do I purchase a listing?',
        content: 'Click on your desired listing, which will redirect You towards the full listing details. Then click on “Purchase Dataset”',
    },
    {
        id: 14,
        title: 'Where do I see my purchased data?',
        content: 'You can check a list of your purchase data by going to your Buyer dashboard. Just select “You are a Buyer” and click on your profile to access the Buyer dashboard.',
    },
    {
        id: 15,
        title: 'There is an issue with the data I purchased. What do I do?',
        content: 'Please contact us at support@sellagen.com and we will have an administrator reach out to you as soon as possible to resolve your issue.',
    }
]

export default dataFaq;