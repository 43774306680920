import React, {Component} from 'react'
import {Editor, EditorState, RichUtils, convertToRaw, convertFromRaw, ContentBlock, ContentState} from 'draft-js'
import './editor.css'
import "draft-js/dist/Draft.css"

class RichEditor extends Component {
  constructor(props) {
		super(props);
		var editorState = null;
		if(this.props.stringState) {
			editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.stringState)));
		} else {
			editorState = EditorState.createEmpty();
		}
		this.state = {editorState: editorState};
		this.editorRef = React.createRef();
		this.focus = () => this.editorRef.current.focus();
		this.onChange = (editorState) => {
			this.setState({editorState}, () => {
				this._saveContents();
			});
		}
		this.handleKeyCommand = (command) => this._handleKeyCommand(command);
		this.onTab = (e) => this._onTab(e);
		this.toggleBlockType = (type) => this._toggleBlockType(type);
		this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
		// console.log('rich editor string', this.props.stringState)
	}

  _saveContents = () => {
		if(this.props.saveContents) {
			var raw = convertToRaw(this.state.editorState.getCurrentContent())
			var stringifiedContent = JSON.stringify(raw);
			this.props.saveContents(stringifiedContent);
		}
  }

  _handleKeyCommand(command) {
		const {editorState} = this.state;
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			this.onChange(newState);
			return true;
		}
		return false;
  }

  _onTab(e) {
		const maxDepth = 4;
		this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }

  _toggleBlockType(blockType) {
		this.onChange(
			RichUtils.toggleBlockType(
				this.state.editorState,
				blockType
			)
		)
  }

  _toggleInlineStyle(inlineStyle) {
		this.onChange(
				RichUtils.toggleInlineStyle(
				this.state.editorState,
				inlineStyle
			)
		)
  }

  render() {
		const {editorState} = this.state;
		// If the user changes block type before entering any text, we can
		// either style the placeholder or hide it. Let's just hide it now.
		let className = 'RichEditor-editor';
		var contentState = editorState.getCurrentContent();
		if (!contentState.hasText()) {
			if (contentState.getBlockMap().first().getType() !== 'unstyled') {
				className += ' RichEditor-hidePlaceholder';
			}
		}

		return (
			<div className="RichEditor-root" style={{borderRadius: '10px'}}>
				<span style={{display: 'flex'}}>
				<BlockStyleControls
					editorState={editorState}
					onToggle={this.toggleBlockType}
				/>
				<InlineStyleControls
					editorState={editorState}
					onToggle={this.toggleInlineStyle}
				/>
			</span>
			<div className={className} onClick={this.focus} style={{padding: '10px 15px'}}>
				<Editor
					blockStyleFn={getBlockStyle}
					customStyleMap={styleMap}
					placeholder={this.props.placeholder}
					editorState={editorState}
					handleKeyCommand={this.handleKeyCommand}
					onChange={this.onChange}
					onTab={this.onTab}
					ref={this.editorRef}
					spellCheck={true}
				/>
			</div>
			</div>
		);
  }
}

const Viewer = ({stringState, style=null}) => {
	var editorState = null;
	if(stringState === '') {
		// editorState = EditorState.createEmpty();
		return null;
	} else {
		editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(stringState)));
	}
	
	let className = 'RichEditor-editor';
	var contentState = editorState.getCurrentContent();
	if (!contentState.hasText()) {
	  if (contentState.getBlockMap().first().getType() !== 'unstyled') {
			className += ' RichEditor-hidePlaceholder';
	  }
	}
	return(
		<div className="RichEditor-root" style = {{border: 'none', height: 'auto', marginBottom: '30px', ...style}}>
			<div className={className} style = {{
					borderTop: 'none', 
					height: 'auto', 
					overflow: 'visible'
			}}>
				<Editor
					editorState = {editorState}
					readOnly
				/>
			</div>
		</div>
	)
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
		fontSize: 16,
		padding: 2
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
		case 'blockquote': return 'RichEditor-blockquote';
		default: return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
	super();
	this.onToggle = (e) => {
	  e.preventDefault();
	  this.props.onToggle(this.props.style);
	};
  }

  render() {
	let className = 'RichEditor-styleButton';
	if (this.props.active) {
	  className += ' RichEditor-activeButton';
	}

	return (
	  <span className={className} onMouseDown={this.onToggle}>
		{this.props.label}
	  </span>
	);
  }
}

const BLOCK_TYPES = [
  {label: <strong>H1</strong>, style: 'header-one'},
  {label: <strong>H2</strong>, style: 'header-two'},
  {label: <strong>H3</strong>, style: 'header-three'},
  {label: <strong>H4</strong>, style: 'header-four'},
  {label: <strong>H5</strong>, style: 'header-five'},
  {label: <strong>H6</strong>, style: 'header-six'},
  {label: <i className="fas fa-quote-right"></i>, style: 'blockquote'},
  {label: <i className="far fa-list"></i>, style: 'unordered-list-item'},
  {label: <i className="far fa-list-ol"></i>, style: 'ordered-list-item'}
];

const BlockStyleControls = (props) => {
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

  return (
	<div className="RichEditor-controls">
	  {BLOCK_TYPES.map((type, index) =>
		<StyleButton
		  key={index}
		  active={type.style === blockType}
		  label={type.label}
		  onToggle={props.onToggle}
		  style={type.style}
		/>
	  )}
	</div>
  );
};

var INLINE_STYLES = [
  {label: <i className="fas fa-bold"></i>, style: 'BOLD'},
  {label: <i className="far fa-italic"></i>, style: 'ITALIC'},
  {label: <i className="far fa-underline"></i>, style: 'UNDERLINE'}
];

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
	<div className="RichEditor-controls">
	  {INLINE_STYLES.map((type, index) =>
		<StyleButton
		  key={index}
		  active={currentStyle.has(type.style)}
		  label={type.label}
		  onToggle={props.onToggle}
		  style={type.style}
		/>
	  )}
	</div>
  );
};

export {RichEditor, Viewer}