import React, { useState, useContext, useEffect, Fragment } from "react"
import '../scss/components/section.scss'
import Header from '../components/header/header.jsx'
import Footer from '../components/footer/footer-v1'
import dataFooter from '../assets/fake-data/data-footer'
import Bottom from '../components/bottom'
import { useNavigate } from 'react-router-dom'
import axios from '../utilities/axios'
import listDS from '../assets/images/post-request.svg'

import { Context  } from '../contexts/GlobalContext'
import MultiInput from "../components/multi-input"
import '../components/overlay-prompts/styles.scss'
import Spinner from "../components/spinner"
import { useIsMounted } from '../utilities/hooks'
import { RichEditor } from '../components/editor'
import MultiInputSelect from "../components/multi-input-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import RequestSuccess from "../components/overlay-prompts/request-success"

function MakeRequest(props) {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState(0.00)
    const [maxPrice, setMaxPrice] = useState(-1.00)
    const [fields, setFields] = useState([])
    const [geoLocation, setGeoLocation] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    // const [datasetID, setDatasetID] = useState('')
    const [completed, setCompleted] = useState(false)
    const [isPrivate, setIsPrivate] = useState(false)
    const [deadline, setDeadline] = useState()

    const { globalState, setGlobalState } = useContext(Context)
    const navigate = useNavigate()
    const isMounted = useIsMounted()

    const isValidSubmissions = () => {
        var msg = []
        if(title === '' || title === null) {
            msg.push('Title: Add a title for the request')
        }
        if(description === '' || description === null) {
            msg.push('Description: Add descriptions for the dataset request')
        }
        if(price === 0.0 || price === null) {
            msg.push('Price: Assign a estimated minimum price budget for the requested dataset')
        }
        if(msg.length !== 0) {
            setGlobalState({
                overlayContent: (
                    <div style={{marginBottom: '-10px'}}>
                        <div className="sellagen-prompt-title">
                            <strong>Problem with following fields</strong>
                        </div>
                        {msg.map((e, i) => (
                            <div key={i}><p className="sellagen-para">{e}</p><br/></div>
                        ))}
                    </div>
                )
            })
            return false
        } else return true
    }

    useEffect(() => {
        if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
            navigate('/')
        }
    })

    const handleSubmission = () => {
        if(isValidSubmissions()) {
            let payload = {
                title,
                description,
                price,
                fields,
                geoLocation,
                deadline: Date(deadline).toString(),
                isPrivate,
                maxPrice
            }
            if(!isLoading) {
                setIsLoading(true)
                axios.post('user/request/create', payload).then(response => {
                    if(isMounted) {
                        if(response.data && response.data._id) {
                            setGlobalState({overlayContent: <RequestSuccess/>})
                        }
                    }
                    setIsLoading(false)
                }).catch(err => {
                    console.log(err)
                    setIsLoading(false)
                })
            }
            // console.log(payload)
        }
    }

    var view = (
        <>
            <form action="#" className="form-profile">
                <div className="form-infor-profile flat-form">
                    <fieldset>
                        <h4 className="title-two fw-6" style={{marginTop: '5px'}}>Title</h4>
                        <input
                            // value={item.title}
                            type="text"
                            className="sv-input-field-correction"
                            placeholder="Enter a concise request title"
                            // required
                            onChange={ event => { setTitle(event.target.value) }}
                        />
                    </fieldset>
                    <fieldset className="message">
                        <h4 className="title-two fw-6" style={{margin: '20px 0 10px 0'}}>Description</h4>
                        {/* <textarea tabIndex="4" rows="3" 
                            placeholder="Describe the data as precisely as possible. Make sure to include complete information regarding the data listed such as the acquisition methodology, variables explanation, subject matter and other." 
                            // required
                            style={{height: '200px', marginBottom: '-5px', color: 'black'}}
                            onChange={ event => { setDescription(event.target.value) }}
                        ></textarea> */}
                        <RichEditor
                            saveContents = { value => {
                                setDescription(value)
                            }}
                            stringState = {description}
                            placeholder = 'Enter a brief description about the requested dataset. The description may include number of expected rows, desired organization of the data, processing methodology, or the file format.'
                        />
                    </fieldset>
                </div>
            </form>
            <fieldset style={{margin: '0 0 0 0'}}>
                {/* <p className="title-infor-account">Tags</p> */}
                <h4 className="title-two fw-6">Desired Data Fields</h4>
                <MultiInput
                    values = {fields}
                    placeholder = 'e.g. ID, Name, Category, Price, Location'
                    buttonTitle = {<strong>Add</strong>} 
                    writeCallback = {(values) => {
                        setFields(values)
                    }}
                    limit = {999}
                />
            </fieldset>
            <fieldset style={{margin: '30px 0 25px 0', zIndex: 800}}>
                {/* <p className="title-infor-account">Tags</p> */}
                <h4 className="title-two fw-6">Data Geography <span style={{color: 'rgb(170, 170, 170)'}}>&nbsp;–&nbsp;&nbsp;You can add multiple locations</span></h4>
                <MultiInputSelect
                    values = {geoLocation}
                    buttonTitle = {<strong>Add</strong>} 
                    writeCallback = {(values) => {
                        setGeoLocation(values)
                    }}
                />
            </fieldset>
            <fieldset style={{margin: '30px 0 20px 0'}}>
                <h4 className="title-two fw-6">Deadline <span style={{color: 'rgb(170, 170, 170)'}}>&nbsp;–&nbsp;&nbsp;Estimated deadline to fullfil the request</span></h4>
                <div style={{width: '100%', position: 'relative', zIndex: 800}}>
                    <DatePicker 
                        closeOnScroll
                        placeholderText="Optional"
                        minDate={new Date()}
                        showPopperArrow={false}
                        selected={deadline}
                        onChange={(date) => setDeadline(date)}
                    />
                </div>
            </fieldset>
            <fieldset style={{margin: '25px 0 30px 0'}}>
                <h4 className="title-two fw-6">Minimum Price<span style={{color: 'rgb(170, 170, 170)'}}>&nbsp;–&nbsp;&nbsp;Estimated minimum budget for the dataset</span></h4>
                <input
                    style={{height: '49px', color: 'black', borderRadius: '10px'}}
                    type="number"
                    placeholder="Price in USD"
                    // required
                    onChange={ event => { setPrice(event.target.value) }}
                />
            </fieldset>
            <fieldset style={{margin: '25px 0 30px 0'}}>
                <h4 className="title-two fw-6">Maximum Price<span style={{color: 'rgb(170, 170, 170)'}}>&nbsp;–&nbsp;&nbsp;Estimated maximum budget for the dataset</span></h4>
                <input
                    style={{height: '49px', color: 'black', borderRadius: '10px'}}
                    type="number"
                    placeholder="Optional - Price in USD"
                    // required
                    onChange={ event => { setMaxPrice(event.target.value) }}
                />
            </fieldset>
            <fieldset>
                <h4 className="title-two fw-6">Post Type<span style={{color: 'rgb(170, 170, 170)'}}>&nbsp;–&nbsp;&nbsp;Keep Requester Identity Public or Anonymous</span></h4>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '12px',
                    marginBottom: '30px'
                }}>
                <div className={`sellagen-selector ${isPrivate ? '' : 'sellagen-selected'}`}
                    style={{marginRight: '10px'}}
                    onClick={() => setIsPrivate(false)}
                >
                    <i className="far fa-clipboard-list" style={{fontSize: 20}}></i><br/>
                    {/* <i className="far fa-users" style={{fontSize: 20}}></i><br/> */}
                    <span>Public</span>
                </div>
                <div className={`sellagen-selector ${isPrivate ? 'sellagen-selected' : ''}`}
                    style={{marginLeft: '10px'}}
                    onClick={() => setIsPrivate(true)}
                >
                    <i className="far fa-lock" style={{fontSize: 20}}></i><br/>
                    <span>Anonymous</span>
                </div>
                </div>
            </fieldset>
            <br/>
            <button className="tf-button-submit mg-t-15"
                style={{
                    margin: '10px 0 0 0', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    padding: '0', 
                    height: '60px', 
                    boxShadow: 'none',
                    borderRadius: '10px'
                }}
                onClick={(event) => {
                    // event.preventDefault()
                    handleSubmission()
                }}
            >
                <strong>Done</strong>
                {isLoading ? 
                <>&nbsp;&nbsp;<Spinner style={{
                    height: '16px', width: '16px',
                }}/></> : ''}
            </button>
        </>
    )
    return (
        <div className='wrapper-style' id='wrapper'>
            <Header />
            <Fragment>
            <div className="tf-section flat-create-item flat-edit-profile flat-auctions-details flat-explore flat-auctions">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6 ">
                            <img src={listDS}
                                style={{
                                    marginBottom: '20px'
                                }}
                            />
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="form-upload-profile post">
                                <h2 style={{marginBottom: '25px'}}><strong>Post a Dataset Request</strong></h2>
                                {view}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Fragment>
            {<Footer data={dataFooter}/>}
            {<Bottom classNameection='widget-social-footer'/>}
        </div>
    );
}

export default MakeRequest