import React from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx'
// import Banner from '../components/banner/banner-v2';
import Banner from '../components/banner/banner-v3';
// import dataBanner from '../assets/fake-data/data-banner';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import Footer from '../components/footer/footer-v2'
// import SearchBar from '../components/searchBar'
// import FrontDeck from '../components/front-deck'
// import APIBanner from '../components/api-banner'
import SandBox from '../pages/SpeedRun/sandbox'

function Home(props) {
    return (
        <div className='wrapper-style' id='wrapper'>
            <Header />
            {/* <Banner data={dataBanner} /> */}
            <Banner/>
            <div className='front-sndbx-wrapper'>
                <SandBox demo={true}/>
            </div>
            {/* <APIBanner/> */}
            {/* <FrontDeck/> */}
            {/* <SearchBar/> */}
            <Footer data={dataFooter}/>
            <Bottom classection='widget-social-footer home2'/>
        </div>
    )
}

export default Home;