import React, { useContext, useEffect, useState } from 'react'
import { RightPanel, RightPanelBasicTitle } from '../panel-utils'
import Agent from '../../title'
import { FlowBasic, FlowButton } from '../../../../components/flow-ui/flow-blocks'
import SandBoxContext from '../../../../contexts/SandBoxContext'
import Spinner from '../../../../components/spinner'
import '../styles.scss'
import { ResponsiveEllipsis } from '../../../../components/sv3'

const LLMContextPanel = () => {
  const {sandboxState, sandboxSetState} = useContext(SandBoxContext)
  const [currTime, setCurrTime] = useState(new Date())
  var contextKeys = Object.keys(sandboxState?.contexts || {})
  var isEnabled = contextKeys.length > 0

  useEffect(() => {
    const clockInterval = setInterval(() => setCurrTime(new Date()), 1000)
    return () => clearInterval(clockInterval)
  }, [])

  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || null
  
  return (
    <RightPanel>
      <RightPanelBasicTitle>
        <div className='sndbx-panel-close'
          onClick={() => {
            sandboxSetState({showSidePanel: false})
          }}
        >
          <i className="far fa-chevron-right"></i>
        </div>
        <i className="fal fa-book" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;&nbsp;Context Panel
      </RightPanelBasicTitle>
      <div style={{width: '100%', overflowY: 'scroll', padding: '10px'}}>
        <FlowBasic
          title={
            <>
              Context
              <div style={{padding: '2px', border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '7px', display: 'inline-block', marginLeft: '10px'}}>
                <FlowButton style={{marginRight: '5px', ...isEnabled ? {color: 'rgb(29, 191, 113)', filter: 'brightness(130%)'} : {backgroundColor: 'transparent'}}}
                  onClick={() => {
                    sandboxSetState({isContextEnabled: true})
                  }}
                >
                  Enabled
                </FlowButton>
                <FlowButton style={{...isEnabled ? {backgroundColor: 'transparent'} : {color: 'orange'}}}
                  onClick={() => {
                    sandboxSetState({isContextEnabled: false})
                  }}
                >
                  Disabled
                </FlowButton>
              </div>
            </>
          }
          titleIcon={<i className="fal fa-book-open"></i>}
          border={'2px solid rgba(255, 255, 255, 0.2)'}
        >
          <div className='sv2-flow-stacked-body' style={{height: 'auto', alignItems: 'unset'}}>
            <div className='title-icon'>
              <div className='square'
                style={{bottom: '12px'}}
              ></div>
            </div>
            <div style={{marginTop: '4px', width: 'auto', maxWidth: '90%'}}>
              <div style={{backgroundColor: 'rgba(255,255, 255, 0.05)', borderRadius: '5px', padding: '10px', lineHeight: 1.3, fontFamily: 'Roboto Mono'}}>
                Contexts are pieces of information about you that <Agent/> uses to provide better responses.
              </div>
              {contextKeys.length > 0 ? <br/> : null}
              {
                contextKeys.map((elem, i) => (
                  <div className='sndbx-context-blocks' key={i}>
                    <i className={sandboxState?.contexts[elem].icon}></i>&nbsp;{sandboxState?.contexts[elem].title}
                    <div style={{marginLeft: '10px', float: 'right'}}>
                      <ResponsiveEllipsis style={{maxWidth: '200px'}}>
                        {
                          sandboxState?.contexts[elem]?.value === '[currTime]' ?
                          currTime?.toLocaleTimeString() || null
                          :
                          sandboxState?.contexts[elem]?.value === '[currZone]' ?
                          timeZone || null
                          :
                          sandboxState?.contexts[elem]?.value === '[currDate]' ?
                          currTime?.toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          }) || null
                          :
                          sandboxState?.contexts[elem]?.value
                        }
                      </ResponsiveEllipsis>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          {
            sandboxState.isContextLoading ? 
            <div style={{position: 'absolute', top: '17.5px', right: '12.5px'}}>
              <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)', height: '15px', width: '15px'}}/>
            </div>
            :
            null
          }
        </FlowBasic>
      </div>
    </RightPanel>
  )
}

export default LLMContextPanel