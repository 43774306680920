import React, { useContext, useEffect, useState } from 'react'
import { FlowInput, FlowOverlayPrompt, FlowTextBox } from '../../../components/flow-ui/flow-blocks'
import SandBoxContext from '../../../contexts/SandBoxContext'
import Axios from '../../../utilities/axios'
import Spinner from '../../../components/spinner'

const CreateEnvVarOverlay = () => {
  const {setOverlay, sandboxState, sandboxSetState} = useContext(SandBoxContext)
  const [name, setName] = useState('')
  const [key, setKey] = useState('')
  const [value, setValue] = useState('')
  const [description, setDescription] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  
  const runValidation = () => {
    if(String(name).trim()?.length <= 0) {
      return false
    }
    if(String(key).trim()?.length <= 0) {
      return false
    }
    if(String(value).trim()?.length <= 0) {
      return false
    }
    return true
  }

  useEffect(() => {
    setIsValid(runValidation)
  }, [name, value, key])

  const createEnvHandler = async () => {
    if(!isLoading) {
      setIsLoading(true)
      try {
        let response = await Axios.post('authStorage/create', {name, key, value, description})
        if(response?.data?.authSecret?._id) {
          let {authStorageCache} = sandboxState
          if(authStorageCache === null) {
            authStorageCache = []
          }
          authStorageCache = [response?.data?.authSecret, ...authStorageCache]
          sandboxSetState({authStorageCache})
          setIsSuccess(true)
        }
        setIsLoading(false)
      } catch(err) {
        setIsLoading(false)
      }
    }
  }
  const dataTypeComponent = (
    <span style={{
      fontSize: '80%', fontFamily: 'Roboto Mono', 
      backgroundColor: 'rgba(255, 255, 255, 0.1)', color: 'rgb(152, 195, 121)',
      borderRadius: '3px', padding: '0px 4px'}}
    >str
    </span>
  )
  return(
    <FlowOverlayPrompt
      title='Add New Environment Variable'
      promptMsg={<div>
        You can add environment variables as key-value pairs that will be accessible to specific functions. Fill out the following details to add a new environment variable to your function's runtime environment.
        <br/><br/>
        <div style={{marginBottom: '5px', fontSize: '12px'}}>Name &nbsp;<span style={{opacity: .3, fontSize: '80%'}}>&nbsp;For identification purpose</span></div>
        <FlowInput
          style={{fontFamily: 'Roboto Mono', width: '100%'}}
          value={name}
          placeHolder='Pi Approximation'
          onChange={(e) => { setName(String(e.target.value)) }}
        />
        <br/><br/>
        <div style={{marginBottom: '5px', fontSize: '12px'}}>Key &nbsp;{dataTypeComponent}&nbsp;<span style={{opacity: .3, fontSize: '80%'}}>&nbsp;Identifier for referencing the variable in code</span></div>
        <FlowInput
          style={{fontFamily: 'Roboto Mono', width: '100%'}}
          value={key}
          placeHolder='PI_APPROX'
          onChange={(e) => { setKey(String(e.target.value)) }}
        />
        <br/><br/>
        <div style={{marginBottom: '5px', fontSize: '12px'}}>Value &nbsp;{dataTypeComponent}&nbsp;<span style={{opacity: .3, fontSize: '80%'}}>&nbsp;Data stored in the variable</span></div>
        <FlowInput
          style={{fontFamily: 'Roboto Mono', width: '100%'}}
          value={value}
          placeHolder='3.141592653589793'
          onChange={(e) => { setValue(String(e.target.value)) }}
        /><br/>
        <div style={{marginTop: '2px', fontSize: '12px', lineHeight: 1}}>
          <span style={{opacity: .3, fontSize: '80%'}}>
            <i style={{marginRight: '5px'}} className="fas fa-info-circle"></i>The value cannot be viewed or modified once saved. Please ensure accurate input.
          </span>
        </div>
        <br/>
        <div style={{marginBottom: '5px', fontSize: '12px'}}>Description &nbsp;<span style={{opacity: .3, fontSize: '80%'}}><span style={{fontStyle: 'italic'}}>(Optional)</span>&nbsp;&nbsp;Brief description of the variable</span></div>
        <FlowTextBox
          value={description}
          placeHolder='High-precision approximation of pi (π) for mathematical calculations'
          onChange={(e) => { setDescription(String(e.target.value)) }}
        />
        <br/><br/>
        {
          isSuccess ?
          <div style={{lineHeight: 1, fontSize: '12px', fontWeight: 400, color: 'rgb(29, 191, 113)', fontStyle: 'italic'}}>Successfully stored Environment Variable</div>
          :
          null
        }
      </div>}
      titleIcon={<i className="far fa-terminal" style={{color: 'rgb(97, 176, 239)'}}></i>}
      proceedMsg={
        <>
          {
            isSuccess ?
            <i className="fal fa-check-double" style={{color: 'rgb(29, 191, 113)'}}></i>
            :
            isLoading ? 
            <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)', height: '10px', width: '10px', borderWidth: '1.5px'}}/>
            :
            isValid ?
            <i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>
            :
            <i className="fal fa-exclamation-circle" style={{color: 'orange'}}></i>
          }
          &nbsp;{isSuccess? 'DONE' : 'Proceed'}
        </>
      }
      cancelMsg={<><i className="fal fa-times" style={{color: 'crimson'}}></i>&nbsp;Cancel</>}
      onProceed={() => {
        if(!isSuccess) {
          createEnvHandler()
        } else {
          setOverlay(null)
        }
      }}
      onCancel={() => {
        setOverlay(null)
      }}
      proceedDisabled={!isValid}
      containerStyle={{width: '100%', maxWidth: '500px'}}
    />
  )
}

export {CreateEnvVarOverlay}