import React, {useEffect, useContext} from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx'
import CardRow from '../components/cardrows/cardrow-v1/';
import Footer from '../components/footer/footer-v2';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';

import { Context } from '../contexts/GlobalContext'
import { useNavigate, useParams } from "react-router-dom"
import '../scss/custom/customComponentStyles.css'
import PaymentManager from '../components/payment-manager'
import { capitalizeFirstLetter } from '../utilities/handyFunctions';

function DashBoard(props) {
  const { globalState, setGlobalState } = useContext(Context)
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
      navigate('/')
    }
    if(params.messages === 'messages') {
      setGlobalState({showMsgPanel: true})
    }
  })
  var view = null
  var namePlate = (
    <div className='payment-manager-container' style={{marginBottom: '-100px'}}>
      <div style={{
          width: '100%',
          fontSize: '14px',
          padding: '20px',
          borderRadius: '10px',
          backgroundColor: 'rgb(246, 248, 250)'
        }}
      >
        <strong>{`${globalState?.user?.firstName}'s Dashboard >`}
        &nbsp;&nbsp;{capitalizeFirstLetter(String(globalState?.mode))}
        </strong>
        <span style={{float: 'right'}}>
          <span
            className='sellagen-logout'
            onClick={() => {
              navigate('/processing', {state: { mode: 'logout' }})
            }}
          >
            Sign Out
          </span>
        </span>
        <span style={{float: 'right'}}>
          <span
            className='sellagen-logout'
            onClick={() => {
              // redirect to profile edit mode
              navigate('/profile', {state: {edit: true}})
            }}
          >
            Profile
          </span>
          <strong>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</strong>
        </span>
        <span style={{float: 'right'}}>
            <span
              className='sellagen-logout'
              style={{color: 'rgb(29, 191, 113)'}}
              onClick={() => {
                // redirect to profile edit mode
                navigate('/make-request')
              }}
            >
              Request a Dataset
            </span>
            <strong>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</strong>
        </span>
      </div>
    </div>
  )
  if(globalState?.mode === 'seller') {
    const data = globalState?.user?.listings || []
    view = (
      <>
        {namePlate}
        <PaymentManager />
        {/* <ProfileManager /> */}
        <CardRow
          data={data}
          edit={true}
          description={
            <p style={{width: '100%'}}>If you want to update the dataset list the updated version as a new dataset</p>
          }
          title={(<strong>Your Listings</strong>)}
          showAdd={true}
          donationPrompt={true}
        />
      </>
    )
  } else {
    var data = []
    if(globalState?.user?.purchases) {
      data = Object.keys(globalState?.user?.purchases).map(key => globalState?.user?.purchases[key])
      if(data.length === 0) {
        view = <>
          {namePlate}
          <CardRow data={data} title={(<strong>Purchased Datasets</strong>)} showAdd={false} donationPrompt={false}/>
          <div className='payment-manager-container' style={{marginTop: '0px', marginBottom: '40px'}}>
            <div style={{
              width: '100%',
              height: '150px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              padding: '20px',
              borderRadius: '10px',
              backgroundColor: 'rgb(246, 248, 250)',
              color: 'rgb(90, 90, 90)',
            }}>
              You haven't purchased any dataset yet.&nbsp;<a href='https://sellagen.com/search'>Explore Datasets.</a>
            </div>
          </div>
        </>
      } else {
        view = <>
          {namePlate}
          <CardRow data={data} title={(<strong>Purchased Datasets</strong>)} showAdd={false}/>
        </>
      }
    }
  }

  return (
      <div className='wrapper-style' id='wrapper'>
          <Header />
          { view }
          <Footer data={dataFooter}/>
          <Bottom classection='widget-social-footer home2'/>
      </div>
  );
}

export default DashBoard;