import Home from "./Home";
import Explore from "./Explore";
import LiveAuction from "./LiveAuction";
import LiveAuctionDetails from "./LiveAuctionDetails";
import About from "./About";
import Author from "./Author";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
// import CreateItem from "./CreateItem";
import Login from "./Login";
import Register from "./Register";
import Faq from "./Faq";
import PopularCollections from "./PopularCollections";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import DashBoard from "./Dashboard";

import Search from "./Search"
import CookiePolicy from "./CookiePolicy"
import TnC from "./TnC"
import HomePromt from "./HomePrompt.jsx"
import ItemPage from "./ItemPage"
import Processing from './Processing.jsx'
import AddListing from "./Form";
import MakeRequest from "./MakeRequest"
import RequestViewer from "./RequestList"
import ApiPage from "./Api";
import SpeedRun from '../contexts/ConsoleContext'
// import ProjectsPanel from "../components/cloud-panels/projects-panel"
import SandBox from '../pages/SpeedRun/sandbox'
import PrivacyPolicy from "./PrivacyPolicy.jsx";
import ServiceIntegration from "./ServiceIntegration.jsx";
import LimitedUseDisclosure from "./Disclosure.jsx";

const routes = [
    { path: '/', component: <Home />},
    { path: '/search/:keyword', component: <Search />},
    { path: '/search/', component: <Search />},
    { path: '/nelima', component: <SandBox />},
    { path: '/service-integration', component: <ServiceIntegration/>},
    { path: '/explore', component: <Explore />},
    { path: '/speed-run/:id', component: <SpeedRun />},
    // { path: '/projects', component: <ProjectsPanel />},
    { path: '/dataset-requests/', component: <RequestViewer />},
    { path: '/dataset-requests/:page', component: <RequestViewer />},
    { path: '/make-request', component: <MakeRequest />},
    { path: '/liveAuction', component: <LiveAuction />},
    { path: '/liveAuctionDetails', component: <LiveAuctionDetails />},
    { path: '/item/:id', component: <ItemPage />},
    { path: '/about', component: <About />},
    { path: '/author', component: <Author />},
    { path: '/profile/:id', component: <Profile />},
    { path: '/profile/', component: <Profile />},
    { path: '/editProfile', component: <EditProfile />},
    { path: '/dashboard/:messages', component: <DashBoard />},
    { path: '/dashboard', component: <DashBoard />},
    { path: '/sellagen-api', component: <ApiPage />}, 
    // { path: '/createItem', component: <CreateItem />},
    { path: '/createItem', component: <AddListing />},
    { path: '/editItem/:item', component: <AddListing edit={true}/>},
    { path: '/login', component: <Login />},
    { path: '/register', component: <Register />},
    { path: '/Faq', component: <Faq />},
    { path: '/popular-collections', component: <PopularCollections />},
    { path: '/blog', component: <Blog />},
    { path: '/blog-details', component: <BlogDetails />},
    { path: '/contact', component: <Contact />},
    { path: '/cookie-policy', component: <CookiePolicy />},
    { path: '/terms-n-conditions', component: <TnC />},
    { path: '/privacy', component: <PrivacyPolicy />},
    { path: '/limited-use-disclosure', component: <LimitedUseDisclosure />},
    { path: '/processing', component: <Processing />},
    // { path: '/blank-site', component: <BlankTest />},
    { path: '/:prompt', component: <HomePromt />}
  ]
  
  export default routes;