import React, { useContext } from "react"
// import './system.scss'
import './styles.scss'
import { projectComponents } from "./project-components"
import { ConsoleContext } from "../../contexts/ConsoleContext"
import { SystemWindowControls } from "./system-manager"

const AutoPilotManager = () => {
  const autoPilotComponent = projectComponents['autopilot']
  const {project} = useContext(ConsoleContext)
  return (
    <div className='sv2-cloud-base-manager'>
      <div className='sv2-manager-subblock sv2-manager-title'>
        <i className={autoPilotComponent.componentIcon} style={{color: autoPilotComponent.color}}></i>&nbsp;&nbsp;{autoPilotComponent.componentTitle}
        <SystemWindowControls/>
      </div>
      <div className="sv2-manager-subblock base-content">
        <div className="sv2-base-content-title">Auto Pilot Operations</div>
        <div className="sv2-manager-data-list">
          <div className="sv2-manager-data-card" style={{padding: '5px'}}>
            {
              project?.mainEntryPoint &&
              <>
                <div style={{
                  fontSize: '12px',
                  backgroundColor: '#323741',
                  lineHeight: 1,
                  padding: '15px 20px',
                  borderRadius: '5px',
                  marginBottom: '5px',
                  fontFamily: 'Roboto Mono'
                }}>
                  <span style={{color: 'cornflowerblue'}}>Trigger</span>&nbsp;<i className="far fa-long-arrow-right"></i>&nbsp;End of&nbsp;<span className="sv2-manager-filename" style={{color: 'rgb(29, 191, 113)', backgroundColor: 'rgba(29, 191, 113, 0.2)'}}>{project.mainEntryPoint}</span>
                </div>
                <div style={{display: 'flex'}}>
                  <div style={{padding: '10px 20px 10px 30px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '24px'}}><i className="fal fa-level-up fa-rotate-90"></i></div>
                  <div style={{
                    flex: 1,
                    fontSize: '12px',
                    backgroundColor: '#323741',
                    lineHeight: 1,
                    padding: '15px 20px',
                    borderRadius: '5px',
                    fontFamily: 'Roboto Mono'
                  }}>
                    <span style={{color: 'cornflowerblue'}}>Action</span>&nbsp;<i className="far fa-long-arrow-right"></i>&nbsp;Deploy&nbsp;<span className="sv2-manager-filename" style={{color: 'rgb(250, 128, 114)', backgroundColor: 'rgb(250, 128, 114, 0.15)'}}>{project.deployEntryPoint}</span>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <div className="sv2-manager-subblock">
        <div className="sv2-manager-data-list">
          <div className="sv2-manager-data-card" style={{backgroundColor: '#323741'}}>
            <div className="sv2-mgr-dc-body" style={{width: '100%', marginTop: '0px', lineHeight: 1.5}}>
              <i className="far fa-info-circle"></i> The Autopilot operates within the project's scope and is triggered based either the project state or execution logs.
              <br/><br/>
              <div className="sv2-manager-instance-details">Agent</div>:&nbsp;<span style={{fontFamily: 'Roboto Mono'}}>Llama2</span><br/>
              <div className="sv2-manager-instance-details">Refresh Rate</div>:&nbsp;120 seconds<br/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AutoPilotManager