import React from 'react'
import StripeSuccess from './stripe-success'
import PaymentSuccess from './payment-success'
import SignUpPrompt from './sign-up'

const getPrompts = (promptKey) => {
  switch (promptKey) {
    case 'stripe-success':
      return <StripeSuccess/>
    case 'payment-success':
      return <PaymentSuccess/>
    case 'signup-success':
      return <SignUpPrompt
        title={'Sign Up Successful'}
        msg={
            <>You have successfully signed up. Now sign in to your account and go to the dashboard to explore more</>
        }
        btnMsg={'Sign In'}
        btnLink={'login'}
      />
    case 'stripe-retry':
      return null
    default:
      return null
  }
}

export default getPrompts