import React from 'react'
import './styles.scss'

const SlidingTab = ({activeTab, onClick, tabs=[]}) => {
  return(
    <div className='sv2-stb-container'>
      <div className='sv2-stb-tabs'>
        {
          tabs.map((element, i) => 
            <div key={i} className={`sv2-stb-tab-base sv2-stb-tab${activeTab === i+1 ? ' sv2-stb-selected' : ''}`}
              onClick={() => {onClick(i+1)}}
            >
              {element}
            </div>
          )
        }
        <span className='sv2-stb-tab-base sv2-stb-glider'
          style={{transform: `translateX(${(activeTab-1)*100}%)`}}
        ></span>
      </div>
    </div>
  )
}

export default SlidingTab