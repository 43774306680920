import React, { useState, useEffect, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Header from "../../components/header/header"
import { useIsMounted } from "../../utilities/hooks"
import axios from '../../utilities/axios'
import Bottom from '../../components/bottom'
import Footer from '../../components/footer/footer-v2'
import dataFooter from '../../assets/fake-data/data-footer';
import './styles.scss'
import '../../scss/custom/customComponentStyles.css'
import Loading from "../../components/loading"
import { Viewer } from "../../components/editor"
import { Context } from "../../contexts/GlobalContext"
import SignUpPrompt from "../../components/overlay-prompts/sign-up"

const RequestViewer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const isMounted = useIsMounted()
  const params = useParams()
  const [requestPost, setRequestPost] = useState({})
  const [page, setPage] = useState([null, null])
  const { globalState, setGlobalState } = useContext(Context)
  const navigate = useNavigate()
  
  const handlePostARequest = () => {
    if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
      setGlobalState({overlayContent: <SignUpPrompt
        title={'Sign Up'}
        msg={
          <>You need to sign up before posting any request. Already have an account? <strong><a href='/login' className="sellagen-propmt-link">Sign In</a></strong> instead.</>
        }
      />})
    } else {
      navigate('/make-request')
    }
  }

  const loadDetails = (page) => {
    axios.get(`/user/requests/${page}`, {withCredentials: false}).then(response => {
      if(isMounted) {
        if(response.data?.requests) {
          console.log(response.data.request)
          setRequestPost(response.data?.requests?.docs)
          setPage([
            response.data?.requests?.prevPage,
            response.data?.requests?.nextPage
          ])
        } else {
          console.log('Error loading data')
        }
        setIsLoading(false)
      }
    }).catch(err => {
      if(isMounted) {
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    loadDetails(params.page || 1)
  }, [])

  var contents = null
  if(isLoading) {
    contents = <Loading/>
  } else {
    if(requestPost) {
      contents = (
        <div className='sv2-request-container'>
          <div className='sv2-request-collections'>
            <span 
              style={{
                fontSize: '40px', 
                fontWeight: 700, 
                color: 'rgb(50, 50, 50)',
                height: '50px'
              }}
              className='sv2-request-col-block1'
            >Data Requests</span>
            <div className='sv2-request-col-block2'>
              {
                requestPost.map((req, idx) => {
                  // console.log(req)
                  return <RequestBlock key={idx} request={req}/>
                  // if(req.private) {
                  //   if(req.userID === globalState?.user?._id) {
                  //     return <RequestBlock key={idx} request={req}/>
                  //   } else if (globalState?.user?._id === '635eb33db9d20aefe8ed8fc6' || globalState?.user?._id === '635eb3d1b9d20aefe8ed8fd2') {
                  //     return <RequestBlock key={idx} request={req}/>
                  //   } else return null
                  // } else {
                  //   return <>
                  //     <RequestBlock key={idx} request={req}/>
                  //   </>
                  // }
                })
              }
            </div>
            <div className='sv2-request-col-block3'>
              <div className='sv2-request-post'>
                <div style={{
                  width: '100%',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  Can't find the data that you are looking for?
                </div>
                <div className='sv2-request-post-button'
                  onClick={handlePostARequest}
                >
                  Post a Request
                </div>
              </div>
              <div style={{
                width: '100%',
                padding: '20px',
                borderRadius: '15px',
                backgroundColor: 'rgb(246, 248, 250)',
                marginTop: '20px',
                fontSize: '14px',
                lineHeight: 1.5,
                fontWeight: 700,
                textAlign: "center",
                color: 'rgb(75, 75, 75)'
              }}>
                If you can fulfil a data request, feel free to email us at
                <br/>
                <div style={{
                  padding: '2px 8px',
                  fontFamily: 'monospace',
                  textAlign: 'center',
                  borderRadius: '8px',
                  border: '1px solid rgb(206, 208, 210)',
                  margin: '10px auto 0 auto',
                  marginTop: '10px',
                  fontWeight: 500
                }}>
                  support@sellagen.com
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  
  return (
    <>
      <Header/>
      {contents}
      <Footer data={dataFooter}/>
      <Bottom classection='widget-social-footer'/>
    </>
  )
}

const RequestBlock = ({request}) => {
  var requestTag = null
  if(request.approvalStatus === 'completed') {
    requestTag = (
      <span className='sellagen-tag green with-link'>
        <strong>Completed</strong>&nbsp;&nbsp;<i className="far fa-link"></i>
      </span>
    )
  } else if(request.approvalStatus === 'in-progress') {
    requestTag = (
      <span className='sellagen-tag purple'>
        <strong>In Progress</strong>
      </span>
    )
  } else {
    requestTag = (
      <span className='sellagen-tag yellow'>
        <strong>Not Fulfilled</strong>
      </span>
    )
  }
  // TODO: Tidy up initiateThread to have one function to call every time
  var userName = String(request.userName).split('#')
  userName = userName.join(' ').trim()

  var displayName = String(userName)
  if(request.private) {
    displayName = 'Anonymous User'
  }

  const { globalState, setGlobalState, threadLookup } = useContext(Context)
  const {userID} = request

  const initiateThread = () => {
    if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
      setGlobalState({overlayContent: <SignUpPrompt
        title={'Sign Up'}
        msg={
          <>You need to sign up in order to message someone. Already have an account? <strong><a href='/login' className="sellagen-propmt-link">Sign In</a></strong> instead.</>
        }
      />})
    } else {
      const threadMatch = threadLookup(userID)
      if(threadMatch) {
        setGlobalState({showMsgPanel: true, activeThread: threadMatch, activeThreadMetadata: null})
      } else {
        setGlobalState({
          showMsgPanel: true, 
          activeThread: 'OPEN_NEW_THREAD', 
          activeThreadMetadata: {
            buyerID: globalState?.user?._id,
            buyerName: String(`${globalState?.user?.firstName} ${globalState?.user?.lastName}`).trim(),
            sellerID: userID,
            sellerName: userName
          }
        })
      }
    }
  }

  return (
    <>
      <div className='sv2-request-individual-block'>
        <div className="sv2-request-block-top">
          <div className='sv2-request-block-top-left'>
            <div className='sv2-request-block-title'>{request.title}</div>
            <br/><br/>
            <div className='sv2-request-block-deadline'>
              Estimated Deadline: {request.deadline}
            </div>
            <br/>
            <br/>
            <div className='sv2-request-block-requester'>
              Requested by <strong>{displayName}</strong> &nbsp;
              {/* {
                userID === globalState?.user?._id || globalState?.user?._id === '635eb33db9d20aefe8ed8fc6' || globalState?.user?._id === '635eb3d1b9d20aefe8ed8fd2'?
                  <>
                    <span className='sellagen-tag grey'>
                      <strong>Private</strong>&nbsp;&nbsp;<i className="far fa-lock"></i>
                    </span> &nbsp;
                  </>
                  :
                  null
              } */}
              {
                userID !== globalState?.user?._id ? 
                <>
                  <span className='sellagen-tag with-link' style={{ border: '1px solid rgb(226, 228, 230)' }}
                    onClick={initiateThread}
                  >
                    <strong>Send a Message</strong>
                  </span> &nbsp;
                </>
                :
                null
              }
              {requestTag}
            </div>
          </div>
          <div className='sv2-request-block-price'>
            {
              request?.maxPrice > 0 ?
              <span style={{fontSize: '1.05vw'}}>
                <div style={{fontSize: '12px', marginBottom: '10px'}}>
                  Budget Range
                </div>
                <strong>${request.price} - ${request.maxPrice}</strong>
              </span>
              :
              <span style={{fontSize: '24px'}}>
                <div style={{fontSize: '12px', marginBottom: '10px'}}>
                  Budget
                </div>
                <strong>${request.price}</strong>
              </span>
            }
          </div>
        </div>
        <div className='sv2-request-block-description'>
          {/* {request.description} */}
          <strong>Description</strong>
          <Viewer
            style={{paddingTop: 0, margin: 0, backgroundColor: 'transparent'}}
            stringState={request.description}
          />
        </div>
        {
          request.fields && request.fields.length > 0 ? 
          <>
            <br/>
            <div className='sv2-request-block-info'>
              <div className="sv2-request-block-info-head">
                Fields
              </div>
              <div className="sv2-request-block-info-body" style={{fontFamily: 'monospace'}}>
                {request.fields.join(', ')}
              </div>
            </div>
          </> : null
        }
        {
          request.geoLocation && request.geoLocation.length > 0 ?
          <>
            <br/>
            <div className='sv2-request-block-info'>
              <div className="sv2-request-block-info-head">
                <br/>
                <i className="far fa-map-marker-alt" style={{fontSize: '24px', color: 'rgb(90, 90, 90)'}}></i>
                <br/>
              </div>
              <div className="sv2-request-block-info-body" style={{fontFamily: 'monospace'}}>
                {request.geoLocation.join(', ')}
              </div>
            </div>
          </> : null
        }
      </div>
    </>
  )
}

export default RequestViewer