import React from 'react'

const NotFound = () => {
  return (
    <div style={{
      width: '100hw',
      height: 'calc(100vh - 90px)',
      marginTop: '90px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'rgb(200, 200, 200)',
      fontSize: '24px'
    }}>
      <strong>Sorry, we can not find the page you are looking for <i className="far fa-sad-cry" style={{color:'rgb(29, 191, 113)'}}></i></strong>
    </div>
  )
}

export default NotFound