import React from 'react'

const categories = [
    {
        id: 1,
        title: "Marketing",
        icon: <i className="fal fa-mail-bulk" style={{color: 'rgb(0, 120, 255)'}}></i>,
        value: '0, 120, 255'
    },
    {
        id: 2,
        title: "Retail",
        icon: <i className="fal fa-shopping-cart" style={{color: 'rgb(20, 183, 137)'}}></i>,
        value: '20, 182, 137'
    },
    {
        id: 3,
        title: "Personal",
        icon: <i className="fal fa-users" style={{color: 'rgb(128, 47, 221)'}}></i>,
        value: '128, 47, 221'
    },
    {
        id: 4,
        title: "Research",
        icon: <i className="fal fa-solar-system" style={{color: 'rgb(250, 50, 135)'}}></i>,
        value: '250, 50, 135'
    },
    {
        id: 5,
        title: "Financial",
        icon: <i className="fal fa-analytics" style={{color: 'rgb(46, 139, 87)'}}></i>,
        value: '46, 139, 87'
    },
    {
        id: 6,
        title: "Health",
        icon: <i className="fal fa-heartbeat" style={{color: 'rgb(220, 20, 60)'}}></i>,
        value: '220, 20, 60'
    },
    {
        id: 7,
        title: "Legal",
        icon: <i className="fal fa-balance-scale" style={{color: 'rgb(238, 130, 1)'}}></i>,
        value: '238, 130, 1'
    },
    {
        id: 8,
        title: "Consumer",
        icon: <i className="fal fa-receipt" style={{color: 'rgb(0, 0, 255)'}}></i>,
        value: '0, 0, 255'
    },
    {
        id: 9,
        title: "Academic",
        icon: <i className="fal fa-graduation-cap" style={{color: 'rgb(158, 42, 43)'}}></i>,
        value: '158, 42, 43'
    },
    {
        id: 10,
        title: "Other",
        icon: <i className="fal fa-database" style={{color: 'rgb(0, 0, 0)'}}></i>,
        value: '190, 190, 190'
    }
]

const getCategory = (title) => {
    let c = categories.find(c => (c.title === title))
    if(c) return c
    else return categories[categories.length-1]
}

export default categories
export {getCategory}