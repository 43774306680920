import React, {useContext} from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx'
import Footer from '../components/footer/footer-v2';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import ProfileLayout from '../components/layouts/profile/profileLayout2'
import { Context  } from '../contexts/GlobalContext'
import { useParams } from "react-router-dom"
import '../scss/custom/customComponentStyles.css'

function Profile(props) {
    const { globalState } = useContext(Context)
    const params = useParams()

    return (
        <div className='wrapper-style' id='wrapper'>
            <Header />
            <div className='sellagen-base-container'>
                <ProfileLayout
                    profileID={
                        params.id || globalState.user._id || null
                    }
                />
            </div>
            <Footer data={dataFooter}/>
            <Bottom classection='widget-social-footer home2'/>
        </div>
    );
}

export default Profile;