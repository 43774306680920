import React from 'react'
import "../styles.scss"

const LicensePrompt = ({title, msg}) => {
  return (
    <>
      <div className="sellagen-prompt-title">
        <strong>{title}</strong>
      </div>
      <p className="sellagen-para"
        style={{
          backgroundColor: 'rgb(246, 248, 250)',
          padding: '10px 7px',
          fontFamily: 'monospace',
          borderRadius: '7px',
          overflowY: 'scroll',
          maxHeight: '500px',
          minHeight: '200px',
          fontSize: '14px',
          lineHeight: 1.5
        }}
      >
        {msg}
      </p>
    </>
  )
}

export default LicensePrompt