import React, { useContext, useEffect, useState } from 'react'
import './styles.scss'
import { readRemoteFile, usePapaParse } from 'react-papaparse'
import Axios from '../../utilities/axios'
import { useIsMounted } from '../../utilities/hooks'
import {ConsoleContext} from '../../contexts/ConsoleContext'
import Spinner from '../spinner'

const TabularViewer = ({datasetId, sample=true, skipFirstNLines=0, preview=30, config=null}) => {
  if(config) {
    datasetId = config.datasetId
    preview = config.preview ? config.preview : 30
    skipFirstNLines = config.skipFirstNLines ? config.skipFirstNLines : 0
    sample = config.sample ? config.sample : false
  }
  const [isDatasetLoading, setIsDatasetLoading] = useState(false)
  const [data, setData] = useState(null)
  const { setState } = useContext(ConsoleContext)
  const isMounted = useIsMounted()

  useEffect(() => {
    datasetDownloadHandler(datasetId)
  }, [datasetId, preview, skipFirstNLines, sample])

  const datasetDownloadHandler = (id) => {
    if(!isDatasetLoading && id) {
      setIsDatasetLoading(true)
      Axios.post('download-sample/get-link', {id}).then(response => {
        if(isMounted) {
          if(response.data?.signedURL) {
            var signedUrl = new URL(response.data?.signedURL)
            if('Signed url pathname', signedUrl.pathname.slice(-3) === 'csv') {
              readRemoteFile(
                response.data?.signedURL, {
                download: true,
                header: true,
                skipEmptyLines: true,
                preview: preview || 0,
                complete: (results, file) => {
                  setData(results)
                  setIsDatasetLoading(false)
                }
              })
            }
          }
          // setIsDatasetLoading(false)
        }
      }).catch(err => {
        if(isMounted) {
          setIsDatasetLoading(false)
          console.log(err)
        }
      })
    }
  }
  console.log(data?.data)
  return (
    <div className="sv2-table-container">
      <div className="sv2-table-window-title">
        <i className="far fa-table"></i>&nbsp;Dataset Viewer&nbsp;&nbsp;{isDatasetLoading && <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>}
        <div style={{
          position: 'absolute', top: '0px', right: '15px', height: '30px',
          display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
          <div className='sv2-spdrn-save-btn'
            onClick={() => { setState({ datasetInView: null }) }}
          >
            <i className="far fa-window-minimize"></i>
          </div>
        </div>
      </div>
      <div className="sv2-table-viewer">
        <table className="sv2-table">
          {
            data?.meta?.fields?.length > 0 ?
            <thead className="sv2-table-head">
              <tr>{ data?.meta?.fields.map((col, i) => <td key={`head-${i}`}>{col}</td>) }</tr>
            </thead>
            :
            null
          }
          <tbody className="sv2-table-body">
            {
              data?.data?.length > 0 ?
              data?.data.map((elem, i) => {
                let row = Object.values(elem)
                if(row.length > 0) {
                  return (<tr key={`row-${i}`}>{ row.map((col, j) => <td key={`col-${i}-${j}`}>{col}</td>) }</tr>)
                } else {
                  return null
                }
              })
              :
              null
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TabularViewer