import React from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';

import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';

import SearchLayout from '../components/layouts/search/searchLayout'
import {useLocation, useParams} from 'react-router-dom'

function Search() {
    const params = useParams()
    var showSearch = false
    let location = useLocation()

    try {
        showSearch = location.state?.showSearch
    } catch(err) {
        showSearch = false
    }
    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header paramShowSearch={showSearch}/>}
            {<SearchLayout query={params.keyword}/>}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Search;