import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../contexts/GlobalContext'
import UserMenu from '../user-menu'

const DarkMode = ({unreadMsgCount=0}) => {
    const navigate = useNavigate()
    const { globalState, setGlobalState } = useContext(Context)

    const handleMenu = () => {
        if(globalState && globalState.user && globalState.user.firstName) {
            setGlobalState({showMenu: true})
        } else {
            navigate('/login')
        }
    }

    return (
        <>
            <div className="mode_switcher" style={{
                height: '45px',
                width: '45px',
                color: 'rgb(60, 60, 60)',
                fontWeight: 700,
                borderRadius: '50%',
                border: '2px solid rgba(29, 191, 113, 1)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: "Poppins, sans-serif",
                fontSize: '17px'
            }} onClick={() => { handleMenu() }}>
                {
                    unreadMsgCount > 0 ?
                        <span style={{
                            position: 'absolute',
                            color: 'crimson',
                            top: '-14px',
                            right: '-2px',
                            fontSize: '36px'
                        }}>•</span>
                        :
                        null
                }
                {
                    globalState && globalState.user && globalState.user.firstName ?
                        String(globalState?.user?.firstName).trim()[0].toUpperCase()
                        :
                        <i className="far fa-sign-in"></i>
                }
                {globalState.showMenu ? 
                    <UserMenu unreadMsgCount={unreadMsgCount}/> : null
                }
            </div>
            {
                globalState.showMenu ?
                <div style={{
                    position: 'fixed', width: '100%', height: '100%',
                    top: 0, left: 0
                }} onClick={() => {setGlobalState({showMenu: false})}}>
                </div>
                : null
            }
        </>
    )
}

const SearchButton = ({display}) => {
    return (
        <div className="mode_switcher" style={{marginRight: '65px'}}>
            <div style={{
                height: '45px',
                width: '45px',
                color: 'rgb(90, 90, 90)', 
                borderRadius: '50%',
                backgroundColor: 'rgb(246, 248, 250)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '17px'
            }}>
                {display}
            </div>
        </div>
    )
}

export default DarkMode
export {SearchButton}