import React, { useState, useContext, Fragment, useEffect } from "react"
import { Context  } from "../../../contexts/GlobalContext"
import { useLocation } from "react-router-dom"
import ImageUploader from "../../img-uploader"
import styles from './styles.module.css'
import CardRow from "../../cardrows/cardrow-v1"
import '../../../scss/components/section.scss'
import axios from "../../../utilities/axios"
import Loading from "../../loading"
import NotFound from "../../non-found"
import { useIsMounted } from "../../../utilities/hooks"
import SignUpPrompt from '../../overlay-prompts/sign-up'

const ProfileLayout = ({profileID}) => {
    const { globalState, setGlobalState, threadLookup } = useContext(Context)
    const routerLocation = useLocation()

    var willEdit = false
    try {
        willEdit = routerLocation.state?.edit
    } catch(err) {
        willEdit = false
    }

    const [isEditing, setIsEditing] = useState(willEdit)
    const [description, setDescription] = useState('')
    const [location, setLocation] = useState('')
    const [site, setSite] = useState('')
    const [linkedIn, setLinkedIn] = useState('')
    const [profile, setProfile] = useState({})
    const [datasets, setDatasets] = useState([])
    const isMounted = useIsMounted()
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)
    

    const loadDetails = (id) => {
        axios.get(`/user/profile/${id}`, {withCredentials: false}).then(response => {
            if(isMounted) {
                if(response.data?.user) {
                    setProfile(response.data.user)
                    setDatasets(response.data?.datasets || [])
                    setSite(response.data.user.website || '')
                    setDescription(response.data.user.description || '')
                    setLinkedIn(response.data.user.social || '')
                    setLocation(response.data.user.location || '')
                } else {
                    console.log('Error loading data')
                    setError(true)
                }
                setIsLoading(false)
            }
        }).catch(err => {
            if(isMounted) {
                setIsLoading(false)
                setError(true)
            }
        })
    }

    const saveChanges = () => {
        setIsLoading(true)
        var payload = {
            description,
            location,
            website: site,
            social: linkedIn,
        }
        axios.patch(`user/update`, payload).then(response => {
            if(response.data) {
                // setIsEditing(false)
                window.location.assign('/profile')
            } else {
                setIsEditing(true)
            }
            setIsLoading(false)
        }).catch(err => {
            setIsEditing(true)
            console.log(err)
        })
    }

    useEffect(() => {
        loadDetails(profileID)
    }, [])

    var bio = (
        <div className={styles.profileBio}>
            <div style={{fontSize: '16px', margin: '20px 0 0 0', fontWeight: 500}}>Summary</div>
            {
                isEditing ?
                (
                    <textarea
                        style={{
                            height: '200px', marginTop: '10px', color: 'black',
                            fontSize: '14px'
                        }}
                        className={styles.sellagenProfileInput}
                        onChange={e => { setDescription(e.target.value) }}
                        value={description}
                    >
                    </textarea>
                )
                :
                <p style={{marginTop: '0px'}}>
                    {description}
                </p>
            }
        </div>
    )
    const regx1 = RegExp('http(s)?(:)?(//)?|(//)?(www.)?')
    const regx2 = RegExp('(//)?(www.)?')
    var siteString = String(site).replace(regx1, '')
    const trimLength = 12
    const postString = siteString.length > trimLength ? '...' : ''
    siteString = String(siteString).replace(regx2, '').slice(0, trimLength) + postString
    // console.log(profile)
    var info = (
        <div className={styles.profileStats}>
            <br/>
            {
                isEditing ?
                <>
                    <br/>
                    <span>
                        <p>Location <span style={{fontSize: '10px'}}>(State, Country)</span></p>
                        <input 
                            className={`${styles.sellagenProfileInput} ${styles.miniIp}`}
                            value={location}
                            onChange={e => setLocation(e.target.value)}
                        />
                    </span>
                    <br/><br/>
                    <span>                        
                        <p>LinkedIn Profile URI</p>
                        <input
                            className={`${styles.sellagenProfileInput} ${styles.miniIp}`}
                            value={linkedIn}
                            onChange={e => setLinkedIn(e.target.value)}
                        />
                    </span>
                    <br/><br/>
                    <span>
                        <p>Website <span style={{fontSize: '10px'}}>(optional)</span></p>
                        <input
                            className={`${styles.sellagenProfileInput} ${styles.miniIp}`}
                            value={site}
                            onChange={e => setSite(e.target.value)}
                        />
                    </span>
                </>
                :
                <>
                    <br/>
                    <ul>
                        <li><i className="far fa-map-marker-alt"></i>&nbsp;{location}</li>
                        <li><i className="fab fa-linkedin"></i>
                            &nbsp;<a style={{fontSize: '14px'}} href={linkedIn} target='_blank' rel="noreferrer">Profile</a>
                        </li>
                        <li><i className="far fa-link"></i>
                            &nbsp;<a style={{fontSize: '14px'}} href={site} target='_blank' rel="noreferrer">Website</a>
                        </li>
                    </ul>
                </>
            }
        </div>
    )

    var view = null
    var style = {
        fontSize: '14px',
        marginLeft: '20px',
        padding: '2.5px 7px', 
        borderRadius: '5px',
        border: `1.5px solid rgb(70, 70, 70)`,
        color: 'rgb(70, 70, 70)'
    }

    if(!isEditing) {
        if(datasets.length > 0) {
            view = (
                <CardRow
                    data={datasets}
                    // description={
                    //     <p style={{width: '100%'}}>If you want to update the dataset list the updated version as a new dataset</p>
                    // }
                    title={(<strong>Listings</strong>)} showAdd={false}
                />
            )
        } else {
            view = (
                <div style={{
                    width: '100%', 
                    height: '200px', 
                    display: 'flex', 
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgb(246, 248, 250)',
                    fontSize: '20px',
                    fontWeight: 700,
                    color: 'rgb(150, 150, 150)',
                    borderRadius: '10px'
                }}>
                    The user hasn't listed any dataset yet
                </div>
            )
        }
    } else {
        if(linkedIn && location && description) {
            // everything is fine
        } else {
            style['color'] = 'rgb(200, 200, 200)'
            style['border'] = '1.5px solid rgb(200, 200, 200)'
            style['cursor'] = 'not-allowed'
        }
    }
    if(isLoading) {
        return <Loading/>
    } else if(error) {
        return <NotFound/>
    } else {
        var actionPanel = null
        if(profileID && globalState?.user?._id) {
            if(profileID === globalState?.user?._id) {
                actionPanel = (
                    <span style={{float: 'right'}} className={`${styles.btn} ${styles.profileSettingsBtn}`}
                        onClick = {() => {
                            if(isEditing && linkedIn && location && description) {
                                // save
                                saveChanges()
                                // setIsEditing(!isEditing)
                            } else if(!isEditing) {
                                setIsEditing(!isEditing)
                            }
                        }}
                    >
                        <span style={style}
                        >
                            {
                                isEditing ?
                                <><i className="far fa-save" aria-hidden="true"></i>&nbsp;&nbsp;Save</>
                                :
                                <><i className="far fa-edit" aria-hidden="true"></i>&nbsp;&nbsp;Edit</>
                            }
                        </span>
                    </span>
                )
            } else {
                var userName = `${profile.firstName} ${profile.lastName}`
                const userID = profile._id

                const initiateThread = () => {
                    if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
                        setGlobalState({overlayContent: <SignUpPrompt
                            title={'Sign Up'}
                            msg={ <>You need to sign up in order to message someone. Already have an account? <strong><a href='/login' className="sellagen-propmt-link">Sign In</a></strong> instead.</> }
                        />})
                    } else {
                        const threadMatch = threadLookup(userID)
                        if(threadMatch) {
                            setGlobalState({showMsgPanel: true, activeThread: threadMatch, activeThreadMetadata: null})
                        } else {
                            setGlobalState({
                                showMsgPanel: true, 
                                activeThread: 'OPEN_NEW_THREAD', 
                                activeThreadMetadata: {
                                    buyerID: globalState?.user?._id,
                                    buyerName: String(`${globalState?.user?.firstName} ${globalState?.user?.lastName}`).trim(),
                                    sellerID: userID,
                                    sellerName: userName
                                }
                            })
                        }
                    }
                }

                actionPanel = (
                    <span style={{
                        float: 'right', 
                        fontSize: '14px', 
                        border: '1.5px solid black', 
                        borderRadius: '7px',
                        padding: '7px',
                    }} className={`${styles.btn} ${styles.profileSettingsBtn}`}
                        onClick = {initiateThread}
                    >Send a Message</span>
                )
            }
        }
        return (
            <Fragment>
                <div className={styles.container}>
                    <div className={styles.profile}>
                        <div className={styles.profileImage}>
                            <div className={styles.imgContainer}>
                                {
                                    isEditing ?
                                    <ImageUploader link={profile.profilePic}/>
                                    :
                                    profile.profilePic ?
                                    <img
                                        style={{
                                            width: '100%',
                                            borderRadius: '50%'
                                        }} 
                                        src={`https://d2np86vew8tc5p.cloudfront.net/${profile.profilePic}`}
                                    />
                                    :
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontSize: '84px',
                                            color: 'rgb(70, 70, 70)'
                                        }}
                                    >
                                        <i className="fal fa-user-circle"></i>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={styles.profileUserSettings}>
                            <h1 className={styles.profileUserName}>
                                {
                                    profile.business ? profile.business : `${profile.firstName} ${profile.lastName}`
                                }
                                {/* {profile.firstName} {profile.lastName} */}
                            </h1>
                            {actionPanel}
                        </div>
                        {info}
                        {bio}
                    </div>
                </div>
                <div style={{width: '100%'}}>
                    {view}
                </div>
            </Fragment>
        )
    }
};


export default ProfileLayout;