const countries = [
  {
    id: 1,
    title: 'Australia',
    code: 'AU',
    flag: '🇦🇺'
  },
  {
    id: 2,
    title: 'Austria',
    code: 'AT',
    flag: '🇦🇹'
  },
  {
    id: 3,
    title: 'Belgium',
    code: 'BE',
    flag: '🇧🇪'
  },
  {
    id: 4,
    title: 'Bulgaria',
    code: 'BG',
    flag: '🇧🇬'
  },
  {
    id: 5,
    title: 'Canada',
    code: 'CA',
    flag: '🇨🇦'
  },
  {
    id: 6,
    title: 'Croatia',
    code: 'HR',
    flag: '🇭🇷'
  },
  {
    id: 7,
    title: 'Cyprus',
    code: 'CY',
    flag: '🇨🇾'
  },
  {
    id: 8,
    title: 'Czech Republic',
    code: 'CZ',
    flag: '🇨🇿'
  },
  {
    id: 9,
    title: 'Denmark',
    code: 'DK',
    flag: '🇩🇰'
  },
  {
    id: 10,
    title: 'Estonia',
    code: 'EE',
    flag: '🇪🇪'
  },
  {
    id: 11,
    title: 'Finland',
    code: 'FI',
    flag: '🇫🇮'
  },
  {
    id: 12,
    title: 'France',
    code: 'FR',
    flag: '🇫🇷'
  },
  {
    id: 13,
    title: 'Germany',
    code: 'DE',
    flag: '🇩🇪'
  },
  {
    id: 14,
    title: 'Gibraltar',
    code: 'GI',
    flag: '🇬🇮'
  },
  {
    id: 15,
    title: 'Greece',
    code: 'GR',
    flag: '🇬🇷'
  },
  {
    id: 16,
    title: 'Hong Kong',
    code: 'HK',
    flag: '🇭🇰'
  },
  {
    id: 17,
    title: 'Hungary',
    code: 'HU',
    flag: '🇭🇺'
  },
  {
    id: 18,
    title: 'Ireland',
    code: 'IE',
    flag: '🇮🇪'
  },
  {
    id: 19,
    title: 'Italy',
    code: 'IT',
    flag: '🇮🇹'
  },
  {
    id: 20,
    title: 'Japan',
    code: 'JP',
    flag: '🇯🇵'
  },
  {
    id: 21,
    title: 'Latvia',
    code: 'LV',
    flag: '🇱🇻'
  },
  {
    id: 22,
    title: 'Liechtenstein',
    code: 'LI',
    flag: '🇱🇮'
  },
  {
    id: 23,
    title: 'Lithuania',
    code: 'LT',
    flag: '🇱🇹'
  },
  {
    id: 24,
    title: 'Luxembourg',
    code: 'LU',
    flag: '🇱🇺'
  },
  {
    id: 25,
    title: 'Malta',
    code: 'MT',
    flag: '🇲🇹'
  },
  {
    id: 26,
    title: 'Mexico',
    code: 'MX',
    flag: '🇲🇽'
  },
  {
    id: 27,
    title: 'Netherlands',
    code: 'NL',
    flag: '🇳🇱'
  },
  {
    id: 28,
    title: 'New Zealand',
    code: 'NZ',
    flag: '🇳🇿'
  },
  {
    id: 29,
    title: 'Norway',
    code: 'NO',
    flag: '🇳🇴'
  },
  {
    id: 30,
    title: 'Poland',
    code: 'PL',
    flag: '🇵🇱'
  },
  {
    id: 31,
    title: 'Portugal',
    code: 'PT',
    flag: '🇵🇹'
  },
  {
    id: 32,
    title: 'Romania',
    code: 'RO',
    flag: '🇷🇴'
  },
  {
    id: 33,
    title: 'Singapore',
    code: 'SG',
    flag: '🇸🇬'
  },
  {
    id: 34,
    title: 'Slovakia',
    code: 'SK',
    flag: '🇸🇰'
  },
  {
    id: 35,
    title: 'Slovenia',
    code: 'SI',
    flag: '🇸🇮'
  },
  {
    id: 36,
    title: 'Spain',
    code: 'ES',
    flag: '🇪🇸'
  },
  {
    id: 37,
    title: 'Sweden',
    code: 'SE',
    flag: '🇸🇪'
  },
  {
    id: 38,
    title: 'Switzerland',
    code: 'CH',
    flag: '🇨🇭'
  },
  {
    id: 39,
    title: 'Thailand',
    code: 'TH',
    flag: '🇹🇭'
  },
  {
    id: 40,
    title: 'United Kingdom',
    code: 'GB',
    flag: '🇬🇧'
  },
  {
    id: 41,
    title: 'United States',
    code: 'US',
    flag: '🇺🇸'
  }
]

export default countries