import React from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';

function TnC(props) {
    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <div className='sellagen-fact'>
                <h2 className="sellagen-fact-title"><strong>Terms & Conditions</strong></h2>
                <br/>
                <p>These Terms of Service ("Terms", "ToS") govern your (“you”, “User”) relationship with the sellagen.com website, software, and services (the "Service") operated by SELLAGEN Inc. ("SELLAGEN", "us", "we", or "our"), a Delaware corporation.</p>
                <p>Please read these Terms of Service carefully before using the Service. Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. In the relationship between you and SELLAGEN, only these Terms apply in their currently valid version.</p>
                <p>By accessing or using the Service, you agree to bound by these Terms. If you disagree with any part of the Terms then you may not access or use the Service.</p>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Definitions</p>
                    <p>•	Service: The Nelima software, websites, and services offered and operated by Sellagen, including any updates, enhancements, new features, and/or the addition of any new web properties.</p>
                    <p>•	User: Any individual or entity that accesses or uses our Service.</p>
                    <p>•	Registered User: A User who creates an account with our Service.</p>
                    <p>•	Contributor: Any User or legal entity that contributes actions, functions, or agents to enhance the capabilities of Nelima.</p>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Intended Use of the Service</p>
                    <p>Our Service is designed to execute actions on behalf of Users through natural language commands, enabling a wide range of tasks. Users can customize Nelima by integrating their own actions or by utilizing and contributing to the collective knowledge base of actions created by the community.</p>
                    <br/>
                    <p><strong>NELIMA</strong></p>
                        <p>Nelima is intended to automate tasks and processes in various domains based on User commands and pre-defined or User-contributed actions. Users are encouraged to contribute to the Nelima ecosystem by sharing their custom actions, thereby enriching the Service's capabilities for all Users. Sellagen is not responsible for the execution of any illegal or unethical tasks requested by Users through Nelima. Users must ensure their requests and contributed actions comply with all applicable laws and ethical standards. Contributions to Nelima's knowledge base must not infringe on intellectual property rights, and Contributors must have all necessary rights to the actions they share. Sellagen reserves the right to remove any actions or contributions that violate these Terms, are deemed harmful, or infringe upon any rights.</p>
                    <br/>
                    <p><strong>SELLER LISTINGS</strong></p>
                        <p>In order to help Data Buyers to discover, learn about, and connect with Data Sellers, we are offering a Service to list and find Data Sellers (“Seller Listing”). 
                        Users can explore, search for, and visit Seller Listings in order to inform yourself and contact Data Sellers. In case you submit a contact request, you agree that we may share your aforementioned request with businesses and other providers from within the platform and notify you as well regarding your request. </p>
                        <p>SELLAGEN automatically creates, curates, and publishes listings of Data Sellers based on publicly accessible information as well as personal information. 
                        Users that pass the data verification process may submit a listing so that the proposed data for sale can be displayed on the Seller Listing. Our data verification process minimizes, to the best of our extent, inaccurate or misleading data but we neither guarantee the accuracy or veracity of Seller Listings, nor do we endorse any Seller Listing on our Service.</p>
                        <p>Upon claiming or creating a Seller Listing, Data Sellers bear the full legal responsibility for ensuring that the content defined by them contains authentic and accurate information and comply with the applicable statutory provisions. 
                        Each Data Seller assures to the best of their knowledge and belief that their data offering protects and does not violate intellectual and industrial property and personal rights, as well as all rights of third parties. 
                        Furthermore, each Data Seller ensures that they are the owner or are in possession of all necessary (license) rights of all data that they offer. Each Data Seller is further obliged to not offer data containing malicious programs (viruses, malware, etc.) or any other illegal content.</p>
                        <p>SELLAGEN may remove Seller Listings that do not comply with quality standards, contain illegal content, abuse functionalities of the Service, or verifiably do not offer any data.</p>
                    <br/>
                    <p><strong>SELLER LICENSES</strong></p>
                        <p>The Open License is the Creative Commons Attribution-ShareAlike 4.0 International (CC BY-SA 4.0) license (the default option for selling or sharing datasets). Unless specified by the Seller, datasets offered on Data & Sons are considered to be Open License.</p>
                        <p>Sellers are free to provide their own Custom License specifying Buyer use and privileges for datasets acquired on SELLAGEN. Any Seller generated Custom License supersedes SELLAGEN Terms & Conditions. Buyers should carefully read any Seller provided Custom License. Any questions regarding a Custom License should be made directly to the Seller.</p>
                        <p>SELLAGEN will uphold and enforce Custom Licenses within the SELLAGEN website and marketplace. We will remove any dataset or derivative product on the SELLAGEN market that violates a Custom License agreement. We will address any copyright infringement claims between a Seller and Buyer in a Custom License in accordance with the U.S. Digital Millennium Copyright Act. SELLAGEN is not responsible for enforcing violations of Custom Licenses off the SELLAGEN site and market beyond our obligations in U.S. and International laws.</p>
                    <br/>
                    <p><strong>DATA REQUESTS & POSTINGS</strong></p>
                        <p>Our Service offers Users a way to create Data Requests in order to get in touch with, get contacted by, and receive information from Data Sellers with a matching data offering. We review and publish Data Requests at our sole discretion. 
                        Data Sellers may also be invited directly by the User to submit information for a Data Request. Both the User creating the Data Request as well as the Data Sellers accessing the Data Request agree to keep all information exchanged confidential.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Prohibited Use of the Service</p>
                    <p>In order to protect the interests of our Users and us, you agree not to:</p>
                        <p>•	copy, modify, distribute, transmit, perform, display, reproduce, publish, license, transfer, create derivative works from, or sell any content or information offered on our Service;</p>
                        <p>•	decompile, decrypt, disassemble, read out, modify, reverse engineer, or copy our Service;</p>
                        <p>•	access, monitor, or copy any content or information of our Service using any robot, spider, scraper, or other automated means or any manual process for any purpose without our express written permission;</p>
                        <p>•	post, publish, transfer false or misleading content or information;</p>
                        <p>•	register and/or log in with an alias that masks your true identity;</p>
                        <p>•	remove copyright or other proprietary notices.</p>
                    <p>Any prohibited use of our Service may result in immediate termination of your account.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Fees</p>
                    <p>The use of our publicly accessible Service is free of charge.</p><br/>
                    <p><strong>FULL SERVICE</strong></p>
                        <p>When registering on SELLAGEN.com, you automatically subscribe to our Full Service, which is free of charge. The Full Service offers basic features only available to Registered Users.</p><br/>
                    <p><strong>BILLING</strong></p>
                        <p>A valid payment method, including credit card, is required to process the payment for any purchase of data. You shall provide SELLAGEN with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize SELLAGEN to charge you for the aforementioned purchase.</p><br/>
                    <p><strong>FEE CHANGES</strong></p>
                        <p>SELLAGEN, Sellagen reserves the right, at its sole discretion, to modify or introduce fees for new services at any time, with prior notice to Users.</p>
                <br/><br/>
                <p className="title-text fs-18 fw-4 text-color-4">Content, IP, and Confidentiality</p>
                    <p><strong>USER-GENERATED CONTENT</strong></p>
                        <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.</p>
                        <p>By posting Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.</p>
                        <p>You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</p>
                        <br/>
                        <p><strong>INTELLECTUAL PROPERTY</strong></p>
                        <p>The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of SELLAGEN and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States of America and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of SELLAGEN.</p><br/>
                    <p><strong>CONFIDENTIALITY</strong></p>
                        <p>All Content and information offered through our Service (which is not already publicly available) shall be treated as confidential information. Especially Content and information only accessible by Registered Users is subject to strict confidentiality if not stated otherwise.</p><br/>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">User Accounts</p>
                <p>Users may create and register an account on our Service in order to access features only available to Registered Users.</p><br/>
                    <p><strong>CREATING A USER ACCOUNT</strong></p>
                        <p>When When registering with us and becoming a Registered User, you're required to provide information that is accurate, complete, and current at all stages of using our Service. Inaccurate information is a breach of these Terms and may lead to the termination of your account.</p>
                        <p>Registration is open to those seeking to use Nelima for executing natural language commands, integrating custom actions, or utilizing our advanced features. We reserve the right to terminate accounts associated with non-business email addresses or those we cannot verify.</p>
                        <p>You are responsible for the security of your account password and any activities conducted under this password. Our Service must not be shared with third parties, and you should immediately notify us of any unauthorized use or security breaches related to your account.</p><br/>
                    <p><strong>UPDATING A USER ACCOUNT</strong></p>
                        <p>You may update your account information such as your first name, last name, and password in the profile section any time after signing in.</p><br/>
                    <p><strong>TERMINATING A USER ACCOUNT</strong></p>
                        <p>Registered Users can request the deletion of their account by contacting us with an explicit message asking for the account deletion. Upon termination, your right to use the Service will immediately cease. As required by law, we may retain information shared by Registered Users even after an account has been deleted.</p>
                        <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. In this case, you may not register for an account with different information again. In case of a re-registration, we reserve the right to terminate the new account with immediate effect and without prior notice.</p>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Data Protection</p>
                    <p>By submitting contact information such as but not limited to your name, email address, or phone number, you agree to be contacted by SELLAGEN via email.</p>
                    <p>If you create a Seller Listing, you agree to be contacted by Users that express interest in your data offering. In case you submit a contact request on a Seller Listing, you agree that your provided contact information and message will be shared other users and that SELLAGEN or the Data Seller may contact you regarding your contact request.</p>
                    <p>In order to run and improve our Service, as well as to provide anonymized insights to Data Sellers, we record the usage of the Service such as the web pages visited by Users or keywords searched for. We do not share personal data of Users with other Users otherwise explicitly requested to do so.</p>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Returns & Disputes Policy</p>
                    <p>Refund Eligibility: Subscribers are entitled to a full refund if they cancel their subscription within a specified period after the initial purchase. Additionally, refunds are applicable in cases where there is a significant deviation between the subscribed services and the provided functionalities, or if there are any other breaches of our Terms of Service.</p>
                    <p>Refund Request Procedure: Subscribers can request refunds within the designated time frame by contacting our support team. Each refund request will be individually reviewed by an administrator to ensure fairness and adherence to our policies.</p>
                    <p>Review Process: Our team conducts thorough reviews of refund requests to ensure that they align with our refund policy. We are committed to transparency and strive to provide timely resolutions to all refund inquiries.</p>
                    <p>Buyers are entitled to receive a full refund for the cancellation of an order by the seller, this includes misleading data compared to the general description of the listing, data not received or any other fraudulent conduct infringing our Terms of Service. Refunds can be requested within a certain time period after the data being delivered and if the request Is made, an administrator will manually review the request. </p>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Links To Other Websites</p>
                    <p>Our Service may contain links to third-party websites or services that are not owned or controlled by SELLAGEN. The provision of these hyperlinks does not imply that we endorse the related websites, services, and products offered.</p>
                    <p>SELLAGEN has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that SELLAGEN shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
                    <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Limitation of Liability</p>
                    <p>In no event shall SELLAGEN, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from 
                    (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, 
                    tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
                    <p>SELLAGEN assumes no liability or warranty for the content or information provided on the Service and for its genuineness, authenticity, accuracy, or veracity. In particular, Sellagen is not liable if it emerges that the offered data infringes the rights of third parties.</p>
                    <p>SELLAGEN has the duty to review any reference to any illegal content and to delete it in the event of the discovery of the illegality of the content.</p>
                    <p>SELLAGEN remains a third-party between Users connecting and communicating through our Service and assumes no liability with regards to the commencement of contracts, contract negotiations, or data transactions between Users. 
                    Disputes between Users are exclusively to be settled between these parties, without Sellagen taking part in them.</p>
                    <p>All compensation claims made by Users against SELLAGEN are fundamentally impossible. Exceptions to this and other limitations and exclusions of liability are claims for damages resulting from injury to life, body, or health, as well as liability for other damages that are based on an intentional or grossly negligent breach of duty by a legal representative, 
                    executive employee, or vicarious agent of SELLAGEN.</p>
                    <p>Claims for damages from a breach of essential contractual obligations, so-called cardinal obligations, are also impossible. A cardinal obligation is an obligation that enables the duly execution of a contract and contractual parties can have confidence and trust in the compliance. However, for slightly negligent violations of cardinal obligations, the liability of SELLAGEN is limited to contractually typical, foreseeable damages. 
                    Exemptions from the aforementioned limitations and exclusions of liability also include claims arising from any guarantees assumed in individual cases or other liability independent of fault, as well as claims under the Product Liability Act.</p>
                    <p>Any further liability of SELLAGEN is impossible. </p>
                    <p>Insofar as the liability of SELLAGEN is impossible or limited, this also applies in favor of the personal liability of its legal representatives, executives and vicarious agents.</p>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Exemption</p>
                    <p>Each User exempts SELLAGEN and all its employees from all claims that third parties assert against SELLAGEN for violating their rights. In this context, Users assume the costs of the required legal defense of SELLAGEN, including all legal fees and lawyers' fees at the statutory rate. This does not apply if the User is not responsible for the infringement. In the event of a claim by third parties, all Users must promptly and truthfully provide SELLAGEN with all information that is necessary for the review of the asserted claims and for any defense against them.</p>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Disclaimer</p>
                    <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>
                    <p>SELLAGEN its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Changes</p>
                    <p>SELLAGEN is constantly developing new features to improve the Service. Therefore, it is necessary to intermittently update these Terms from time to time in order to guarantee that the Service is accurately reflected or to reflect changes in the law.</p>
                    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least fourteen (14) days notice prior to any new terms taking effect. 
                    While Registered Users will receive a notice via their registered email address, Users that have not signed up should regularly revisit this page. What constitutes a material change will be determined at our sole discretion.</p>
                    <p>By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please cease from using the Service.</p>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Governing Law</p>
                    <p>The legal relationship between you and SELLAGEN as well as these Terms shall be governed and construed in accordance with the United States of America and the GDPR, excluding the United Nations Convention on 
                    Contracts for the International Sale of Goods (CISG, UN Sales Convention). </p>
                    <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. 
                    These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have between us regarding the Service.</p>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Final Provisions</p>
                    <p>Should individual clauses of these Terms be wholly or partially invalid, this shall not affect the validity of the remaining clauses of these Terms. In place of unincluded or ineffective clauses, the respective statutory provision occurs. 
                    In the absence of such legal provision or if its application leads to a result that is unbearable for the User or SELLAGEN, SELLAGEN and the parties concerned will agree on an effective provision that comes as close as possible to the ineffective or unincluded provision.</p>
                    <p>The headings chosen under these Terms of Use have no particular legal significance but are merely intended to facilitate understanding and reading of these Terms.</p>
                    <p>These Terms are always available in their most recent version on the web pages of SELLAGEN and are also made available as part of the registration.</p>
                <br/>
                <p className="title-text fs-18 fw-4 text-color-4">Contact Us</p>
                    <p>If you have any questions about these Terms, please contact.</p>
            </div>
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default TnC