import React, { useState } from 'react'
import "../styles.scss"
import "../../../scss/components/checkbox.scss"
import Spinner from '../../spinner'
import { useIsMounted } from '../../../utilities/hooks'
import axios from '../../../utilities/axios'
import { Dropdown } from 'react-bootstrap'
import countries from '../../../utilities/supportedCountries'

const CreateStripe = (props) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [country, setCountry] = useState('')
  const isMounted = useIsMounted()

  const handleRegistration = () => {
    if(!isLoading && country) {
      setIsLoading(!isLoading)
      axios.post('user/create-payment-account', {country: country.code}).then(response => {
        if(response.data?.url) {
          if(isMounted) {
            setIsRedirecting(true)
            window.location.assign(response.data.url)
          }
        } else {
          console.log(response.data)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
  return (
    <>
      <div className="sellagen-prompt-title">
        <strong>Register in Stripe Connect</strong>
      </div>
      <p className="sellagen-para">
        You will be redirected to <strong><a href='https://stripe.com' className="sellagen-propmt-link">Stripe's</a></strong> Onboarding Page to fill-out details for registration and accepting payments.
      </p>
      <br/>
      <div id="item_category" className="dropdown" style={{margin: '10px 0 25px 0'}}>
        <h4 className="title-two fw-6" style={{marginBottom: '10px'}}>Country of Individual / Business:</h4>
        <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" style={{width: '100%', height: '49px'}}>
                {country ? 
                    <div>
                        <span style={{
                            display: 'inline-block', 
                            width: '20px',
                            marginRight: '10px',
                            textAlign: 'center'
                        }}>
                            {country.flag}
                        </span>{country.title}
                    </div> : 'Select Country'}
            </Dropdown.Toggle>
            <Dropdown.Menu 
                style={{ 
                    margin: '3px 0', 
                    boxShadow: 'none',
                    border: '1px solid rgb(240, 240, 240)',
                    borderRadius: '5px',
                    overflow: 'hidden'
                }}>
                {
                  countries.map(c=> (
                    <Dropdown.Item  key={c.id} to="#"
                      className="sellagen-dropdown-item"
                      onClick={() => {
                        setCountry(c)
                      }}
                    >
                      <span style={{
                        display: 'inline-block',
                        textAlign: 'center',
                        width: '20px',
                        marginRight: '10px'
                      }}
                      >{c.flag}</span>{c.title}
                    </Dropdown.Item>
                  ))
                }
            </Dropdown.Menu>
        </Dropdown>
      </div>
      <div style={{display: 'inline-flex'}}>
        <div className="round">
          <input type="checkbox" id="checkbox" checked={isChecked}
            onChange={() => {
              if(!isLoading) setIsChecked(!isChecked)
            }}
          />
          <label htmlFor="checkbox"></label>
        </div>
        <div style={{lineHeight: 1.5, marginTop: '-2.5px'}}>By registering your account, you agree to our <a className="sellagen-propmt-link" href='https://sellagen.com/terms-n-conditions' target=''>Terms and Conditions</a> and the <a className="sellagen-propmt-link" href='https://stripe.com/legal/connect-account' target=''>Stripe Connected Account Agreement</a>.</div>
      </div>
      <button 
        className={`sellagen-prompt-button ${isLoading ? 'loading-style' : 'outlined-style'}`}
        disabled={!isChecked || !country}
        onClick={handleRegistration}
      >
        <strong>
          {!isLoading ? 
            'Register'
            :
            isRedirecting ? 'Redirecting' : 'Please Wait'
          }
        </strong> &nbsp;&nbsp;
        {isLoading ? <Spinner/> : null}
      </button>
    </>
  )
}

export default CreateStripe