import React from 'react'
import DummySearch from '../dummySearch'
import Button from '../button/button-st1'

function SearchBar (props) {
    return (
        <div className={`tf-section flat-help home2`} style={{marginTop: '100px', marginBottom: '0'}}>
        <div className="themes-container3 style-container" style={{background: 'white'}}>
            <div className="row">
                <div style={{textAlign: 'center'}}>
                  <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                    <div style={{borderBottom: '2px solid rgb(230, 230, 230)', marginBottom: '50px'}}>
                    <DummySearch/>
                    </div>
                  </div>
                  <Button
                    title='Go to Search' addclass='sc-button style-2 btn-2' path='/search'
                    style={{
                        border: 'none', boxShadow: 'none', borderRadius: '10px', backgroundColor: 'rgb(29, 191, 113)',
                    }}
                    style2={{color: 'white'}}
                  />
                </div>
            </div>
        </div>
    </div>
    );
}

export default SearchBar