import React, { Component } from 'react'
import './styles.scss'

class MultiInput extends Component {					// * value data structure values : { value: '', key: '' }
	constructor(props) {
		super(props);
		this.state = {
			individualInput: null,
			keyValues: this.generateKeyValues(this.props.values || []),					/// multi values are sent from server or starts from empty array
			values: this.props.values || []
		}
	}

	generateKeyValues = (values) => {
		if(values && values.length > 0) {
			var finalValue = []
			values.map((value, index) => {
				var inputValue = {
					value: value,
					key: new Date().getTime() + index
				}
				finalValue.push(inputValue);
				return null;
			})
			return finalValue;
		} else {
			return [];
		}
	}

	remove = (unitKey) => {
		var tempKeyValues = [...this.state.keyValues];
		var tempValues = [...this.state.values];
		var valueIndex = this.state.keyValues.findIndex(n => {
			return n.key === unitKey
		});
		tempKeyValues.splice(valueIndex,1);
		tempValues.splice(valueIndex, 1);
		this.setState({keyValues: tempKeyValues, values: tempValues}, () => {
			this.writeCallback();
		})
	}

	enter = (event) => {
		event.preventDefault();
		if(this.state.keyValues.length >= this.props.limit) return
		if(this.state.individualInput && this.state.individualInput !== '' && this.state.individualInput !== ' ') {
			const keyValues = [...this.state.keyValues];
			const values = [...this.state.values];
			var inputValue = {
				value: this.state.individualInput,
				key: new Date().getTime()
			}
			keyValues.push(inputValue);
			values.push(this.state.individualInput);
			this.setState({keyValues: keyValues, values: values, individualInput: ''}, () => {
				this.writeCallback();
			});
		}
	}

	push = (value) => {
		const valueIndex = value.findIndex(n => {
			return n === value
		});
		if(valueIndex >= 0) {
			return
		} else {
			const keyValues = [...this.state.keyValues];
			const values = [...this.state.values];
			var inputValue = {
				value: value,
				key: new Date().getTime()
			}
			keyValues.push(inputValue);
			values.push(this.state.individualInput);
			this.setState({keyValues: keyValues, values: values, individualInput: ''}, () => {
				this.writeCallback();
			})
		}
	}

	writeCallback = () => {
		if(this.props.writeCallback) {
			return this.props.writeCallback(this.state.values);
		} else {
			return
		}
	}
	
	render() {
		var valueCollections = null;
		if(this.state.keyValues.length > 0) {
			var collcetions = this.state.keyValues.map((unit, index) => {
				return(
					<div key = {`${unit.key}`} className = 'UnitValueWrapper'>
						<div className = 'MultiValueUnit'>{unit.value}</div>
						<div className = 'UnitValueRemove'
							onClick = {() => {this.remove(unit.key)}}
						><i className="far fa-times"></i></div>
					</div>
				)
			})
			valueCollections = (
				<div className = 'ValueCollectionsWrapper' style={{marginBottom: '15px', borderRadius: '10px'}}>
					{collcetions}
				</div>
			)
		}
		var inputField = null;
		if(!this.props.lite) {
			inputField = (
				<form action="#" className = 'InputWrapper' onSubmit = {(event) => {this.enter(event)}}>
					<input 
						className = 'InputObject'
						style={{color: 'rgb(50, 50, 50)', borderRadius: '10px'}}
						type = {this.props.type || 'text'}
						placeholder = {this.props.placeholder || ' '}
						value = {this.state.individualInput || ''}
						onChange = {(event) => {
							this.setState({individualInput: event.target.value});
						}}
					/>
					<button
						className = 'buttonStyle'
						style={{borderRadius: '10px'}}
						type = 'submit'
					>{this.props.buttonTitle || 'Add'}</button>
				</form>
			)
		}
		return(
			<div className = 'InputContainer' style = {{flexDirection: 'column', height: 'auto', paddingBottom: '0'}}>
				{valueCollections}
				{inputField}
			</div>
		)
	}
}

export default MultiInput