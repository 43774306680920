import React, {useContext} from 'react';
import '../../scss/components/section.scss';
import Header from '../../components/header/header.jsx'
import Footer from '../../components/footer/footer-v2';
import dataFooter from '../../assets/fake-data/data-footer';
import Bottom from '../../components/bottom';

import { Context } from '../../contexts/GlobalContext'
import '../../scss/custom/customComponentStyles.css'
import APIManager from '../../components/api-manager'
import APIDocumentation from '../../components/api-docs';

function ApiPage(props) {
  const { globalState } = useContext(Context)

  var view = null
  if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
    view = null
  } else {
    view = <APIManager />
  }

  return (
    <div className='wrapper-style' id='wrapper'>
      <Header />
      { view }
      <APIDocumentation />
      <br/><br/><br/><br/><br/><br/><br/><br/><br/>
      <Footer data={dataFooter}/>
      <Bottom classection='widget-social-footer home2'/>
    </div>
  )
}

export default ApiPage