import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import CardModal from "../../CardModal";

import "./searchStyles.scss"
import axios from '../../../utilities/axios'

import Card from "../../cards/card-v2"
import DummySearch from "../../dummySearch";

const SearchLayout = (props) => {
  const { query } = props
  const [ notYetSearched, setNotYetSearched ] = useState(true)
  const [ results, setResults ] = useState([])

  useEffect(() => {
    if(query) {
      axios.get(window.location.pathname, {withCredentials: false}).then(response => {
        if(response.data) {
          setResults(response.data)
          setNotYetSearched(false)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }, [query])

  const [modalShow, setModalShow] = useState(false)
  var searchContainer = null

  if(notYetSearched) {
    searchContainer = (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '30px',
            height: '80vh',
            color: 'rgb(200, 200, 200)',
            fontWeight: 700
        }}>
          <DummySearch/>
        </div>
    )
  } else {
    if(results.length === 0) {
      searchContainer = (
        <>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '30px',
            height: '80vh',
            color: 'rgb(200, 200, 200)',
            fontWeight: 700
          }}>
            No results found :(
          </div>
        </>
      )
    } else {
      searchContainer = (
        <div className="row">
          <h2>Search Results</h2>
          <div className="CollectionsGrid" style={{marginTop: '20px'}}>
            {
              results.map(item => {
                return (
                <Link
                  key={item._id}
                  className="CardContainer"
                  to={`/item/${item._id}`}
                >
                  <Card item={item}/>
                </Link>
              )})
            }
          </div>
        </div>
      )
    }
  }
  return (
    <Fragment>
            <div className="tf-section flat-explore flat-auctions dark-style" style={{paddingTop: '120px'}}>
                <div className="container">
                    {searchContainer}
                </div>
            </div>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </Fragment>
  );
}


export default SearchLayout;
