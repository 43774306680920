import React , { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation} from 'swiper';
import CardModal from '../../CardModal'
import './customStyles.scss'
import Card from '../../cards/card-v2'

function CardRow (props) {
    const [modalShow, setModalShow] = useState(false);
    const {data, title, description, showAdd, edit, donationPrompt} = props;

    var addButton = null;
    if(showAdd === true) {
        addButton = (
            <SwiperSlide key={'AUG-19-2022'} className='swiperHover'>
                <div className='customAddButton'
                    onClick={() => {window.open(`/createItem`, '_blank')}}
                >
                    <i className="far fa-plus" style={{fontSize: 60, padding: '10px'}}></i>
                    <span>List a new Dataset</span>
                </div>
            </SwiperSlide>
        )
    }
    return (
        <Fragment>
            <div className="tf-section flat-explore flat-auctions mtop-3 home2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12" style={{width: '100%'}}>
                            <div className="heading-section2 style" style={{width: '100%'}}>
                                <h2 className="fw-5" style={{color: 'rgb(50, 50, 50)'}}>{title}</h2>
                                {description}
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <Swiper
                                className='swiper-container carousel-5 auctions show-slider3 btn-collection'
                                style={{overflow: 'visible'}}
                                modules={[Navigation, Autoplay]}
                                spaceBetween={30}
                                navigation
                                breakpoints={{
                                    668: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                    1100: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },
                                    1300: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    1450: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                }}
                            >
                                {addButton}
                                {
                                    data.map(item => (
                                        <SwiperSlide key={item._id} className='swiperHover'>
                                            <div onClick={() => {
                                                window.open(`/item/${item._id}`, '_blank')
                                            }}>
                                                <Card item={item} showStatus={showAdd} edit={edit} donationPrompt={donationPrompt && item?.licenseType === 'open-source'}/>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>  
                        </div>
                    </div>
                </div>
            </div>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment>
        
    );
}

export default CardRow;