import React from 'react'
import './styles.scss'

const ToolTip = ({children=null, styles={}, onClick=()=>{}, view=null, cursor='default'}) => {
  return (
  <span 
    className='sv2-tooltip'
    onClick={onClick}
    style={{cursor}}
  >
    {children}
    <span className='sv2-tooltip-container'>
      <span style={styles} className='sv2-tooltiptext'>{view}</span>
    </span>
  </span>)
}

export default ToolTip