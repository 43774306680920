import Slider from "react-slick"
import './slick-theme.scss'
import './slick.scss'

const SV2Slider = ({children, sliderRef}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    style: {
      width: '100%',
      height: '100%',
    }
  }
  return (
    <Slider {...settings} ref={sliderRef}
      swipe={false}
      accessibility={false}
      infinite={false}
    >
      {children}
    </Slider>
  )
}

export default SV2Slider