import React from 'react'
import './styles.scss'

const ResponsiveEllipsis = ({children=null, style={}}) => (
  <div className='sv3-ellipsis-container' style={{...style}}>
    <div className='sv3-ellipsis-main'>
      {children}
    </div>
  </div>
)

export {ResponsiveEllipsis}