import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import '../styles.scss';


Button.propTypes = {
    title: PropTypes.string,
    path: PropTypes.string,
    style: PropTypes.object
};

function Button(props) {
    const {title , path , onClick, addclass, style, style2} = props;
    return (
        <Link className={`sc-button ${addclass}`} to={path} onClick={onClick} style={style}>
            <span style={style2}>{title}</span>
        </Link>
    );
}

export default Button;