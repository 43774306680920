import { useParams } from "react-router-dom"
import { FlowBasic } from "../components/flow-ui/flow-blocks"
import Spinner from "../components/spinner"
import Axios from "../utilities/axios"

const ServiceIntegration = () => {
  const params = useParams()
  const state = String(params.state)

  const handleOnboarding = () => {
    let payload = {
      state
    }
    // Axios.post()
  }

  return (
    <div style={{
      width: '100vw', height: '100vh', position: 'fixed', 
      zIndex: 200, top: 0, left: 0,
      backgroundColor: 'rgb(var(--theme-nelima-lite-val))',
      display: 'flex', alignItems: 'center', justifyContent: 'center'
    }}>
      <div style={{width: '300px'}}>
        <FlowBasic
          title={
          <div style={{position: 'relative'}}>
            Adding Service
            <Spinner style={{
            width: '15px', height: '15px',
            position: 'absolute', top: '-2.5px', left: '112.5%',
            borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>
          </div>}
          titleIcon={<i className="fal fa-cogs"></i>}
          border={'2px solid rgba(255, 255, 255, 0.2)'}
        >
          <div style={{padding: '3px 10px', lineHeight: 1.5}}>
            Please wait while we verify your access and add the service to Nelima.
          </div>
        </FlowBasic>
      </div>
    </div>
  )
}

export default ServiceIntegration