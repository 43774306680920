import React, { useState, useContext } from 'react'
import './styles.scss'
import {FlowBasic, FlowStackedBody, FlowDropDown, FlowTags, FlowOverlayPrompt, FlowButton, FlowCheckChoice, FlowAddEnvsV2} from './flow-blocks'
import SandBoxContext from '../../contexts/SandBoxContext'
import { Context } from '../../contexts/GlobalContext'
import Spinner from '../spinner'
import Agent from '../../pages/SpeedRun/title'

const FlowActionInitiator = ({exampleMode=false}) => {
  const {
    parseScript, setOverlay, lockFunctionDetails, 
    resetFunctionDetails, sandboxState, sandboxSetState, 
    updateMetadata, openWindow
  } = useContext(SandBoxContext)
  const {globalState} = useContext(Context)
  const [options, setOptions] = useState([])
  const [selection, setSelection] = useState(sandboxState?.functionDetails?.title || '')
  const [isPrivate, setIsPrivate] = useState(sandboxState?.functionDetails?.status === 'private')
  var isLocked = sandboxState?.functionDetails?.title ? true : false
  var selectionView = ''
  if(selection) {
    if(isLocked) {
      selectionView = <><i className="fas fa-lock" style={{color: 'orange', fontSize: '10px'}}></i>&nbsp;{selection}</>
    } else {
      selectionView = String(selection)
    }
  }
  var currentFunction = sandboxState?.functionDetails?.title || ''
  return (
    <>
      <FlowBasic
        title={
          <>
            Action Invocation
            {
              !exampleMode && currentFunction ?
              <FlowButton style={{marginLeft: '10px'}}
                onClick={() => {
                  setOverlay(
                    <FlowOverlayPrompt
                      title='Invocation Function Reset'
                      promptMsg={<>Warning: You are resetting the function <span style={{color: 'rgb(97, 176, 239)', fontFamily: 'Roboto Mono'}}>{currentFunction}</span> and all its descriptions from Action Invocation. You will lose any details you've added to the function's properties. <br/><br/>Do you want to proceed?</>}
                      titleIcon={<i className="fal fa-exclamation-triangle" style={{color: 'orange'}}></i>}
                      proceedMsg={<><i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;Proceed</>}
                      cancelMsg={<><i className="fal fa-times" style={{color: 'crimson'}}></i>&nbsp;Cancel</>}
                      onProceed={() => {
                        resetFunctionDetails()
                        updateMetadata('delete', 'editorActive', false)
                        setOverlay(null)
                      }}
                      onCancel={() => {
                        setOverlay(null)
                      }}
                    />
                  )
                }}
              >Reset</FlowButton>
              :
              null
            }
          </>
        }
        titleIcon={<i className="fal fa-project-diagram"></i>}
        border={'2px solid rgba(255, 255, 255, 0.2)'}
      >
        <FlowStackedBody
          showStep={true}
          icon={
            <FlowTags 
              styles={{
                backgroundColor: 'rgba(255, 255, 255, 0.12)', 
                color: 'rgb(97, 176, 239)'
              }}>
              <i className="far fa-function"></i>
            </FlowTags>
          }
        >
          <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
            <div style={{flexGrow: 1}}>
              Function
            </div>
            <FlowDropDown
              selected={selectionView}
              options={options}
              onCheck={() => {
                // parse
                let funcs = parseScript()
                let funcNames = funcs.map(func => func.title)
                setOptions(funcNames)
              }}
              onSelection={elem => {
                updateMetadata('write', 'editorActive', false)
                setOverlay(
                  <FlowOverlayPrompt
                    title='Function Selection'
                    promptMsg={
                    <>
                      Warning: You are selecting the function <span style={{color: 'rgb(97, 176, 239)', fontFamily: 'Roboto Mono'}}>{elem}</span> for Action Invocation. 
                      You won't be able to change it later nor make changes to your code. <br/><br/>
                      It is recommended to&nbsp;
                      <FlowButton style={{lineHeight: 1.0}}
                        onClick={() => {
                          openWindow('lvl1-functesting')
                          setOverlay(null)
                          updateMetadata('delete', 'editorActive', false)
                        }}
                      ><i className="fal fa-code"></i>&nbsp;TEST</FlowButton>
                      &nbsp;your code before proceeding.<br/><br/>
                      Do you want to proceed?
                    </>
                    }
                    titleIcon={<i className="fal fa-exclamation-triangle" style={{color: 'orange'}}></i>}
                    proceedMsg={<><i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;Proceed</>}
                    cancelMsg={<><i className="fal fa-times" style={{color: 'crimson'}}></i>&nbsp;Cancel</>}
                    onProceed={() => {
                      lockFunctionDetails(elem)
                      // updateMetadata('delete', 'editorActive', false)
                      setOverlay(null)
                    }}
                    onCancel={() => {
                      updateMetadata('delete', 'editorActive', false)
                      setOverlay(null)
                    }}
                  />
                )
                setSelection(elem)
              }}
              color='rgb(97, 176, 239)'
              initialMsg='Select Function'
              emptyMsg='No Function Detected'
              disabled={isLocked}
            />
          </div>
        </FlowStackedBody>
        <br/>
        <FlowCheckChoice
          style={{marginTop: '7.5px', marginLeft: '15px', marginBottom: '2.5px'}}
          choiceTitle='Set as Private Action'
          choiceDetails={'Private actions are a powerful way to customize Nelima to your specific needs. Actions defined as private are invoked only for your account and cannot be accessed by others.'}
          choiceValue={isPrivate}
          onSelection={value => {
            setIsPrivate(value)
            const {functionDetails} = sandboxState
            if(value) {
              functionDetails.status = 'private'
            } else {
              functionDetails.status = 'in-review'
            }
            sandboxSetState({functionDetails})
          }}
          disabled={isLocked}
        />
        {
          globalState?.user?._id ?
          <>
            <br/>
            <FlowAddEnvsV2 isLocked={isLocked}/>
          </>
          :
          null
        }
      </FlowBasic>
    </>
  )
}

const FlowActionTransition = () => {
  return (
    <FlowBasic
      title={
        <>
          Auto Action Transition<FlowTags 
            styles={{
              marginLeft: '5px',
              backgroundColor: 'rgba(255, 255, 255, 0.12)', 
              color: 'rgb(29, 191, 113)',
              fontSize: '10px',
              fontFamily: 'Roboto Mono',
              fontWeight: 700
            }}>
            Coming Soon
          </FlowTags>
        </>
      }
      titleIcon={<i className="fal fa-chart-network"></i>}
      border={'2px solid rgba(255, 255, 255, 0.2)'}
    >
      <FlowStackedBody>
        <FlowButton>Test Prompt</FlowButton>
        <FlowButton>INTEGRATE</FlowButton>
      </FlowStackedBody>
    </FlowBasic>
  )
}

const FlowActionDone = ({isValid=false, updatePrompt=()=>{}, exampleMode=false}) => {
  const { sandboxState, setOverlay, updateMetadata, integrateFunction, temporaryStateManagement, openWindow, cleanFunctionDetails } = useContext(SandBoxContext)
  const { globalState } = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const handleIntegration = () => {
    if(exampleMode) return null
    if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
      setOverlay(
        <FlowOverlayPrompt
          title='Sign Up required'
          promptMsg={
          <>
            You need to sign up in order to save and integrate functions. Proceed to Sign Up?
          </>
          }
          titleIcon={<i className="fal fa-exclamation-triangle" style={{color: 'orange'}}></i>}
          proceedMsg={<><i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;Proceed</>}
          cancelMsg={<><i className="fal fa-times" style={{color: 'crimson'}}></i>&nbsp;Cancel</>}
          onProceed={() => {
            localStorage.setItem('code-initiation', 'true')
            temporaryStateManagement('save')
            window.location.href = '/register?continue=integrate'
            // let tempSandboxState = {...sandboxState}
            // tempSandboxState['overlay'] = null
            // setGlobalState({sandboxState: tempSandboxState}, () => {
            //   window.location.href = '/register?continue=integrate'
            // })
            // updateMetadata('delete', 'editorActive', false)
          }}
          onCancel={() => {
            setOverlay(null)
          }}
        />
      )
    } else {
      setIsError(false)
      if(!isLoading) {
        setIsLoading(true)
        integrateFunction(() => {
          setIsLoading(false)
          openWindow('lvl1-funclist')
          cleanFunctionDetails()
          setOverlay(
            <FlowOverlayPrompt
              title='Integration Successful'
              promptMsg={
                <>
                  The action was successfully integrated into <Agent/>. Thank you for your invaluable contribution, enabling <Agent/> with a new SUPERPOWER!! This action is now accessible to everyone under Experimental Actions category.
                </>
              }
              titleIcon={<i className="fal fa-check-double" style={{color: 'rgb(29, 191, 113)'}}></i>}
              proceedMsg={<><i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;OK</>}
              onProceed={() => {
                setOverlay(null)
              }}
              singleButton={true}
            />
          )
        }, () => {
          setIsError(true)
          setIsLoading(false)
        })
      }
    }
  }
  return (
    <FlowBasic
      title='Action Completion'
      titleIcon={<i className="fal fa-check-double"></i>}
      border={'2px solid rgba(255, 255, 255, 0.2)'}
    >
      <FlowStackedBody>
        <FlowButton 
          style={{marginLeft: '0px', marginRight: '5px'}}
          disabled={!isValid}
          onClick={() => {
            if(isValid) {
              updateMetadata('write', 'promptTesting', {
                enabled: true, 
                prompt: sandboxState?.functionDetails?.samplePrompts[0] || ''
              })
              updatePrompt(sandboxState?.functionDetails?.samplePrompts[0] || '')
            }
          }}
        >Prompt Test</FlowButton>
        {
          exampleMode ?
          null
          :
          <FlowButton 
            style={{marginRight: '5px', filter: 'brightness(110%)', color: 'rgb(29, 191, 113)'}}
            disabled={!isValid}
            onClick={() => { if(isValid) { handleIntegration() } }}
          >
            INTEGRATE
            {
              isLoading ?
              <Spinner style={{marginLeft: '5px', borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)', height: '10px', width: '10px', borderWidth: '1.5px'}}/>
              :
              null
            }
          </FlowButton>
        }
        {!isValid ? <i className="fal fa-exclamation-circle" style={{color: 'orange', marginRight: '5px'}}></i> : null}
        {isError ? <span style={{fontSize: '10px', fontFamily: 'Roboto Mono', color: 'crimson'}}>Error: Try again later</span> : null}
      </FlowStackedBody>
    </FlowBasic>
  )
}

export default FlowActionInitiator
export {FlowActionDone, FlowActionTransition}