import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet'
import './styles.scss'
import SlidingTab from '../sliding-tab'
const hljs = window.hljs

const languageTitles = {
  'syntax': 'Request Syntax',
  'bash': <><i className="fas fa-terminal"></i>&nbsp;&nbsp;curl</>,
  'javascript': <><i className="fab fa-node-js" style={{fontSize: '14px'}}></i>&nbsp;&nbsp;Node.js</>,
  'python': <><i className="fab fa-python" style={{fontSize: '14px'}}></i>&nbsp;&nbsp;Python</>
}

const CodeViewer = ({sourceCode}) => {
  const [currTab, setCurrTab] = useState(1)
  useEffect(() => {
    hljs.highlightAll()
  }, [])
  var sourceKeys = Object.keys(sourceCode)
  return (
    <>
      <Helmet>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/languages/javascript.min.js"></script>
        <script src='https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/languages/bash.min.js'></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/languages/python.min.js"></script>
      </Helmet>
      <pre>
        <SlidingTab 
          activeTab={currTab}
          onClick={(val) => {
            setCurrTab(val)
          }}
          tabs={sourceKeys.map(k => (languageTitles[sourceCode[k].language]))}
        />
        {
          sourceKeys.map(k => (
            <code key={`sv2-code-${k}`} className={sourceCode[k].className} style={currTab === Number(k) ? null: {display: 'none'}}>
              {sourceCode[k].source}
            </code>
          ))
        }
      </pre>
    </>
  )
}

export default CodeViewer