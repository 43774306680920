import React, { useEffect, useRef } from "react"

const projectComponents = {
  datablock: {
    order: 1,
    componentID: 'datablock',
    componentTitle: 'Data Pipeline',
    componentIcon: 'far fa-database',
    color: 'orange',
    description: 'This component enables you to import datasets from Sellagen and various open-source data repositories. Your training script can access the imported datasets during execution.'
  },
  compute: {
    order: 2,
    componentID: 'compute',
    componentTitle: 'Compute Instance',
    componentIcon: 'far fa-microchip',
    color: 'rgb(67, 187, 115)',
    description: 'Compute Instance connects your Python script to computational resources. Instances automatically shut down after script completes execution, and you are billed only for the utilized resources.'
  },
  autopilot: {
    order: 3,
    componentID: 'autopilot',
    componentTitle: 'Auto Pilot',
    // componentIcon: 'far fa-sparkles',
    componentIcon: 'far fa-bolt',
    // color: 'salmon',
    color: 'rgb(207, 17, 55)',
    description: 'The Auto Pilot component enables Cloud Copilot to take the pilot\'s seat. Cloud Copilot monitors the compute instance and takes pre-specified actions based on real-time execution logs.'
  },
  // evaluate: {
  //   order: 3,
  //   componentID: 'evaluate',
  //   componentTitle: 'Model Evaluation',
  //   componentIcon: 'far fa-chart-bar',
  //   color: 'salmon',
  //   description: 'The Model Evaluation component adds visualization capability and so that you can observe the model\'s performance using training, validation, or testing metrics.'
  // },
  deploy: {
    order: 4,
    componentID: 'deploy',
    componentTitle: 'Deployment Service',
    // componentIcon: 'far fa-broadcast-tower',
    componentIcon: 'far fa-rocket',
    color: 'salmon',
    description: 'The Deployment Service wraps your script within a function and exposes it to real-world applications through API endpoints. Resources automatically scale up to meet your demand.'
  }
}

const ColorPill = (props) => {
  const ref = useRef(null)
  useEffect(() => {
    ref.current.style.setProperty('--sv2-general-pill', props.color)
  }, [])
  return (
    <div className='sv2-general-pill' style={{
      ...props.color ? {color: props.color} : {},
      ...props.styles
    }}
      ref={ref}
      onClick={() => {
        if(props.onClick) {
          props.onClick()
        }
      }}
    >
      {props.children}
    </div>
  )
}

export { ColorPill, projectComponents }