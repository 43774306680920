const supportedDataTypes = [
  'int',
  'float',
  'str'
]

const functionPropStyle = {
  'int': {
    icon: 'int',
    color: 'rgb(209, 154, 102)'
  },
  'float': {
    icon: 'float',
    color: 'rgb(209, 154, 102)'
  },
  'str': {
    icon: 'str',
    color: 'rgb(152, 195, 121)'
  },
  'function': {
    icon: <i className="far fa-function"></i>,
    color: 'rgb(97, 176, 239)'
  },
  'other': {
    icon: 'other',
    color: 'rgb(229, 192, 123)'
  }
}

export default supportedDataTypes
export {functionPropStyle}