const menus = [
    {
        id: 9,
        name: 'Home',
        links: '/'
    },
    {
        id: 3,
        name: 'About',
        links: '/about'
    },
    // {
    //     id: 4,
    //     name: 'Contact',
    //     links: '/contact'
    // },
    // {
    //     id: 5,
    //     name: 'Requests',
    //     links: '/dataset-requests',
    // },
    {
        id: 6,
        name: 'Nelima',
        links: '/nelima'
    },
    // {
    //     id: 1,
    //     name: 'Buyer',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Buyer',
    //             links: '/',
    //             mode: 'buyer'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Seller',
    //             links: '/home-2',
    //             mode: 'seller'
    //         }
    //     ]
    // },
]

export default menus;