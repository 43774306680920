import React , { useEffect , useState, useContext } from 'react';
import '../scss/components/section.scss';
import {useLocation, useParams} from 'react-router-dom';
import Header from '../components/header/header.jsx';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';

import Card from '../components/cards/card-v2'
import axios from '../utilities/axios'
import { useIsMounted } from '../utilities/hooks'
import Loading from '../components/loading'
import NotFound from '../components/non-found'
import { Context } from '../contexts/GlobalContext'
import SignUpPrompt from '../components/overlay-prompts/sign-up'
import ProceedCheckout from '../components/overlay-prompts/proceed-checkout'
import Spinner from '../components/spinner'
import { Viewer } from '../components/editor'
import licenses from '../utilities/licenses'
import LicensePrompt from '../components/overlay-prompts/license';
import DataURIManager from '../components/data-uri-manager';

function ItemPage(props) {
    const { globalState, setGlobalState, isPurchased, threadLookup } = useContext(Context)
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingButton, setIsLoadingButton] = useState(false)
    const [isSampleLoadingButton, setIsSampleLoadingButton] = useState(false)
    const [error, setError] = useState(false)
    const isMounted = useIsMounted()
    const params = useParams()
    const location = useLocation()
    var contents = null

    const [product, setProduct] = useState({})
    const loadDetails = (id) => {
        axios.get(`/dataset/${id}`, {withCredentials: false}).then(response => {
            if(isMounted) {
                if(response.data?.dataset) {
                    setProduct(response.data.dataset)
                    setError(false)
                    try {
                        var promptDownload = location.state?.downloadSample
                        if(promptDownload === true) {
                            sampleDownloadHandler(id)
                        }
                    } catch (err) {
                        // do nothing
                    }
                } else {
                    console.log('Error loading data')
                    setError(true)
                }
                setIsLoading(false)
            }
        }).catch(err => {
            if(isMounted) {
                setIsLoading(false)
                setError(true)
            }
        })
    }

    const purchaseHandler = () => {
        if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {
            setGlobalState({overlayContent: <SignUpPrompt
                title={'Sign Up'}
                msg={
                    <>You need to sign up before buying or selling any data. Already have an account? <strong><a href='/login' className="sellagen-propmt-link">Sign In</a></strong> instead.</>
                }
            />})
        } else {
            setGlobalState({overlayContent: <ProceedCheckout product_id={product?._id}/>})
        }
    }

    const downloadHandler = (id) => {
        if(!isLoadingButton) {
            setIsLoadingButton(true)
            axios.post('download/get-link', {id}).then(response => {
                if(isMounted) {
					if(response.data.signedURL) {						
						window.location.assign(response.data.signedURL)
					}
					setIsLoadingButton(false)
                }
            }).catch(err => {
				if(isMounted) {
					setIsLoadingButton(false)
				}
                console.log(err)
            })
        }
    }

    const sampleDownloadHandler = (id) => {
        if(!isSampleLoadingButton) {
            setIsSampleLoadingButton(true)
            axios.post('download-sample/get-link', {id}).then(response => {
                if(isMounted) {
					if(response.data.signedURL) {
						window.location.assign(response.data.signedURL)
					}
					setIsSampleLoadingButton(false)
                }
            }).catch(err => {
				if(isMounted) {
					setIsSampleLoadingButton(false)
				}
                console.log(err)
            })
        }
    }

    const initiateThread = () => {
        const {userID} = product
        const threadMatch = threadLookup(userID?._id)
        if(threadMatch) {
            setGlobalState({showMsgPanel: true, activeThread: threadMatch, activeThreadMetadata: null})
        } else {
            setGlobalState({
                showMsgPanel: true, 
                activeThread: 'OPEN_NEW_THREAD', 
                activeThreadMetadata: {
                    buyerID: globalState?.user?._id,
                    buyerName: `${globalState?.user?.firstName} ${globalState?.user?.lastName}`,
                    sellerID: userID._id,
                    sellerName: `${userID?.firstName} ${userID?.lastName}`
                }
            })
        }
    }

    useEffect(() => {
        loadDetails(params.id)
    }, [])

    var date = null
    if(product?.createdAt) {
        date = new Date(product.createdAt).toDateString()
        date = date.split(' ').slice(1).join(" ")
    }

    if(isLoading) {
        contents = (<Loading/>)
    } else if(error) {
        contents = (<NotFound/>)
    } else {
        var purchased = isPurchased(params.id)
        var isOpenSource = product?.licenseType === 'open-source'
        contents = (
            <>
                <div className="tf-section flat-auctions-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="wrap-img">
                                    <div className="sellagen-prod-detail">
                                        <Card item={product}/>
                                        <DataURIManager datasetObject={product}/>
                                        <div className="button-place-bid" style={{width: '100%', margin: '15px 0 5px 0'}}>
                                            <div style={{
                                                width: '100%', height: '50px',
                                                borderRadius: '10px', backgroundColor: 'rgb(246, 248, 250)',
                                                display: 'flex', overflow: 'hidden'
                                            }}>
                                                <div style={{
                                                    width: '100%', height: '100%', 
                                                    display: 'flex', alignItems: 'center',
                                                    paddingLeft: '20px', fontSize: '14px', fontWeight: 700
                                                }}>
                                                    <span style={{fontWeight: 500}}>License Included -&nbsp;</span> {product?.license?.key ? licenses[product?.license?.key].title : licenses['personal-use'].title}
                                                </div>
                                                <div className='sellagen-license-info'
                                                    onClick={() => {
                                                        setGlobalState({
                                                            overlayContent: <LicensePrompt 
                                                                title={product?.license?.key ? 
                                                                    licenses[product?.license?.key].title
                                                                    :
                                                                    licenses['personal-use'].title
                                                                }
                                                                msg={product?.license?.key ?
                                                                        product?.license?.key === 'custom-license' ?
                                                                            product?.license?.details
                                                                            :
                                                                            licenses[product?.license?.key].details
                                                                    :
                                                                    licenses['personal-use'].details
                                                                }
                                                            />
                                                        })
                                                    }}
                                                >
                                                    <i className="far fa-info-circle"></i>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            product?.userID?._id !== globalState?.user?._id ? 
                                            <div className="button-place-bid" style={{width: '100%', margin: '15px 0 5px 0', display: globalState.enableMessaging ? 'block' : 'none'}}>
                                                <div style={{
                                                    width: '100%', height: '50px',
                                                    borderRadius: '10px', backgroundColor: 'rgb(246, 248, 250)',
                                                    display: 'flex', overflow: 'hidden'
                                                }}
                                                    onClick={() => {
                                                        if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {                                                        
                                                            setGlobalState({overlayContent: <SignUpPrompt
                                                                title={'Sign Up'}
                                                                msg={
                                                                    <>You need to sign up before messaging any seller. Already have an account? <strong><a href='/login' className="sellagen-propmt-link">Sign In</a></strong> instead.</>
                                                                }
                                                            />})
                                                        } else {
                                                            initiateThread()
                                                        }
                                                    }}
                                                >
                                                    <div style={{
                                                        width: '100%', height: '100%', 
                                                        display: 'flex', alignItems: 'center',
                                                        paddingLeft: '20px', fontSize: '14px', fontWeight: 700
                                                    }} 
                                                        className='sellagen-license-info'
                                                    >
                                                        Contact Seller&nbsp;&nbsp;&nbsp;<i className="far fa-envelope" style={{fontSize: '20px'}}></i>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="button-place-bid" style={{width: '100%', margin: '0 0 10px 0'}}>
											{
                                                product.sampleDatasetKey ?
                                                <button 
                                                    className={`sellagen-big-button big-sample`}
                                                    onClick={() => {
                                                        if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) {                                                        
                                                            setGlobalState({overlayContent: <SignUpPrompt
                                                                title={'Sign Up'}
                                                                msg={
                                                                    <>You need to sign up before downloading any sample data. Already have an account? <strong><a href='/login' className="sellagen-propmt-link">Sign In</a></strong> instead.</>
                                                                }
                                                            />})
                                                        } else {
                                                            sampleDownloadHandler(params.id)
                                                        }
                                                    }}
                                                >
                                                    <strong>
                                                    Download Sample
                                                    </strong> &nbsp;&nbsp;
                                                    {isSampleLoadingButton ? <Spinner/> : null}
                                                </button>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="button-place-bid" style={{width: '100%', margin: '0 0 10px 0'}}>
											<button 
												className={`sellagen-big-button big-loading`}
												onClick={() => {
                                                    if(purchased) {
                                                        downloadHandler(product._id)
                                                    } else {
                                                        if(product?.approvalStatus === 'approved') {
                                                            if(isOpenSource) {
                                                                downloadHandler(product._id)
                                                            } else {
                                                                purchaseHandler()
                                                            }
                                                        }
                                                    }
                                                }}
											>
												<strong>
												{ purchased ?
													'Download Dataset'
													:
													product?.approvalStatus === 'approved' ? 
                                                        isOpenSource ?
                                                            'Download Dataset'
                                                            :
                                                            'Purchase Dataset'
                                                        :
                                                        'Dataset Under Review'
												}
												</strong> &nbsp;&nbsp;
												{isLoadingButton ? <Spinner/> : null}
											</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="post mtop-7">
                                    <h3 className="fw-5 mt-2" style={{fontSize: '18px'}}><strong>Description</strong></h3><br/>
                                    {/* <p className="texts">{product.description}</p> */}
                                    <Viewer stringState = {product.description}/>
                                    <div className="featured-countdown">
                                        <div className="flex">
                                        <h3><span className="title-countdown">Created On:</span></h3>
                                        <h3><span className="title-countdown" style={{color: 'black', fontWeight: 500}}>{date}</span>
                                        </h3>
                                        </div>
                                    </div>
                                    {
                                        product?.donationLink ?
                                            <div className="title-countdown" style={{color: 'rgb(70,70,70)', fontSize: '14px', fontWeight: 500}}>Love this dataset?&nbsp;
                                            <div className='sv2-general-button'
                                                style={{fontWeight: 500, display: 'inline-block', width: 'content-fit', padding: '5px 10px', margin: '15px 0 30px 0'}}
                                                onClick={() => {window.open(product.donationLink, '_blank')}}
                                            >Send a Tip</div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>                                               
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    
    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            {contents}
            <br/><br/><br/><br/><br/><br/><br/><br/>
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default ItemPage