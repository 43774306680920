import React from 'react'
import './styles.scss'
import { useLockBodyScroll } from '../../utilities/hooks'

const Overlay = ({ content, close }) => {
  useLockBodyScroll()
  return (
    <>
      <div className="overlay-container">
        <div className="overlay-content">
          <span className="overlay-close" onClick={close}>
            <i className="far fa-times"></i>
          </span>
          {content}
        </div>
      </div>
    </>
  )
}

export default Overlay