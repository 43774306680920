import React, { Component } from 'react'
import { Dropdown } from 'react-bootstrap'
import geoLocations from '../../utilities/geoLocations'
import './styles.scss'

class MultiInputSelect extends Component {					// * value data structure values : { value: '', key: '' }
	constructor(props) {
		super(props);
		this.state = {
			individualInput: null,
			keyValues: this.generateKeyValues(this.props.values || []),					/// multi values are sent from server or starts from empty array
			values: this.props.values || []
		}
	}

	generateKeyValues = (values) => {
		if(values && values.length > 0) {
			var finalValue = []
			values.map((value, index) => {
				var inputValue = {
					value: value,
					key: new Date().getTime() + index
				}
				finalValue.push(inputValue)
				return null
			})
			return finalValue
		} else {
			return []
		}
	}

	remove = (unitKey) => {
		var tempKeyValues = [...this.state.keyValues]
		var tempValues = [...this.state.values]
		var valueIndex = this.state.keyValues.findIndex(n => {
			return n.key === unitKey
		})
		tempKeyValues.splice(valueIndex,1)
		tempValues.splice(valueIndex, 1)
		this.setState({keyValues: tempKeyValues, values: tempValues}, () => {
			this.writeCallback()
		})
	}

	enter = (value) => {
		let keyValues = [...this.state.keyValues]
		let values = [...this.state.values]
		var inputValue = {
			value: value,
			key: new Date().getTime()
		}
		if(!values.includes(value)) {
			keyValues.push(inputValue)
			values.push(value)
			this.setState({keyValues: keyValues, values: values}, () => {
				this.writeCallback(values)
			})
		}
	}

	writeCallback = (values) => {
		if(this.props.writeCallback) {
			return this.props.writeCallback(values);
		} else {
			return
		}
	}
	
	render() {
		var valueCollections = null;
		if(this.state.keyValues.length > 0) {
			var collcetions = this.state.keyValues.map((unit, index) => {
				return(
					<div key = {`${unit.key}`} className = 'UnitValueWrapper'>
						<div className = 'MultiValueUnit'>{unit.value}</div>
						<div className = 'UnitValueRemove'
							onClick = {() => {this.remove(unit.key)}}
						><i className="far fa-times"></i></div>
					</div>
				)
			})
			valueCollections = (
				<div className = 'ValueCollectionsWrapper' style={{marginBottom: '15px', borderRadius: '10px'}}>
					{collcetions}
				</div>
			)
		}
		var inputField = null;
		if(!this.props.lite) {
			inputField = (
				<Dropdown style={{width: '100%'}}>
					<Dropdown.Toggle id="dropdown-basic" style={{width: '100%', height: '49px', textAlign: 'left', boxShadow: 'none', borderRadius: '10px'}}>
						Select Geolocation
					</Dropdown.Toggle>
					<Dropdown.Menu 
						style={{ 
							margin: '3px 0',
							boxShadow: 'none',
							border: '1px solid rgb(240, 240, 240)',
							borderRadius: '5px',
							overflow: 'scroll',
							maxHeight: '300px',
							zIndex: 1000
						}}>
						{
							geoLocations.map((c, idx)=> (
								<Dropdown.Item  key={idx} to="#"
									className="sellagen-dropdown-item"
									onClick={() => {
										// this.props.setCategory(c)
										this.enter(c)
									}}
								>{c}
								</Dropdown.Item>
							))
						}
					</Dropdown.Menu>
				</Dropdown>
			)
		}
		return(
			<div className = 'InputContainer' style = {{flexDirection: 'column', height: 'auto', paddingBottom: '0'}}>
				{valueCollections}
				{inputField}
			</div>
		)
	}
}

export default MultiInputSelect