import React , { useState ,useEffect } from 'react';
import { Link , NavLink } from 'react-router-dom';
import logo from '../../../assets/images/logo/logo_dark.png';
import Footeritem from '../footer-item';
import '../styles.scss';

function Footer(props) {
    const {data, attribution} = props;
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <div>
            <footer id="footer" className="clearfix" style={{background: 'rgb(246, 248, 250)'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="widget widget-logo">
                            <div className="flat-box">
                                <div className="logo-footer" id="logo-footer">
                                    <NavLink to="/" className="main-logo"><img id="logo_footer" src={logo} alt="Icoland" /></NavLink>
                                </div>
                                {/* <p className="sub-widget-logo">Connect to data, all in one place. Create your own data storefront.</p> */}
                            </div>
                            <div className="widget-social style-1 mg-t32">
                                <ul>
                                    <li><a href='https://www.reddit.com/r/Sellagen/' target='_blank' rel="noreferrer"><i className="fab fa-reddit-alien"></i></a></li>
                                    <li><a href='https://twitter.com/sellageninc' target='_blank' rel="noreferrer"><i className="fab fa-twitter"></i></a></li>
                                    <li className="style-2"><a href='https://www.linkedin.com/company/sellageninc/about/' target='_blank' rel="noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {
                        data.map(item => {
                            var extra = null
                            if(attribution && item.id === 4) {
                                extra = <>
                                    <br/><br/><br/><br/>
                                    {attribution}
                                </>
                            }
                            return <Footeritem key={item.id} item={item} extra={extra}/>
                        })
                    }
                </div>
            </div>
            </footer>
            {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
        </div>
    );
}

export default Footer;