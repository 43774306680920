import React, { useRef, useEffect, useCallback, useContext } from 'react'
import './styles.scss'
import '../../components/code-viewer/styles.scss'
import logo from './sellagen_cropped.png'
import { parseCodeNText } from '../../utilities/handyFunctions'
import {AIPanel, CodePanel, LogViewer} from './ConsoleKeyComponents'
import { ConsoleContext } from '../../contexts/ConsoleContext'
import Spinner from '../../components/spinner'
import SystemPanel from '../../components/cloud-panels/system-manager'
import { projectComponents } from '../../components/cloud-panels/project-components'
import { CloudContext } from '../../contexts/CloudContext'
import { MiniDataList } from '../../components/cloud-panels/data-import'
import TabularViewer from '../../components/dataviewer/viewer'

const SpeedRun = () => {
  const {state, project, setState, handleConversation, saveScript, openPanel, editorRef, updateProject, saveLocally} = useContext(ConsoleContext)
  const {createInstance, cloudState} = useContext(CloudContext)

  const focusOnEditor = () => {
    let cmContent = editorRef?.current.editor.getElementsByClassName('cm-content')[0]
    if(cmContent) cmContent.focus()
  }

  const focusOnPrompt = () => {
    let promptInput = document.getElementById('sv2-spdrn-ai-id')
    if(promptInput) promptInput.focus()
  }

  const insertText = (code, at=null) => {
    let view = editorRef?.current?.view
    focusOnEditor()
    if(view) {
      var chunkSize = 15
      var i = 0
      var typingSpeed = 50

      const timer = setInterval(() => {
        if(i < code.length) {
          var chunk = code.substring(i, i + chunkSize)
          i += chunkSize
          dynamicInsert(view, chunk)
        } else {
          clearTimeout(timer)
        }
      }, typingSpeed)
    }
  }

  const addIndent = (value) => {
    let view = editorRef?.current?.view
    var currentIndent = ''
    try {
      var text = view.state?.doc.lineAt(view.state.selection.ranges[0].from).text
      const match = text.match(/^ +/);
      if (match) {
        var numOfCurrentIndent = match[0].length;
        currentIndent = ' '.repeat(numOfCurrentIndent)
      } else {
        currentIndent = ''
      }
    } catch(err) {
      currentIndent = ''
    }
    // todo: exclude \\n -> currently all regex matches with \\n (only intended for \n)
    if(currentIndent) {
      return String(value).replace(/\n+/g, match => match + currentIndent)
    } else {
      return value
    }
  }

  const dynamicInsert = (view, value, cursor=null) => {
    let from
    let to
    let ranges = view.state.selection.ranges[0]
    if(!cursor) {
      from = ranges.from
      to = ranges.to
    } else {
      from = cursor
      to = cursor
    }
    
    const transaction = view.state.update({
      changes: {
        from: from,
        to: to,
        insert: value,
      },
      selection: { anchor: from + value.length, head: from + value.length },
      scrollIntoView: true
    })
    if (transaction) {
      view.dispatch(transaction)
    }
  }

  const convertToDoubleSpace = (value) => {
    return String(value).replace(/(\n +)/g, (match, spaces) => {
      const halvedSpaces = ' '.repeat(spaces.length / 2);
      return `\n${halvedSpaces}`
    })
  }

  const onKeyDown = useCallback((event) => {
    if (event.key === 'k' && (event.ctrlKey || event.metaKey)) {
      if(document.activeElement?.id === 'sv2-spdrn-ai-id') {
        focusOnEditor()
      } else {
        focusOnPrompt()
      }
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false)
    return () => {
      document.removeEventListener("keydown", onKeyDown, false)
    }
  }, [onKeyDown])
  
  var updateDate = null
  if(project?.updatedAt) {
    updateDate = new Date(project.updatedAt).toLocaleDateString([], {month: 'long', day: '2-digit', year: 'numeric'})
    updateDate = `Updated on ${updateDate}`
  }
  var entryPoint = ''
  if(state?.activeEditor === 'compute') {
    entryPoint = project.mainEntryPoint
  } else if(state?.activeEditor === 'deploy') {
    entryPoint = project.deployEntryPoint
  }

  return (
    <div className='sv2-spdrn-container'>
      <div className='sv2-spdrn-sidemenu'></div>
      <div className='sv2-spdrn-body'>
        <div className='sv2-spdrn-toptool'>
          <img src={logo} className='sv2-spdrn-logo'/>
          <div className='sv2-spdrn-title'>
            <>
              {project?.projectName}
              {(state?.mainUpdated || state?.deployUpdated) &&
                <div className='sv2-spdrn-save-btn' style={{marginLeft: '10px'}} onClick={saveScript}>
                  Save
                </div>
              }
              {/* {(state.bs === 'Running') &&
                <div className='sv2-spdrn-save-btn' style={{marginLeft: '10px', color: 'rgb(29, 191, 113)', textTransform: 'capitalize'}}>
                  Deployed
                </div>
              } */}
              {
              (cloudState?.projectStatus !== 'idle' && cloudState?.projectStatus !== 'exit') &&
                <div className='sv2-spdrn-save-btn' style={{marginLeft: '10px', color: 'rgb(29, 191, 113)', textTransform: 'capitalize'}}>
                  {String(cloudState?.projectStatus).toLowerCase()}
                </div>
              }
              &nbsp;&nbsp;{state.isLoading ? <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>: null}
            </>
            <div className='sv2-spdrn-subtitle'>
              {updateDate}
            </div>
          </div>
        </div>
        <div className='sv2-spdrn-main-view'>
          <div className='sv2-spdrn-task-panel'>
            <div className='sv2-spdrn-tasks'>
              {
                project?.components?.length > 0 ?
                project.components.map(comp => 
                  <TaskUnits 
                    key={projectComponents[comp].componentID}
                    id={projectComponents[comp].componentID}
                    color={projectComponents[comp].color} 
                    icon={projectComponents[comp].componentIcon}
                    onClick={(k) => {
                      openPanel(k)
                    }}
                  />
                )
                :
                <TaskUnits color='rgb(80, 84, 90)' icon='fas fa-layer-group' willGlow={false} styles={{cursor: 'default'}}/>
              }
              {/* <div className='sv2-spdrn-taskbar-line'></div>
              {
                (cloudState?.projectStatus === 'idle' || cloudState?.projectStatus === 'exit') ?
                <TaskUnits color='rgb(29, 191, 113)' icon='fas fa-play-circle' styles={{fontSize: '31px'}}
                  onClick={() => {
                    createInstance()
                  }}
                />
                :
                <TaskUnits color='rgb(70, 74, 80)' icon='fas fa-play-circle' styles={{fontSize: '31px'}}/>
              } */}
            </div>
            <div className='sv2-spdrn-logs-section'>
              <div className='sv2-spdrn-view-logs'>
                <div className='sv2-spdrn-save-btn'
                  style={{fontFamily: 'Roboto Mono', fontWeight: 700, padding: '4px 7px'}}
                  onClick={() => {setState({logMinimized: !state.logMinimized})}}
                >logs</div>
              </div>
            </div>
          </div>
          <div className='sv2-spdrn-left-panel'>
            <div className='sv2-spdrn-run-manager'>NO DISPLAY</div>
            <div className='sv2-spdrn-code-panel'>
              {
                entryPoint && 
                <div className='sv2-spdrn-code-filename'>
                  {entryPoint}
                </div>
              }
              {
                (state.showAskScriptPrompt === 'yes') &&
                <div className='sv2-spdrn-what-script'>
                  <i className="fas fa-info-circle"></i>
                  &nbsp;&nbsp;<strong>What is the purpose of this script?</strong>
                  <span style={{float: 'right', marginRight: '5px', cursor: 'pointer'}}
                    onClick={()=> {
                      setState({showAskScriptPrompt: 'ignore'})
                    }}
                  ><i className="far fa-times"></i></span>
                  <div className='sv2-spdrn-minioptions' style={{marginTop: '5px'}}
                    onClick={() => {
                      updateProject({
                        task: 'update-component',
                        action: 'add',
                        componentID: 'compute'
                      }, () => {
                        setState({showAskScriptPrompt: 'no', activeEditor: 'compute', mainUpdated: true})
                        var config = {
                          activeComponent: state.activeComponent,
                          activeEditor: 'compute'
                        }
                        saveLocally(config)
                      })
                    }}
                  >
                    <div style={{fontWeight: 700, marginBottom: '3px', fontSize: '11px'}}>
                      <i
                        className={projectComponents['compute'].componentIcon}
                        style={{color: projectComponents['compute'].color}}
                      ></i> &nbsp;&nbsp;Train a model</div>
                    Add a Compute Instance component to the project and attach the script for execution.
                  </div>
                  <div className='sv2-spdrn-minioptions' style={{marginTop: '5px'}}
                    onClick={() => {
                      updateProject({
                        task: 'update-component',
                        action: 'add',
                        componentID: 'deploy'
                      }, () => {
                        setState({showAskScriptPrompt: false, activeEditor: 'deploy', mainUpdated: true})
                        var config = {
                          activeComponent: state.activeComponent,
                          activeEditor: 'deploy'
                        }
                        saveLocally(config)
                      })
                    }}
                  >
                    <div style={{fontWeight: 700, marginBottom: '3px', fontSize: '11px'}}>
                      <i
                        className={projectComponents['deploy'].componentIcon}
                        style={{color: projectComponents['deploy'].color}}
                      ></i> &nbsp;&nbsp;Deploy a model</div>
                    Add a Deployment Service component to the project and attach the script for serverless deployment.
                  </div>
                </div>
              }
              <AIPanel
                isLoading={ state.isPromptLoading }
                onSubmit={() => {
                  handleConversation(({role, content, someAction}) => {
                    if(content?.functionName === 'execute_action') {
                      createInstance()
                    }
                    if(content?.functionName) return
                    var {code} = parseCodeNText(content)
                    code = code.join('\n')
                    code = convertToDoubleSpace(code)
                    // todo: exclude \\n -> currently all regex matches with \\n (only intended for \n)
                    code = addIndent(code)
                    insertText(code)
                  })
                }}
                onChange={ e => { setState({prompt: e.target.value}) }}
                value={state.prompt}
              />
              {state.datasetInView && <TabularViewer config={state.datasetInView}/>}
              <CodePanel/>
              {!state.logMinimized && <LogViewer/>}
            </div>
          </div>
          <div className='sv2-spdrn-right-panel'>
            <div className='sv2-spdrn-prompt-panel'>
              {
                state.conversations.map(conv => {
                  if(conv?.content?.functionName) {
                    var functionName = conv?.content?.functionName
                    if(functionName === 'search_action') {
                      var {results} = conv?.content
                      if(results?.length > 0) {
                        return (
                          <>
                            <div className='sv2-spdrn-unit-prompt' key={`${Math.random()}`}
                              style={{lineHeight: 1, padding: '5px'}}
                            >
                              <div style={{width: '100%', padding: '5px', lineHeight: 1.5, marginBottom: '5px'}}>
                                {/* <span className='sv2-spdrn-agent-tag'></span> */}
                                {/* {conv?.role === 'user' ? <div className='sv2-agent-icons'>User</div> : <div className='sv2-agent-icons'>Assistant</div>} */}
                                <AgentIcon role={conv?.role}/>
                                I found these datasets from the Sellagen database. You can search with other key words.<br/>
                              </div>
                              <div className="sv2-manager-subblock base-content" key={`${Math.random()}`}>
                                <MiniDataList
                                  list={results}
                                />
                              </div>
                            </div>
                          </>
                        )
                      } else {
                        return (
                          <div className='sv2-spdrn-unit-prompt' key={`${Math.random()}`}>
                            {/* {conv?.role === 'user' ? <div className='sv2-agent-icons'>User</div> : <div className='sv2-agent-icons'>Assistant</div>}&nbsp; */}
                            <AgentIcon role={conv?.role}/>
                            Could not find any dataset with these keywords
                          </div>
                        )
                      }
                    } else if(functionName = 'execute_action') {
                      var {instructions} = conv?.content
                      return (
                        <div className='sv2-spdrn-unit-prompt' key={`${Math.random()}`}>
                          {/* {conv?.role === 'user' ? <div className='sv2-agent-icons'>User</div> : <div className='sv2-agent-icons'>Assistant</div>} */}
                          <AgentIcon role={conv?.role} extra='Action Taken'/>
                          {instructions.response}
                        </div>
                      )
                    } else if(functionName = 'add_dataset') {
                      var {instructions} = conv?.content
                      return (
                        <div className='sv2-spdrn-unit-prompt' key={`${Math.random()}`}>
                          {/* {conv?.role === 'user' ? <div className='sv2-agent-icons'>User</div> : <div className='sv2-agent-icons'>Assistant</div>} */}
                          <AgentIcon role={conv?.role} extra='Action Taken'/>
                          {instructions.response}
                        </div>
                      )
                    } else {
                      return null
                    }
                  }
                  let {text} = parseCodeNText(conv?.content)
                  text = text.join('')
                  // let role = conv?.role === 'user' ? "<div class='sv2-agent-icons'>User</div>" : "<div class='sv2-agent-icons'>Assistant</div>"
                  // let role = "<AgentIcon role={conv?.role}/>"
                  // text = role + text
                  text = text.replace(/```([\s\S]*?)```/g, "<code class=\"sv2-code-block\">$1</code>")
                  text = text.replace(/`([^`]+)`/g, "<span class=\"sv2-code-tag\" style=\"font-weight:500\">$1</span>")
                  return (
                    <div className='sv2-spdrn-unit-prompt' key={`${Math.random()}`}>
                      <AgentIcon role={conv?.role}/>
                      <div dangerouslySetInnerHTML={{__html: text}}></div>
                    </div>
                  )
                })
              }
            </div>
            <SystemPanel/>
          </div>
        </div>
      </div>
    </div>
  )
}

const TaskUnits = ({id, icon, color, styles, willGlow=true, onClick}) => {
  const ref = useRef(null)
  useEffect(() => {
    if(willGlow) {
      ref.current.style.setProperty('--sv2-spdrn-task-btn-clr', color)
    }
  }, [])
  return (
    <div className='sv2-spdrn-task-unit' style={{
      ...color ? {color: color} : {},
      // border: `2px solid ${color}`,
      ...styles
    }}
      ref={ref}
      onClick={() => {
        if(onClick) onClick(id)
      }}
    >
      <i className={`${icon}`}></i>
    </div>
  )
}

export default SpeedRun


const AgentIcon = ({role, extra=null}) => {
  var title = null
  var icon = null
  if(role === 'user') {
    title = 'You'
    icon = <i className="fas fa-user" style={{color: 'orange', marginRight: '5px'}}></i>
  } else {
    title = 'Copilot'
    icon = <i className="fas fa-bolt" style={{color: 'rgb(29, 191, 113)', marginRight: '5px'}}></i>
  }
  return (
    <div style={{display: 'flex'}}>
      <div className='sv2-agent-icons'>{icon}&nbsp;{title}</div>
      {
        (extra && role === 'assistant') &&
        <>
          &nbsp;&nbsp;
          <div className='sv2-agent-icons'><i className="far fa-check-double" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;{extra}</div>
          {/* <div className='sv2-agent-icons'><i className="fas fa-info-circle" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;{extra}</div> */}
        </>
      }
    </div>
  )
}