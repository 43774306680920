import React from 'react'
import './styles.scss'
import mapImage from './maps-preview.png'
import nvidiaInceptionBadge from './nvidia-inception-prg.png'
import resizeImage from './resize.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import { FunctionEntryDemo } from '../../flow-ui/function-entry'
import Banneritem from '../banner-item2'

const Banner = () => {
  return(
    <div className='sndbx-banner-container'>
      <div className='sndbx-banner-top-layer'>
      <div className='banner-msg'>
        <Swiper
          modules={[ Autoplay ]}
          spaceBetween={30}
          // navigation
          breakpoints={{
            1300: {
              slidesPerView: 1,
              spaceBetween: 30,
            }
          }}
        >
          <SwiperSlide>
            <Banneritem/>
          </SwiperSlide>
        </Swiper>
        <div className='supported-by-badge'>
          <span style={{lineHeight: 1.25}}>
            Supported <br/>By
          </span>
          <img className='inception-badge' src={nvidiaInceptionBadge}/>
        </div>
        <div className='banner-stats'>
          <div className='banner-unit-stat'>
            <span style={{fontWeight: 500, color: '', fontSize: '30px'}}>100+</span><br/><br/>
            <span>Action</span><br/>
            <span>Integrations</span>
          </div>
          <div className='banner-unit-stat'>
            <span style={{fontWeight: 500, color: '', fontSize: '30px'}}>400+</span><br/><br/>
            <span>Community</span><br/>
            <span>Members</span>
          </div>
          <div className='banner-unit-stat'>
            <span style={{fontWeight: 500, color: '', fontSize: '30px'}}>1st</span><br/><br/>
            <span>of its kind</span><br/>
            <span>Community-led</span><br/>
            <span>Initiative</span>
          </div>
        </div>
      </div>
      </div>
      <OverlayTwo/>
    </div>
  )
}

const ComponentLoop = () => {
  return (
    <Swiper className='swiper-container'
      spaceBetween={0}
      grabCursor={false}
      a11y={false}
      freeMode={true}
      speed={7000}
      loop={true}
      slidesPerView="auto"
      autoplay={{
        delay: 0.5,
        disableOnInteraction: false
      }}
      modules={[Autoplay]}
    >
      <SwiperSlide>
        <div className='swiper-slide'>
          <CodeExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <MapExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <PlotExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <TextExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <TableExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <IntegrationExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <UrlExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <SummarizeTextExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <NoteExample/>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

const ComponentLoop2 = () => {
  return (
    <Swiper className='swiper-container'
      style={{marginLeft: '100px'}}
      spaceBetween={0}
      grabCursor={false}
      a11y={false}
      freeMode={true}
      speed={7000}
      loop={true}
      slidesPerView="auto"
      autoplay={{
        delay: 0.5,
        disableOnInteraction: false
      }}
      modules={[Autoplay]}
    >
      <SwiperSlide>
        <div className='swiper-slide'>
          <NoteExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <TableExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <IntegrationExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <SummarizeTextExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <PhotoExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <MapExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          {/* <PlotExample/> */}
          <CodeExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <TextExample/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='swiper-slide'>
          <UrlExample/>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default Banner

const OverlayTwo = () => (
  <div className='banner-visuals'>
    <OverlayCutout/>
    <div className='banner-carousel-container'>
      <ComponentLoop/>
      <ComponentLoop2/>
    </div>
  </div>
)

const OverlayCutout = () => (
  <div className='banner-overlay-container'>
    <div className='cut0-shadow'></div>
    <div className='cut1-shadow'></div>
    <div className='cut2-sub-shadow'></div>
    <div className='cut0'></div>
    <div className='cut1'>
      <div className='cut1-sub'>
        <div className='cut1-sub-fill'></div>
        <div className='cut1-sub-sub'>
          <div className='cut-sub-sub-fill'></div>
          <div className='cut1-sub-sub-sub'>
            <div className='cut1-sub-sub-sub-fill'></div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const UrlExample = () => (
  <div className='banner-example-block' style={{overflow: 'hidden'}}>
    {/* <div className='msg-bubble' style={{float: 'right', borderBottomRightRadius: '7px'}}>
      <span style={{color: 'orange'}}>Read</span> this link
    </div> */}
    <div style={{fontFamily: 'Poppins', position: 'absolute', left: '0px', top: '0px', zIndex: 10, width: '120%', padding: '15px', paddingLeft: '15px'}}>
      <div className='msg-bubble' style={{
        borderRadius: '15px', maxWidth: '100%', 
        backgroundColor: 'rgb(35, 38, 45)', 
        border: '2px solid rgb(97, 176, 239)',
      }}>
        <span style={{color: 'rgb(97, 176, 239)'}}>Search</span> Golden Retriever on the web
      </div>
    </div>
    <div style={{marginTop: '75px', width: '100%', height: '233px', border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '15px', padding: '10px', overflow: 'hidden', position: 'relative'}}>
      <div style={{width: '118px', height: '89px', backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: '7px', position: 'absolute', right: '10px', padding: '10px 5px'}}>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
      </div>
      <div style={{width: '118px', height: '25px', backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: '7px', position: 'absolute', right: '10px', top: '110px'}}></div>
      <div style={{width: '55%', height: '140px', backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: '7px', padding: '10px 5px'}}>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
      </div>
      <div style={{width: '55%', height: '50%', backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: '7px', marginTop: '10px', padding: '10px 5px'}}>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
        <div className='placeholder-text'></div>
      </div>
    </div>
  </div>
)

const NoteExample = () => (
  <div className='banner-example-block'>
    <div className='msg-bubble' style={{float: 'right', borderBottomRightRadius: '7px'}}>
      <span style={{color: 'orange'}}>Add</span> this to my note
    </div>
    <div className='msg-bubble' style={{borderRadius: '15px', marginTop: '75px'}}>
      <span style={{color: 'orange'}}>Notes</span><br/>
      <div className='placeholder-text' style={{marginTop: '7px', marginBottom: '10px'}}></div>
      <div className='placeholder-text'></div>
      <div className='placeholder-text'></div>
      <div className='placeholder-text'></div>
      <div className='placeholder-text' style={{width: '100px'}}></div>
      <div style={{marginBottom: '-10px', marginLeft: '5px'}}>
        <div className='placeholder-text' style={{width: '4px', display: 'inline-block'}}></div>
        &nbsp;<div className='placeholder-text' style={{display: 'inline-block', width: '60px'}}></div>
      </div>
      <div style={{marginBottom: '-10px', marginLeft: '5px'}}>
        <div className='placeholder-text' style={{width: '4px', display: 'inline-block'}}></div>
        &nbsp;<div className='placeholder-text' style={{display: 'inline-block', width: '60px'}}></div>
      </div>
      <div style={{marginBottom: '-10px', marginLeft: '5px'}}>
        <div className='placeholder-text' style={{width: '4px', display: 'inline-block'}}></div>
        &nbsp;<div className='placeholder-text' style={{display: 'inline-block', width: '60px'}}></div>
      </div>
      <div style={{marginBottom: '-10px', marginLeft: '5px'}}>
        <div className='placeholder-text' style={{width: '4px', display: 'inline-block'}}></div>
        &nbsp;<div className='placeholder-text' style={{display: 'inline-block', width: '60px'}}></div>
      </div>
      <div style={{marginBottom: '-10px', marginLeft: '5px'}}>
        <div className='placeholder-text' style={{width: '4px', display: 'inline-block'}}></div>
        &nbsp;<div className='placeholder-text' style={{display: 'inline-block', width: '60px'}}></div>
      </div>
      <div className='placeholder-text' style={{marginTop: '10px'}}></div>
      <div className='placeholder-text'></div>
    </div>
  </div>
)

const TextExample = () => (
  <div className='banner-example-block'>
    <div className='msg-bubble' style={{float: 'right', borderBottomRightRadius: '7px'}}>
      Write an email congratulating Alice
    </div>
    <div className='msg-bubble' style={{borderBottomLeftRadius: '7px', marginTop: '100px', backgroundColor: 'rgb(66, 125, 235)'}}>
      <div className='placeholder-text' style={{width: '40px', marginTop: '7px', marginBottom: '10px'}}></div>
      <div className='placeholder-text'></div>
      <div className='placeholder-text'></div>
      <div className='placeholder-text'></div>
      <div className='placeholder-text' style={{width: '70px'}}></div>
    </div>
    <div className='msg-bubble' style={{float: 'right', borderBottomRightRadius: '7px', marginTop: '23px'}}>
      Looks great!! Now <span style={{color: 'rgb(119, 167, 255)'}}>send</span> the email.
    </div>
  </div>
)

const PlotExample = () => (
  <div className='banner-example-block'>
    <div className='y-label' style={{textAlign: 'center', fontFamily: 'Roboto Mono'}}>Profit</div>
    <div className='msg-bubble' style={{width: 'fit-content'}}>
      <span style={{color: '#f554a5'}}>Plot</span> the bar graph!
    </div>
    <br/><br/>
    <div className='result-holder'>
      <div>
        <div className='bars' style={{height: '30px'}}></div>
        <div className='bars bars-g' style={{height: '35px'}}></div>
      </div>
      <div>
        <div className='bars' style={{height: '60px'}}></div>
        <div className='bars bars-g' style={{height: '55px'}}></div>
      </div>
      <div>
        <div className='bars' style={{height: '110px'}}></div>
        <div className='bars bars-g' style={{height: '120px'}}></div>
      </div>
      <div>
        <div className='bars' style={{height: '50px'}}></div>
        <div className='bars bars-g' style={{height: '70px'}}></div>
      </div>
      <div>
        <div className='bars' style={{height: '70px'}}></div>
        <div className='bars bars-g' style={{height: '60px'}}></div>
      </div>
      <div>
        <div className='bars' style={{height: '100px'}}></div>
        <div className='bars bars-g' style={{height: '96px'}}></div>
      </div>
      <div>
        <div className='bars' style={{height: '40px'}}></div>
        <div className='bars bars-g' style={{height: '35px'}}></div>
      </div>
      <div>
        <div className='bars' style={{height: '140px'}}></div>
        <div className='bars bars-g' style={{height: '150px'}}></div>
      </div>
      <div>
        <div className='bars' style={{height: '80px'}}></div>
        <div className='bars bars-g' style={{height: '75px'}}></div>
      </div>
      <div className='grid'></div>
    </div>
    <div style={{width: '100%', textAlign: 'center', paddingTop: '5px', fontFamily: 'Roboto Mono'}}>Time</div>
  </div>
)

const MapExample = () => (
  <div className='banner-example-block'>
    <div style={{position: 'absolute', right: '30px', bottom: '30px', zIndex: 10}}>
      <div className='msg-bubble' style={{width: 'fit-content', backgroundColor: 'rgb(57, 60, 66)', borderBottomRightRadius: '7px'}}>
        If it still rains at 5 <span style={{color: 'orange'}}>schedule</span> an Uber to home
      </div>
    </div>
    <span style={{
      position: 'absolute', top: '30px', left: '30px', zIndex: 10,
      backgroundColor: 'rgb(57, 60, 66)', padding: '5px 10px', borderRadius: '10px', fontSize: '20px'}}>
      <i className="fas fa-cloud-showers"></i>
    </span>
    <div className='map-holder'>
      <img className='map-image' src={mapImage}/>
    </div>
  </div>
)

const TableExample = () => (
  <div className='banner-example-block' style={{overflow: 'hidden', fontFamily: 'Roboto Mono'}}>
    <div style={{width: '200%', border: '1px solid rgba(255, 255, 255, 0.2', borderRadius: '10px', overflow: 'hidden'}}>
    <table className='sv2-table'>
      <thead className='sv2-table-head'>
        <tr>
          <td>id</td><td>temp</td><td>humidity</td><td>time</td>
        </tr>
      </thead>
      <tbody className="sv2-table-body">
        <tr>
          <td>10001</td><td>45</td><td>62</td><td>02-29-2024</td>
        </tr>
        <tr>
          <td>10002</td><td>42</td><td>61</td><td>03-01-2024</td>
        </tr>
        <tr>
          <td>10003</td><td>49</td><td>52</td><td>03-02-2024</td>
        </tr>
        <tr>
          <td>10004</td><td>NaN</td><td>50</td><td>03-03-2024</td>
        </tr>
        <tr>
          <td>10005</td><td>54</td><td>51</td><td>03-04-2024</td>
        </tr>
        <tr>
          <td>10006</td><td>51</td><td>NaN</td><td>03-05-2024</td>
        </tr>
        <tr>
          <td>10007</td><td>48</td><td>NaN</td><td>03-06-2024</td>
        </tr>
        <tr>
          <td>10008</td><td>46</td><td>65</td><td>03-07-2024</td>
        </tr>
      </tbody>
    </table>
    </div>
    <div style={{fontFamily: 'Poppins', position: 'absolute', left: '0px', bottom: '0px', zIndex: 10, width: '120%', padding: '15px', paddingLeft: '15px'}}>
      <div className='msg-bubble' style={{
        borderRadius: '15px', maxWidth: '100%', 
        backgroundColor: 'rgb(35, 38, 45)', 
        // backgroundColor: '#D63484',
        border: '2px solid #D63484',
      }}>
        Get rid of <span style={{fontFamily: 'Roboto Mono'}}>NaN</span> values and <span style={{color: '#f8409c'}}>run</span><br/>Linear Regression on this data
      </div>
    </div>
  </div>
)

const IntegrationExample = () => (
  <div className='banner-example-block' style={{overflow: 'hidden'}}>
    <FunctionEntryDemo/>
  </div>
)

const SummarizeTextExample = () => (
  <div className='banner-example-block' style={{overflow: 'hidden'}}>
    <span style={{
      position: 'absolute', top: '30px', left: '30px', zIndex: 10,
      backgroundColor: 'rgb(57, 60, 66)', padding: '5px 10px', borderRadius: '10px', fontSize: '20px'}}>
      <i className="fal fa-file"></i>
    </span>
    <div style={{width: '100%', height: '100%', backgroundColor: 'rgb(240, 245, 250)', borderRadius: '10px', padding: '10px'}}>
      <i className="fal fa-image" style={{position: 'absolute', right: '50px', top: '22px', fontSize: '70px', color: 'rgba(0, 0, 0, 0.2)'}}></i>
      <div className='placeholder-text dark' style={{width: '180px'}}></div>
      <div className='placeholder-text dark' style={{width: '180px'}}></div>
      <div className='placeholder-text dark' style={{width: '180px'}}></div>
      <div className='placeholder-text dark' style={{width: '180px'}}></div>
      <div className='placeholder-text dark' style={{width: '180px'}}></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark' style={{width: '100px'}}></div>
      <br/>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
      <div className='placeholder-text dark'></div>
    </div>
    <div style={{position: 'absolute', right: '30px', bottom: '30px', zIndex: 10}}>
      <div className='msg-bubble' style={{width: 'fit-content', backgroundColor: 'rgb(57, 60, 66)', borderBottomRightRadius: '7px'}}>
        Summarize this document and <span style={{color: 'limegreen'}}>post</span> it on my blog
      </div>
    </div>
  </div>
)

const CodeExample = () => (
  <div className='banner-example-block' style={{overflow: 'hidden'}}>
    <div className='msg-bubble' style={{maxWidth: 'fit-content', position: 'absolute', bottom: '25px', right: '25px', borderBottomRightRadius: '10px', zIndex: 10}}>
      <span style={{color: 'rgb(97, 176, 239)', filter: 'brightness(120%)'}}>Run</span> this code!
    </div>
    <div className='ide' style={{width: '300%', height: '200%', filter: 'brightness(120%)'}}>
      <span className='def'>import</span> requests<br/><br/>
      <span className='def'>def </span><span className='func'>get_time</span>(area: str, location: str) -&gt; str:<br/>
      &nbsp;&nbsp;url = <span className='str'>f'http://worldtimeapi.org/api/timezone/</span>&#123;area&#125;<span className='str'>/</span>&#123;location&#125;<br/>
      &nbsp;&nbsp;response = requests.<span className='func'>get</span>(url)<br/>
      &nbsp;&nbsp;data = response.<span className='func'>json()</span><br/>
      &nbsp;&nbsp;<span className='def'>return</span>&nbsp;data&#91;<span className='str'>'datetime'</span>&#93;<br/>
      <br/>
      <span className='func'>get_time</span>(<span className='str'>'America'</span>, <span className='str'>'New York'</span>)
    </div>
  </div>
)

const PhotoExample = () => (
  <div className='banner-example-block'>
    <div style={{
      position: 'absolute', top: '20px', right: '15px', zIndex: 10, border: '1px solid rgba(var(--border-color))',
      backgroundColor: 'rgb(57, 60, 66)', padding: '15px 10px', borderRadius: '10px', fontSize: '20px'}}>
      <i className="far fa-edit"></i><br/><br/>
      <i className="far fa-crop"></i><br/><br/>
      <i className="far fa-magic"></i><br/>
    </div>
    <div style={{position: 'absolute', right: '20px', bottom: '20px', zIndex: 10}}>
      <div className='msg-bubble' style={{width: 'fit-content', backgroundColor: 'rgb(57, 60, 66)', borderBottomRightRadius: '7px'}}>
        <span style={{color: 'rgb(179, 104, 255)'}}>Resize</span> all the images to square shapes.
      </div>
    </div>
    <div className='map-holder' style={{width: '220px', height: '270px', border: '4px solid rgb(220, 220, 220)', borderRadius: '5px', position: 'absolute', top: '40px', left: '20px', zIndex: 3}}>
      <img className='map-image' src={resizeImage}/>
    </div>
    <div className='map-holder' style={{width: '220px', height: '270px', border: '4px solid rgb(220, 220, 220)', borderRadius: '5px', position: 'absolute', top: '30px', left: '30px', zIndex: 2}}>
      <img className='map-image' src={resizeImage} style={{filter: 'blur(5px) contrast(150%) hue-rotate(90deg)', transform: 'rotate(180deg)'}}/>
    </div>
    <div className='map-holder' style={{width: '220px', height: '270px', border: '4px solid rgb(220, 220, 220)', borderRadius: '5px', position: 'relative', zIndex: 1, marginLeft: '20px'}}>
      <img className='map-image' src={resizeImage} style={{filter: 'hue-rotate(-30deg) saturate(200%)', transform: 'scaleX(-1)'}}/>
    </div>
  </div>
)