import { useContext, useEffect, useState } from 'react'
import { getLocation } from '../../../../utilities/geoLocations'
import SandBoxContext from '../../../../contexts/SandBoxContext'
import { Context } from '../../../../contexts/GlobalContext'

const LLMContextLoader = () => {
  const {sandboxState, sandboxSetState} = useContext(SandBoxContext)
  const {globalState} = useContext(Context)

  useEffect(() => {
    try {
      if(sandboxState?.isContextEnabled) {
        contextHandler()
      } else {
        contextHandler('clear')
      }
    } catch(err) {
      contextHandler('clear')
    }
  }, [])

  useEffect(() => {
    if(sandboxState?.isContextEnabled) {
      contextHandler()
    } else {
      contextHandler('clear')
    }
  }, [sandboxState?.isContextEnabled])

  useEffect(() => {
    if(sandboxState?.isContextEnabled) {
      contextHandler()
    } else {
      contextHandler('clear')
    }
  }, [globalState?.user?.webServices?.services])

  const contextHandler = async (mode='write', loadFull=true) => {
    let contexts = {}
    if(mode === 'clear') {
      sandboxSetState({contexts})
      localStorage.removeItem('context_enabled')
      sandboxSetState({isContextLoading: false})
    } else {
      if(!sandboxState?.isContextLoading) {
        sandboxSetState({isContextLoading: true})
        if(globalState?.user?._id) {
          contexts['name'] = {
            title: 'My Name',
            key: 'name',
            value: globalState?.user?.firstName || 'Unknown',
            icon: 'fal fa-user'
          }
          contexts['email'] = {
            title: 'My Email',
            key: 'email',
            value: getEmailContext(globalState) || 'Unknown',
            icon: 'fal fa-envelope'
          }
        } else {
          delete contexts['name']
          delete contexts['email']
        }
        contexts['date'] = {
          title: 'Local Date',
          key: 'date',
          value: '[currDate]', // filled out by contextPanel state 'currDate'
          icon: 'fal fa-calendar-alt'
        }
        contexts['time'] = {
          title: 'Local Time',
          key: 'time',
          value: '[currTime]', // filled out by contextPanel state 'currTime'
          icon: 'fal fa-clock'
        }
        contexts['time-zone'] = {
          title: 'IANA Timezone',
          key: 'timezone',
          value: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Unknown',
          icon: 'fal fa-globe-americas'
        }
        try {
          var locationContext = await getLocationContext()
        } catch (error) {
          if(error.key === 'location') {
            locationContext = error
          } else {
            locationContext = {
              title: 'Location',
              key: 'location',
              value: 'Unknown',
              icon: 'fal fa-map-marker-alt'
            }
          }
        }
        contexts[locationContext.key] = locationContext
        
        // todo: load location half of the time
        
        sandboxSetState({contexts, isContextLoading: false})
        localStorage.setItem('context_enabled', 'true')
      }
    }
  }
  return null
}

const getEmailContext = (globalState) => {
  const defaultEmail = globalState?.user?.email || null
  const services = globalState?.user?.webServices?.services || []
  const integrateEmail = services.find(s => s.serviceID === 'gmail-send' && s.status === 'active')?.serviceUser || null
  return integrateEmail || defaultEmail || null
}

const getLocationContext = async () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      try {
        return navigator.geolocation.getCurrentPosition(async position => {
          let latitude = position.coords.latitude
          let longitude = position.coords.longitude
          try {
            let result = await getLocation(latitude, longitude)
            result = `${result.postcode}, ${result.city}, ${result.country}`
            resolve({
              title: 'Location',
              key: 'location',
              value: result,
              icon: 'fal fa-map-marker-alt',
              latitude: latitude || null,
              longitude: longitude || null
            })
          } catch(err) {
            console.error(err)
            reject({
              title: 'Location',
              key: 'location',
              value: 'Unknown',
              icon: 'fal fa-map-marker-alt'
            })
          }
        }, (error) => {
          console.error("Error getting geolocation:", error)
          reject({
            title: 'Location',
            key: 'location',
            value: 'Unknown',
            icon: 'fal fa-map-marker-alt'
          })
        })
      } catch (error) {
        console.log("There was an error extracting geolocation.")
        console.error(error)
        return Promise.reject({
          title: 'Location',
          key: 'location',
          value: 'Unknown',
          icon: 'fal fa-map-marker-alt'
        })
      }
    } else {
      console.error("Geolocation is not supported by this browser.")
      reject({
        title: 'Location',
        key: 'location',
        value: 'Unknown',
        icon: 'fal fa-map-marker-alt'
      })
    }
  })
}

export default LLMContextLoader