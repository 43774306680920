import React, { useContext, useEffect, useState } from "react";
import { FilePond } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import axios from "../../utilities/axios"
import { formatBytes } from "../../utilities/handyFunctions"
import { Context } from "../../contexts/GlobalContext"
import UploadSuccess from "../overlay-prompts/upload-success"

const Uploader = ({id, setBusy, disabled=false, onCompletion=null}) => {
  const [files, setFiles] = useState([])
  const { globalState, setGlobalState } = useContext(Context)

  const getSignedURL = async (fileFormat, fileSize, fileType) => {
    let payload = {
      id: id,
      format: fileFormat,
      size: fileSize,
      type: fileType
    }
    var url = ''
    try {
      url = await (await axios.post('upload/get-presigned', payload)).data.signedURL
    } catch(err) {
      console.log(err)
    }
    return url
  }

  return (
    <>
    <div style={{fontSize: 14}}>
      <br/><strong>Upload Dataset</strong> {globalState?.user?.business ? '(Optional)' : null}
      <br/><br/>
      <p>If you want to include multiple files, zip them with an archiver and upload as a single <strong>.zip</strong> or <strong>.rar</strong> file.</p>
      <br/>
    </div>
    <FilePond
      files={files}
      onupdatefiles={setFiles}
      disabled={disabled}
      allowMultiple={false}
      onaddfile={(err, file) => {
        if(err) {
          console.log('problem loading file')
        } else {
          setBusy(true)
          // file loaded and will start upload
        }
      }}
      onprocessfile={(err, file) => {
        if(err) {
          console.log('Upload error', formatBytes(file.fileSize))
        } else {
          if(onCompletion) {
            setBusy(false)
            onCompletion()
          } else {
            setBusy(false)
            setGlobalState({overlayContent: <UploadSuccess id={id}/>})
          }
        }
      }}
      server={{
        process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
          // fieldName is the name of the input field
          // file is the actual file object to send
          let extension = file.name.substring(file.name.lastIndexOf('.'))
          const url = await getSignedURL(extension, file.size, file.type)

          const request = new XMLHttpRequest()
          request.open('PUT', url)
          request.setRequestHeader('Content-Type', 'application/octet-stream')

          // Should call the progress method to update the progress to 100% before calling load
          // Setting computable to false switches the loading indicator to infinite mode
          request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total)
            // console.log(formatBytes(e.loaded))
          }
          // Should call the load method when done and pass the returned server file id
          // this server file id is then used later on when reverting or restoring a file
          // so your server knows which file to return without exposing that info to the client
          request.onload = function () {
            if (request.status >= 200 && request.status < 300) {
              load('some upload completion msg')
            } else {
              error('some error msg')
            }
          }
          request.send(file)
          return {
            abort: () => {
              request.abort()
              abort()
            }
          }
        }
      }}
      name="file"
      labelIdle='<strong>Drag & Drop your files or <span class="filepond--label-action">Browse</span></strong>'
      credits=''
    />
    </>
  )
}

const SampleUploader = ({id, setBusy, exit=false, onCompletion}) => {
  const [files, setFiles] = useState([])
  const { setGlobalState } = useContext(Context)

  const getSignedURL = async (fileFormat, fileSize, fileType) => {
    let payload = {
      id: id,
      format: fileFormat,
      size: fileSize,
      type: fileType
    }
    var url = ''
    try {
      url = await (await axios.post('upload-sample/get-presigned', payload)).data.signedURL
    } catch(err) {
      console.log(err)
    }
    return url
  }

  return (
    <>
    <div style={{fontSize: 14}}>
      <br/><strong>Upload Sample Dataset</strong> (Optional)
      <br/><br/>
      <p>The sample allows buyers to get a glimpse of the dataset.</p>
      <br/>
    </div>
    <FilePond
      files={files}
      onupdatefiles={setFiles}
      allowMultiple={false}
      allowReplace={false}
      allowRevert={false}
      onaddfile={(err, file) => {
        if(err) {
          console.log('problem loading file')
        } else {
          setBusy(true)
          // file loaded (will start uploading)
        }
      }}
      onprocessfile={(err, file) => {
        if(err) {
          console.log('Upload error', formatBytes(file.fileSize))
        } else {
          // finished uploading !!!
          if(onCompletion) {
            onCompletion()
          } else {
            if(exit) {
              setGlobalState({overlayContent: <UploadSuccess id={id}
                msg={
                  (
                    <>
                      Sample was successfully uploaded and the dataset is submitted for review. We will contact you via email while to go through the details of the dataset and getting it approved.
                      <br/><br/>Meanwhile, create a Stripe Connect account in the <a href='/dashboard'>Dashboard</a> (if you haven't already) to setup the payout process.
                    </>
                  )
                }
              />})
            }
          }
          // setSampleDone(true)
          setBusy(false)
        }
      }}
      server={{
        process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
          // fieldName is the name of the input field
          // file is the actual file object to send
          let extension = file.name.substring(file.name.lastIndexOf('.'))
          const url = await getSignedURL(extension, file.size, file.type)

          const request = new XMLHttpRequest()
          request.open('PUT', url)
          request.setRequestHeader('Content-Type', 'application/octet-stream')

          // Should call the progress method to update the progress to 100% before calling load
          // Setting computable to false switches the loading indicator to infinite mode
          request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total)
            // console.log(formatBytes(e.loaded))
          }
          // Should call the load method when done and pass the returned server file id
          // this server file id is then used later on when reverting or restoring a file
          // so your server knows which file to return without exposing that info to the client
          request.onload = function () {
            if (request.status >= 200 && request.status < 300) {
              load('some upload completion msg')
            } else {
              error('some error msg')
            }
          }
          request.send(file)
          return {
            abort: () => {
              request.abort()
              abort()
            }
          }
        }
      }}
      name="file"
      labelIdle='<strong>Drag & Drop your Sample Dataset or <span class="filepond--label-action">Browse</span></strong>'
      credits=''
    />
    </>
  )
}

const BasicUploader = ({onAddFile=null, onCompletion=null, onAbort=null}) => {
  const [files, setFiles] = useState([])
  const [key, setKey] = useState('')

  const getSignedURL = async (fileFormat, fileSize, fileType) => {
    var url = ''
    var key = ''
    try {
      fileFormat = encodeURIComponent(fileFormat)
      var response = await axios.get(`files/general/upload/${fileFormat}`)
      url = await response.data?.signedURL
      key = await response.data?.key
      if(key) setKey(key)
    } catch(err) {
      console.log(err)
    }
    return [url, key]
  }

  return (
    <>
    <FilePond
      files={files}
      onupdatefiles={setFiles}
      allowMultiple={false}
      allowReplace={false}
      allowRevert={false}
      allowImagePreview={false}
      onaddfile={(err, file) => {
        if(err) {
          console.log('problem loading file')
        } else {
          if(onAddFile) onAddFile(key)
          // file loaded (will start uploading)
        }
      }}
      onprocessfile={(err, file) => {
        if(err) {
          console.log('Upload error', formatBytes(file.fileSize))
        } else {
          // finished uploading !!!
          if(onCompletion) {
            onCompletion(key)
          }
        }
      }}
      server={{
        process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
          // fieldName is the name of the input field
          // file is the actual file object to send
          let extension = file.name.substring(file.name.lastIndexOf('.'))
          const [url, key] = await getSignedURL(extension, file.size, file.type)
          if(file.size > 262144000) {
            abort()
            if(onAbort) onAbort('File Size Exceeded 250MB')
          } else {
            const request = new XMLHttpRequest()
            request.open('PUT', url)
            request.setRequestHeader('Content-Type', 'application/octet-stream')

            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
              progress(e.lengthComputable, e.loaded, e.total)
              // console.log(formatBytes(e.loaded))
            }
            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            request.onload = function () {
              if (request.status >= 200 && request.status < 300) {
                load('Upload complete')
              } else {
                error('some error msg')
              }
            }
            request.send(file)
            return {
              abort: () => {
                request.abort()
                abort()
              }
            }
          }
        }
      }}
      name="file"
      labelIdle='<strong>Drag & Drop file or <span class="filepond--label-action">Browse</span></strong>'
      credits=''
    />
    </>
  )
}

export default Uploader
export {SampleUploader, BasicUploader}