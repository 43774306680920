import React from 'react'
import { ChonkyIconName } from 'chonky'
import Spinner from '../spinner'

const SV2IconComponent = ({ icon }) => {
  switch (icon) {
    case ChonkyIconName.loading:
      // return <i className="fal fa-spinner-third"></i>
      return <Spinner dark={true} size={13.5}/>
    case ChonkyIconName.dropdown:
      return <i className="fas fa-chevron-down"></i>
    case ChonkyIconName.placeholder:
      return <i className="fal fa-minus"></i>
    case ChonkyIconName.dndDragging:
      return <i className="fal fa-fist-raised"></i>
    case ChonkyIconName.dndCanDrop:
      return <i className="fal fa-arrow-down"></i>
    case ChonkyIconName.dndCannotDrop:
      return <i className="fal fa-times"></i>
    case ChonkyIconName.openFiles:
      return <i className="fal fa-box-open"></i>
    case ChonkyIconName.openParentFolder:
      return <i className="fal fa-level-up"></i>
    case ChonkyIconName.copy:
      return <i className="fal fa-copy"></i>
    case ChonkyIconName.paste:
      return <i className="fal fa-paste"></i>
    case ChonkyIconName.share:
      return <i className="fal fa-share-alt"></i>
    case ChonkyIconName.search:
      return <i className="fal fa-search"></i>
    case ChonkyIconName.selectAllFiles:
      return <i className="fal fa-object-group"></i>
    case ChonkyIconName.clearSelection:
      return <i className="fal fa-eraser"></i>
    case ChonkyIconName.sortAsc:
      return <i className="fal fa-sort-amount-down-alt"></i>
    case ChonkyIconName.sortDesc:
      return <i className="fas fa-sort-amount-up-alt"></i>
    case ChonkyIconName.toggleOn:
      return <i className="fas fa-sort-amount-up-alt"></i>
    case ChonkyIconName.toggleOff:
      return <i className="fal fa-toggle-off"></i>
    case ChonkyIconName.list:
      return <i className="fal fa-list"></i>
    case ChonkyIconName.compact:
      return <i className="fal fa-th-list"></i>
    case ChonkyIconName.smallThumbnail:
      return <i className="fal fa-th"></i>
    case ChonkyIconName.largeThumbnail:
      return <i className="fal fa-th-large"></i>
    case ChonkyIconName.folder:
      return <i className="fal fa-folder"></i>
    case ChonkyIconName.folderCreate:
      return <i className="fal fa-folder-plus"></i>
    case ChonkyIconName.folderOpen:
      return <i className="fal fa-folder-open"></i>
    case ChonkyIconName.folderChainSeparator:
      return <i className="fal fa-angle-right"></i>
    case ChonkyIconName.download:
      return <i className="fal fa-download"></i>
    case ChonkyIconName.upload:
      return <i className="fal fa-upload"></i>
    case ChonkyIconName.trash:
      return <i className="fal fa-trash"></i>
    case ChonkyIconName.fallbackIcon:
      return <i className="fal fa-exclamation-triangle"></i>
    case ChonkyIconName.symlink:
      return <i className="fal fa-external-link"></i>
    case ChonkyIconName.hidden:
      return <i className="fal fa-eye-slash"></i>
    case ChonkyIconName.file:
      return <i className="fal fa-file"></i>
    case ChonkyIconName.license:
      return <i className="fal fa-balance-scale"></i>
    case ChonkyIconName.code:
      return <i className="fal fa-file-code"></i>
    case ChonkyIconName.config:
      return <i className="fal fa-cogs"></i>
    case ChonkyIconName.model:
      return <i className="fal fa-cubes"></i>
    case ChonkyIconName.database:
      return <i className="fal fa-database"></i>
    case ChonkyIconName.text:
      return <i className="fal fa-file-alt"></i>
    case ChonkyIconName.archive:
      return <i className="fal fa-file-archive"></i>
    case ChonkyIconName.image:
      return <i className="fal fa-file-image"></i>
    case ChonkyIconName.video:
      return <i className="fal fa-film"></i>
    case ChonkyIconName.info:
      return <i className="fal fa-info-circle"></i>
    case ChonkyIconName.key:
      return <i className="fal fa-key"></i>
    case ChonkyIconName.lock:
      return <i className="fal fa-lock"></i>
    case ChonkyIconName.music:
      return <i className="fal fa-music"></i>
    case ChonkyIconName.terminal:
      return <i className="fal fa-terminal"></i>
    case ChonkyIconName.users:
      return <i className="fal fa-users"></i>
    case ChonkyIconName.linux:
      return <i className="fab fa-linux"></i>
    case ChonkyIconName.ubuntu:
      return <i className="fab fa-ubuntu"></i>
    case ChonkyIconName.windows:
      return <i className="fab fa-windows"></i>
    case ChonkyIconName.rust:
      return <i className="fab fa-rust"></i>
    case ChonkyIconName.python:
      return <i className="fab fa-python"></i>
    case ChonkyIconName.nodejs:
      return <i className="fab fa-node-js"></i>
    case ChonkyIconName.php:
      return <i className="fab fa-php"></i>
    case ChonkyIconName.git:
      return <i className="fal fa-code-merge"></i>
    case ChonkyIconName.adobe:
      return <i className="fas fa-file-pdf"></i>
    case ChonkyIconName.pdf:
      return <i className="fal fa-file-pdf"></i>
    case ChonkyIconName.excel:
      return <i className="fal fa-file-excel"></i>
    case ChonkyIconName.word:
      return <i className="fal fa-file-word"></i>
    case ChonkyIconName.flash:
      return <i className="fal fa-running"></i>
    case 'custom-icon-rename':
      return <i className="fal fa-pen"></i>
    case 'custom-icon-delete':
      return <i className="fal fa-trash"></i>
    case 'custom-icon-download':
      return <i className="fal fa-download"></i>
    default:
      return null
  }
}

export default SV2IconComponent