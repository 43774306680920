import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../contexts/GlobalContext'
import './styles.scss'

const UserMenu = ({unreadMsgCount}) => {
  const navigate = useNavigate()
  const { globalState } = useContext(Context)
  return (
    <>
      <div className='sv2-menu-container'>
        <div className='sv2-menu-section'>Account Details</div>
        <div className='sv2-menu-item sv2-menu-profile'
          onClick={() => navigate('/profile')}
        >
          <div className='sv2-menu-profile-text'>
            {
              globalState?.user?.business ? globalState?.user?.business : `${globalState?.user?.firstName} ${globalState?.user?.lastName}`
            }
          </div>
          <div style={{fontSize: '11px', fontWeight: 300, margin: '6px 0 5px 0'}}>
            {
              globalState?.user?.business ? 'Business Account' : 'Individual Account'
            }
          </div>
          <div 
            className='sv2-menu-profile-text'
            style={{fontSize: '11px', fontWeight: 300, margin: '5px 0'}}>
            {globalState?.user?.email || 'email'}
          </div>
        </div>
        <div className='sv2-menu-section'>More Options</div>
        <div className='sv2-menu-item'
          onClick={() => navigate('/contact')}
        >Contact Us</div>
        <div className='sv2-menu-item'
          onClick={() => navigate('/terms-n-conditions')}
        >See Terms & Conditions</div>
        <div className='sv2-menu-item'
          onClick={() => {
              navigate('/processing', {state: { mode: 'logout' }})
          }}
        >Sign Out <span style={{float: 'right'}}><i className="far fa-sign-out"></i></span></div>
      </div>
    </>
  )
}

export default UserMenu