import React, { useContext } from "react"
import './styles.scss'
import { projectComponents } from "./project-components"
import { ConsoleContext } from "../../contexts/ConsoleContext"
import { SystemWindowControls } from "./system-manager"
import { CloudContext } from "../../contexts/CloudContext"
import Spinner from "../spinner"

const DeployManager = () => {
  const deployComponent = projectComponents['deploy']
  const {project} = useContext(ConsoleContext)
  const {cloudState, deployService, deleteDeployService, fetchStatus, updateDeployService, getDeploymentEndpoint} = useContext(CloudContext)

  var deployButton = null
  if(cloudState.services?.deploymentStatus === 'idle') {
    deployButton = (
      <div className="sv2-manager-subblock base-content base-add-ds-btn" onClick={deployService}>
        {
          cloudState.isCloudLoading ?
          <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>
          :
          'Deploy Function'
        }
      </div>
    )
  } else if(cloudState.services?.deploymentStatus === 'deployed') {
    deployButton = (
      <div className="sv2-manager-subblock base-content base-add-ds-btn" onClick={updateDeployService}>
        {
          cloudState.isCloudLoading ?
            <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>
            :
            'Update Function'
        }
      </div>
    )
  }
  var deployEndpoint = null
  if(cloudState?.services?.deploymentStatus === 'deployed') {
    deployEndpoint = (
      <>
        <div className="sv2-manager-instance-details">Endpoint</div>:&nbsp;
        {
          cloudState.deploymentUri ? 
          <div className="sv2-endpoint-viewer"
            onClick={() => {
              navigator.clipboard.writeText(cloudState.deploymentUri+'/run').then(() => {
                alert('Function Endpoint Copied to Clipboard')
              }).catch(err => {
                console.log(err)
              })
            }}
          ><i className="fas fa-copy"></i>&nbsp;{cloudState.deploymentUri+'/run'}</div>
          :
          <div className="sv2-cloud-misc-action-button misc-alt"
            onClick={getDeploymentEndpoint}
          >Get Function Endpoint</div>
        }<br/>
      </>
    )
  }
  return (
    <div className='sv2-cloud-base-manager'>
      <div className='sv2-manager-subblock sv2-manager-title'>
        <i className={deployComponent.componentIcon} style={{color: deployComponent.color}}></i>&nbsp;&nbsp;{deployComponent.componentTitle}
        &nbsp;&nbsp;<div 
          className="sv2-cloud-misc-action-button"
          onClick={() => {
            fetchStatus()
          }}
          style={{float: 'right', padding: '4px', fontSize: '14px', borderRadius: '20px'}}
        ><i className="far fa-sync-alt"></i></div>
        <SystemWindowControls/>
      </div>
      <div className="sv2-manager-subblock base-content">
        <div className="sv2-base-content-title">Deployment Environment</div>
        <div className="sv2-manager-data-list">
          <div className="sv2-manager-data-card sv2-dc-no-hov">
            {
              project?.mainEntryPoint &&
              <div className="sv2-mgr-dc-title" style={{width: '100%', fontSize: '14px', overflow: 'visible', marginBottom: '5px'}}>
                Serverless Function&nbsp;&nbsp;<span className="sv2-manager-filename" style={{color: 'rgb(250, 128, 114)', backgroundColor: 'rgb(250, 128, 114, 0.15)'}}>{project.deployEntryPoint}</span>
              </div>
            }
            <div className="sv2-mgr-dc-body" style={{width: '100%', marginTop: '0px', lineHeight: 1.5}}>
              <div className="sv2-manager-instance-details">Language</div>:&nbsp;Python3<br/>
              <div className="sv2-manager-instance-details">Version</div>:&nbsp;3.10<br/>
              <div className="sv2-manager-instance-details">Datasource</div>:&nbsp;API Request Body<br/>
              <BuildUploader/>
            </div>
          </div>
        </div>
      </div>
      <div className="sv2-manager-subblock base-content">
        <div className="sv2-base-content-title">Spcifications</div>
        <div className="sv2-manager-data-list">
          <div className="sv2-manager-data-card sv2-dc-no-hov">
            <div className="sv2-mgr-dc-body" style={{width: '100%', marginTop: '0px', lineHeight: 1.5}}>
              <div className="sv2-manager-instance-details">Type</div>:&nbsp;Serverless<br/>
              <div className="sv2-manager-instance-details">Accelerator</div>:&nbsp;Nvidia T4<br/>
              <div className="sv2-manager-instance-details">Number of Acc.</div>:&nbsp;1<br/>
              <div className="sv2-manager-instance-details">Disk Size</div>:&nbsp;10 GB<br/>
              <div className="sv2-manager-instance-details">Max Runtime</div>:&nbsp;15 mins<br/>
            </div>
          </div>
        </div>
      </div>
      {
        cloudState?.services?.latestDeployment &&
        <div className="sv2-manager-subblock base-content">
          <div className="sv2-base-content-title">
            Deployment Endpoint&nbsp;&nbsp;{cloudState?.updatedDeploy === 'updated' && <i style={{color: 'rgb(29, 191, 113)'}} className="far fa-check-double"></i>}
            <div 
              className="sv2-cloud-misc-action-button" 
              style={{float: 'right'}}
              onClick={() => {deleteDeployService()}}
            >Delete Service</div>
          </div>
          <div className="sv2-manager-data-list">
            <div className="sv2-manager-data-card sv2-dc-no-hov">
              <div className="sv2-mgr-dc-body" style={{width: '100%', marginTop: '0px', lineHeight: 1.5}}>
                {deployEndpoint}
                <div className="sv2-manager-instance-details">Status</div>:&nbsp;<span style={{textTransform: 'capitalize'}}>{cloudState?.services?.deploymentStatus}</span><br/>
                <div className="sv2-manager-instance-details">Method</div>:&nbsp;POST<br/>
                <div className="sv2-manager-instance-details">Version</div>:&nbsp;1<br/>
              </div>
            </div>
          </div>
        </div>
      }
      {deployButton}
    </div>
  )
}

export default DeployManager

const BuildUploader = () => {
  return (
    <div className="sv2-dc-serve-file">
      <div className="sv2-dc-basic-tag">Serve a directory</div>
      <div className="sv2-dc-basic-tag" style={{backgroundColor: 'transparent', fontFamily: 'Roboto Mono'}}>build.zip</div>
      <div style={{display: 'flex', marginTop: '5px'}}>
        <div style={{padding: '0 5px 0 0'}}><i className="fas fa-info-circle"></i></div>&nbsp;
        <div>Attach the build file to serve on the base path. This feature enables you to deploy your website files quickly and efficiently, ensuring a ready-to-go deployment.</div>
      </div>
    </div>
  )
}