import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Spinner from '../components/spinner'
import { Context } from '../contexts/GlobalContext'

const Processing = (props) => {
  const location = useLocation()
  var mode = null
  var view = null
  const { logOutUser } = useContext(Context)

  try {
    mode = location.state?.mode
  } catch(err) {
    mode = null
  }

  useEffect(() => {
    if(mode === 'logout') {
      logOutUser()
    }
  }, []);

  if(mode === 'logout') {
    view = <>
      <h3 style={{color: 'rgb(200, 200, 200)'}}>Signing Out</h3>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Spinner style={{height: '24px', width: '24px'}}/>
    </>
  } else {
    view = <Spinner style={{height: '24px', width: '24px'}}/>
  }
  return(
    <div style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      {view}
    </div>
  )
}

export default Processing