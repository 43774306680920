
const dataFooter = [
    {
        id: 1,
        title: '',
        classAction : 'style-1',
        listlink: [
            {
                id: 1,
                text: '',
                link: '#'
            },
            {
                id: 3,
                text: '',
                link: '#'
            }
        ]
    },
    {
        id: 2,
        title: 'Company',
        classAction : 'style-2',
        listlink: [
            {
                id: 1,
                text: 'About',
                link: '/about'
            },
            {
                id: 4,
                text: 'Contact',
                link: '/contact'
            },
        ]
    },
    {
        id: 3,
        title: 'Resources',
        classAction : 'style-3',
        listlink: [
            {
                id: 3,
                text: 'Terms & Conditions',
                link: '/terms-n-conditions'
            },
            {
                id: 4,
                text: 'Privacy',
                link: '/privacy'
            },
            {
                id: 5,
                text: 'Disclosure',
                link: '/limited-use-disclosure'
            }
        ]
    },
    // {
    //     id: 4,
    //     title: 'My Account',
    //     classAction : 'style-4',
    //     listlink: [
    //         {
    //             id: 3,
    //             text: 'Dashboard',
    //             link: '/dashboard'
    //         },
    //         {
    //             id: 4,
    //             text: 'List a Dataset',
    //             link: '/createItem'
    //         },
    //     ]
    // },
]

export default dataFooter;