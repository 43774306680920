import Agent from "../../title"
var originName = 'https://sellagen.com/'
if(process.env.NODE_ENV==='development') {
  originName = 'http://localhost:4000/'
}

const supportedServices = {
  'Email (Gmail)': {
    serviceID: 'gmail-send',
    title: 'Add Email Service',
    icon: 'fal fa-mailbox',
    serviceKey: 'Email (Gmail)',  // same as the key of the object
    value:
      <>
        Adding the Email Service allows <Agent/> to send emails from your email address. <br/><br/>
        These are the list of information we will collect from Google regarding Gmail integration and sent to 3rd Party Apps.<br/>
        <ul style={{marginTop: '5px', marginBottom: '15px', marginLeft: '15px'}}>
          <li style={{listStyleType: 'disc'}}>Email ID</li>
        </ul>
        Please note that we do not collect or access the contents of your emails or inbox. This service is strictly limited to sending emails on your behalf. Also refer to <a href="/privacy" style={{color: 'rgb(29, 191, 113)'}} target='_blank'>Privacy Policy</a> and <a href="/limited-use-disclosure" style={{color: 'rgb(29, 191, 113)'}} target='_blank'>Limited Use Disclosure</a> for more information.
        <br/>
      </>,
    link: originName+'api/service/initiate/nelima/gmail-send',
    method: 'oauth2',
    consent: <>I'm aware that by enabling Gmail integration, my email address will be sent to 3rd party apps.</>,
    extra: <>Nelima can now send emails through your Gmail account. You can disable this access whenever you want.</>
  }
}

const getRemainingServices = (a, b) => {
  const filteredB = b.filter((element) => !a.includes(element))
  return filteredB
}

const getCompatibleServices = (integratedServices = []) => {
  let supportedServiceKeys = Object.keys(supportedServices)
  let compatibleServices = []
  supportedServiceKeys.forEach(key => {
    if(!integratedServices.includes(supportedServices[key].serviceID)) {
      compatibleServices.push(key)
    }
  })
  return compatibleServices
}

const getServiceWithID = (serviceID) => {
  if(!serviceID) return null
  let supportedServiceVals = Object.values(supportedServices)
  return supportedServiceVals.find(val => val.serviceID === serviceID) || null
}

export default supportedServices

export {getRemainingServices, getCompatibleServices, getServiceWithID}