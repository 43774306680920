import React from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';

const LimitedUseDisclosure = (props) => {
  return (
      <div className='wrapper-style' id='wrapper'>
          {<Header />}
          <div className='sellagen-fact'>
              <h2 className="sellagen-fact-title"><strong>Limited Use Disclosure</strong></h2>
              <br/>
              <p>Sellagen's use and transfer of information received from Google APIs to any other app will adhere to <a style={{color: 'rgb(29, 191, 113)'}} href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes' target='_blank'>Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
              <br/>
              <br/>
          </div>
          {<Footer data={dataFooter}/>}
          {<Bottom classection='widget-social-footer'/>}
      </div>
  );
}

export default LimitedUseDisclosure