import React, { useState } from 'react'
import '../../../components/cloud-panels/styles.scss'

const RightPanel = (props) => {
  return(
    <div className='sv2-cloud-base-manager' style={{padding: '0', height: '100%'}}>
      {props.children}
    </div>
  )
}

const RightPanelBasicTitle = (props) => {
  return(
    <div className='sv2-manager-subblock sv2-manager-title'  style={{backgroundColor: 'transparent', borderTopRightRadius: '10px', height: '60px', padding: '20px 20px 0 20px', marginBottom: '10px'}}>
      {props.children}
    </div>
  )
}

const RightPanelBasicBlock = (props) => {
  var someColor = 'rgb(48, 53, 62)'
  return(
    <>
      <div className="sv2-manager-subblock base-content" style={{backgroundColor: someColor}}>
        <div className="sv2-manager-data-list">
          <div className="sv2-manager-data-card" style={{backgroundColor: someColor}}>
            {
              props?.title ?
              <div className="sv2-mgr-dc-title" style={{width: '100%'}}>
                {props.title}
              </div>
              :
              null
            }
            {
              props.children ?
              <div className="sv2-mgr-dc-body" style={{width: '100%', marginTop: '5px', lineHeight: 1.4}}>
                {props.children}
              </div>
              :
              null
            }
          </div>
        </div>
      </div>
    </>
  )
}

const RightPanelNoTitleBasicBlock = (props) => {
  var someColor = 'rgb(48, 53, 62)'
  return(
    <>
      <div className="sv2-manager-subblock base-content" style={{backgroundColor: someColor}}>
        <div className="sv2-manager-data-list">
          <div className="sv2-manager-data-card" style={{backgroundColor: someColor, paddingTop: '5px'}}>
            <div className="sv2-mgr-dc-body" style={{width: '100%', marginTop: '5px', lineHeight: 1.4}}>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const RightPanelBasicButton = (props) => {
  return (
    <div className="sv2-manager-subblock base-content base-add-ds-btn" style={{backgroundColor: 'rgb(48, 53, 62)', marginTop: '5px'}}
      onClick={() => {
        if(props.onClick) {
          props.onClick()
        }
      }}
    >
      {props.children}
    </div>
  )
}

const InfoToolTip = ({children=null, icon=null, position='', style={}}) => {
  var modifier = ''
  if(position === 'top') { modifier = ' top' }
  return(
    <div className='sv2-cloud-info-tooltip-container' style={{...style}}>
      {icon ? icon : <i className="fas fa-info-circle"></i>}
      <div className={`sv2-cloud-info-tooltip-body${modifier}`}>
        {children}
      </div>
    </div>
  )
}

const PanelDropDown = ({funcObjs = [], selected = null, onCheck = () => {}, onSelection = () => {}}) => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <div className='sv2-cloud-dropdown'>
      <div className='block' onClick={() => {
        onCheck()
        setShowMenu(!showMenu)
      }}>
        {
          selected ?
          <><i className="fas fa-sort-down"></i><span style={{float: 'right', color: 'rgb(29, 191, 113)'}}>{selected}</span></>
          :
          <><i className="fas fa-sort-down"></i><span style={{float: 'right'}}>Select Function</span></>
        }
      </div>
      {showMenu ? 
        funcObjs.length > 0 ?
          funcObjs.map((elem, idx) => (
            <div className='block option' key={`${Math.random()}-${idx}`}
              style={elem.name === selected ? {color: 'rgb(29, 191, 113)'} : {}}
              onClick={() => {
                onSelection(elem)
                setShowMenu(false)
              }}
            >
              {elem?.name || 'undefined'}
            </div>
          ))
          :
          <div className='block'>
            <span style={{float: 'right'}}>None</span>
          </div>
        :
        null
      }
    </div>
  )
}

export {RightPanel, RightPanelBasicBlock, RightPanelBasicButton, RightPanelBasicTitle, InfoToolTip, RightPanelNoTitleBasicBlock, PanelDropDown}